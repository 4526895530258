import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, CardHeader, Container, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Select, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../../assets/images/bell.png'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../../components/MyPagination';
import { MdHistory } from 'react-icons/md';
import coupon from '../../../assets/images/coupon.png'
import { IoBarChartSharp } from 'react-icons/io5';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import ReactSelect from 'react-select';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function GestCharges() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { user } = useStateContext();
    const toast = useToast()
    const [id, setId] = useState(null);
    const cancelRef = React.useRef()
    const [date, setDate] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const [productsNames, setProductsNames] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedProductFilter, setSelectedProductFilter] = useState('');

    const onOpenDrawer = () => {
        setIsDrawerOpen(true);
    };
    const onCloseDrawer = () => {
        setIsDrawerOpen(false);
    };
    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [changeModal, setChangeModal] = useState(true)
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState()
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)


    const [chargeData, setChargeData] = useState();
    const [chargeId, setChargeId] = useState('');

    const [selectedCategory, setSelectedCategory] = useState("");

    const [selectedOption, setSelectedOption] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState();
    const [dateCharge, setDateCharge] = useState();

    const [chargesStats, setChargesStats] = useState();

    const labels = [t("Livraison"), t("Marqueting"), t("Salaires"), t("Frais de stockage"), t("Frais d'emballage"), t("Frais de maintenance"), t("Frais d'assurance"), t("Frais de service client"), t("Frais de matériel")];

    const dataChart = {
        labels: labels,
        datasets: [
            {
                label: t("Charges"),
                data: [chargesStats?.livraison,
                chargesStats?.marketing,
                chargesStats?.salaires,
                chargesStats?.fraisStockage,
                chargesStats?.fraisEmballage,
                chargesStats?.fraisMaintenance,
                chargesStats?.fraisAssurance,
                chargesStats?.fraisServiceClient,
                chargesStats?.fraisMateriel],
                backgroundColor: [
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(91, 255, 76, 0.2)",
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(91, 255, 76, 0.2)",
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                ],
                borderColor: [
                    "rgb(54, 162, 235)",
                    "rgba(153, 102, 255)",
                    "rgba(91, 255, 76)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgba(153, 102, 255)",
                    "rgba(91, 255, 76)",
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Charges Chart',
                font: {
                    size: 16,
                },
            },
            legend: { display: false }
        },
    };
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    const [currentPage, setCurrentPage] = useState(0);

    //////////////////////////////////////////////////////////////////////////////////
    const fetchProductsNames = async (userId) => {
        axiosClient.get(`/products/get_All_Products_names/?client=${userId}`)
            .then((response) => {
                const products = response.data.map(product => ({
                    value: product.id,
                    label: product.title
                }));
                setProductsNames(products);
                setProducts(response.data);
            })
            .catch((error) => {
                console.error(error);

            });
    };

    function empty(){
        setTitle()
        setDescription()
        setAmount()
        setDateCharge()
        setSelectedOption()
        setSelectedProduct()
        setMessage()
    }
    //get the data
    useEffect(() => {

        axiosClient.get(`/charges/getAllCharges/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&category=${selectedCategory}&client=${user.id}&date=${date}&product=${selectedProductFilter}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

        axiosClient.get(`/charges/getCharges/?client=${user.id}`)
            .then((response) => {
                setChargesStats(response.data)
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);


            });
        fetchProductsNames(user?.id)
    }, [pageSize, currentPage, searchTerm, selectedCategory, date,selectedProductFilter])

    function update() {

        axiosClient.get(`/charges/getAllCharges/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&category=${selectedCategory}&client=${user.id}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

        axiosClient.get(`/charges/getCharges/?client=${user.id}`)
            .then((response) => {
                setChargesStats(response.data)
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);

            });

        fetchProductsNames(user?.id)
    }

    const AjoutCharge = () => {
        if (!title || title.trim() === "") {
            setMessage(t("Veuillez remplir le titre"))
            return;
        }
        if (!description || description.trim() === "") {
            setMessage(t("Veuillez remplir la description"))
            return;
        }
        const formData = new FormData()
        formData.append("title", title)
        formData.append("description", description)
        formData.append("category", selectedOption)
        formData.append("amount", amount)
        formData.append("date_charge", dateCharge)
        if(selectedProduct) formData.append("product", selectedProduct.value)
        formData.append("added_by", user.id)

        axiosClient.post('/charges/', formData)
            .then((response) => {
                setData([response.data, ...data]);
                empty()
                update()
                toast({
                    description: t(`La charge est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
        update()
    }

    const UpdateCharge = (id) => {

        const formData = new FormData();
        if (title) formData.append("title", title)
        if (description) formData.append("description", description)
        if (selectedOption) formData.append("category", selectedOption)
        if (amount) formData.append("amount", amount)
        if (dateCharge) formData.append("date_charge", dateCharge)
        if(selectedProduct) formData.append("product", selectedProduct.value)
        axiosClient.patch(`/charges/${id}/`, formData).then(() => {

            update()

            toast({
                description: t(`La charge est modifiée avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            empty()
        })

        onClose()
    }
    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/charges/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                // setNotesData(notesData.filter((row) => row.id !== id));
                toast({
                    description: t("la charge est supprimée avec succès"),
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }
    const chargeColor = useColorModeValue('blue.700', 'blue.500')
    const colorbg = useColorModeValue('white', '#2d3748')

    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')
    return (
        <Box position={"relative"}>
            <Flex gap={2} direction={'column'} zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        onOpenDrawer()
                    }}
                    colorScheme='teal'
                >
                    <IoBarChartSharp size={18} />

                </Button>
                <Button

                    onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Charges")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>

            <Flex
                px={2}
                py={5}
                mx={{ base: 1, md: 5 }}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={{ base: 5, md: 2 }}
                mb={5}
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        justify={'center'}
                        gap={5}
                        direction={{ base: 'column', md: 'row' }}
                        mb={4}
                        w='100%'
                    >
                        <Flex gap={3} w={{ base: '100%', lg: '60%' }} direction={{ base: 'column', md: 'row' }}>
                            <Select borderRadius="5" w={{ base: '100%', lg: '30%' }} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                <option value="">{t("toutes les options")}</option>
                                <option value="Livraison">{t("Livraison")}</option>
                                <option value="Marketing">{t("Marketing")}</option>
                                <option value="Salaires">{t("Salaires")}</option>
                                <option value="FraisDeStockage">{t("Frais de stockage")}</option>
                                <option value="FraisDemballage">{t("Frais d'emballage")}</option>
                                <option value="FraisDeMaintenance">{t("Frais de maintenance")}</option>
                                <option value="FraisDassurance">{t("Frais d'assurance")}</option>
                                <option value="FraisDeServiceClient">{t("Frais de service client")}</option>
                                <option value="FraisDeMatériel">{t("Frais de matériel")}</option>
                            </Select>
                            <Select  w={{ base: '100%', lg: '30%' }} rounded={"md"} value={selectedProductFilter} onChange={(e) => setSelectedProductFilter(e.target.value)}>
                                    <option value="">{t("Chosissez un produit")}</option>
                                    {products.map((product) => (
                                        <option value={product?.id}>{product?.title}</option>

                                    ))}
                                </Select>
                            <Input onChange={e => setDate(e.target.value)} w={{ base: '100%', lg: '30%' }} placeholder='Select Date and Time' size='md' type='month' />
                        </Flex>

                        <InputGroup w={{ base: '100%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius={"5"}
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}

                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table variant='simple' >
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>{t("Titre")}</Th>
                                    <Th borderBottomColor='gray.400'>Description</Th>
                                    <Th borderBottomColor='gray.400'>{t("Catégorie")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Montant")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Date de charge")}</Th>
                                    <Th borderBottomColor='gray.400'>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}
                                {((!searchTerm && !selectedCategory && !date) && paginationData?.length === 0) ? (
                                    <Tr>
                                        <Td colSpan={11}>{t("Il n'y a pas de charges à afficher")}</Td>
                                    </Tr>) :
                                    <>
                                        {(paginationData?.length !== 0) &&
                                            data.map((row) => (
                                                <Tr p={0} >
                                                    <Td>{row.title}</Td>
                                                    <Td><Flex flexDirection='row' gap={1}>
                                                        {row.description.length > 0 ?
                                                            <>
                                                                <Text>{row.description.substring(0, 20)} {row.description.length > 20 && <>...</>}</Text>
                                                                <Popover>
                                                                    <PopoverTrigger >
                                                                        <Text cursor='pointer' _hover={{ color: 'lightgray' }}> {row.description.length > 20 && <>{t("(voir plus)")}</>} </Text>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent >
                                                                        <PopoverArrow />
                                                                        {/* <PopoverCloseButton /> */}
                                                                        <PopoverBody>
                                                                            <Textarea readOnly border={"none"} resize='none' >
                                                                                {row.description}
                                                                            </Textarea>
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </>

                                                            :
                                                            <Text color={'gray.400'}>{t("Non disponible")}</Text>



                                                        }

                                                    </Flex>
                                                    </Td>
                                                    <Td>{row.category === "Livraison" ? t("Livraison")
                                                        : row.category === "Marketing" ? t("Marketing")
                                                            : row.category === "Salaires" ? t("Salaires")
                                                                : row.category === "FraisDeStockage" ? t("Frais de stockage")
                                                                    : row.category === "FraisDemballage" ? t("Frais d'emballage")
                                                                        : row.category === "FraisDeMaintenance" ? t("Frais de maintenance")
                                                                            : row.category === "FraisDassurance" ? t("Frais d'assurance")
                                                                                : row.category === "FraisDeServiceClient" ? t("Frais de service client")
                                                                                    : row.category === "FraisDeMatériel" ? t("Frais de matériel")
                                                                                        : "non-défini"}</Td>
                                                    <Td fontWeight={"bold"} fontSize={"xl"} color={chargeColor}>{row.amount}</Td>
                                                    <Td>{new Date(row.date_charge).toLocaleDateString(t("fr-FR"))}</Td>

                                                    <Td>
                                                        <Menu >
                                                            <MenuButton
                                                                colorScheme='cyan'
                                                                as={IconButton}
                                                                aria-label='Options'
                                                                icon={<HamburgerIcon />}
                                                                variant='outline'
                                                            />
                                                            <MenuList>
                                                                <MenuItem onClick={() => {
                                                                    onOpen()
                                                                    setChangeModal(false)
                                                                    setChargeData(row)
                                                                    setChargeId(row.id)
                                                                }} icon={<EditIcon />} >
                                                                    {t("Modifier")}
                                                                </MenuItem>
                                                                <MenuItem onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }} icon={<DeleteIcon />} >
                                                                    {t("Supprimer")}
                                                                </MenuItem>
                                                                <MenuItem icon={<MdHistory />} >
                                                                    {t("Historique")}
                                                                </MenuItem>

                                                            </MenuList>
                                                        </Menu>

                                                        {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") && <IconButton
                                                                ml={5}
                                                                size="sm"
                                                                onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }}
                                                                colorScheme='red'
                                                                icon={<DeleteIcon />}>
                                                                {t("Supprimer")}
                                                            </IconButton>
                                                            } */}

                                                    </Td>
                                                </Tr>
                                            ))}
                                    </>}



                                {((searchTerm && paginationData?.length === 0) || (selectedCategory && paginationData?.length === 0) || (date && paginationData?.length === 0) || (checkLengthData)) && (

                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune charge correspondante n'a été trouvée.")}</Td>
                                    </Tr>

                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select>
                    </Flex>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                </Stack>
            </Flex>


            {changeModal ?
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    empty()
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'une charge")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <Flex w="100%" gap={2} mb={3}>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Titre")}</FormLabel>
                                            <Input value={title} onChange={(e) => setTitle(e.target.value)} maxLength={50} name="Titre" placeholder={t('Titre de charge...')} />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>{t("Type de charge")}</FormLabel>
                                            <Select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                                <option value="">{t("toutes les options")}</option>
                                                <option value="Livraison">{t("Livraison")}</option>
                                                <option value="Marketing">{t("Marketing")}</option>
                                                <option value="Salaires">{t("Salaires")}</option>
                                                <option value="FraisDeStockage">{t("Frais de stockage")}</option>
                                                <option value="FraisDemballage">{t("Frais d'emballage")}</option>
                                                <option value="FraisDeMaintenance">{t("Frais de maintenance")}</option>
                                                <option value="FraisDassurance">{t("Frais d'assurance")}</option>
                                                <option value="FraisDeServiceClient">{t("Frais de service client")}</option>
                                                <option value="FraisDeMatériel">{t("Frais de matériel")}</option>
                                            </Select>
                                        </FormControl>
                                    </Flex>


                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Description")}</FormLabel>
                                        <Textarea resize={"none"} value={description} onChange={(e) => setDescription(e.target.value)} maxLength={250} name="Description" placeholder={t('Description de charge...')} />
                                    </FormControl>

                                    <Flex mb={3} w={"100%"} gap={2}>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Montant")}</FormLabel>
                                            <Input value={amount} onChange={(e) => setAmount(e.target.value)} maxLength={50} name="Montant" type='number' placeholder={t('Montant de charge...')} />
                                        </FormControl>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Date de charge")}</FormLabel>
                                            <Input value={dateCharge} onChange={(e) => setDateCharge(e.target.value)} name="Date" type='date' />
                                        </FormControl>
                                    </Flex>

                                    <FormControl  >
                                        <FormLabel>{t("Produit")}</FormLabel>
                                        {/* <Select rounded={"md"} value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                            <option value="">Chosissez un produit</option>
                                            {productsNames.map((product) => (
                                                <option value={product?.id}>{product?.title}</option>

                                            ))}
                                        </Select> */}
                                        <ReactSelect
                                            onChange={setSelectedProduct}
                                            className="basic-single"
                                            placeholder={t("Chosissez un produit")}
                                            defaultValue={selectedProduct}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="product"
                                            options={productsNames}
                                            styles={{

                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',
                                                    borderColor:'gray.200'

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                option: (provided, state) => ({
                                                    backgroundColor: colorbg,

                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },

                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                }),

                                            }}
                                        />
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!title || !description || !selectedOption || !amount || !dateCharge)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutCharge}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }


                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    // setMessage('')
                    // setSelectedCible('')
                    // setTitle('')
                    // setContent('')
                    // setSelectedCible('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Modification de charge")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <Flex  gap={2} mb={3}>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Titre")}</FormLabel>
                                            <Input defaultValue={chargeData.title} onChange={(e) => setTitle(e.target.value)} maxLength={50} name="Titre" placeholder={t('Titre de charge...')} />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>{t("Type de charge")}</FormLabel>
                                            <Select defaultValue={chargeData.category} onChange={(e) => setSelectedOption(e.target.value)}>
                                                <option value="">{t("toutes les options")}</option>
                                                <option value="Livraison">{t("Livraison")}</option>
                                                <option value="Marketing">{t("Marketing")}</option>
                                                <option value="Salaires">{t("Salaires")}</option>
                                                <option value="FraisDeStockage">{t("Frais de stockage")}</option>
                                                <option value="FraisDemballage">{t("Frais d'emballage")}</option>
                                                <option value="FraisDeMaintenance">{t("Frais de maintenance")}</option>
                                                <option value="FraisDassurance">{t("Frais d'assurance")}</option>
                                                <option value="FraisDeServiceClient">{t("Frais de service client")}</option>
                                                <option value="FraisDeMatériel">{t("Frais de matériel")}</option>
                                            </Select>
                                        </FormControl>
                                    </Flex>

                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Description")}</FormLabel>
                                        <Textarea resize={"none"} defaultValue={chargeData.description} onChange={(e) => setDescription(e.target.value)} maxLength={250} name="Description" placeholder={t('Description de charge...')} />
                                    </FormControl>



                                    <Flex mb={3} w={"100%"} gap={2}>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Montant")}</FormLabel>
                                            <Input defaultValue={chargeData.amount} onChange={(e) => setAmount(e.target.value)} maxLength={50} name="Montant" type='number' placeholder={t('Montant de charge...')} />
                                        </FormControl>
                                        <FormControl isRequired >
                                            <FormLabel>{t("Date de charge")}</FormLabel>
                                            <Input  defaultValue={chargeData.date_charge} onChange={(e) => setDateCharge(e.target.value)} name="Date" type='date' />
                                        </FormControl>
                                    </Flex>

                                    <FormControl  >
                                        <FormLabel>{t("Produit")}</FormLabel>
                                        {/* <Select rounded={"md"} value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                            <option value="">Chosissez un produit</option>
                                            {productsNames.map((product) => (
                                                <option value={product?.id}>{product?.title}</option>

                                            ))}
                                        </Select> */}
                                        <ReactSelect
                                            onChange={setSelectedProduct}
                                            className="basic-single"
                                            placeholder={t("Chosissez un produit")}
                                            defaultValue={selectedProduct?selectedProduct:
                                                productsNames?.find(e=>e.value === chargeData?.product)
                                            }
                                            isClearable={true}
                                            isSearchable={true}
                                            name="product"
                                            options={productsNames}
                                            styles={{

                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',
                                                    borderColor:'gray.200'

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                option: (provided, state) => ({
                                                    backgroundColor: colorbg,

                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },

                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                }),

                                            }}
                                        />
                                    </FormControl>
                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateCharge(chargeId)}
                            >
                                {t("Modifier")}
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }


            <Drawer placement={'right'} size={"xl"} onClose={onCloseDrawer} isOpen={isDrawerOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        {t("Statistiques")}</DrawerHeader>
                    <DrawerBody>
                        <Box w='100%' minH={{ base: '500px', sm: "300px" }}>
                            <Bar w='100%' data={dataChart} options={options} />
                        </Box>

                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>

    )
}

export default GestCharges