import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Heading, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Skeleton, SkeletonText, Stack, Stat, StatLabel, StatNumber, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient from '../../../axios-client'
import { useTranslation } from 'react-i18next'
import MyPagination from '../../../components/MyPagination'
import { MdAddShoppingCart, MdOutlineRemoveShoppingCart } from 'react-icons/md'
import { LiaShippingFastSolid } from 'react-icons/lia'
import { useNavigate } from 'react-router-dom'
function StatsCard({ title, stat, icon }) {
  const background = useColorModeValue('rgba(237, 242, 247, 0.8)', 'rgba(45, 55, 72, 0.8)'); // Adjust the RGBA values for desired transparency

  return (
    <Stat
      px={{ base: 2, md: 2 }}
      py={'3'}
      shadow={'lg'}
      border={'1px'}
      borderColor={useColorModeValue('gray.300', 'gray.500')}
      rounded={'lg'}

      background={background}
    >
      <Flex w={"100%"} justifyContent={'space-between'}>
        <Box w={"70%"} pl={{ base: 2, md: 3 }}>
          <StatLabel fontSize={"md"} fontWeight={'medium'} >
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          w={"30%"}
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}
function AllProducts() {

  const [dataProduct, setDataProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [productStats, setProductStats] = useState();
  const navigate = useNavigate()

  const { user } = useStateContext()
  const { t } = useTranslation();
  const [paginationData, setPaginationData] = useState()
  const [dataImagesProduct, setDataImagesProduct] = useState([])
  const [checkLengthData, setCheckLengthData] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('');

  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const getImagesProduct = (id) => {
    setDataImagesProduct(dataProduct.find(e => e.id === id).images)
    console.table(dataProduct.find(e => e.id === id)?.images);
    // onOpen()
  }
  // PAGINATION

  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  const fetchProductStats = async (userId, productId) => {
    axiosClient.get(`/commandes/getStatsProduct/?client=${userId}&product=${productId}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
    .then((response) => {
        setProductStats(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axiosClient.get(`/products/getAllProducts/?id_user=${user.id}&page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
      .then((response) => {

        let filteredData = response.data.results;
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setDataProduct(filteredData);
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
    fetchProductStats(user?.id, selectedProduct);

  }, [user?.id, currentPage, searchTerm, pageSize, selectedProduct ,dateStart,dateEnd])

    const getMinEndDate = (startDate) => {
      if (!startDate) return '';
      const date = new Date(startDate);
      date.setDate(date.getDate() + 1);
      return date.toISOString().split('T')[0];
  };

  return (
    <Box>
      <Flex mt={5} flexDirection={{ base: 'column', md: "row" }} justifyContent="space-between" justify={"center"}>
        <Flex  >
          <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate('/statistics')}>{t("Statistiques")}</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{t("Liste des produits")}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
          <InputGroup size={"md"} >
            <InputLeftAddon children={t('Date debut :')} />
            <Input
              type="date"
              borderColor={'gray.300'}
              borderRadius={"5"}
              placeholder="Start Date"
              onChange={e => {
                setDateStart(e.target.value)
                setDateEnd(getMinEndDate(e.target.value))
            }

            } value={dateStart}
            />
          </InputGroup>

          <InputGroup size={"md"} >
            <InputLeftAddon children={t('Date fin :')} />

            <Input
              type="date"
              borderColor={'gray.300'}
              borderRadius={"5"}
              placeholder="End Date"
              onChange={e => setDateEnd(e.target.value)} value={dateEnd}
            />
          </InputGroup>
        </Flex>

      </Flex>
      <SimpleGrid py={{ base: 6, md: 5 }} px={10} templateColumns='repeat(auto-fill,minmax(200px,1fr))' spacing={7}>

        {
          loading &&
          <Card mt={5} rounded={"2xl"}>
            <CardBody>
              <Stack position={"relative"}>
                <Skeleton height={200} borderRadius='2xl' />
              </Stack>
              <Stack alignItems='center'>
                <SkeletonText mt='4' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
              </Stack>
              <Stack>
                <SkeletonText mt='6' noOfLines={2} spacing='4' skeletonHeight='2' />
              </Stack>

              <Stack alignItems='center'>
                <SkeletonText mt='6' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
              </Stack>
            </CardBody>
          </Card>
        }
        {
          dataProduct.map((val, key) => {
            return (
              <Card onClick={() => {
                onOpen()
                setSelectedProduct(val.id)
              }
              } key={key} my={{ base: 5, md: 10 }} rounded={"xl"}>
                <CardBody >
                  <Stack position={"relative"}>
                    <Image
                      height={"200px"}
                      mt={-12}
                      src={val.images[0]?.image}
                      borderRadius='2xl'
                      objectFit="cover"
                      cursor={'pointer'}
                      onClick={() => {
                        getImagesProduct(val.id)
                      }}

                    />

                  </Stack>
                  <Stack pt={3} align={'center'}>

                    <Heading fontSize={'xl'} fontFamily={'body'} fontWeight={500}>
                      {val.title}
                    </Heading>

                    {/* <Text lineHeight={5} textAlign={'justify'} color={"descColor"}>
                      {val.description}
                    </Text>
                    <Stack direction={'row'} align={'center'}>
                      <Text fontWeight={"semibold"} fontSize={'lg'}>
                        Qte : {val.total_quantity}
                      </Text>

                    </Stack> */}
                  </Stack>
                </CardBody>
              </Card>
            )
          })
        }
      </SimpleGrid>
      {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
      <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
        <Box flex="1">
          <MyPagination
            paginationData={paginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            PAGE_SIZE={pageSize}
          />
        </Box>

        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Select>
      </Flex>
      {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
      <Drawer placement='bottom' onClose={() => {
        onClose()
      }} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          background={"#ffffff24"}
          backdropFilter='auto' backdropBlur='8px'>
          <DrawerBody w={{ base: "100%", md: "90%", xl: '80%' }}>
            <SimpleGrid my={5} columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
              <StatsCard title={t('nombre de fois commandé')} stat={productStats?.product_ordered} icon={<MdAddShoppingCart size={'3em'} />} />
              <StatsCard title={t('nombre de fois livré')} stat={productStats?.product_delivered} icon={<LiaShippingFastSolid size={'3em'} />} />
              <StatsCard title={t('nombre de fois annulé')} stat={productStats?.product_canceled} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


    </Box>

  )
}

export default AllProducts