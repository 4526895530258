import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import axiosClient from '../../axios-client';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import image from "../../assets/images/signInImage.jpg";

const ResetPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("")
  const toast = useToast()
  const navigate = useNavigate()

  const { token } = useParams();

  const onSubmit = ev => {
    toast.closeAll()
    ev.preventDefault();
    if (email === "") {
      toast({
        description: t("Veuillez entrer votre adresse email"),
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "left-accent"
      });
    } else {
      axiosClient.get('reset_email/', { params: { email: email } })
        .then((response) => {
          if (response.status === 203) {
            toast({
              description: response.data.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
              variant: "left-accent"
            });
          } else {
            toast({
              description: t("Un e-mail de réinitialisation de mot de passe a été envoyé, vérifiez votre boîte de réception"),
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
              variant: "left-accent"
            });
            navigate('/login')
          }

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Flex
      alignItems={'center'} justifyContent={'center'}
      w={"100%"}
      position='relative' backgroundSize={'cover'} bgImage={image} h={'100vh'}
    >
        <Flex
        gap={5}
          w={{ base: "96%", sm: "90%", md: '500px' }}
          backdropFilter='auto' backdropBlur='8px'
          padding={10}
          direction='column'

          rounded={"xl"}
          background='#2b283fab'>
          <Heading color={"gray.300"} lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            {t("Mot de passe oublié ?")}
          </Heading>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.400', 'gray.400')}>
            {t("Entrez votre adresse email")}
          </Text>
          <FormControl id="email">
            <Input
              name='email'
              color={'gray.300'}
              placeholder={t("votre-email@example.com")}
              _placeholder={{ color: 'gray.500' }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
            colorScheme='purple'
              name='submit'
              type='submit'
              onClick={onSubmit}
              >
              {t("Demander une réinitialisation")}
            </Button>
          </Stack>
        </Flex>

    </Flex>
  )
}

export default ResetPassword