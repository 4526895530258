import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { createRef, useState, useEffect } from 'react';
import { useStateContext } from '../../../context/ContextProvider';
import { GrAdd } from 'react-icons/gr';
import countryData from '../../../assets/dataJson/countries.json';


import {
    Box,
    Select,
    FormControl,
    FormLabel,
    Input,
    Flex,
    InputGroup,
    HStack,
    InputRightElement,
    useColorModeValue,
    Stack,
    useToast,
    Button,
    Text,
    Alert,
    AlertIcon,
    Spinner,
    InputLeftElement,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,

} from '@chakra-ui/react';
import axiosClient from '../../../axios-client';
import { TfiReload } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';


export default function AddUser() {
    //toast variable
    const inputColor = useColorModeValue("gray.300", "gray.600")
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const toast = useToast()
    const navigate = useNavigate()

    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;
    const { t } = useTranslation();


    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [rue, setRue] = useState("")
    const [ville, setVille] = useState("")
    const password = createRef()
    const [codePostal, setCodePostal] = useState("")
    const [tel, setTel] = useState()

    const confirmPassword = createRef()

    const [pwd, setPwd] = useState('');

    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [selectedRole, setSelectedRole] = useState('')
    const [selectedSexe, setSelectedSexe] = useState('')

    const { user, isLoading, setIsLoading } = useStateContext()

    const handleRoleChange = (ev) => {
        setSelectedRole(ev.target.value) // update selected role when option is selected
    }
    const handleSexeChange = (ev) => {
        setSelectedSexe(ev.target.value) // update selected role when option is selected
    }

    useEffect(() => {
        const Mycountries = [];
        const data = countryData;
        for (let index = 0; index < data.countries.length; index++) {
            Mycountries.push({
                value: data.countries[index].value,
                label: data.countries[index].label.substring(5, 50)
            });
        }
        setCountries(Mycountries);
    }, []);
    const handleCountryChange = (e) => {
        setSelectedCountry(countries.find(
            (country) => country.value === e.target.value
        ))

    };


    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(pwd)) {
            missingRequirements.push(t("caractère spécial"));
        } else if (!lowercaseRegex.test(pwd)) {
            missingRequirements.push(t("minuscule"));
        } else if (!uppercaseRegex.test(pwd)) {
            missingRequirements.push(t("majuscule"));
        } else if (!digitRegex.test(pwd)) {
            missingRequirements.push(t("chiffres"));
        } else if (!minLengthRegex.test(pwd)) {
            missingRequirements.push(t("longueur minimale de 8 caractères"));
        }

        if (missingRequirements.length > 0) {
            const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength(t('Le mot de passe est correct!'));
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [pwd]);
    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
        }

        if (!digitRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
        }

        if (!lowercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
        }

        if (!uppercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
        }
        if (!specialchar.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
        }
        if (password.length > 20) {
            errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const isEmpty = () => {
        return !last_name.trim() || !first_name.trim() || !email.trim() || !pwd.trim() || !selectedRole || !selectedSexe || !selectedCountry.label
    };

    const onSubmit = ev => {
        ev.preventDefault()
            const payload = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                pays: selectedCountry.label,
                ville: ville,
                code_postal: codePostal,
                rue: rue,
                tel: tel,
                gender: selectedSexe,
                added_by: user.first_name + ' ' + user.last_name,
                password: password.current.value,
                role_client: selectedRole,
                works_for:user.id
            }
    
            if (first_name.trim() === "" || last_name.trim() === "" || (ville && ville.trim() === "") || (codePostal && codePostal.trim() === "") || (rue && rue.trim() === "")) {
                window.scrollTo(0, 0)
                setMessage(t('Veuillez remplir les champs correctement'))
                return
            }    
            if (password.current.value !== confirmPassword.current.value) {
                setMessage(t("Veuillez confirmer votre mot de passe"));
            } else if (passwordStrength !== 'Fort' && password.current.value.length > 0 && !isPasswordValid(password.current.value)) {
                return;
            }
            else if (!emailRegex.test(email)) {
                setMessage(t("Veuillez entrer un email valide."));
                setIsLoading(false)
            }
            else if (codePostal && !codepRegex.test(codePostal)) {
                setMessage(t("Veuillez entrer un code postal valide."));
                return;
            }
    
            else {
                axiosClient.post('/clients/', payload)
                    .then((response) => {
                        toast({
                            description: t("l'utilisateur est ajouté avec succès"),
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                            position: 'bottom-right',
                            variant:"left-accent",
                        })
                        navigate('/utilisateurs')
    
                    })
                    .catch((err) => {
                        const response = err.response;
                        setMessage(response.data)
                    })
            }
            
        
        
    }

    const [disableInputConfirm, setDisableInputConfirm] = useState(false)
    const [generetedPwd, setGeneretedPwd] = useState()
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setPwd(newPassword);
        confirmPassword.current.value = newPassword
        setGeneretedPwd(newPassword)
        setDisableInputConfirm(true)

    };


    // const [countries, setCountries] = useState([]);
    // const [selectedCountry, setSelectedCountry] = useState({});

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);


    const colorbg = useColorModeValue('white', '#2d3748')
    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box>
            <Box w={"100%"} mt={3} >
                {/* <Text as="cite" fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t('Accueil')}</span>  / <span onClick={() => navigate('/utilisateurs')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t('Utilisateurs')}</span> / <span> {t('Ajouter un utilisateur')}</span></Text> */}
                <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/utilisateurs')}>{t("Utilisateurs")}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t('Ajouter un utilisateur')}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
            </Box>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={"center"}
                p={5}
            >

                <Stack
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'xl'}
                    p={6}
                    my={5}
                    w={{ base: '100%', md: '100%', lg: '70%', sm: '100%' }}
                >
                    {message &&
                        <Alert fontSize={"sm"} padding={2} status='error' rounded="md">
                            <AlertIcon />
                            {message}
                        </Alert>
                    }
                    <Box p={5} rounded={'lg'} w="100%" maxW="full"
                        align={'center'}
                        justify={'center'}
                    >
                        <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
                        >
                            <HStack >
                                <Box w="50%">
                                    <FormControl id="firstName" isRequired >
                                        <FormLabel ml={0.5}>{t("Nom")} </FormLabel>
                                        <Input value={last_name} onChange={(e) => setLastName(e.target.value)} name="last_name" type="text" placeholder={t('Tapez le nom')} maxLength={25} />
                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="lastName" isRequired >
                                        <FormLabel ml={0.5}>{t("Prénom")}</FormLabel>
                                        <Input value={first_name} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder={t('Tapez le prénom')} maxLength={25} />

                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack spacing={2}>
                                <Box w="100%">
                                    <FormControl id="email" isRequired >
                                        <FormLabel ml={0.5}>{t("Adresse email")}</FormLabel>
                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder={t("Tapez l'adresse email")} maxLength={50} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="rue">
                                        <FormLabel ml={0.5}>{t("Rue")}</FormLabel>
                                        <Input value={rue} onChange={(e) => setRue(e.target.value)} name="rue" type="text" placeholder={t('Tapez la rue')} maxLength={50} />
                                    </FormControl>

                                </Box>
                                <Box w="50%">
                                    <FormControl id="ville">
                                        <FormLabel ml={0.5}>{t("Ville")}</FormLabel>
                                        <Input value={ville} onChange={(e) => setVille(e.target.value)} name="ville" type="text" placeholder={t('Tapez la ville')} maxLength={30} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="codePostal" >
                                        <FormLabel ml={0.5}>{t("Code postal")}</FormLabel>
                                        <Input value={codePostal} onChange={(e) => setCodePostal(e.target.value)} name="codePostal" type="text" placeholder={t('Tapez le code postal')} maxLength={15} />
                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="pays" isRequired>
                                        <FormLabel ml={0.5}>{t("Pays")}</FormLabel>
                                        {/* <Input  onChange={(e) => setPays(e.target.value)} name="pays" type="text" placeholder='Tapez le pays' maxLength={30} /> */}
                                        <Select
                                            borderColor={inputColor}

                                            id="pays"
                                            name="pays"
                                            defaultValue={selectedCountry}
                                            onChange={handleCountryChange}
                                            isSearchable
                                            bg={colorbg}
                                            color={txtcolor}
                                            _focus={{ bg: colorbg, borderColor: "transparent" }}
                                        >
                                            <option>-- {t("Choisir un pays")} --</option>
                                            {countries.sort((a, b) => a.label.localeCompare(b.label)).map((country) => (
                                                <option
                                                    key={country.value}
                                                    value={country.value}
                                                    bg={colorbg}
                                                    color={txtcolor}
                                                    _hover={{ bg: colorbghover, cursor: "pointer" }}
                                                >
                                                    {country.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="tel">
                                        <FormLabel ml={0.5}>{t('Téléphone')}</FormLabel>
                                        <Input value={tel} onChange={(e) => setTel(e.target.value)} name="tel" type="number" placeholder={t('Tapez le numéro de téléphone')} maxLength={50} />
                                    </FormControl>

                                </Box>
                                <Box w="50%">
                                    <FormControl id="sexe" isRequired>
                                        <FormLabel ml={0.5}>{t("Sexe")}</FormLabel>
                                        <Select placeholder={t("Sélectionnez")} onChange={handleSexeChange}>

                                            <option value="Femme">{t('Femme')}</option>
                                            <option value="Homme">{t('Homme')}</option>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </HStack>

                            <Text align={'left'} fontSize="sm" color='yellow.600'>{t('Le mot de passe doit comporter au moins 8 caractères et contenir au moins un chiffre, une lettre minuscule , une lettre majuscule et un chiffre special.')}</Text>

                            <HStack>
                                <Box w="50%">
                                    <FormControl id="password" isRequired>
                                        <FormLabel ml={0.5}>{t('Mot de passe')}</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement onClick={generatePassword} as={'Button'} >
                                                <TfiReload color='gray.300' />
                                            </InputLeftElement>
                                            <Input value={pwd} ref={password} name="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPwd(e.target.value)} onCopy={(e) => e.preventDefault()} placeholder={t('Tapez le mot de passe')} />

                                            <InputRightElement h={'full'}>

                                                <Button
                                                    variant={'ghost'}
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }>
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>


                                    </FormControl>

                                </Box>

                                <Box w="50%">
                                    <FormControl id="password_confirmation" isRequired>
                                        <FormLabel ml={0.5}>{t('Confirmer le mot de passe')}</FormLabel>
                                        <InputGroup>
                                            {(disableInputConfirm && pwd === generetedPwd) ?
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder={t('Confirmez le mot de passe')} disabled />
                                                :
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder={t('Confirmez le mot de passe')} />
                                            }
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                            </HStack>
                            {pwd.length > 0 ?
                                <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                : ""}
                                <HStack spacing={2}>
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="role" isRequired >
                                            <FormLabel ml={0.5}>Role</FormLabel>
                                            <Select placeholder={t("Sélectionnez")} onChange={handleRoleChange}>

                                                <option value="ManagerClient">{t("Manager")}</option>
                                                <option value="RespoSuiviClient">{t("Centre d'appel")}</option>
                                                <option value="StockManagerClient">{t("Gestionnaire de stock")} </option>
                                            </Select>
                                        </FormControl>

                                    </Box>

                                </HStack>
                            
                            <Stack direction={['column', 'row']} spacing={10} pt={2} justifyContent="end">
                                {
                                    isEmpty() ? (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="blue"
                                            isDisabled
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : t("Ajouter")}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="blue"
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : t("Ajouter")}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                {/* <Stack w="50%"
                    justify="center"
                    alignItems="center"
                    p={5}
                >
                    <Image display={{ base: 'none', md: 'flex' }} src={"responsable_img"} />
                </Stack> */}
            </Flex>
        </Box>

    )
}