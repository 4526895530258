import { createContext, useContext, useState, useEffect } from "react";
import axiosClient from "../axios-client";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setIsLoading: () => { },
  countTickets: () => { },
  count: () => { },
  countNotes: () => { },
});

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [countTickets, setCountTickets] = useState();
  const [countTicketsManager, setCountTicketsManager] = useState();
  const [countTicketsAdmin, setCountTicketsAdmin] = useState();
  const [countTicketsManagerAdmin, setCountTicketsManagerAdmin] = useState();
  const [count, setCount] = useState('')
  const [countNotes, setCountNotes] = useState('')

  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);


  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };

  const setIsLoading = (v) => {
    _setIsLoading(v);
  };

  function updateUser() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        setUser(data);
        // console.log(data);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateTicketCount() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        axiosClient.get(`/tickets/getTotalTicketsCount/?user=${data.id}`)
          .then((res) => {
            setCountTickets(res.data.total_count_admin);
            setCountTicketsManager(res.data.total_count_manager);
          })
          .catch((error) => {
            console.error(error);
          });

      })
      .catch((err) => {
        console.log(err);
      });

  }
  function updateTicketCountAdmin() {
    axiosClient
      .get("auth/user/")
      .then(({ data }) => {
        axiosClient.get(`/tickets/getTotalTicketsCount/`)
          .then((res) => {
            setCountTicketsAdmin(res.data.total_count_admin);
            setCountTicketsManagerAdmin(res.data.total_count_manager);
          })
          .catch((error) => {
            console.error(error);
          });

      })
      .catch((err) => {
        console.log(err);
      });

  }


  return (
    <StateContext.Provider
      value={{

        user,
        setUser,
        token,
        setToken,
        setIsLoading,
        isLoading,
        countTickets,
        countTicketsManager,
        setCountTickets,
        setCountTicketsManager,
        count,
        setCount,
        countNotes,
        setCountNotes,
        updateUser,
        updateTicketCount,
        updateTicketCountAdmin,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
