import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Avatar, AvatarBadge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, FormControl, FormLabel, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import Resizer from "react-image-file-resizer";
import { CloseIcon } from '@chakra-ui/icons';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import { useStateContext } from '../../../context/ContextProvider';

function EditStock() {

    const { user } = useStateContext()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()
    const toast = useToast()

    const [imageListe, setimageListe] = useState([]);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [loadingImg, setLoadingImg] = useState(false)
    const [inputKey, setInputKey] = useState(0);

    const [dataProduit, setDataProduit] = useState([])
    const [titreProduit, setTitreProduit] = useState('')
    const [descriptionProduit, setDescriptionProduit] = useState('')
    const [dataStock, setDataStock] = useState([])
    const [dataHistoriqueProduct, setDataHistoriqueProduct] = useState([])
    const [dataHistoriqueStock, setDataHistoriqueStock] = useState([])


    const [totalQuantity, setTotalQuantity] = useState()
    const [totalPrice, setTotalPrice] = useState()

    //MESSAGES ERROR
    const [messageErrImages, setMessageErrImages] = useState('')
    const [messageErrTitle, setMessageErrTitle] = useState('')

    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);

    const fetchDataCategories = async () => {
        try {
            await axiosClient.get(`/categories/getAllCategories/?page_size=100&page=1&&client=${user.id}`).then(res => {
                const categories = res.data.results.map(cat => ({
                    value: cat.id,
                    label: cat.name
                }));
                setCategoriesOptions(categories)
            })
        } catch (error) {
            console.error('Error : ', error);

        }
    }

    useEffect(() => {
        fetchDataCategories()
    }, [])


    // function display images
    const handleChangeImage = (e) => {
        const images = e.target.files;
        const extensions = ['.png', '.jpg', '.jpeg', '.webp'];

        if (images && images.length > 0) {
            if (selectedImageList.length + images.length > 5) {
                toast({
                    description: t("Vous ne pouvez séléctionner que 5 images au maximum !"),
                    status: 'warning',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'top-center'
                });
                setInputKey((prevKey) => prevKey + 1)
                return
            }
            const selectedImages = Array.from(images);

            selectedImages.forEach(async (image) => {
                if (image.name.length > 60) {
                    toast({
                        description: t("Nom de l'image dépasse 60 caractères! Veuillez modifier le nom ! "),
                        status: 'warning',
                        variant: 'left-accent',
                        duration: 2000,
                        isClosable: true,
                        position: 'top-center'
                    });
                } else if (!extensions.includes(image.name.substring(image.name.lastIndexOf(".")))) {
                    toast({
                        title: t("Erreur d'extension."),
                        description: t("Veuillez modifier l'extension de l'image {'[*.png, *.jpg, *.jpeg, *.webp]'} !"),
                        status: 'warning',
                        variant: 'left-accent',
                        duration: 2000,
                        isClosable: true,
                        position: 'top-center'
                    });
                } else {
                    const imageURL = URL.createObjectURL(image);
                    const uri = await new Promise((resolve, reject) => {
                        Resizer.imageFileResizer(
                            image,
                            451,
                            800,
                            'png',
                            100,
                            0,
                            (uri) => {
                                resolve(uri);
                            },
                            'file'
                        );
                    });
                    setimageListe((prevImageList) => [...prevImageList, uri]);
                    setSelectedImageList((prevSelectedImages) => [...prevSelectedImages, imageURL]);
                }
            });

            // Reset the input value after processing all selected images
            setInputKey((prevKey) => prevKey + 1)
        }
    };


    const fetchData = async (id) => {
        await axiosClient.get(`/products/${id}/`).then((res => {
            setTitreProduit(res.data.title)
            setDescriptionProduit(res.data.description)
            setSelectedCategory(res.data.category)
            setTotalPrice(res.data.total_price)
            setTotalQuantity(res.data.total_quantity)

            setSelectedImageList(() => {
                let data = []
                for (let index = 0; index < res.data.images.length; index++) {

                    data.push(res.data.images[index].image)
                }
                return data
            })
            setimageListe(() => {
                let data = []
                for (let index = 0; index < res.data.images.length; index++) {
                    data.push(res.data.images[index].image)
                }
                return data
            })
            setDataStock(res.data.stock.filter(e => e.is_deleted === false))

            //Historique
            setDataHistoriqueProduct(res.data.historique)
        }))
    }

    useEffect(() => {

        fetchData(location.state.id)

    }, [])

    const updateProduct = async () => {

        if (imageListe.length === 0) {
            setMessageErrImages(t('Il faut ajouter au moins une image !'))
            return;
        }

        if (!titreProduit) {
            setMessageErrTitle(t('Il faut ajouter un titre !'))
            return
        }


        await updateInfoProduct(location.state.id)
        await updateImageProduct(location.state.id)

        navigate('/My_Stock')
    }

    const updateImageProduct = async (id) => {
        await axiosClient
            .get(`/productImages/?search=${id}`)
            .then((res) => {
                const deleteImagesList = res.data.filter((e) => !imageListe.includes(e.image));
                console.log("data image : ", deleteImagesList);
                console.log("image list : ", imageListe);

                // Delete images not present in the updated imageListe
                for (let index = 0; index < deleteImagesList.length; index++) {
                    axiosClient.delete(`/productImages/${deleteImagesList[index].id}/`);
                }

                // Upload new images
                const newImagesToAdd = imageListe.filter((image) => typeof image !== "string");

                const uploadPromises = newImagesToAdd.map(async (newImage) => {
                    const formData = new FormData();
                    formData.append("product", id);
                    formData.append("image", newImage);

                    return await axiosClient.post(`/productImages/`, formData);
                });

                // Wait for all image uploads to complete
                // Promise.all(uploadPromises)
                //     .then(() => {

                //         // Fetch updated service data with images
                //         return axiosClient.get(`/services/${location.state.idService}/`);
                //     })
                //     .then((res) => {
                //         const { images, ...myNewData } = res.data;
                //         navigate('/apa-agency-services');
                //     })
                //     .catch((error) => {
                //         console.error('Error uploading new images:', error);
                //     });
            })
            .catch((error) => {
                console.error('Error fetching existing images:', error);
            });
    }

    const updateInfoProduct = async (id) => {
        let historique = dataHistoriqueProduct
        historique.push({
            'title': titreProduit,
            'description': titreProduit,
            'category': selectedCategory,
            'user': user.id,
            'created_at': new Date().toLocaleString()
        })
        console.log('Historique : ', historique);
        const formData = new FormData()
        formData.append('title', titreProduit)
        formData.append('description', descriptionProduit)
        formData.append('category', selectedCategory)
        formData.append('historique', JSON.stringify(historique))
        await axiosClient.patch(`/products/${id}/`, formData).catch((err) => {
            console.log('ERROR : ', err);
        })
    }

    // STOCK
    // Edit
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [dataStockSelected, setDataStockSelected] = useState()
    const [colorInput, setColorInput] = useState()
    const [sizeInput, setSizeInput] = useState()
    const [quatityInput, setQuantityInput] = useState()
    const [purchase_PriceInput, setPurchase_PriceInput] = useState()

    const getDataForStockSelected = (id) => {
        onOpen()
        setDataStockSelected(dataStock.find(e => e.id === id))
        setColorInput(dataStock.find(e => e.id === id)?.color)
        setSizeInput(dataStock.find(e => e.id === id)?.size)
        setDataHistoriqueStock(dataStock.find(e => e.id === id)?.historique)
        console.log("HISTORIQUE STOCK ! ", dataStock.find(e => e.id === id)?.historique);
    }

    const updateStockSelected = async (id, oldQntt, oldPrice) => {

        const total_quantity = quatityInput ? totalQuantity - oldQntt + parseInt(quatityInput) : totalQuantity
        const total_price = purchase_PriceInput ? totalPrice - oldPrice + parseInt(purchase_PriceInput) : totalPrice

        let historique = dataHistoriqueStock

        historique.push({
            'color': colorInput ? colorInput : dataStockSelected?.color,
            'size': sizeInput ? sizeInput : dataStockSelected?.size,
            'quantity': quatityInput ? parseInt(quatityInput) : dataStockSelected?.quantity,
            'purchase_price': purchase_PriceInput ? parseInt(purchase_PriceInput) : dataStockSelected?.purchase_price,
            'user': user.id,
            'total_quantity': quatityInput ? totalQuantity - oldQntt + parseInt(quatityInput) : totalQuantity,
            'total_price': purchase_PriceInput ? totalPrice - oldPrice + parseInt(purchase_PriceInput) : totalPrice,
            'created_at': new Date().toLocaleString()
        })

        console.log('HISTORiQUE STOCK : ', historique);

        const formData = new FormData()

        formData.append('color', colorInput)
        formData.append('size', sizeInput)
        quatityInput && formData.append('quantity', parseInt(quatityInput))
        purchase_PriceInput && formData.append('purchase_price', parseInt(purchase_PriceInput))
        formData.append('historique', JSON.stringify(historique))

        await axiosClient.patch(`/stock/${id}/`, formData)
            .then(async (res) => {

                if (quatityInput || purchase_PriceInput) {
                    await axiosClient.patch(`/products/${location.state.id}/`, {
                        total_quantity: total_quantity,
                        total_price: total_price,
                    }).then(response => {
                        setTotalQuantity(response.data.total_quantity)
                        setTotalPrice(response.data.total_price)
                    })
                }

                setDataStock(dataStock.map((item) => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            color: res.data.color,
                            size: res.data.size,
                            quantity: res.data.quantity,
                            purchase_price: res.data.purchase_price,
                        }
                    }
                    return item
                }))
                setDataStockSelected()
                setColorInput()
                setSizeInput()
                setQuantityInput()
                setPurchase_PriceInput()
                onClose()

            })
            .catch(err => {
                console.log("ERROR : ", err);
            })
    }

    // DELETE

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [deletedId, setDeletedId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setDeletedId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setDeletedId()
    };

    const deleteStockSelected = async () => {
        await axiosClient.patch(`/stock/${deletedId}/`, {
            is_deleted: true
        }).then(async (res) => {
            await axiosClient.patch(`/products/${location.state.id}/`, {
                total_quantity: totalQuantity - res.data.quantity,
                total_price: totalPrice - res.data.purchase_price,
            }).then(res => {
                setTotalQuantity(res.data.total_quantity)
                setTotalPrice(res.data.total_price)
            })
            setDataStock(dataStock.filter(e => e.id !== deletedId))
            onCloseAlert()
        }).catch(err => {
            console.log("ERROR : ", err);
        })

    }

    return (
        <Stack>
            <Flex>
                <Box w="90%" pt='12px' mb={8}>
                <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/My_Stock')}>{t("Gestion stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Modifier stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>

            <Flex justify={'center'}>
                <Box w={{ base: '100%', md: '80%', lg: '70%' }}>
                    <Alert rounded={"lg"} status='warning'>
                        <Flex align={"center"}>
                            <AlertIcon />
                           
                            <Text fontWeight="semibold">
                                {t("Attention! Si vous souhaitez mettre à niveau le stock plutôt que de l'éditer, veuillez cliquer sur ce lien")}
                                <Text
                                textColor={useColorModeValue("gray.700", "gray.400")}
                                    as="span"
                                    onClick={() => {
                                        navigate('/UpgradeStock', {
                                            state: { id: location.state.id }
                                        });
                                    }}
                                    style={{ marginLeft: '5px', textDecoration: 'underline', cursor: 'pointer' }}
                                >
                                    {t("mise à niveau de stock")}
                                </Text>
                            </Text>

                        </Flex>
                    </Alert>
                    <Stack mt={2} pt={3} px={7} bg={useColorModeValue('white', '#212835')} rounded='lg' pb={5}>
                        <Text color={useColorModeValue('gray', 'gray.400')}>{t("Ajouter les images jusqu'au 5 images")}</Text>

                        {messageErrImages && <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            {messageErrImages}
                        </Alert>}

                        <Flex
                            align={'center'}
                            w={"100%"}
                            // gap={2}
                            px={5}
                            py={5}
                            justify={'center'}
                            // py={{ base: 3, xl: 0 }}

                            minHeight={"100px"}
                        // style={{
                        //     border: '1px dashed lightgray',
                        // }}
                        >
                            {selectedImageList.length === 0 && (
                                <Flex
                                // align={'center'}
                                // justify={'center'}
                                // style={{ width: '100%' }}
                                >
                                    <Button
                                        size={{ base: 'sm', md: 'md' }}
                                        ml={{ base: 0, lg: 5 }}
                                        colorScheme='purple'
                                        variant='outline'
                                    >
                                        <AiOutlinePlus style={{ fontSize: '18px', marginRight: 2 }} />
                                        <Text ml="2" fontWeight="lighter">
                                            {t("Télécharger des images")}
                                        </Text>
                                        <input
                                            key={inputKey}
                                            multiple
                                            type="file"
                                            id="inputGroupFile01"
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                height: '100%',
                                                opacity: 0,
                                                cursor: 'pointer',
                                            }}
                                            onMouseEnter={(e) => (e.target.style.cursor = 'pointer')}
                                            onMouseLeave={(e) => (e.target.style.cursor = 'context-menu')}
                                            onChange={handleChangeImage}
                                            accept="image/png,image/jpg,image/jpeg,image/webp"
                                        />
                                    </Button>
                                </Flex>
                            )}
                            <Grid templateColumns={{ base: 'repeat(1,1fr)', sm: 'repeat(3,1fr)', md: 'repeat(3,1fr)', lg: 'repeat(4,1fr)', xl: 'repeat(5,1fr)' }} gap={3}>

                                {selectedImageList &&
                                    selectedImageList.map((val, key) => (
                                        <GridItem>
                                            <Flex
                                                flexDirection={'row'}
                                                w={"100%"}
                                                key={key}
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <Avatar
                                                    size={"xl"}
                                                    src={val}
                                                    borderRadius={"10px"}
                                                    style={{

                                                        objectFit: 'contain',
                                                    }}
                                                >
                                                    <AvatarBadge style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        position: 'absolute',
                                                        fontSize: 10,

                                                        cursor: 'pointer',
                                                        // color: '#c7c4c4',
                                                        background: 'black',
                                                        right: '-5%',
                                                        top: '-5%',
                                                    }}  >
                                                        <CloseIcon onClick={() => {
                                                            // deleteimg(key)
                                                            const newImageList = imageListe;
                                                            const deleteItemImageListe = newImageList.splice(key, 1);
                                                            setSelectedImageList(
                                                                selectedImageList.filter((e) => e !== val)
                                                            );
                                                            setimageListe(newImageList);
                                                        }} />
                                                    </AvatarBadge>

                                                </Avatar>




                                            </Flex>
                                        </GridItem>

                                    ))}


                                {selectedImageList.length > 0 && selectedImageList.length < 5 && (
                                    <Flex
                                        align={'center'}
                                        justify={'center'}
                                        style={{
                                            height: '100px',
                                        }}
                                    >
                                        <Button
                                            colorScheme='blue'
                                            variant='outline'
                                        >
                                            <AiOutlinePlus style={{ fontSize: 22 }} />
                                            <input
                                                key={inputKey}
                                                multiple
                                                type="file"
                                                onChange={handleChangeImage}
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    left: 0,
                                                    opacity: 0,
                                                }}
                                                accept="image/png,image/jpg,image/jpeg,image/webp"
                                            />
                                        </Button>
                                    </Flex>
                                )}
                            </Grid>
                        </Flex>
                    </Stack>

                    <Stack mt={3} pt={3} px={7} bg={useColorModeValue('white', '#212835')} rounded='lg' pb={5}>
                        <Text color={useColorModeValue('gray', 'gray.400')}>{t("Informations du produit")}</Text>

                        {messageErrTitle && <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            {messageErrTitle}
                        </Alert>}

                        <Flex mt={2}>
                            <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} id='titre' isRequired>
                                <FormLabel color={useColorModeValue('gray', 'gray.400')} w={'80%'}>{t("Titre")}</FormLabel>
                                <Input
                                    id='titre'
                                    type='text'
                                    name='titreProduit'
                                    color={useColorModeValue('black', 'gray.400')}
                                    borderColor={useColorModeValue('gray.300', 'gray.500')}
                                    _focus={{ boxShadow: 'none', borderColor: 'gray' }}
                                    placeholder={t('Titre')}
                                    value={titreProduit}
                                    onChange={e => setTitreProduit(e.target.value)}
                                />
                            </FormControl>
                        </Flex>

                        <Flex mt={3}>
                            <FormControl display={"flex"} flexDirection={{ base: 'column', md: 'row' }} id="titre">
                                <FormLabel
                                    color={useColorModeValue('gray', 'gray.400')}
                                    w={"80%"}
                                >
                                    {t("Catégorie")}
                                </FormLabel>
                                <div style={{ width: '104%' }}>
                                    <Select
                                        onChange={e => setSelectedCategory(e.target.value)}
                                        placeholder={t("Choisissez une catégorie")}
                                        value={selectedCategory}
                                        isClearable
                                        isSearchable
                                        name="category"
                                    >
                                        {categoriesOptions.map((val, key) => {
                                            return (
                                                <option key={key} value={val.value}>{val.label}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </FormControl>
                        </Flex>

                        <Flex mt={3}>
                            <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} id='description'>
                                <FormLabel color={useColorModeValue('gray', 'gray.400')} w={'80%'}>Description</FormLabel>
                                <Textarea
                                    id='description'
                                    name='descriptionProduit'
                                    color={useColorModeValue('black', 'gray.400')}
                                    borderColor={useColorModeValue('gray.300', 'gray.500')}
                                    _focus={{ boxShadow: 'none', borderColor: 'gray' }}
                                    resize={'none'}
                                    lineHeight={1.3}
                                    placeholder='Description'
                                    value={descriptionProduit}
                                    onChange={e => setDescriptionProduit(e.target.value)}
                                >

                                </Textarea>
                            </FormControl>
                        </Flex>
                    </Stack>

                    <Stack mt={3} pt={3} pl={2} bg={useColorModeValue('white', '#212835')} rounded='lg' pb={5}>
                        <Text color={useColorModeValue('gray', 'gray.400')} ml={3}>{t("Stock produit")}</Text>

                        {dataStock.length !== 0 && <TableContainer mt={3} overflowY={'auto'} maxH={300}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        
                                        <Th>{t("Couleur")}</Th>
                                        <Th>{t("Taille")}</Th>
                                        <Th>{t("Quantité")}</Th>
                                        <Th>{t("Prix d'achat")}</Th>
                                        <Th>{t("Date d'achat")}</Th>
                                        <Th>Options</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>



                                    {dataStock.map((val, key) => {
                                        return (
                                            <>
                                                <Tr key={key}>
                                                    <Td>{val.color ? val.color : '-'}</Td>
                                                    <Td>{val.size ? val.size : '-'}</Td>
                                                    <Td>{val.quantity}</Td>
                                                    <Td>{val.purchase_price}</Td>
                                                    <Td>{new Date(val.date_creation).toLocaleDateString(t('fr-FR'))}</Td>
                                                    <Td>
                                                        <Flex gap={2} justify="space-around">
                                                            <Button p={0} size="sm" variant='outline' colorScheme='green' onClick={() => {
                                                                getDataForStockSelected(val.id)
                                                            }}><FiEdit3 /></Button>
                                                            <Button p={0} size="sm" variant='outline' colorScheme='red' onClick={() => {
                                                                onOpenAlert(val.id)
                                                            }}><FiTrash2 /></Button>

                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            </>
                                        )
                                    })}

                                </Tbody>
                            </Table>
                        </TableContainer>}

                        {dataStock.length === 0 &&
                            <Text fontSize={18} color="red.400" fontWeight="semibold">
                                {t("Votre stock est actuellement vide.")}

                            </Text>
                        }

                    </Stack>

                    <Stack mt={3} pt={3} px={7} bg={useColorModeValue('white', '#212835')} rounded='lg' pb={5}>
                        <Text color={useColorModeValue('gray', 'gray.400')}>Total</Text>
                        <Flex justifyContent={"space-between"}>
                            <Flex mt={2} gap={2}>
                                <Text textTransform={'uppercase'} color={useColorModeValue('gray', 'gray.400')}>{t("Quantité")} : </Text>
                                <Text color={useColorModeValue('gray', 'gray.200')}>{totalQuantity}</Text>
                            </Flex>
                            <Flex mt={2} gap={2}>
                                <Text textTransform={'uppercase'} color={useColorModeValue('gray', 'gray.400')}>{t("Prix")} : </Text>
                                <Text color={useColorModeValue('gray', 'gray.200')}>{totalPrice}</Text>
                            </Flex>
                        </Flex>

                    </Stack>

                    <Flex mt={5} justifyContent={'end'}>
                        <Button colorScheme='yellow' size={"sm"} variant={'outline'} onClick={updateProduct}>{t("Modifier")}</Button>
                    </Flex>

                </Box>
            </Flex>

            {/* Modal to update stock */}
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Modification du stock")} </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>{t("Couleur")}</FormLabel>
                            <Input
                                placeholder={t('Couleur')}
                                name='colorInput'
                                defaultValue={colorInput}
                                onChange={e => setColorInput(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mt={2}>
                            <FormLabel>{t("Taille")}</FormLabel>
                            <Input
                                placeholder={t('Taille')}
                                defaultValue={sizeInput}
                                onChange={e => setSizeInput(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mt={2}>
                            <FormLabel>{t("Quantité")}</FormLabel>
                            <Input
                                placeholder={t('Quantité')}
                                defaultValue={dataStockSelected?.quantity}
                                onChange={e => setQuantityInput(e.target.value)}
                            />
                        </FormControl>

                        <FormControl mt={2}>
                            <FormLabel>{t("Prix d'achat")}</FormLabel>
                            <Input
                                placeholder={t("Prix d'achat")}
                                defaultValue={dataStockSelected?.purchase_price}
                                onChange={e => setPurchase_PriceInput(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='yellow' size="sm" variant='outline' mr={3} onClick={() => updateStockSelected(dataStockSelected?.id, dataStockSelected?.quantity, dataStockSelected?.purchase_price)}>
                            {t("Modifier")}
                        </Button>
                        <Button onClick={onClose} size="sm" >{t("Annuler")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Alert to Delete Stolck */}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={deleteStockSelected} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Stack>
    )
}

export default EditStock