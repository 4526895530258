import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';

function UpgradeStock() {

    const { user } = useStateContext()

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()

    const [dataProperties, setDataProperties] = useState([
        { color: "", size: "", quantity: "", purchase_price: "" },
    ]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    //Message Error
    const [messageErrColor, setMessageErrColor] = useState('')
    const [messageErrSize, setMessageErrSize] = useState('')
    const [messageErrQuantity, setMessageErrQuantity] = useState('')
    const [messageErrPurchase_price, setMessageErrPurchase_price] = useState('')


    const colorText_BoxProperties = useColorModeValue("gray", "gray.400");
    const colorInput_BoxProperties = useColorModeValue("black", "gray.400");
    const colorBorderInput_BoxProperties = useColorModeValue(
        "gray.300",
        "gray.500"
    );

    const addProperty = () => {
        setDataProperties([
            ...dataProperties,
            { color: "", size: "", quantity: "", purchase_price: "" },
        ]);
    };

    const removeProperty = (index) => {
        const listProp = [...dataProperties];
        listProp.splice(index, 1);
        setDataProperties(listProp);
    };

    const handleChangeProperty = (e, index) => {
        const { name, value } = e.target;
        const listProp = [...dataProperties];
        listProp[index][name] = value;
        setDataProperties(listProp);
    };

    const addTotalQntt = () => {
        let totalQntt = 0;
        dataProperties.forEach((item) => {
            // Convertir la quantité en nombre et l'ajouter au total
            totalQntt += parseInt(item.quantity || 0); // Si la quantité est vide, on prend 0
        });
        setTotalQuantity(totalQntt);
    };

    const removeTotlaQntt = (qtt) => {
        if (qtt) {
            setTotalQuantity(totalQuantity - parseInt(qtt));
        }
    };

    const addTotalPrice = () => {
        let totalPrice = 0;
        dataProperties.forEach((item) => {
            // Convertir la quantité en nombre et l'ajouter au total
            totalPrice += parseInt(item.purchase_price || 0); // Si la quantité est vide, on prend 0
        });
        setTotalPrice(totalPrice);
    };

    const removeTotlaPrice = (prix) => {
        if (prix) {
            setTotalPrice(totalPrice - parseInt(prix));
        }
    };


    const [totalQnttProduct, setTotalQnttProduct] = useState()
    const [totalPriceProduct, setTotalPriceProduct] = useState()
    useEffect(() => {
        axiosClient.get(`/products/${location.state.id}/`).then(res => {
            setTotalQnttProduct(res.data.total_quantity)
            setTotalPriceProduct(res.data.total_price)
        })
    }, [])

    const UpgradeStockProduct = () => {
        try {
            const formDataArray = dataProperties.map((val) => {
                const formData = new FormData();
                formData.append("color", val.color);
                formData.append("size", val.size);
                formData.append("quantity", val.quantity);
                formData.append("purchase_price", val.purchase_price);
                formData.append("product", location.state.id);
                formData.append('stock_added_by', user.id)
                formData.append('historique', JSON.stringify([
                    {
                        'color': val.color,
                        'size': val.size,
                        'quantity': val.quantity,
                        'purchase_price': val.purchase_price,
                        'user': user.id,
                        'created_at': new Date().toLocaleString()
                    }
                ]))
                return formData;
            });

            Promise.all(formDataArray.map(async (formData, index) => {
                return await axiosClient.post("/stock/", formData)
            }))
                .then(() => {
                    axiosClient.patch(`/products/${location.state.id}/`, {
                        total_quantity: totalQnttProduct + totalQuantity,
                        total_price: totalPriceProduct + totalPrice,
                    }).then(() => {
                        navigate("/My_Stock")
                    })
                })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }

    return (
        <Stack>
            <Flex>
                <Box w="90%" pt="12px" mb={8}>
                    
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/My_Stock')}>{t("Gestion stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Mise à niveau du stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>

            <Flex justify={'center'}>
                <Box
                    bg={useColorModeValue("white", "#212835")}
                    borderWidth="1px"
                    rounded="lg"
                    w={{ base: "100%", lg: "75%" }}
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    p={6}
                    m="10px auto"
                    as="form"
                >
                    <>
                        <Stack
                            mt={3}
                            pt={3}
                            px={{ base: 2, lg: 7 }}
                            bg={useColorModeValue("white", "#212835")}
                            rounded="lg"
                            pb={5}
                        >
                            {/* <Text color={useColorModeValue('gray', 'gray.400')}>Propriétés produit</Text> */}
                            <Heading
                                color={useColorModeValue("gray.500", "gray.400")}
                                w="100%"
                                fontSize={"3xl"}
                                textAlign={"center"}
                                fontWeight="normal"
                                mb={5}
                            >
                                {t("Propriétés produit")}
                            </Heading>
                            {dataProperties.map((val, key) => {
                                return (
                                    <Box key={key} mt={2}>
                                        <Flex gap={5}>
                                            <FormControl id="couleur" isRequired>
                                                <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                    {t("Couleur")}
                                                </FormLabel>
                                                <Input
                                                    id="couleur"
                                                    name="color"
                                                    type="text"
                                                    color={colorInput_BoxProperties}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    placeholder={t("Couleur")}
                                                    value={val.color}
                                                    onChange={(e) => handleChangeProperty(e, key)}
                                                />
                                            </FormControl>
                                            <FormControl id="size" isRequired>
                                                <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                    {t("Taille")}
                                                </FormLabel>
                                                <Input
                                                    id="size"
                                                    name="size"
                                                    type="text"
                                                    color={colorInput_BoxProperties}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    placeholder={t("Taille")}
                                                    value={val.size}
                                                    onChange={(e) => handleChangeProperty(e, key)}
                                                />
                                            </FormControl>
                                        </Flex>
                                        <Flex gap={5} mt={3}>
                                            <FormControl id="quantity" isRequired>
                                                <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                    {t("Quantité")}
                                                </FormLabel>
                                                <Input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="text"
                                                    color={colorInput_BoxProperties}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    placeholder={t("Quantité")}
                                                    value={val.quantity}
                                                    onChange={(e) => {
                                                        handleChangeProperty(e, key);
                                                        addTotalQntt();
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl id="price" isRequired>
                                                <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                    {t("Prix d'achat")}
                                                </FormLabel>
                                                <Input
                                                    id="price"
                                                    name="purchase_price"
                                                    type="text"
                                                    color={colorInput_BoxProperties}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    placeholder={t("Prix d'achat")}
                                                    value={val.purchase_price}
                                                    onChange={(e) => {
                                                        handleChangeProperty(e, key);
                                                        addTotalPrice();
                                                    }}
                                                />
                                            </FormControl>
                                        </Flex>

                                        <Flex justifyContent={"end"} mt={3} gap={3}>
                                            <Flex justifyContent={"end"} gap={3}>
                                                {dataProperties.length > 1 && (
                                                    <Button
                                                        colorScheme="red"
                                                        size={"sm"}
                                                        variant={"outline"}
                                                        onClick={() => {
                                                            removeProperty(key);
                                                            removeTotlaQntt(val.quantity);
                                                            removeTotlaPrice(val.price);
                                                        }}
                                                    >
                                                        {t("Supprimer")}
                                                    </Button>
                                                )}
                                                {dataProperties.length - 1 === key && (
                                                    <Button
                                                        colorScheme="green"
                                                        size={"sm"}
                                                        variant={"outline"}
                                                        onClick={() => {
                                                            addProperty();
                                                        }}
                                                    >
                                                        Plus
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Box>
                                );
                            })}
                        </Stack>

                        <Stack
                            mt={3}
                            pt={3}
                            px={7}
                            bg={useColorModeValue("gray.50", "#212835")}
                            rounded="lg"
                            pb={5}
                        >
                            <Text color={useColorModeValue("gray", "gray.400")}>Total</Text>
                            <Flex justifyContent={"space-between"}>
                                <Flex mt={2} gap={2}>
                                    <Text
                                        textTransform={"uppercase"}
                                        color={useColorModeValue("gray", "gray.400")}
                                    >
                                        {t("Quantité")} :{" "}
                                    </Text>
                                    <Text color={useColorModeValue("gray", "gray.200")}>
                                        {totalQuantity}
                                    </Text>
                                </Flex>
                                <Flex mt={2} gap={2}>
                                    <Text
                                        textTransform={"uppercase"}
                                        color={useColorModeValue("gray", "gray.400")}
                                    >
                                        {t("Prix")} :{" "}
                                    </Text>
                                    <Text color={useColorModeValue("gray", "gray.200")}>
                                        {totalPrice}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Stack>
                    </>
                </Box>
            </Flex>

            <Flex
                justify={'center'}>
                <Flex w={{ base: '100%', lg: '75%' }} justify={"end"}>
                    <Button
                        w="7rem"
                        colorScheme="purple"
                        size={"md"}
                        variant={"outline"}
                        onClick={UpgradeStockProduct}
                    >
                        {t("Ajouter")}
                    </Button>
                </Flex>
            </Flex>

        </Stack>
    )
}

export default UpgradeStock