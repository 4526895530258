import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, Textarea, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../../assets/images/bell.png'
import animationData from '../../../assets/lotties/notif.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../../components/MyPagination';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function GestNotifications() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState()

    const { user, setUser } = useStateContext();
    const [notificationsData, setNotificationsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [notifData, setNotifData] = useState();
    const [notifId, setNotifId] = useState('');

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [selectedCible, setSelectedCible] = useState(null);
    const [selectedC, setSelectedC] = useState('');
    const [checkLengthData, setCheckLengthData] = useState(false)

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const handleCibleChange = (ev) => {
        setSelectedCible(ev.target.value)

    }
    const [paginationData, setPaginationData] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        setLoadingData(true)

        axiosClient.get(`/notifications/getAllNotifications/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&cible=${selectedC}&userId=${user.id}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setNotificationsData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

    }, [pageSize, currentPage, searchTerm, selectedC, user])

    function update() {
        setLoadingData(true)

        axiosClient.get(`/notifications/getAllNotifications/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&cible=${selectedC}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setNotificationsData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return notificationsData.filter((row) => {
    //         return row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.content.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()) ||
    //             row.cible.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())

    //     });
    // }, [notificationsData, searchTerm]);

    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/notifications/${id}/`)
            .then((response) => {
                setNotificationsData((prevData) => prevData.filter((row) => row.id !== id));
                // setNotificationsData(notificationsData.filter((row) => row.id !== id));
                toast({
                    description: t("la notification est supprimé avec succès"),
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    const AjoutNotification = () => {
        if (!title || title.trim() === "") {
            setMessage(t("Veuillez remplir le titre"))
            return;
        }
        if (!content || content.trim() === "") {
            setMessage(t("Veuillez remplir le contenu"))
            return;
        }
        const formData = new FormData()
        formData.append("title", title)
        formData.append("cible", selectedCible)
        formData.append("content", content)
        formData.append("added_by", user.id)

        axiosClient.post('/notifications/', formData)
            .then((response) => {
                setNotificationsData([response.data, ...notificationsData]);
                setTitle('')
                setContent('')
                setSelectedCible('')
                setMessage("")
                update()
                toast({
                    description: t(`La notificiation est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
        update()
    }

    const UpdateNotif = (id) => {

        const formData = new FormData();
        if (title) formData.append("title", title)
        if (selectedCible) formData.append("cible", selectedCible)
        if (content) formData.append("content", content)

        axiosClient.patch(`/notifications/${id}/`, formData).then(() => {

            update()

            toast({
                description: t(`La notification est modifiée avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            setTitle('')
            setContent('')
            setSelectedCible('')
            setMessage("")
        })

        onClose()
    }
    const myColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("gray.300", "gray.600");

    const hoverDeleteBtn = useColorModeValue("red.50", "gray.600");
    const DeleteBtnIcon = useColorModeValue("red.400", "red.300");

    return (
        <Box position={"relative"}>
            <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Notifications</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>
            {(paginationData?.length === 0 && !searchTerm && !selectedC) ? (
                <Flex mb={'10'} mt={20} flexDirection={'column'} align={'center'} justify={'center'}>
                    <Image
                        height={150} width={150}
                        rounded={'md'}
                        alt={'empty'}
                        src={
                            empty
                        }
                        objectFit={'empty'}
                    />
                    <Text mt={10} color={"#aeaeae"}>{t("Il n'y a pas de notifications à afficher")}</Text>
                </Flex>) :



                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'stretch', md: 'center' }}
                    justify={'center'}
                    p={{ base: 1, sm: 5 }}
                    gap={10}
                >

                    <Flex
                        direction="column"
                        justify={{ base: 'flex-start', md: 'space-between' }}
                        width={{ base: "100%", lg: "70%" }}
                    >
                        <Flex

                            // px={5}
                            justifyContent="end"
                            alignItems="center"
                            direction={{ base: 'column', md: 'row' }}
                            mb={{ base: 2, md: 4 }}
                        >
                            <Select borderRadius={"5"} borderColor={borderColor} fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: "25%" }} mr={{ base: 0, md: 5 }} mb={4} value={selectedC} onChange={(e) => setSelectedC(e.target.value)}>
                                <option value="">{t("Tout")}</option>
                                <option value="AdminClient">{t("Clients")}</option>
                                {user.role === "Admin" && <option value="Manager">{t("Manager")}</option>}

                                <option value="RespoSuivi">Call center</option>
                                <option value="StockManager">{t("Stock Manager")}</option>
                                <option value="All">Tous les membres</option>
                            </Select>
                            <InputGroup w={{ base: '100%', md: "40%" }}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color='gray.300' />}
                                />
                                <Input
                                    borderColor={borderColor}
                                    borderRadius={"5"}
                                    placeholder={t("Recherche...")}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setCurrentPage(0)
                                        setSearchTerm(e.target.value)
                                    }}
                                    mb={4}
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                />  </InputGroup>

                        </Flex>

                        {(paginationData?.length !== 0) &&
                            notificationsData.map((row) => (

                                <Stack
                                    bg={myColor}
                                    mb={4}
                                    rounded={'lg'}
                                    p={6}
                                    boxShadow={'md'}
                                    alignItems={'start'}
                                    justify="space-between"
                                // as={"Button"}

                                >
                                    <Flex direction="row"
                                        justify="space-between"
                                        ml={'auto'}
                                        width="full">
                                        <Stack display={'flex'} flexDirection={{ base: "column", md: "row" }} align={'center'}>
                                            <Text fontSize={'lg'} fontWeight="semibold">{row.title}</Text>
                                            <Stack mt={1}>
                                                <Badge
                                                    rounded={'lg'}
                                                    colorScheme={row.cible === "Manager" ? "blue" :
                                                        row.cible === "RespoSuivi" ? "green" :
                                                            row.cible === "StockManager" ? "yellow" :
                                                                row.cible === "All" ? "pink" :
                                                                    row.cible === "AdminClient" ? "purple" : ""}
                                                >
                                                    {row.cible === "Manager" ? "Manager" :
                                                        row.cible === "RespoSuivi" ? t("Call center") :
                                                            row.cible === "StockManager" ? `Stock Manager ` :
                                                                row.cible === "All" ? `All ` :
                                                                    row.cible === "AdminClient" ? "Client" : ""}
                                                </Badge>
                                            </Stack>
                                        </Stack>
                                        <Flex align={"center"}>
                                            <Text  fontSize={'xs'} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'), { hour: '2-digit', minute: '2-digit' })}</Text>
                                            <Stack
                                                color={DeleteBtnIcon}
                                                onClick={() => {
                                                    setId(row.id);
                                                    onOpenAlert();
                                                }}
                                                cursor='pointer'
                                                p={3}
                                                rounded={"full"}
                                                _hover={{ bg: hoverDeleteBtn }}
                                                ml={2}
                                            >
                                                <DeleteIcon


                                                    size="sm"

                                                // color='#e53e3e'
                                                // icon={<DeleteIcon />}>
                                                />
                                            </Stack>

                                        </Flex>

                                    </Flex>

                                    <Flex
                                        direction="row"
                                        width="full"
                                        ml={'auto'}
                                        maxHeight={"150px"}
                                        overflowY={"auto"}
                                        pr={2}
                                        justify="space-between">
                                        <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={"100%"}>
                                            {row.content}

                                        </Text>


                                    </Flex>
                                </Stack>
                            ))}


                        {((searchTerm && paginationData?.length === 0) || (selectedC && paginationData?.length === 0) || (checkLengthData)) && (
                            <Flex alignItems={'center'}>
                                <Text >{t("Aucune notification correspondante n'a été trouvée.")}</Text>
                            </Flex>

                        )}

                        {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                            <Box flex="1">
                                <MyPagination
                                    paginationData={paginationData}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    PAGE_SIZE={pageSize}
                                />
                            </Box>

                            <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Select>
                        </Flex>
                        {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                    </Flex>


                </Flex>
            }

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedCible('')
                    setTitle('')
                    setContent('')
                    setSelectedCible('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'une notification")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Titre")}</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setTitle(e.target.value)} placeholder={t('Titre de notification...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea height={"100px"} resize={"none"} type='text' name="message" onChange={e => setContent(e.target.value)} placeholder={t('Message de notification...')} />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>{t("Cible")}</FormLabel>
                                        <Select onChange={handleCibleChange} placeholder={t('Sélectionner une option')}>
                                            <option value="AdminClient">{t("Client")}</option>
                                            {user.role === "Admin" && <option value="Manager">{t("Manager")}</option>}
                                            <option value="RespoSuivi">Call center</option>
                                            <option value="StockManager">{t("Stock Manager")}</option>
                                            <option value="All">All</option>
                                        </Select>
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!title || !content || !selectedCible)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutNotification}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedCible('')
                    setTitle('')
                    setContent('')
                    setSelectedCible('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Modification de notification")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Titre")}</FormLabel>
                                        <Input maxLength={50} defaultValue={notifData.title} name="title" onChange={e => setTitle(e.target.value)} placeholder={t('Titre de notification...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea height={"100px"} defaultValue={notifData.content} resize={"none"} type='text' name="message" onChange={e => setContent(e.target.value)} placeholder={t('Message de notification...')} />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>{t("Cible")}</FormLabel>
                                        <Select onChange={handleCibleChange} defaultValue={notifData.cible} placeholder={t('Sélectionner une option')}>
                                            <option value="AdminClient">{t("Client")}</option>
                                            <option value="Manager">{t("Manager")}</option>
                                            <option value="RespoSuivi">Call center</option>
                                            <option value="StockManager">{t("Stock Manager")}</option>
                                            <option value="All">All</option>
                                        </Select>
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateNotif(notifId)}
                            >
                                {t("Modifier")}
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>
    )
}

export default GestNotifications