import { SearchIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, IconButton, Input, InputGroup, InputLeftElement, Select, Skeleton, SkeletonCircle, Stack, Switch, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { PiPlusBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import { FiEdit3 } from 'react-icons/fi';
import { MdGroupRemove } from 'react-icons/md';
import axiosClient from '../../../axios-client';
import MyPagination from '../../../components/MyPagination';
import { LiaUserEditSolid } from 'react-icons/lia';

function GestUsersAdmin() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { user, setUser } = useStateContext();
    const [loadingData, setLoadingData] = useState(true)
    const toast = useToast()

    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedRole, setSelectedRole] = useState()
    const [fullName, setFullName] = useState()
    const [oldRole, setOldRole] = useState()
    const [newRole, setNewRole] = useState()
    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [message, setMessage] = useState(false)

    //Apprenant data
    const [data, setData] = useState([]);
    const [checkLengthData, setCheckLengthData] = useState(false)

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        // setLoadingData(true)
        axiosClient.get(`/admins/getAllAdmins/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&role=${selectedUser}&id=${user.id}`)
            .then((response) => {

                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });
    }, [user.role, selectedEtat, pageSize, currentPage, searchTerm,selectedUser]);

    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_user_etat/${id}/`, formData)
            .then((response) => {
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: t("le compte est activé"),
                variant: 'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: t("le compte est désactivé"),
                status: 'warning',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }
    const handleRoleSubmit = () => {
        changeRole(deleteId, selectedRole);
        setIsModalOpen(false);
    };

    const changeRole = (id) => {
        const updatedData = data.map(row => {
            if (row.id === id) {
                const formData = new FormData();

                formData.append("role_admin", selectedRole);
                formData.append("edited_by", user.first_name + ' ' + user.last_name);

                axiosClient.put(`/update_user_role/${id}/`, formData)
                    .then((response) => {
                        // const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                    // date_modification: (updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: t("le role est changé avec succès"),
                            status: 'success',
                            duration: 2000,
                            variant: 'left-accent',
                            isClosable: true,
                            position: 'bottom-right'
                        })
                    })

                    .catch((error) => console.error(error));

            }
        });
    }
    const handleDelete = async (id = deleteId) => {
        if (!id) return;
    
        try {
    
                await axiosClient.post('/commandes/update_commande_assignments_admin/', { deleted_user_id: id , userId:user.id});
    
                // await axiosClient.delete(`/admins/${id}/`);
                
                setData((prevData) => prevData.filter((row) => row.id !== id));
                
                toast({
                    description: t("l'utilisateur est supprimé avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                });
            
        } catch (error) {
            console.error(error);
            toast({
                description: t("Erreur lors de la suppression de l'utilisateur"),
                status: 'error',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            });
        }
    
        onCloseAlert();
    };
    const borderColor = useColorModeValue("gray.300", "gray.600")
    return (
        <Box position={"relative"} mb={5}>
            <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        navigate("/ajouter-utilisateur-admin")
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Utilisateurs")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>
            <Flex
                px={2}
                py={5}
                mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={2}
                align={'center'}
                justify={'center'}

            >

                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                        mb={2}
                    >
                        <Flex w={"100%"}>
                            <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">{t("Tout les utilisateurs")}</option>
                                <option value="Manager">{t("Manager")}</option>
                                <option value="RespoSuivi">{t("Centre d'appel")} </option>
                                <option value="StockManager">{t("Gestionnaire de stock")}</option>
                            </Select>
                            <Select borderColor={borderColor}   borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">{t("Tout")}</option>
                                <option value="true">{t("Activé")}</option>
                                <option value="false">{t("Désactivé")}</option>
                            </Select>
                        </Flex>


                        <InputGroup size="md" w={{ base: '100%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color={borderColor} />}
                            />
                            <Input
                                borderColor={borderColor}
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table variant='simple'>
                            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>{t("Utilisateur")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("État")}</Th>
                                    <Th borderBottomColor='gray.400'>Role</Th>
                                    <Th borderBottomColor='gray.400'>{t("Date Création")}</Th>
                                    <Th borderBottomColor='gray.400'>Options</Th>

                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}
                                    {(!searchTerm && selectedEtat === null && !selectedUser) && paginationData?.length === 0 ? (
                                    <Tr>
                                        <Td colSpan={11}>{t("Il n'y a pas d'utilisateurs à afficher")}</Td>
                                    </Tr>) :
                                    <>
                                {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        <Tr key={row.id}>
                                            <Td
                                                minWidth={{ sm: "250px" }}
                                                ps='0px'
                                                borderBottomColor='gray.300'>
                                                <Flex align='center' minWidth='100%' flexWrap='nowrap'>
                                                    <Avatar w='50px'
                                                        borderRadius='12px'
                                                        me='18px'
                                                        name='Dan Abrahmov' src={row.image} />

                                                    <Flex direction='column'>
                                                        <Text
                                                            fontSize='sm'

                                                            fontWeight='normal'
                                                            minWidth='100%'>
                                                            {row.first_name} {row.last_name}
                                                        </Text>
                                                        <Text fontSize='sm' color='gray.500' fontWeight='normal'>
                                                            {row.email}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td borderBottomColor='gray.300'> {(row.pays || row.ville || row.rue || row.code_postal) ? `${row.pays} ${row.ville} ${row.rue} ${row.code_postal}` : <Text color={'gray.400'} fontSize={"14px"}>{t("pas disponible")}</Text>} </Td>
                                            <Td borderBottomColor='gray.300'>
                                                <Flex>
                                                    <Stack direction='row' mr="3px">
                                                        {(user.role === "Admin" || user.role === "Manager") &&
                                                            <Switch
                                                                colorScheme='green'
                                                                isChecked={row.etat}
                                                                onChange={() => handleEtatClick(row.id, row.etat)}
                                                            />
                                                        }
                                                    </Stack>

                                                    <Badge
                                                        size="sm"
                                                        rounded={'lg'}
                                                        colorScheme={!row.etat ? 'red' : 'green'}
                                                    >
                                                        {row.etat ? t("Activé") : t("Désactivé")}

                                                    </Badge>
                                                </Flex>
                                            </Td>
                                            {/* <Td borderBottomColor='gray.300'>{row.role}</Td> */}
                                            <Td borderBottomColor='gray.300' >
                                                
                                                <Badge
                                                    colorScheme={row.role === "Manager" ? 'purple' : row.role === "RespoSuivi" ? 'blue' : row.role === "StockManager" ? "yellow" : "gray"}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    rounded="full"
                                                    w={"fit-content"}
                                                    py={1}
                                                    px={3}
                                                    size={"sm"}
                                                    cursor={"pointer"}
                                                    title={"changer le rôle de l'utilisateur"}
                                                    onClick={() => {
                                                        setFullName(`${row.first_name} ${row.last_name}`)
                                                        setSelectedRole(row.role)
                                                        setDeleteId(row.id);
                                                        setIsModalOpen(true);


                                                    }}>
                                                    <LiaUserEditSolid size={18} />
                                                    <Text fontSize={"xs"} ml={1}>{row.role === "Manager" ? t("Manager") : row.role === "RespoSuivi" ? t("Centre d'appel") : row.role === "StockManager" ? t("Gestionnaire de stock") : null}</Text>
                                                </Badge>
                                            </Td>
                                            <Td borderBottomColor='gray.300'>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'))}</Td>
                                            <Td borderBottomColor='gray.300'>
                                                <IconButton
                                                    size="sm"
                                                    onClick={() => navigate(`/modifier-utilisateur-admin`, {
                                                        state: {
                                                            idUser: row.id
                                                        }
                                                    })}
                                                    mr="5px"
                                                    variant={'outline'}
                                                    colorScheme="whatsapp"
                                                    icon={<FiEdit3 />}>
                                                </IconButton>
                                                {(user.role === "Admin" || user.role === "Manager") &&

                                                    <IconButton
                                                        size="sm"
                                                        onClick={() => {
                                                            setDeleteId(row.id);
                                                            setSelectedRole(row.role === "Manager" ? t("Manager") : row.role === "RespoSuivi" ? t("Centre d'appel") : row.role === "StockManager" ? t("Gestionnaire de stock") : null)
                                                            onOpenAlert();

                                                        }}
                                                        variant={'outline'}
                                                        mr="5px"
                                                        colorScheme="red"
                                                        icon={<MdGroupRemove />}>
                                                    </IconButton>}
                                            </Td>
                                        </Tr>
                                    ))}</>}
                                
                                {(((searchTerm || selectedEtat !== null || selectedUser ) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>

                            {/* <Tfoot>
                            <Tr>
                                    <Th borderBottomColor='gray.400'>Utilisateur</Th>
                                    <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("État")}</Th>
                                    <Th borderBottomColor='gray.400'>Role</Th>
                                    <Th borderBottomColor='gray.400'>{t("Date Création")}</Th>
                                    <Th borderBottomColor='gray.400'>Options</Th>

                                </Tr>
                            </Tfoot> */}
                        </Table>


                        {/* <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                            </Select> */}
                    </TableContainer>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select>
                    </Flex>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Supprimer')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {t(`Attention : Si cet utilisateur a des responsabilités de gestion, ses tâches vous seront attribuées`)}
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t('Annuler')}
                            </Button>
                            <Button size={"sm"} onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t('Supprimer')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Modifier le rôle de")} {fullName}
                        </AlertDialogHeader>

                        <AlertDialogBody >

                            <Stack>
                                <Text mb={2} fontSize={"14px"}>
                                    {t("Êtes-vous sûr de vouloir changer le rôle de cet utilisateur ? Changer le rôle mettra à jour ses privilèges et peut affecter sa capacité à effectuer certaines actions.")}
                                </Text>
                                <Select
                                    size={"sm"}
                                    defaultValue={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                    {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                        <option value="MASTER">Super-administrateur</option>
                    } */}
                                    <option value="Manager">{t("Manager")}</option>
                                    <option value="RespoSuivi">{t("Centre d'appel")}</option>
                                    <option value="StockManager">{t("Gestionnaire de stock")} </option>
                                </Select>
                            </Stack>


                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage('')
                                setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button size={"sm"} onClick={handleRoleSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                {t("Modifier")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}

export default GestUsersAdmin