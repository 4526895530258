import { Alert, AlertIcon, Avatar, AvatarGroup, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, ButtonGroup, Card, CardBody, CardFooter, Divider, Flex, Heading, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, Select, SimpleGrid, Stack, StackDivider, Text, useBreakpointValue, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoAddSharp, IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { AddIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { CiMenuKebab } from "react-icons/ci";
import { FiEdit3 } from 'react-icons/fi';
import { MdHistory } from 'react-icons/md';
import { TbListDetails, TbTruckDelivery, TbTruckReturn } from "react-icons/tb";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { TbPackages } from "react-icons/tb";
import { MdDeliveryDining } from "react-icons/md";
import { GrStakeholder } from "react-icons/gr";
import axiosClient from '../../../axios-client';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick';




const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
}

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
}

const slideImages = [
    {
        image: 'https://bit.ly/ryan-florence',
    },
    {
        image: 'https://bit.ly/sage-adebayo',
    },
    {
        image: 'https://bit.ly/kent-c-dodds',
    },
    {
        image: 'https://bit.ly/prosper-baba',
    },
    {
        image: 'http://127.0.0.1:8000/media/uploads/images/jsx.png',
    },
];

// Settings for the slider
const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
}


function DetailsStock() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()

    const [detailProduct, setDetailProduct] = useState()

    const [dataDetailProduct, setDataDetailProduct] = useState([])
    const [imagesProduct, setImagesProduct] = useState([])
    const [dataStock, setDataStock] = useState([])

    const [slider, setSlider] = useState(null)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axiosClient.get(`/products/${location.state.id}/`).then(res => {
            console.log(res.data);
            setDataDetailProduct(res.data)
            setImagesProduct(res.data.images)
            setDataStock(res.data.stock.filter(e => e.is_deleted === false))
            setLoading(false)
        }).catch(err => {
            console.log('ERROR ! ', err);
            setLoading(false)
        })
        axiosClient.get(`/products/getDetailsProduct/?idProduct=${location.state.id}`).then(res => {
            setDetailProduct(res.data)
            console.log('Detail productttt: ', res.data);
        })
    }, [])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const descColor = useColorModeValue('gray.400', 'gray.500')
    const menuCard = useColorModeValue('white', 'gray.500')

    const ProductAnalytics = () => {
        return (
            <>
                <Text fontWeight="semibold" mt={2} fontSize="xl">{t("Analyse du produit")}</Text>
                <Flex alignItems={'center'} mt={2.5} justify={'space-between'}>
                    <Flex alignItems={'center'} gap={2}>
                        <TbPackages size={20} />
                        <Text fontSize={"sm"} color={"gray"}>{t('Quantité total')}</Text>
                    </Flex>
                    <Badge colorScheme='purple' rounded={"xl"} fontSize={15} px={2}>1000</Badge>
                </Flex>
                <Flex alignItems={'center'} mt={2.5} justify={'space-between'}>
                    <Flex alignItems={'center'} gap={2}>
                        <MdDeliveryDining size={20} />
                        <Text fontSize={"sm"} color={"gray"}>{t("En cours de livraison")}</Text>
                    </Flex>
                    <Badge colorScheme='orange' rounded={"xl"} fontSize={15} px={2}>500</Badge>
                </Flex>
                <Flex alignItems={'center'} mt={2.5} justify={'space-between'}>
                    <Flex alignItems={'center'} gap={2}>
                        <GrStakeholder size={20} />
                        <Text fontSize={"sm"} mt={1} color={"gray"}>{t("Total livré")}</Text>
                    </Flex>
                    <Badge colorScheme='green' rounded={"xl"} fontSize={15} px={2}>350</Badge>
                </Flex>
            </>
        )
    }

    return (
        <Stack position={'relative'}>

            <Flex>
                <Box w="90%" pt='12px' mb={8}>
                   
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/My_Stock')}>{t("Gestion stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Détails stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>

            <Flex
                gap={5}
                flexDirection={{ base: 'column', xl: 'column' }}
            >
                {/* <Flex flexDirection={{ base: 'column', md: 'row', xl: 'column' }} gap={5}>
                    <Box
                        bg={useColorModeValue("white", "#212835")}
                        w={{ base: "100%", lg: 350 }}
                        // h={350}
                        px={2}
                        pt={1}>
                        <Slide>
                            {imagesProduct.map((slideImage, index) => (
                                <Stack key={index}>
                                    <Image src={slideImage.image} h={350} w={{ base: "100%", lg: 350 }} objectFit={'contain'} />
                                </Stack>
                            ))}
                        </Slide>

                        <Flex mt={3} display={{ base: 'block', lg: 'none' }} flexDirection={'column'} gap={5} bg={useColorModeValue("white", "#212835")} p={3} rounded={"xl"} w={"100%"}>
                            <Divider h={.3} bg={'gray.500'} />
                            <ProductAnalytics />
                        </Flex>
                    </Box>
                    <Flex display={{ base: 'none', lg: 'block' }} flexDirection={'column'} gap={2} bg={useColorModeValue("white", "#212835")} p={3} rounded={"xl"} w={{ base: "100%", lg: 350 }} >
                        <ProductAnalytics />
                    </Flex>
                </Flex> */}

                <SimpleGrid px={{ base: 5, md: 10 }} columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4}>
                        {/* <Text
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize={'sm'}
                            bg={useColorModeValue('blue.50', 'blue.900')}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}>
                            Our Story
                        </Text> */}
                        <Heading>{dataDetailProduct.title}</Heading>
                        {/* <Text color={'gray.500'} fontSize={'lg'}>
                            {dataDetailProduct.description}
                        </Text> */}
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider borderColor={useColorModeValue('purple.100', 'purple.700')} />
                            }>
                            <Stack direction={'row'} align={'center'} justify={"space-between"}>
                                <Flex align={'center'} gap={3}>
                                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('yellow.100', 'yellow.900')}>
                                        <Icon as={TbPackages} color={'yellow.500'} w={5} h={5} />
                                    </Flex>
                                    <Text fontWeight={600}>{t("En cours de traitement")}</Text>
                                </Flex>
                                <Badge bg={useColorModeValue('yellow.100', 'yellow.900')} color={'yellow.500'} rounded={"xl"} fontSize={15} px={2}>{detailProduct?.en_cours_traitement}</Badge>
                            </Stack>
                            <Stack direction={'row'} align={'center'} justify={"space-between"}>
                                <Flex align={'center'} gap={3}>
                                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('green.100', 'green.900')}>
                                        <Icon as={TbTruckDelivery} color={'green.500'} w={5} h={5} />
                                    </Flex>
                                    <Text fontWeight={600}>{t("En cours de livraison")}</Text>
                                </Flex>
                                <Badge bg={useColorModeValue('green.100', 'green.900')} color={'green.500'} rounded={"xl"} fontSize={15} px={2}>{detailProduct?.en_cours_livraison}</Badge>
                            </Stack>
                            <Stack direction={'row'} align={'center'} justify={"space-between"}>
                                <Flex align={'center'} gap={3}>
                                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('red.100', 'red.900')}>
                                        <Icon as={TbTruckReturn} color={'red.500'} w={5} h={5} />
                                    </Flex>
                                    <Text fontWeight={600}>{t("En cours de retour")}</Text>
                                </Flex>
                                <Badge bg={useColorModeValue('red.100', 'red.900')} color={'red.500'} rounded={"xl"} fontSize={15} px={2}>{detailProduct?.en_cours_retour}</Badge>
                            </Stack>
                            <Stack direction={'row'} align={'center'} justify={"space-between"}>
                                <Flex align={'center'} gap={3}>
                                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('purple.100', 'purple.900')}>
                                        <Icon as={GrStakeholder} color={'purple.500'} w={5} h={5} />
                                    </Flex>
                                    <Text fontWeight={600}>{t("Total livré")}</Text>
                                </Flex>
                                <Badge bg={useColorModeValue('purple.100', 'purple.900')} color={'purple.500'} rounded={"xl"} fontSize={15} px={2}>{detailProduct?.total_livre}</Badge>
                            </Stack>
                            <Stack direction={'row'} align={'center'} justify={"space-between"}>
                                <Flex align={'center'} gap={3}>
                                    <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('blue.100', 'blue.900')}>
                                        <Icon as={IoSearchSharp} color={'blue.500'} w={5} h={5} />
                                    </Flex>
                                    <Text fontWeight={600}>{t("Stock disponible")}</Text>
                                </Flex>
                                <Badge bg={useColorModeValue('blue.100', 'blue.900')} color={'purple.500'} rounded={"xl"} fontSize={15} px={2}>{detailProduct?.en_cours_traitement}</Badge>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Flex>
                        <Box position={'relative'} height={'full'} width={'full'} overflow={'hidden'}>
                            {/* CSS files for react-slick */}
                            <link
                                rel="stylesheet"
                                type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                            />
                            <link
                                rel="stylesheet"
                                type="text/css"
                                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                            />

                            {/* Left Icon */}
                            <IconButton
                                aria-label="left-arrow"
                                colorScheme="messenger"
                                borderRadius="full"
                                position="absolute"
                                left={useBreakpointValue({ base: '30%', md: '10px' })}
                                top={useBreakpointValue({ base: '90%', md: '50%' })}
                                transform={'translate(0%, -50%)'}
                                zIndex={2}
                                onClick={() => slider?.slickPrev()}>
                                <BiLeftArrowAlt />
                            </IconButton>
                            {/* Right Icon */}
                            <IconButton
                                aria-label="right-arrow"
                                colorScheme="messenger"
                                borderRadius="full"
                                position="absolute"
                                right={useBreakpointValue({ base: '30%', md: '10px' })}
                                top={useBreakpointValue({ base: '90%', md: '50%' })}
                                transform={'translate(0%, -50%)'}
                                zIndex={2}
                                onClick={() => slider?.slickNext()}>
                                <BiRightArrowAlt />
                            </IconButton>
                            {/* Slider */}
                            <Slider {...settings} ref={(slider) => setSlider(slider)}>
                                {imagesProduct.map((slideImage, index) => (
                                    // <Box
                                    //     key={index}
                                    //     height={'100px'}
                                    //     position="relative"
                                    //     backgroundPosition="center"
                                    //     backgroundRepeat="no-repeat"
                                    //     backgroundSize="cover"
                                    //     backgroundImage={slideImage.image}
                                    // />
                                    <Image src={slideImage.image} h={350} objectFit={'contain'} />
                                ))}
                            </Slider>

                        </Box>
                        {/* <Image
                            rounded={'md'}
                            alt={'feature image'}
                            src={
                                'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                            }
                            objectFit={'cover'}
                        /> */}
                    </Flex>
                </SimpleGrid>
                {/* ------------------------------------------------------------------------------------------------------------------------------ */}
                <Divider display={{ base: 'block', lg: 'none' }} h={.5} bg={'purple.500'} />
                <Flex mt={8} px={{ base: 5, md: 10 }} flexDirection={'column'} w={{ xl: '100%' }} gap={4}>
                    <Box
                        bg={useColorModeValue("white", "#212835")}
                        rounded={'xl'}
                        py={4}
                        pl={2}
                        pr={(!loading && dataStock.length === 0) && 2}
                    >
                        <Text color={useColorModeValue('gray', 'gray.400')} ml={3}>{t("Stock produit")}</Text>
                        {(!loading && dataStock.length !== 0) && <TableContainer mt={3} overflowY={'auto'} maxH={500}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>{t("Couleur")}</Th>
                                        <Th>{t("Taille")}</Th>
                                        <Th>{t("Quantité")}</Th>
                                        <Th>{t("Prix d'achat")}</Th>
                                        <Th>{t("Date d'achat")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        dataStock.map((val, key) => {
                                            return (
                                                <>
                                                    <Tr key={key}>
                                                        <Td>{val.color ? val.color : '-'}</Td>
                                                        <Td>{val.size ? val.size : '-'}</Td>
                                                        <Td>{val.quantity}</Td>
                                                        <Td>{val.purchase_price}</Td>
                                                        <Td>{new Date(val.date_creation).toLocaleDateString(t('fr-FR'))}</Td>
                                                    </Tr>
                                                </>
                                            )
                                        })
                                    }

                                </Tbody>
                            </Table>
                        </TableContainer>}

                        {(!loading && dataStock.length === 0) &&
                            <Alert status='warning' variant='left-accent'>
                                <AlertIcon />
                                {t("Votre stock est actuellement vide.")}
                            </Alert>
                        }
                    </Box>
                </Flex>
            </Flex>

        </Stack>
    )
}

export default DetailsStock