import { SearchIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Input, InputGroup, InputLeftElement, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function HistoriqueStock() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Stack>

            <Flex>
                <Box w="90%" pt="12px" mb={8}>
                    <Text
                        fontFamily="Plus Jakarta Display"
                        as="cite"
                        fontSize={{ base: "20px", sm: "25px", lg: "27px" }}
                        color="gray"
                    >
                        <span
                            onClick={() => navigate("/")}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={(e) =>
                                (e.target.style.textDecoration = "underline")
                            }
                            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                        >
                            {" "}
                            {t("Accueil")}
                        </span>{" "}
                        / <span>{t("Historique")}</span>
                    </Text>
                </Box>
            </Flex>

            <Stack
                px={2}
                py={5}
                // mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={2}
            >
                <Flex
                    justifyContent="end"
                    alignItems="center"
                    w={"100%"}
                >
                    <InputGroup size="md" w={{ base: '80%', md: "60%", lg: "40%" }} borderColor={useColorModeValue('gray.400', "gray.500")}>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<SearchIcon color='gray' />}
                        />
                        <Input

                            borderRadius="5"
                            placeholder={t("Recherche...")}
                            mb={4}
                            sx={{
                                marginLeft: 'auto',
                            }}
                        />
                    </InputGroup>
                </Flex>

                <TableContainer>
                    <Table size={{ base: 'sm', md: 'md' }} variant='simple'>
                        <Thead>
                            <Tr>
                                <Th borderBottomColor='gray.400'>Image</Th>
                                <Th borderBottomColor='gray.400'>{t("titre")}</Th>
                                <Th borderBottomColor='gray.400'>{t("description")}</Th>
                                <Th borderBottomColor='gray.400'>{t("couleur")}</Th>
                                <Th borderBottomColor='gray.400'>{t("taille")}</Th>
                                <Th borderBottomColor='gray.400'>{t("quantité")}</Th>
                                <Th borderBottomColor='gray.400'>{t("prix")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Date de modification")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <>
                                <Tr>
                                    <Td>
                                        <Avatar w='50px'
                                            borderRadius='12px'
                                            me='18px'
                                            name='Dan Abrahmov' src='https://bit.ly/kent-c-dodds' />
                                    </Td>
                                    <Td>
                                        <Text>Article1</Text>
                                    </Td>
                                    <Td>
                                        <Text>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                            Ea aut recusandae sint voluptatem provident! Asperiores 
                                            minima est voluptatibus totam animi explicabo deserunt 
                                            eveniet ut molestiae, nemo hic corporis saepe rem.
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>
                                            Red
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>
                                            Xl
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>
                                            56835
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>
                                            100
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Text>20-02-2024</Text>
                                    </Td>
                                </Tr>
                            </>
                        </Tbody>
                    </Table>
                </TableContainer>

            </Stack>

        </Stack>
    )
}

export default HistoriqueStock