import { AddIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Flex, IconButton, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, SkeletonCircle, Stack, Switch, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast, Tooltip,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    FormControl,
    FormLabel,

} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { PiPlusBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom'
import { FiEdit3 } from 'react-icons/fi';
import { MdGroupRemove } from 'react-icons/md';
import { LiaUserEditSolid } from 'react-icons/lia';
import { useStateContext } from '../../../../context/ContextProvider';
import axiosClient from '../../../../axios-client';
import { TbListDetails } from 'react-icons/tb';
import { RiDeleteBinLine, RiFileTransferLine } from 'react-icons/ri';
import MyPagination from '../../../../components/MyPagination';
import { IoIosCheckmark } from 'react-icons/io';
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaCloudArrowDown } from "react-icons/fa6";
import { LuTextCursorInput } from "react-icons/lu";



function New_Confirmed_Orders() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { user, setUser } = useStateContext();
    const toast = useToast()

    const [confirmUsers, setConfirmUsers] = useState([])
    const [dataOrders, setDataOrders] = useState([])
    const [paginationData, setPaginationData] = useState()

    const [searchTerm, setSearchTerm] = useState('');
    const [userSelected, setUserSelected] = useState('');

    const [dateDebutValue, setDateDebutValue] = useState("");
    const [dateFinValue, setDateFinValue] = useState("");

    const [loading, setLoading] = useState(true)

    const [userConfirmationSelected, setUserConfirmationSelected] = useState()

    const fetchUsers = async () => {
        try {
            await axiosClient.get(`/clients/getClientUsers/?id=${user.id}&role=RespoSuiviClient&page_size=100`).then(res => {
                setConfirmUsers(res.data.results)
                console.log('Confirmation users ll : ', res.data);
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        ["AdminClient", "ManagerClient"].includes(user.role) && fetchUsers()
    }, [])



    // PAGINATION

    const [currentPage, setCurrentPage] = useState(0);

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    const fetchData = async (pageSize, currentPage, searchTerm, dateDebutValue, dateFinValue) => {
        try {
            setLoading(true)
            await axiosClient.get(`/commandes/getNewConfirmedStock/?clientId=${user.id}&page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&dateDebut=${dateDebutValue}&dateFin=${dateFinValue}`).then(res => {

                if (res.data.length !== 0) {
                    setDataOrders(res.data.results)
                    setPaginationData(res.data)
                } else {
                    setDataOrders([])
                    setPaginationData([])
                }
            })
            setLoading(false)
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(pageSize, currentPage, searchTerm, dateDebutValue, dateFinValue)
    }, [user?.id, currentPage, searchTerm, pageSize, dateDebutValue, dateFinValue])


    // État pour stocker les lignes sélectionnées
    const [selectedRows, setSelectedRows] = useState([]);

    // Fonction pour gérer le clic sur la case à cocher
    const handleCheckboxClick = (index) => {
        // Vérifie si la ligne est déjà sélectionnée
        const selectedIndex = selectedRows.indexOf(index);
        if (selectedIndex === -1) {
            // Si la ligne n'est pas déjà sélectionnée, l'ajouter à l'état
            setSelectedRows([...selectedRows, index]);
        } else {
            // Si la ligne est déjà sélectionnée, la retirer de l'état
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        }

        console.log('INDEEEEEEEX : ', index);
    };

    //Assign Order TO user    

    const [isAssignOrderOpen, setIsAssignOrderOpen] = useState(false);
    const assignOrderRef = React.useRef()
    const [userID, setUserID] = useState()
    const [orderID, setOrderID] = useState()

    const onOpenAssignOrder = (user, order) => {
        setIsAssignOrderOpen(true);
        setUserID(user)
        setOrderID(order)
    };
    const onCloseAssignOrder = () => {
        setIsAssignOrderOpen(false);
        setUserID()
        setOrderID()
    };

    const assignOrder = async () => {
        try {
            await axiosClient.patch(`/commandes/${orderID}/`, {
                assigned_to_stock: userID
            }).then(res => {
                setDataOrders(dataOrders.map((val) => {
                    if (val.id === res.data.id) {
                        return {
                            ...val,
                            assigned_to_stock: res.data.assigned_to_stock
                        }
                    }
                    return val
                }))
                onCloseAssignOrder()
            })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }

    //ASSIGN LIST of ORDERs to user

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    const assign_order_to = async (user) => {

        console.log('USEEEER :', user);

        for (let index = 0; index < selectedRows.length; index++) {
            try {
                await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                    assigned_to: user
                })
            } catch (error) {
                console.log('Error : ', error);
            }
        }

        // setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
        setDataOrders(dataOrders.map((val) => {
            if (selectedRows.includes(val.id)) {

                return {
                    ...val,
                    assigned_to: userConfirmationSelected
                }
            }
            return val
        }))
        setSelectedRows([])
        onClose()
    }


    // DELETE

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [deletedId, setDeletedId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setDeletedId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setDeletedId()
    };

    const DeleteArrayOfOrders = async () => {
        console.log('DELETED ID ; ', deletedId);
        if (deletedId !== null) {
            try {
                await axiosClient.patch(`commandes/${deletedId}/`, {
                    is_deleted: true
                }).then(res => {
                    setDataOrders(dataOrders.filter(e => e.id !== deletedId))
                })
            } catch (error) {
                console.log('ERROR : ', error);
            }
        }
        else {
            for (let index = 0; index < selectedRows.length; index++) {
                try {
                    await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                        is_deleted: true
                    })
                } catch (error) {
                    console.log('Error : ', error);
                }
            }

            setDataOrders(dataOrders.filter(e => !selectedRows.includes(e.id)))
        }
        onCloseAlert()
    }

    // Update status


    const [statusConfirmation, setStatusConfirmation] = useState('')
    const [isModalEditStatusOpen, setIsModalEditStatusOpen] = useState(false);
    const ModalEditStatusRef = React.useRef()
    const [statusId, setStatusId] = useState()

    const onOpenModalEditStatus = (id) => {
        setIsModalEditStatusOpen(true);
        setStatusId(id)
    };
    const onCloseModalEditStatus = () => {
        setIsModalEditStatusOpen(false);
        setStatusConfirmation('')
        setStatusId()
    };

    const HandleChangeStatus = async () => {
        try {
            await axiosClient.patch(`commandes/${statusId}/`, {
                status_confirmation: statusConfirmation
            }).then((res) => {
                setDataOrders(dataOrders.filter(e => e.id !== statusId))
                onCloseModalEditStatus()
                toast({
                    description: t(`Statut est changé avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }

    const borderColor = useColorModeValue("gray.300", "gray.600")
    const textSM = useColorModeValue('gray.400', 'gray.500')

    const [showTitleAddManual, setShowTitleAddManual] = useState(false)
    const [showTitleAddExcel, setShowTitleAddExcel] = useState(false)
    const [showTitleAddApi, setShowTitleAddApi] = useState(false)

    const [activeMenu, setActiveMenu] = useState(false)
    const menuRef = useRef(null);

    // Function to handle clicks outside the menu
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setActiveMenu(false);
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    //vider l'input de date fin
    useEffect(() => {
        if (!dateDebutValue) {
            // Si dateDebutValue est vide, on réinitialise dateFinValue
            setDateFinValue('');
        } else if (dateFinValue && dateDebutValue >= dateFinValue) {
            // Si dateDebutValue est supérieur ou égal à dateFinValue, on réinitialise dateFinValue
            setDateFinValue('');
        }
    }, [dateDebutValue, dateFinValue]);

    return (
        <Box position={"relative"} mb={5}>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>


                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Nouvelles commandes confirmées")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>

            <Flex
                px={2}
                py={5}
                mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={2}
                align={'center'}
                justify={'center'}

            >

                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                        mb={2}
                    >
                        <Flex w={"100%"} direction={{ base: 'column', lg: 'row' }}>
                            <Flex gap={2} w={{ base: '100%', lg: '50%' }}>
                                <FormControl position="relative" mb={4}>
                                    <FormLabel
                                        position="absolute"
                                        top={dateDebutValue ? "9px" : "45%"}
                                        left="10px"
                                        background={useColorModeValue('white', 'gray.700')}
                                        borderRadius='lg'
                                        px={1}
                                        pt={1}
                                        color="gray.500"
                                        fontSize={dateDebutValue ? 10 : 16}
                                        transform={dateDebutValue ? "translateY(-100%)" : "translateY(-50%)"}
                                        transition="all 0.2s ease-out"
                                        zIndex={2}
                                        w={!dateDebutValue && 95}
                                        onClick={() => document.getElementById('dateDebutInput').focus()} // Focuses the input when clicking the label
                                        cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                    >
                                        Date début
                                    </FormLabel>
                                    <Input
                                        id="dateDebutInput" // Assign an ID to the input for reference
                                        type="date"
                                        borderColor={borderColor}
                                        borderRadius="5"
                                        size="md"
                                        fontSize={{ base: 13, lg: 16 }}
                                        w="100%"
                                        value={dateDebutValue}
                                        onChange={(e) => setDateDebutValue(e.target.value)}
                                        position="relative"
                                        placeholder=" " // This keeps space for the floating label
                                        _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                    />
                                </FormControl>
                                <FormControl position="relative" mb={4}>
                                    <FormLabel
                                        position="absolute"
                                        top={dateFinValue ? "9px" : "45%"}
                                        left="10px"
                                        background={useColorModeValue('white', 'gray.700')}
                                        borderRadius='lg'
                                        px={1}
                                        pt={1}
                                        color="gray.500"
                                        fontSize={dateFinValue ? 10 : 16}
                                        transform={dateFinValue ? "translateY(-100%)" : "translateY(-50%)"}
                                        transition="all 0.2s ease-out"
                                        zIndex={2}
                                        w={!dateFinValue && 95}
                                        onClick={() => document.getElementById('dateFinInput').focus()} // Focuses the input when clicking the label
                                        cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                    >
                                        Date fin
                                    </FormLabel>
                                    <Input
                                        id="dateFinInput" // Assign an ID to the input for reference
                                        type="date"
                                        borderColor={borderColor}
                                        borderRadius="5"
                                        size="md"
                                        fontSize={{ base: 13, lg: 16 }}
                                        w="100%"
                                        value={dateFinValue}
                                        min={dateDebutValue || undefined}
                                        onChange={(e) => setDateFinValue(e.target.value)}
                                        position="relative"
                                        placeholder=" " // This keeps space for the floating label
                                        _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                        disabled={!dateDebutValue}
                                    />
                                </FormControl>
                            </Flex>
                        </Flex>

                        <InputGroup size="md" w={{ base: '100%', xl: "40%" }} ml={{ base: 0, xl: 3 }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color={borderColor} />}
                            />
                            <Input
                                borderColor={borderColor}
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />
                        </InputGroup>

                    </Flex>


                    <TableContainer>
                        <Table variant='simple'>
                            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>{t("#ID")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Client")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Téléphone")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Status")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Coupons")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Prix")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Attribuer")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Détails")}</Th>

                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading ?
                                    <Tr>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>
                                    :
                                    dataOrders.length > 0 ?
                                        dataOrders.map((val, key) => {
                                            return (
                                                <Tr key={key}>
                                                    {/* <Td>
                                                    <Checkbox size="md" colorScheme='purple' _focus={"none"} _active={'none'}
                                                        onChange={() => handleCheckboxClick(val.id)}
                                                        isChecked={isSelected} />
                                                </Td> */}
                                                    <Td>
                                                        <Text>{val.code}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontWeight='semibold'>{val.full_name}</Text>
                                                            {val.email !== "undefined" && <Text fontSize='sm' color={textSM}>{val.email}</Text>}
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontSize={'md'}>{val.pays}, {val.ville}</Text>
                                                            <Text fontSize={'sm'} color={textSM}>{val.address}</Text>
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <a href={`https://wa.me/${val.tel}`} target='blank'>
                                                            {val.tel}
                                                        </a>
                                                    </Td>
                                                    <Td>
                                                        <Badge colorScheme='blue'
                                                            rounded="xl"
                                                            px={2}
                                                            cursor={["AdminClient", "ManagerClient"].includes(user.role) && 'pointer'}
                                                            onClick={() => {
                                                                ["AdminClient", "ManagerClient"].includes(user.role) && onOpenModalEditStatus(val.id)
                                                            }}>
                                                            {val.status_stock ? t(`${val.status_stock}`) : t('Nouveau')}
                                                        </Badge>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign='center'>
                                                            {val.coupon ? val.couponTitle : '-'}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon && 15} fontWeight='semibold' textDecoration={val?.coupon && 'line-through'}>{val?.price}</Text>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon ? 11 : 13} fontWeight='gray'>MAD</Text>
                                                        </Flex>
                                                        {
                                                            val?.coupon &&
                                                            <Flex>
                                                                <Text fontWeight='semibold'>{val?.priceWithCoupon}</Text>
                                                                <Text fontWeight='gray' fontSize={13}>MAD</Text>
                                                            </Flex>
                                                        }
                                                        {/* {val.coupon ?
                                                        <Flex flexDirection={"column"}>
                                                            <Flex>
                                                                <Text fontWeight='semibold'>{val.price} MAD</Text>
                                                                <Text fontWeight=''>{val.price} MAD</Text>
                                                            </Flex>
                                                            <Text fontSize='sm' color={textSM}>{val.price}</Text>
                                                        </Flex>
                                                        :
                                                        <Text fontWeight='semibold'>{val.price}</Text>
                                                    } */}
                                                        {/* <Text>
                                                        {val.price} DH
                                                    </Text> */}
                                                    </Td>
                                                    <Td>
                                                        {!val.assigned_to_stock ? <Button variant='outline' colorScheme={'purple'} onClick={() => { onOpenAssignOrder(user.id, val.id) }}>
                                                            {t('Prends-la')}
                                                        </Button>
                                                            :
                                                            <Flex alignItems='center' gap={2}>
                                                                <Avatar h={25} w={25} src={user.image} />
                                                                <Flex flexDirection="column" justifyContent="center">
                                                                    <Text fontSize={12} fontWeight='semibold'>{user.first_name}</Text>
                                                                    <Text fontSize={12} fontWeight='semibold'>{user.last_name}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        }
                                                    </Td>
                                                    <Td>
                                                        <Button colorScheme='blue' variant='outline' onClick={() => {
                                                            navigate('/Detail-Gestion-confirmation', {
                                                                state: {
                                                                    id: val.id,
                                                                    tel: val.tel
                                                                }
                                                            })
                                                        }}>
                                                            {t('Détails')}
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td colSpan={9}>
                                                <Text fontSize={16}>{t("Donnée introuvable")}</Text>
                                            </Td>
                                        </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>


                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select>
                    </Flex>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                </Stack>
            </Flex>




            {/* Alert to Take Order */}

            <AlertDialog
                isOpen={isAssignOrderOpen}
                leastDestructiveRef={assignOrderRef}
                onClose={onCloseAssignOrder}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Attribuer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("Voulez-vous gérer cette commande ?")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAssignOrder}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={assignOrder} colorScheme='green' variant='outline' ml={3}>
                                {t("Prendre")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>



            {/* Alert to Update confirmation status */}

            <AlertDialog
                isOpen={isModalEditStatusOpen}
                leastDestructiveRef={ModalEditStatusRef}
                onClose={onCloseModalEditStatus}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Statut de confirmation')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {t("Ici, vous pouvez modifier le statut de confirmation.")}
                            </Text>

                            <Select
                                mt={3}
                                rounded='lg'
                                size={"sm"}
                                value={statusConfirmation}
                                onChange={e => setStatusConfirmation(e.target.value)}
                            >
                                <option value=''>{t('Statut de confirmation')}</option>
                                <option value='Pas de réponse'>{t('Pas de réponse')}</option>
                                <option value='Messagerie vocale'>{t('Messagerie vocale')}</option>
                                <option value='En attente'>{t('En attente')}</option>
                                <option value='Ligne occupée'>{t('Ligne occupée')}</option>
                                <option value='Mauvais numéro'>{t('Mauvais numéro')}</option>
                                <option value='Annulé'>{t('Annulé')}</option>
                                <option value='Confirmé'>{t('Confirmé')}</option>
                            </Select>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!statusConfirmation} mr={3} onClick={HandleChangeStatus}>
                                {t("Modifier")}
                            </Button>
                            <Button onClick={onCloseModalEditStatus} size="sm" >{t("Annuler")}</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box >
    )
}

export default New_Confirmed_Orders