import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  useColorModeValue,
  createIcon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NewOrdersFromAPI() {
  const {t}= useTranslation()
  const navigate=useNavigate()
  return (
    <Container maxW={'7xl'}>
    <Stack
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
      direction={{ base: 'column', md: 'row' }}>
      <Stack justify={"center"} align={"center"} flex={1} spacing={{ base: 5, md: 10 }}>
        <Heading
        textAlign={"center"}
          lineHeight={1.3}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
          <Text>
            Api data import,
          </Text>
          {/* <br /> */}
          <Text as={'span'} color={'purple.400'}>
            Coming soon!
          </Text>
        </Heading>
        <Text fontSize={{ base: 'md', sm: 'md', lg: 'lg' }} align={"center"} w={"80%"} color={'gray.500'}>
        {t("La fonctionnalité d'importation des données via API arrive bientôt. Restez à l'écoute pour une gestion simplifiée et plus efficace de vos commandes.")}
        </Text>
        <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
          <Button
            rounded={'full'}
            size={'lg'}
            fontWeight={'normal'}
            px={6}
            colorScheme={'purple'}
            onClick={()=>navigate("/")}
            >
            {t("Accueil")}
            
          </Button>
          
        </Stack>
      </Stack>
      
    </Stack>
  </Container>
  )
}

export default NewOrdersFromAPI

