import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { BiLeftArrowAlt } from 'react-icons/bi';
import MySelect from 'react-select';
import cats from "../../../assets/images/cats.png"
import makeAnimated from 'react-select/animated';

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Avatar,
    Td,
    Input,
    FormControl,
    FormLabel,
    useToast,
    TableContainer,
    useColorModeValue,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    Alert,
    AlertIcon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    Text,
    Tag,
    PopoverFooter,
    TagCloseButton,
    AvatarBadge,
    Icon,
    Textarea,
    TagLabel,
    SkeletonCircle,
    Skeleton,
    Image,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink

} from '@chakra-ui/react';
import { FaPencilAlt } from 'react-icons/fa';
import { PiPlus, PiPlusBold } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../../components/MyPagination';
import { useStateContext } from '../../../context/ContextProvider';

export const GestCategory = () => {
    const animatedComponents = makeAnimated();
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const { user } = useStateContext();

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [paginationData, setPaginationData] = useState()

    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isCatOpen, setIsCatOpen] = useState(false);
    //Modal
    const [changeModal, setChangeModal] = useState(true)


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    const onOpenCat = () => {
        setIsCatOpen(true);
    };

    const onCloseCat = () => {
        setIsCatOpen(false);
        setMessage('')
    };


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 5);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    const [currentPage, setCurrentPage] = useState(0);
    //////////////////////////////////////////////////////////////////////////////////

    const [catId, setCatId] = useState(null);
    const cancelRef = React.useRef()

    //Category data
    const [data, setData] = useState([]);
    const [name, setName] = useState();


    const [message, setMessage] = useState('');

    const [catData, setCatData] = useState([]);


    //get the data
    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/categories/getAllCategories/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&client=${user.id}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });

    }, [pageSize, currentPage, searchTerm])

    const AjoutCategory = () => {
        if (!name || name.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            return;
        }

        const formData = new FormData()
        formData.append("name", name)
        formData.append("added_by", user.id)


        if (data.length > 0) {
            const check = data.find(obj => {
                return obj.name.toLowerCase() === name.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t('Cette catégorie existe déja !'))
            }
        }
        axiosClient.post('/categories/', formData)
            .then((response) => {
                setData([response.data, ...data]);
                setName('')

                setMessage("")

                toast({
                    description: `${t("La catégorie")} ${name} ${t("est ajoutée avec succès")} `,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                update()
            })
        onClose()
    }
    function update() {
        axiosClient.get(`/categories/getAllCategories/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&client=${user.id}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });

    }

    function reset() {

        setIsAddFormOpen(false)
        setIsEditFormOpen(false)
        setMessage('')
    }

    const updateCategory = (id) => {
        if (!catData.name || catData.name.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            return;
        }

        const formData = new FormData();
        if (name) formData.append("name", name);

        const newList = data.filter((e) => e.id !== id)
        if (name) {
            if (newList.length > 0) {
                const check = newList.find(obj => {
                    return obj.name.toLowerCase() === name.toLowerCase()
                })
                if (check) {
                    return setMessage(t('Cette catégorie existe déja !'))
                }
            }
        }

        axiosClient.patch(`/categories/${id}/`, formData).then((res) => {

            update()
            toast({
                description: `${t("La catégorie")} ${t("est modifiée avec succès")}`,
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
            setName('')
            setMessage("")
        })

        onClose()
    }

    const handleDelete = (id = catId) => {
        if (!id) return;
        axiosClient.delete(`/categories/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("La catégorie est supprimée avec succès"),
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }


    const colorbg = useColorModeValue('white', '#2d3748')
    const multiLabel = useColorModeValue('#e6e6e6', '#4e536c')

    const multitext = useColorModeValue('black', 'white')


    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box position={"relative"} mb={5} >
            <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        setChangeModal(true)
                        onOpen()
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t('Catégories')}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>
            <Flex h="100%">
            <Flex
                  px={2}
                  py={5}
                  mx={5}
                  bg={useColorModeValue('white', 'gray.700')}
                  rounded={"2xl"}
                  mt={2}
                  align={'center'}
                  justify={'center'}
                  w={{base:"100%",xl:"60%"}}
                  h={"fit-content"}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    // bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    px={{ base: 1, md: 5 }}>
                    {/* Search input */}
                    <Flex
                        justifyContent="end"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >

                        <InputGroup size={"md"} w={{ base: '100%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius={"5"}
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>{t('Nom de catégorie')}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Date Création")}</Th>

                                    <Th isNumeric borderBottomColor='gray.400'></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                    </Tr>}
                                {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        <Tr p={0} key={row.id}>
                                            <Td>{row.name}</Td>

                                            <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>


                                            <Td isNumeric>
                                                <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        setChangeModal(false)
                                                        setCatData(row)
                                                        setCatId(row.id);
                                                        onOpen();
                                                    }}

                                                    variant={'outline'}
                                                    colorScheme='whatsapp'


                                                    leftIcon={<EditIcon />}>
                                                    {t("Modifier")}
                                                </Button>

                                                <Button
                                                    ml={5}
                                                    size="sm"
                                                    onClick={() => {
                                                        setCatId(row.id);
                                                        onOpenAlert();
                                                    }}

                                                    variant={'outline'}
                                                    colorScheme='red'
                                                    leftIcon={<DeleteIcon />}>
                                                    {t("Supprimer")}
                                                </Button>


                                            </Td>
                                        </Tr>
                                    ))}
                                {((searchTerm && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={8}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        {/* <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                        </Select> */}
                    </Flex>
                </Stack>
            </Flex>
             <Flex  display={{base:'none',xl:"flex"}} w={"40%"}>
                <Image objectFit={"contain"} src={cats}></Image>
                </Flex>                   

            </Flex>
            

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer catégorie")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(catId)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


            {changeModal ?
                //Modal d'ajout cat
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'une catégorie")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired>
                                        <FormLabel>{t("Nom de catégorie")}</FormLabel>
                                        <Input maxLength={50} name="name" onChange={e => setName(e.target.value)} placeholder={t("Nom de catégorie")} />
                                    </FormControl>

                                </Flex>
                            </Flex>
                        </ModalBody>

                        <ModalFooter>
                            {(!name?.trim())
                                ?
                                <Button colorScheme='blue' title="Ajouter" isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutCategory}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t("Modification de catégorie")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired>
                                        <FormLabel>{t("Nom de catégorie")}</FormLabel>
                                        <Input maxLength={50} name="name" defaultValue={catData.name} onChange={e => setName(e.target.value)} placeholder={t("Nom de catégorie")} />
                                    </FormControl>

                                </Flex>
                            </Flex>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='gray' onClick={() => {
                                updateCategory(catId)
                            }}>
                                {t("Modifier")}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>

    )
}

export default GestCategory