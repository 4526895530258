import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import ProgressBar from "@ramonak/react-progress-bar";

const intervals = [0, 500, 1000, 2000, 5000, 10000, 20000, 50000, 1000000];

const getIntervalBounds = (revenue) => {
    for (let i = 0; i < intervals.length - 1; i++) {
        if (revenue >= intervals[i] && revenue < intervals[i + 1]) {
            return [intervals[i], intervals[i + 1]];
        }
    }
    // If revenue >= last interval value, return last interval as bounds
    return [intervals[intervals.length - 1], Infinity];
};

const ProgressBarComponent = ({ revenue }) => {
    // Get the interval bounds based on the client's revenue
    const [lowerBound, upperBound] = getIntervalBounds(revenue);

    return (
        <Flex align="center" gap={5} direction="row" justify="end" w="100%">
            {/* Display the lower bound and upper bound as labels */}
            <Box>{lowerBound} MAD</Box>
            
            {/* Display the progress bar with the actual revenue and no percentage symbol */}
            <Box w="50%">
                <ProgressBar
                    completed={revenue}
                    maxCompleted={upperBound}
                    customLabel={`${revenue} MAD`}  // Set the custom label with revenue and currency
                />
            </Box>

            <Box>{upperBound} MAD</Box>
        </Flex>
    );
};

export default ProgressBarComponent;
