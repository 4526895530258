import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, Textarea, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../assets/images/bell.png'
import animationData from '../../assets/lotties/notif.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../components/MyPagination';


function NoteDetail() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState()

    const { user, setUser } = useStateContext();
    const [noteData, setNoteData] = useState([]);
    const location = useLocation()


    //get the data
    useEffect(() => {
        axiosClient.get(`/notes/${location.state.idNote}/`)
            .then(({ data }) => {
                setNoteData(data)
            })

    }, [user])


    const myColor = useColorModeValue("white", "gray.700");


    return (
        <Box position={"relative"}>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/my-notes')}>Mes note</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Detail note</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>



            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={'center'}
                p={{ base: 1, sm: 5 }}
                gap={10}
            >

                <Flex
                    direction="column"
                    justify={{ base: 'flex-start', md: 'space-between' }}
                    width={{ base: "100%", lg: "70%" }}
                    
                >



                    <Stack
                        bg={myColor}
                        mb={4}
                        rounded={'lg'}
                        p={6}
                        boxShadow={'md'}
                        alignItems={'start'}
                        justify="space-between"
                        
                    // as={"Button"}

                    >
                        <Flex direction="row"
                            justify="space-between"
                            ml={'auto'}
                            width="full">
                            <Stack display={'flex'} flexDirection={{ base: "column", md: "row" }} align={'center'}>
                                <Text fontSize={'lg'} fontWeight="semibold">{noteData.title}</Text>

                            </Stack>
                            <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(noteData.date_creation).toLocaleDateString(t('fr-FR'), { hour: '2-digit', minute: '2-digit' })}</Text>

                        </Flex>

                        <Flex
                            direction="row"
                            width="full"
                            ml={'auto'}
                            justify="space-between">
                            <Text fontSize={{ base: 'sm' }} textAlign={'justify'} w={"100%"}>
                                {noteData.content}

                            </Text>


                        </Flex>
                    </Stack>

                </Flex>


            </Flex>




        </Box>
    )
}

export default NoteDetail