import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, Textarea, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../assets/images/bell.png'
import animationData from '../../assets/lotties/notif.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../components/MyPagination';


function UserNotes() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState()

    const { user, setUser , setCountNotes } = useStateContext();
    const [notesData, setNotesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [notifData, setNotifData] = useState();
    const [notifId, setNotifId] = useState('');

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [selectedCible, setSelectedCible] = useState(null);
    const [selectedC, setSelectedC] = useState('');
    const [checkLengthData, setCheckLengthData] = useState(false)

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const handleCibleChange = (ev) => {
        setSelectedCible(ev.target.value)

    }
    const [paginationData, setPaginationData] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        setLoadingData(true)

        axiosClient.get(`/notes/getUserNotes/?page_size=${pageSize}&page=${currentPage + 1}&userRole=${user.role}&userId=${user.id}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results.notes;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setNotesData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

    }, [pageSize, currentPage, searchTerm, user])

    function update() {
        axiosClient.get(`/notes/getUserNotes/?page_size=${pageSize}&page=${currentPage + 1}&userRole=${user.role}&userId=${user.id}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results.notes;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setNotesData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });
        axiosClient
            .get(`notes/getUserNotes/?page_size=7&page=1&userRole=${user.role}&userId=${user.id}`)
            .then((res) => {

                setCountNotes(res.data.results.count);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const IsReadNote = (id) => {
        axiosClient.get(`/notes/${id}/`)
            .then((response) => {
                const note = response.data;

                if (!note.is_read.includes(user.id)) {
                    note.is_read.push(user.id);

                    axiosClient.put(`/notes/${id}/`, {
                        ...note,
                        is_read: note.is_read,
                    })
                        .then((response) => {
                            update()
                        })
                        .catch((error) => {
                            console.error(error)
                        });
                }
            })
            .catch((error) => {
                console.error(error)
            });

    };
    const myColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("gray.300", "gray.600");

    const hoverDeleteBtn = useColorModeValue("red.50", "gray.600");
    const DeleteBtnIcon = useColorModeValue("red.400", "red.300");

    return (
        <Box position={"relative"}>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink >Mes notes</BreadcrumbLink>
                        </BreadcrumbItem>

                    </Breadcrumb>
                </Box>

            </Flex>
            {(paginationData?.length === 0 && !searchTerm && !selectedC) ? (
                <Flex mb={'10'} mt={20} flexDirection={'column'} align={'center'} justify={'center'}>
                    <Image
                        height={150} width={150}
                        rounded={'md'}
                        alt={'empty'}
                        src={
                            empty
                        }
                        objectFit={'empty'}
                    />
                    <Text mt={10} color={"#aeaeae"}>{t("Il n'y a pas de notes à afficher")}</Text>
                </Flex>) :



                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'stretch', md: 'center' }}
                    justify={'center'}
                    p={{ base: 1, sm: 5 }}
                    gap={10}
                >

                    <Flex
                        direction="column"
                        justify={{ base: 'flex-start', md: 'space-between' }}
                        width={{ base: "100%", lg: "70%" }}
                    >
                        <Flex

                            // px={5}
                            justifyContent="end"
                            alignItems="center"
                            direction={{ base: 'column', md: 'row' }}
                            mb={{ base: 2, md: 4 }}
                        >

                            <InputGroup w={{ base: '100%', md: "40%" }}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color='gray.300' />}
                                />
                                <Input
                                    borderColor={borderColor}
                                    borderRadius={"5"}
                                    placeholder={t("Recherche...")}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setCurrentPage(0)
                                        setSearchTerm(e.target.value)
                                    }}
                                    mb={4}
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                />  </InputGroup>

                        </Flex>

                        {(paginationData?.length !== 0) &&
                            notesData.map((row) => (

                                <Stack
                                    backgroundColor={row.is_read.includes(user?.id) ? 'white' : 'blue.50'}
                                    mb={4}
                                    rounded={'lg'}
                                    p={6}
                                    boxShadow={'md'}
                                    alignItems={'start'}
                                    justify="space-between"
                                    // cursor={"pointer"}
                                    // as={"Button"}
                                    onClick={() => {
                                        IsReadNote(row.id)
                                        navigate(`/detail-note`, {
                                            state: {
                                                idNote: row.id
                                            }
                                        })
                                    }}
                                    cursor={"pointer"} transition={"transform .4s"} _hover={{
                                        shadow: "md",
                                        transform: "scale(1.01)",
                                    }} shadow={'none'}


                                >
                                    <Flex direction="row"
                                        justify="space-between"
                                        ml={'auto'}
                                        width="full">
                                        <Stack display={'flex'} flexDirection={{ base: "column", md: "row" }} align={'center'}>
                                            <Text fontSize={'lg'} fontWeight="semibold">{row.title}</Text>
                                            <Stack mt={1}>
                                                <Badge
                                                    rounded={'lg'}
                                                    colorScheme={row.cible === "ManagerClient" ? "blue" :
                                                        row.cible === "RespoSuiviClient" ? "green" :
                                                            row.cible === "StockManagerClient" ? "yellow" : row.cible === "All" ? "pink" : ""}
                                                >
                                                    {row.cible === "ManagerClient" ? "Manager" :
                                                        row.cible === "RespoSuiviClient" ? t("Call center") :
                                                            row.cible === "StockManagerClient" ? `Stock Manager ` : row.cible === "All" ? `All ` : ""}
                                                </Badge>
                                            </Stack>
                                        </Stack>
                                        <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'), { hour: '2-digit', minute: '2-digit' })}</Text>

                                    </Flex>

                                    <Flex
                                        direction="row"
                                        width="full"
                                        ml={'auto'}
                                        justify="space-between">
                                        <Text fontSize={{ base: 'sm' }} textAlign={'justify'} w={"100%"}>
                                            {row.content.substring(0, 450)} {row.content.length > 450 && "..."}

                                        </Text>


                                    </Flex>
                                </Stack>
                            ))}


                        {((searchTerm && paginationData?.length === 0) || (checkLengthData)) && (
                            <Flex alignItems={'center'}>
                                <Text >{t("Aucune note correspondante n'a été trouvée.")}</Text>
                            </Flex>

                        )}

                        {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                            <Box flex="1">
                                <MyPagination
                                    paginationData={paginationData}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    PAGE_SIZE={pageSize}
                                />
                            </Box>

                            <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Select>
                        </Flex>
                        {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                    </Flex>


                </Flex>
            }



        </Box>
    )
}

export default UserNotes