import React from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Box, IconButton } from "@chakra-ui/react";
import { GrPowerReset } from "react-icons/gr";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialChartData: [],
      initialChartOptions: {},
      chartData: [],
      chartOptions: {},
      chartKey: 0,  // Add a key to force re-render
    };
  }

  componentDidMount() {
    const { lineChartData, lineChartOptions } = this.props;

    this.setState({
      initialChartData: lineChartData,
      initialChartOptions: lineChartOptions,
      chartData: lineChartData,
      chartOptions: lineChartOptions,
    });
  }

  resetChart = () => {
    this.setState((prevState) => ({
      chartData: prevState.initialChartData,
      chartOptions: prevState.initialChartOptions,
      chartKey: prevState.chartKey + 1,  // Change the key to force re-render
    }));
  };

  render() {
    return (
      <Box position="relative" width="100%" height="100%">
      <IconButton
        onClick={this.resetChart}
        icon={<GrPowerReset />}
        position="absolute"
        top="10px"
        colorScheme="purple"
        right="10px"
        zIndex="1"
        rounded={"50%"}
      />
      <ReactApexChart
        key={this.state.chartKey}  // Use the key to force re-render
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="area"
        width="100%"
        height="100%"
      />
    </Box>
    );
  }
}

export default LineChart;
