import { Avatar, Badge, Box, Button, Card, CardBody, CardHeader, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Grid, Heading, Image, Progress, Select, SimpleGrid, Skeleton, SkeletonText, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoMdFemale, IoMdMale } from 'react-icons/io'
import { useStateContext } from '../../../context/ContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconBox from '../../../components/Icons/IconBox';
import { MdOutlineAddShoppingCart } from 'react-icons/md';
import { TbTruckDelivery, TbTruckReturn } from 'react-icons/tb';
import { LuPackageX } from 'react-icons/lu';
import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import axiosClient, { linkImage } from '../../../axios-client';
import { LiaUserEditSolid } from 'react-icons/lia';
import MyPagination from '../../../components/MyPagination';
import ProgressBarComponent from './RevenuProgressBar';
function ClientDetails() {
    const { user } = useStateContext();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const location = useLocation()
    const [countNew, setCountNew] = useState()
    const [countAnnule, setCountAnnule] = useState()
    const [countLivre, setCountLivre] = useState()
    const [countRetour, setCountRetour] = useState()

    const [client, setClient] = useState()
    const [data, setData] = useState([]);
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageStock, setCurrentPageStock] = useState(0);
    const [loadingData, setLoadingData] = useState(true)

    const [dataProduct, setDataProduct] = useState([])
    const [loading, setLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [revenu, setRevenu] = useState(0)
    const [searchTerm, setSearchTerm] = useState('');


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    const [pageSizeStock, setPageSizeStock] = useState(PAGE_SIZE);
    const handlePageSizeChangeStock = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSizeStock(newSize);
        setCurrentPageStock(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const labels = ["Nouveau", "Livrée", "Retournée", "Annulée"];

    const fetchBarData = (userId) => {

        axiosClient.get(`/commandes/getCommandesCount/?client=${userId}`)
            .then((res) => {
                setCountNew(res.data.count_new);

                setCountLivre(res.data.count_livre);
                setCountRetour(res.data.count_retourne);
                setCountAnnule(res.data.count_annule);
            })
            .catch((error) => {
                console.error(error);
            });

    }
    const dataChart = {
        labels: labels,
        datasets: [
            {
                label: "",
                data: [countNew, countLivre, countRetour, countAnnule],
                backgroundColor: [
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(246, 173, 85, 0.2)",
                    "rgba(255, 99, 132, 0.2)",
                ],
                borderColor: [
                    "rgb(54, 162, 235)",
                    "rgba(153, 102, 255)",
                    "rgba(246, 173, 85)",
                    "rgb(255, 99, 132)",

                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Charges Chart',
                font: {
                    size: 16,
                },
            },
            legend: {
                display: false
            }
        },
    };


    const [dataImagesProduct, setDataImagesProduct] = useState([])
    useEffect(() => {
        axiosClient.get(`/products/getAllProducts/?id_user=${location.state.idUser}&page_size=${pageSizeStock}&page=${currentPageStock + 1}&search_term=${searchTerm}`)
            .then((response) => {

                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setDataProduct(filteredData);
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });
    },[client?.id, user, pageSizeStock, currentPageStock])

    useEffect(() => {

        axiosClient.get(`/clients/${location.state.idUser}/`)
            .then(({ data }) => {
                setClient(data)
                fetchBarData(data.id)

            })

        axiosClient.get(`/clients/getClientUsers/?page_size=${pageSize}&page=${currentPage + 1}&id=${client?.id}`)
            .then((response) => {

                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });



        axiosClient.get(`/commandes/getRevenus/?client=${client?.id}`)
            .then((res) => {
                setRevenu(res.data);
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });

    }, [client?.id, pageSize, pageSizeStock, currentPageStock, currentPage, user, searchTerm])

    const getImagesProduct = (id) => {
        setDataImagesProduct(dataProduct.find(e => e.id === id).images)
        console.table(dataProduct.find(e => e.id === id)?.images);
        onOpen()
    }


    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '20px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'>
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={client?.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>

                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                    <Text
                                        fontSize={{ sm: 'lg', lg: 'xl' }}
                                        color={useColorModeValue("gray.700", "white")}
                                        fontWeight='bold'
                                        ms={{ sm: '8px', md: '0px' }}
                                        mr={2}
                                    >
                                        {client?.first_name} {client?.last_name}
                                    </Text>
                                    {client?.gender === "Femme" ? <IoMdFemale size={"22px"} color='#ff8096' /> : client?.gender === "Homme" ? <IoMdMale size={"22px"} color='#5da4ff' /> : null}
                                </Flex>

                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {client?.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <ProgressBarComponent revenue={revenu} />

                    </Flex>
                </Flex>
                <Grid px={5} pb={5} templateColumns={{ sm: "1fr", md: "1fr", lg: "3fr 2fr " }} justify={"center"} gap='22px'>

                    <Card
                        rounded={"xl"}
                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t("Employées")}
                            </Text>
                        </CardHeader>
                        <CardBody px='5px'>
                            <TableContainer>
                                <Table size={"sm"} variant='simple'>
                                    <Thead>
                                        <Tr>

                                            <Th fontSize={"sm"} color='gray.500' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
                                                {t("Employée")}
                                            </Th>
                                            <Th fontSize={"sm"} color='gray.500' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
                                                Role
                                            </Th>


                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {loadingData &&
                                            <Tr>

                                                <Td> <Skeleton height='20px' /></Td>
                                                <Td> <Skeleton height='20px' /></Td>


                                            </Tr>}


                                        {(paginationData?.length !== 0) &&
                                            data.map((row) => (
                                                <Tr key={row.id}>
                                                    <Td
                                                        minWidth={{ sm: "250px" }}
                                                        ps='0px'
                                                        borderBottomColor='gray.300'>
                                                        <Flex align='center' minWidth='100%' flexWrap='nowrap'>
                                                            <Avatar w='50px'
                                                                borderRadius='12px'
                                                                me='18px'
                                                                name='Dan Abrahmov' src={row.image} />

                                                            <Flex direction='column'>
                                                                <Text
                                                                    fontSize='sm'

                                                                    fontWeight='normal'
                                                                    minWidth='100%'>
                                                                    {row.first_name} {row.last_name}
                                                                </Text>
                                                                <Text fontSize='sm' color='gray.500' fontWeight='normal'>
                                                                    {row.email}
                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Td>
                                                    <Td borderBottomColor='gray.300' >

                                                        <Badge
                                                            colorScheme={row.role === "ManagerClient" ? 'purple' : row.role === "RespoSuiviClient" ? 'blue' : row.role === "StockManagerClient" ? "yellow" : "gray"}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            rounded="full"
                                                            w={"fit-content"}
                                                            py={1}
                                                            px={3}
                                                            size={"sm"}
                                                        >
                                                            <LiaUserEditSolid size={18} />
                                                            <Text fontSize={"xs"} ml={1}>{row.role === "ManagerClient" ? t("Manager") : row.role === "RespoSuiviClient" ? t("Centre d'appel") : row.role === "StockManagerClient" ? t("Gestionnaire de stock") : null}</Text>
                                                        </Badge>
                                                    </Td>

                                                </Tr>
                                            ))}
                                        {((paginationData?.length === 0) || (checkLengthData)) && (
                                            <Tr>
                                                <Td colSpan={3}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                            </Tr>
                                        )}





                                    </Tbody>
                                </Table>

                            </TableContainer>
                            <Flex justify="space-between" align="center" w="100%">
                                <Box flex="1">
                                    <MyPagination
                                        paginationData={paginationData}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        PAGE_SIZE={pageSize}
                                    />
                                </Box>
                            </Flex>
                        </CardBody>
                    </Card>
                    <Card
                        rounded={"xl"}
                        bg={useColorModeValue("white", "purple.900")}
                        display={'flex'}
                        p='16px'
                    >
                        {/* <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' fontWeight='bold'>
                                {t("Status de commandes")}
                            </Text>
                        </CardHeader> */}
                        <CardBody px='10px' >
                            <Flex direction='column' w='100%'>
                                <Box
                                    bg={useColorModeValue('linear-gradient(71deg, rgba(255,255,255,1) 0%, rgba(239,229,253,1) 53%, rgba(214,188,250,1) 100%)', 'linear-gradient(71deg, rgba(50,62,81,1) 0%, rgba(65,49,117,1) 100%)')}
                                    borderRadius='20px'
                                    shadow={'xl'}
                                    w='100%'

                                    // minH={{ sm: '300px' }}
                                    display={{ sm: 'flex', md: 'block' }}
                                    justify={{ sm: 'center', md: 'flex-start' }}
                                    align={{ sm: 'center', md: 'flex-start' }}
                                    minH={{ base: '180px', md: '220px' }}
                                    p={{ base: '15px', sm: "20px", md: '22px' }}>
                                    {/* <BarChart

									barChartOptions={barChartOptionsDashboard}
									barChartData={barChartDataDashboard}
								/> */}
                                    <Bar data={dataChart} options={options} />
                                </Box>
                                <Flex direction='column' mt='24px' mb='10px' alignSelf='flex-start'>
                                    <Text fontSize='lg' fontWeight='bold' mb='6px'>
                                        {t("Statuts de commandes")}
                                    </Text>
                                    {/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										(+23%)
									</Text>{' '}
									than last week
								</Text> */}
                                </Flex>
                                <SimpleGrid gap={{ base: "12px", sm: '12px' }} columns={{ base: 2, md: 4 }}>
                                    <Flex direction='column'>
                                        <Flex alignItems='center'>
                                            <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                                <MdOutlineAddShoppingCart size={"23px"} />
                                            </IconBox>
                                            <Text fontSize='sm' color='gray.400'>
                                                {t("Nouveau")}
                                            </Text>
                                        </Flex>
                                        <Text
                                            fontSize={{ sm: 'md', lg: 'lg' }}

                                            fontWeight='bold'
                                            mb='6px'
                                            my='6px'>
                                            {countNew}
                                        </Text>
                                        <Progress colorScheme='brand' bg='blue.300' borderRadius='30px' h='5px' value={20} />
                                    </Flex>
                                    <Flex direction='column'>
                                        <Flex alignItems='center'>
                                            <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                                <TbTruckDelivery size={"23px"} />
                                            </IconBox>
                                            <Text fontSize='sm' color='gray.400'>
                                                {t("Livrée")}
                                            </Text>
                                        </Flex>
                                        <Text
                                            fontSize={{ sm: 'md', lg: 'lg' }}

                                            fontWeight='bold'
                                            mb='6px'
                                            my='6px'>
                                            {countLivre}
                                        </Text>
                                        <Progress colorScheme='brand' bg='purple.300' borderRadius='30px' h='5px' value={90} />
                                    </Flex>
                                    <Flex direction='column'>
                                        <Flex alignItems='center'>
                                            <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                                <TbTruckReturn size={"23px"} />
                                            </IconBox>
                                            <Text fontSize='sm' color='gray.400'>
                                                {t("Retournée")}
                                            </Text>
                                        </Flex>
                                        <Text
                                            fontSize={{ sm: 'md', lg: 'lg' }}

                                            fontWeight='bold'
                                            mb='6px'
                                            my='6px'>
                                            {countRetour}
                                        </Text>
                                        <Progress colorScheme='brand' bg='orange.300' borderRadius='30px' h='5px' value={30} />
                                    </Flex>
                                    <Flex direction='column'>
                                        <Flex alignItems='center'>
                                            <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                                <LuPackageX size={"23px"} />
                                            </IconBox>
                                            <Text fontSize='sm' color='gray.400'>
                                                {t("Annulée")}
                                            </Text>
                                        </Flex>
                                        <Text
                                            fontSize={{ sm: 'md', lg: 'lg' }}

                                            fontWeight='bold'
                                            mb='6px'
                                            my='6px'>
                                            {countAnnule}
                                        </Text>
                                        <Progress colorScheme='brand' bg='red.300' borderRadius='30px' h='5px' value={50} />
                                    </Flex>
                                </SimpleGrid>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
                <Heading px={10} py={3} fontSize={"2xl"}>Stock</Heading>
                <SimpleGrid pt={{ base: 6, md: 5 }} px={10} templateColumns='repeat(auto-fill,minmax(250px,1fr))' spacing={7}>

                    {
                        loading &&
                        <Card mt={5} rounded={"2xl"}>
                            <CardBody>
                                <Stack position={"relative"}>
                                    <Skeleton height={200} borderRadius='2xl' />
                                </Stack>
                                <Stack alignItems='center'>
                                    <SkeletonText mt='4' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
                                </Stack>
                                <Stack>
                                    <SkeletonText mt='6' noOfLines={2} spacing='4' skeletonHeight='2' />
                                </Stack>

                                <Stack alignItems='center'>
                                    <SkeletonText mt='6' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
                                </Stack>
                            </CardBody>
                        </Card>
                    }
                    {
                        dataProduct.map((val, key) => {
                            return (
                                <Card
                                    key={key} my={{ base: 5, md: 8 }} rounded={"xl"}>
                                    <CardBody >
                                        <Stack position={"relative"}>
                                            <Image
                                                height={"200px"}
                                                mt={-12}
                                                src={val.images[0]?.image}
                                                borderRadius='2xl'
                                                objectFit="cover"
                                                cursor={'pointer'}
                                                onClick={() => {
                                                    getImagesProduct(val.id)
                                                }}

                                            />

                                        </Stack>
                                        <Stack pt={3} align={'center'}>

                                            <Heading fontSize={'xl'} fontFamily={'body'} fontWeight={500}>
                                                {val.title}
                                            </Heading>
                                            <Text lineHeight={5} textAlign={'justify'} color={"descColor"}>
                                                {val.description}
                                            </Text>
                                            <Stack direction={'row'} align={'center'}>
                                                <Text fontWeight={"semibold"} fontSize={'lg'}>
                                                    {t("Qte")} : {val.total_quantity}
                                                </Text>

                                            </Stack>
                                        </Stack>
                                    </CardBody>
                                </Card>
                            )
                        })
                    }
                </SimpleGrid>
                {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                <Flex mb={3} flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            paginationData={paginationData}
                            currentPage={currentPageStock}
                            setCurrentPage={setCurrentPageStock}
                            PAGE_SIZE={pageSizeStock}
                        />
                    </Box>

                    <Select rounded={"md"} size={"sm"} w="70px" value={pageSizeStock} onChange={handlePageSizeChangeStock}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Select>
                </Flex>
                {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

            </Flex>
            <Drawer placement='bottom' onClose={() => {
                onClose()
                setDataImagesProduct([])
            }} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent
                    background={"#ffffff42"}
                    backdropFilter='auto' backdropBlur='8px'>
                    <DrawerBody>
                        <Flex gap={5} justify={{ base: 'none', md: 'center' }}>
                            {
                                dataImagesProduct.map((val, key) => {
                                    return (
                                        <Avatar key={key} h={150} w={150} borderRadius={'xl'} name='Ryan Florence' src={val.image} />
                                    )
                                })
                            }
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    )
}

export default ClientDetails