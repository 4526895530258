import axios from "axios";

export const linkImage = 'https://admin.ecolinke.com/'
// export const linkImage = 'http://127.0.0.1:8000/'
// export const linkImage = 'http://192.168.100.183:8001/'

const axiosClient = axios.create({
  baseURL: "https://admin.ecolinke.com/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://192.168.100.183:8001/",

});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


export default axiosClient;
