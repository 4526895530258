import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  list,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../context/ContextProvider";

function NewOrder() {
  const { user } = useStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeStep, setActiveStep } = useSteps({ index: 0 });
  const steps = [
    { title: t("Informations client") },
    { title: t("Produits") },
    { title: "Coupon" },
  ];

  const [dataProduct, setDataProduct] = useState([
    {
      produit: "",
    },
  ]);

  const [dataProduit, setDataProduit] = useState([]);
  const [optionsTitre, setOptionsTitre] = useState([]);

  const fetchData = async () => {
    let optionsTitreData = [];
    await axiosClient
      .get(`/products/get_All_Products/?client=${user.id}`)
      .then((res) => {
        setDataProduit(res.data);
        for (let index = 0; index < res.data.length; index++) {
          optionsTitreData.push({
            value: res.data[index].id,
            label: res.data[index].title,
          });
        }
      });
    setOptionsTitre(optionsTitreData);
  };

  useEffect(() => {
    fetchData();
  }, [user.id]);

  //Var Info Client
  const [nomCompletClient, setNomCompletClient] = useState();
  const [emailClient, setEmailClient] = useState();
  const [paysClient, setPaysClient] = useState();
  const [villeClient, setVilleClient] = useState();
  const [adresseClient, setAdresseClient] = useState();
  const [phoneClient, setPhoneClient] = useState();
  const [totalPrice, setTotalPrice] = useState(0);

  //Var Coupon
  const [codeCoupon, setCodeCoupon] = useState();

  //info produit
  // const optionsTitre = [
  //     {
  //         value: 1,
  //         label: "Chaussettes"
  //     },
  //     {
  //         value: 2,
  //         label: "Jordan Nike"
  //     },
  //     {
  //         value: 365,
  //         label: "Puma"
  //     }
  // ]

  const getColor = (id) => {
    let colorOptions = [];

    const produit = dataProduit.find((e) => e.id === id);

    if (produit) {
      // Utilisation d'un Set pour éviter les duplications en ignorant la casse
      const uniqueColors = new Set(
        produit.stock
          .filter((e) => e.color.length !== 0)
          .map((val) => val.color.toLowerCase()) // Conversion en minuscule
      );

      // Recréer le tableau avec les couleurs uniques, en gardant le format original
      colorOptions = [...uniqueColors].map((color) => ({
        value: produit.stock.find((val) => val.color.toLowerCase() === color)
          ?.id,
        label: color.charAt(0).toUpperCase() + color.slice(1), // Rendre la première lettre en majuscule
      }));
    }

    return colorOptions;
  };

  const getSize = (id) => {
    let sizeOptions = [];

    const produit = dataProduit.find((e) => e.id === id);

    if (produit) {
      // Utilisation d'un Set pour éviter les duplications en ignorant la casse
      const uniqueSizes = new Set(
        produit.stock
          .filter((e) => e.size.length !== 0)
          .map((val) => val.size.toLowerCase()) // Conversion en minuscule
      );

      // Recréer le tableau avec les tailles uniques, en gardant le format original
      sizeOptions = [...uniqueSizes].map((size) => ({
        value: produit.stock.find((val) => val.size.toLowerCase() === size)?.id,
        label: size.charAt(0).toUpperCase() + size.slice(1), // Rendre la première lettre en majuscule
      }));
    }

    return sizeOptions;
  };

  const handleChangeTitleProduct = (e, index) => {
    const { value, label } = e;
    console.log(index, " Selected : value : ", label);

    setDataProduct((prevState) => {
      const newData = [...prevState];
      // let dataCouleur = []

      // for (let index = 0; index < dataProduit.find(e => e.id === value)?.properties.length; index++) {
      //     dataCouleur.push({
      //         value: index,
      //         label: dataProduit.find(e => e.titre === label)?.properties[index].couleur
      //     })
      // }

      // let dataTaille = []

      // for (let index = 0; index < dataProduit.find(e => e.titre === label)?.properties.length; index++) {
      //     dataTaille.push({
      //         value: index,
      //         label: dataProduit.find(e => e.titre === label)?.properties[index].taille
      //     })
      // }
      // console.log('DATAAA : ', {
      //     image: dataProduit.find(e => e.titre === label)?.image,
      //     produit: e,
      //     couleur: '',
      //     taille: '',
      //     quantite: null,
      //     prix: null
      // });

      newData[index] = {
        ...newData[index],
        image: dataProduit.find((e) => e.id === value)?.images[0]?.image,
        produit: e,
        couleur: "",
        taille: "",
        quantite: null,
        prix: null,
      };
      return newData;
    });
  };

  const addNewDataProduct = () => {
    console.log("DATAAA : 2 ", dataProduct);
    setDataProduct([...dataProduct, { produit: "" }]);
  };

  const removeDataProduct = (index) => {
    const listDataProduct = [...dataProduct];
    listDataProduct.splice(index, 1);
    setDataProduct(listDataProduct);
  };

  const handleChangeInputs = (e, index) => {
    const { name, value } = e.target;
    const listProduct = [...dataProduct];
    listProduct[index][name] = value;
    setDataProduct(listProduct);
  };

  const handleChangeInputsColorAndSize = (e, name, index) => {
    const { value, label } = e;
    console.log(index, " Value : ", value, " label : ", label, " name: ", name);
    const listProduct = [...dataProduct];
    listProduct[index][name] = e;
    console.log(listProduct);
    setDataProduct(listProduct);
  };

  const addTotalPrice = () => {
    let totalPrice = 0;
    dataProduct.forEach((item) => {
      // Convertir la quantité en nombre et l'ajouter au total
      totalPrice += parseFloat(item.prix * item.quantite || 0); // Si la quantité est vide, on prend 0
    });
    setTotalPrice(totalPrice);
  };

  const removeTotlaPrice = (prix) => {
    if (prix) {
      setTotalPrice(totalPrice - parseFloat(prix));
    }
  };

  const [checkBoxCoupon, setCheckBoxCoupon] = useState(false);

  const bgCard = useColorModeValue("gray", "gray.400");

  const addOrdre = async () => {
    try {
      const formData = new FormData();
      formData.append("full_name", nomCompletClient);
      formData.append("email", emailClient);
      formData.append("pays", paysClient);
      formData.append("ville", villeClient);
      formData.append("address", adresseClient);
      formData.append("tel", phoneClient);
      formData.append("status_confirmation", "Nouveau");
      formData.append("price", totalPrice);
      formData.append("order_added_by", user.id);
      // formData.append('client', user.id)
      // formData.append('products', JSON.stringify(dataProduct))

      await axiosClient.post("/commandes/", formData).then(async (res) => {
        console.log(
          "ID PRODUIT : ",
          dataProduct[0].produit.value,
          " ID COMMANDE : ",
          res.data.id,
          " QUANTITY : ",
          dataProduct[0].quantite
        );
        for (let index = 0; index < dataProduct.length; index++) {
          await axiosClient.post("/produitCommande/", {
            product: dataProduct[index].produit.value,
            commande: res.data.id,
            color: dataProduct[index].couleur.label,
            size: dataProduct[index].taille.label,
            quantity: parseInt(dataProduct[index].quantite),
            price: parseFloat(dataProduct[index].prix),
          });
        }
        navigate("/list_new_orders");
      });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  return (
    <Box>
      <Flex>
        <Box
          w="100%"
          display={"flex"}
          justifyContent="space-between"
          flexDirection={{ base: "column", md: "row" }}
          mt={2}
          mb={{ base: 0, md: 5 }}
        >
          <Breadcrumb
            fontFamily="Plus Jakarta Display"
            fontStyle={"italic"}
            fontSize={{ base: "20px", sm: "25px", lg: "27px" }}
            color="gray"
          >
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/")}>
                {t("Accueil")}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/list_new_orders")}>
                {t("Nouvelles commandes")}{" "}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{t("Ajouter commande")} </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Flex>

      <Flex justify="center">
        <Stepper w={{ base: "100%", lg: "75%" }} index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Flex>

      <Stack
        bg={useColorModeValue("white", "#212835")}
        borderWidth="1px"
        rounded="xl"
        w={{ base: "100%", lg: "75%" }}
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={6}
        m="10px auto"
        as="form"
      >
        {activeStep === 0 ? (
          <>
            <Flex>
              <Text color={bgCard}>{t("Informations client")}</Text>
            </Flex>
            <Flex gap={4}>
              <FormControl isRequired>
                <FormLabel>{t("Nom complet")}</FormLabel>
                <Input
                  type="text"
                  placeholder={t("Nom complet")}
                  value={nomCompletClient}
                  onChange={(e) => setNomCompletClient(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email {t("(optionnel)")}</FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  value={emailClient}
                  onChange={(e) => setEmailClient(e.target.value)}
                />
              </FormControl>
            </Flex>
            <Flex gap={4}>
              <FormControl isRequired>
                <FormLabel>{t("Pays")}</FormLabel>
                <Input
                  type="text"
                  placeholder={t("Pays")}
                  value={paysClient}
                  onChange={(e) => setPaysClient(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>{t("Ville")}</FormLabel>
                <Input
                  type="email"
                  placeholder={t("Ville")}
                  value={villeClient}
                  onChange={(e) => setVilleClient(e.target.value)}
                />
              </FormControl>
            </Flex>
            <Flex gap={4}>
              <FormControl isRequired>
                <FormLabel>{t("Adresse")}</FormLabel>
                <Input
                  type="text"
                  placeholder={t("Adresse")}
                  value={adresseClient}
                  onChange={(e) => setAdresseClient(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>{t("N°téléphone")}</FormLabel>
                <Input
                  type="email"
                  placeholder={t("N°téléphone")}
                  value={phoneClient}
                  onChange={(e) => setPhoneClient(e.target.value)}
                />
              </FormControl>
            </Flex>
          </>
        ) : activeStep === 1 ? (
          <>
            <Flex>
              <Text color={bgCard}>{t("Informations du produit")}</Text>
            </Flex>
            {dataProduct.map((val, key) => {
              return (
                <>
                  <Flex
                    key={key}
                    alignItems={"center"}
                    gap={5}
                    w={"100%"}
                    flexDirection={"column"}
                  >
                    <FormControl isRequired w={"50%"}>
                      <FormLabel>{t("Liste des produits")}</FormLabel>
                      <ReactSelect
                        onChange={(e) => handleChangeTitleProduct(e, key)}
                        name="produit"
                        value={val.produit}
                        options={optionsTitre}
                        placeholder={t("Produits")}
                      />
                    </FormControl>

                    {val.image && (
                      <Avatar
                        h={150}
                        w={150}
                        borderRadius="12px"
                        me="18px"
                        name="Dan Abrahmov"
                        src={val.image}
                      />
                    )}
                  </Flex>
                  <Flex gap={4}>
                    {val.produit &&
                      getColor(val.produit.value).length !== 0 && (
                        <FormControl isRequired>
                          <FormLabel>{t("Couleur")}</FormLabel>
                          <ReactSelect
                            onChange={(e) =>
                              handleChangeInputsColorAndSize(e, "couleur", key)
                            }
                            value={val.couleur}
                            options={getColor(val.produit.value)}
                            placeholder={t("Couleur")}
                          />
                          {/* <Input type='text' placeholder='Couleur' name='couleur' value={val.couleur} onChange={e => handleChangeInputs(e, key)} /> */}
                        </FormControl>
                      )}
                    {val.produit && getSize(val.produit.value).length !== 0 && (
                      <FormControl isRequired>
                        <FormLabel>{t("Taille")}</FormLabel>
                        <ReactSelect
                          onChange={(e) =>
                            handleChangeInputsColorAndSize(e, "taille", key)
                          }
                          value={val.taille}
                          options={getSize(val.produit.value)}
                          placeholder={t("Taille")}
                        />
                        {/* <Input type='text' placeholder='Taille' name='taille' value={val.taille} onChange={e => handleChangeInputs(e, key)} /> */}
                      </FormControl>
                    )}
                  </Flex>
                  <Flex gap={4}>
                    {val.produit && (
                      <FormControl isRequired>
                        <FormLabel>{t("Quantité")}</FormLabel>
                        <Input
                          type="text"
                          placeholder={t("Quantité")}
                          name="quantite"
                          value={val.quantite}
                          onChange={(e) => {
                            handleChangeInputs(e, key);
                            addTotalPrice();
                          }}
                        />
                      </FormControl>
                    )}
                    {val.produit && (
                      <FormControl isRequired>
                        <FormLabel>{t("Prix de ventes")}</FormLabel>
                        <Input
                          type="text"
                          placeholder={t("Prix")}
                          name="prix"
                          value={val.prix}
                          onChange={(e) => {
                            handleChangeInputs(e, key);
                            addTotalPrice();
                          }}
                        />
                      </FormControl>
                    )}
                  </Flex>

                  <Flex justifyContent={"end"} mt={3} gap={3}>
                    <Flex justifyContent={"end"} gap={3}>
                      {dataProduct.length > 1 && (
                        <Button
                          colorScheme="red"
                          size={"sm"}
                          variant={"outline"}
                          onClick={() => {
                            removeDataProduct(key);
                            removeTotlaPrice(val.prix * val.quantite);
                            // removeTotlaQntt(val.quantity);
                            // removeTotlaPrice(val.price);
                          }}
                        >
                          {t("Supprimer")}
                        </Button>
                      )}
                      {dataProduct.length - 1 === key && (
                        <Button
                          colorScheme="green"
                          size={"sm"}
                          variant={"outline"}
                          onClick={() => {
                            addNewDataProduct();
                          }}
                        >
                          Plus
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                  <Divider orientation="horizontal" />
                </>
              );
            })}
          </>
        ) : (
          <>
            <Flex>
              <Text color={bgCard}>Coupon</Text>
            </Flex>
            <Flex w={"100%"} justify={"center"}>
              <Checkbox
                size="lg"
                colorScheme="purple"
                _focus={"none"}
                isChecked={checkBoxCoupon}
                onChange={(e) => setCheckBoxCoupon(e.target.checked)}
              >
                {t("Cette commande contient un coupon ?")}
              </Checkbox>
            </Flex>
            <Flex justify={"center"}>
              {checkBoxCoupon && (
                <FormControl w={"50%"} mt={3} isRequired>
                  <FormLabel>{t("Code coupon")}</FormLabel>
                  <Input
                    type="text"
                    placeholder={t("Code coupon")}
                    value={codeCoupon}
                    onChange={(e) => setCodeCoupon(e.target.value)}
                  />
                </FormControl>
              )}
            </Flex>
          </>
        )}

        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setActiveStep(activeStep - 1);
                  // setProgress(progress - 33.33);
                }}
                isDisabled={activeStep === 0}
                colorScheme="blue"
                variant="solid"
                w="7rem"
                mr="5%"
              >
                {t("Précédent")}
              </Button>
              <Button
                w="7rem"
                isDisabled={activeStep === 2}
                onClick={() => {
                  setActiveStep(activeStep + 1);
                }}
                colorScheme="blue"
                variant="outline"
              >
                {t("Suivant")}
              </Button>
            </Flex>
          </Flex>
        </ButtonGroup>
      </Stack>

      <Flex justify={"center"}>
        <Flex w={{ base: "100%", lg: "75%" }} justify={"end"}>
          {activeStep === 2 ? (
            <Button
              w="7rem"
              colorScheme="purple"
              size={"md"}
              variant={"outline"}
              onClick={addOrdre}
            >
              {t("Ajouter")}
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
}

export default NewOrder;
