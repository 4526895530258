import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient from '../../../axios-client'
import { Bar } from 'react-chartjs-2';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Avatar, Badge, Box, Button, Card, CardBody, CardHeader, CloseButton, Collapse, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Grid, Icon, IconButton, Image, Input, Progress, Select, SimpleGrid, Stack, Stat, StatLabel, StatNumber, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { MdAddShoppingCart, MdOutlineAddShoppingCart, MdOutlineRemoveShoppingCart } from 'react-icons/md';
import { TbTruckDelivery, TbTruckReturn } from 'react-icons/tb';
import { LuPackageX } from 'react-icons/lu';
import IconBox from '../../../components/Icons/IconBox';
import { useTranslation } from 'react-i18next';
import LineChart from '../../../components/Charts/LineChart';
import Slider from 'react-slick';
import { IoIosArrowForward } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

// import 'react-slideshow-image/dist/styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LiaShippingFastSolid } from 'react-icons/lia';
import { GrPowerReset } from 'react-icons/gr';

const styles = `
    .slick-prev:before, .slick-next:before {
    color: purple;
    font-size: 25px;
    
}

.slider-container .slick-prev, .slider-container .slick-next {
    z-index: 1; 
}`
function StatsCard({ title, stat, icon }) {
    return (
        <Stat
            px={{ base: 2, md: 2 }}
            py={'3'}
            shadow={'lg'}
            border={'1px'}
            borderColor={useColorModeValue('gray.300', 'gray.500')}
            rounded={'lg'}
        >
            <Flex w={"100%"} justifyContent={'space-between'}>
                <Box w={"70%"} pl={{ base: 2, md: 3 }}>
                    <StatLabel fontSize={"md"} fontWeight={'medium'} >
                        {title}
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    w={"30%"}
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}
                >
                    {icon}
                </Box>
            </Flex>
        </Stat>
    );
}
function UsersStatsCard({ title, stat, icon }) {
    return (
        <Stat
            px={{ base: 2, md: 2 }}
            py={'3'}
            shadow={'lg'}
            border={'1px'}
            borderColor={useColorModeValue('gray.300', 'gray.500')}
            rounded={'lg'}
        >
            <Flex w={"100%"} justifyContent={'space-between'}>
                <Box w={"100%"} pl={{ base: 2, md: 3 }}>
                    <StatLabel fontSize={"md"} fontWeight={'medium'} >
                        {title}
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>

            </Flex>
        </Stat>
    );
}

function Statistics() {
    const { user } = useStateContext()
    const { t } = useTranslation();
    const { isOpen, onToggle, onClose } = useDisclosure()
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isCollapseUsersOpen, setIsCollapseUsersOpen] = useState(false);
    const firstField = React.useRef()
    const [resetting, setResetting] = useState(false);
    const [SelectedSuiviUser, setSelectedSuiviUser] = useState("");
    const navigate = useNavigate()

    const onOpenDrawer = () => {
        setIsDrawerOpen(true);
    };
    const onCloseDrawer = () => {
        setIsDrawerOpen(false);
    };
    const onOpenCollapse = () => {
        setIsCollapseOpen(true);
    };
    const onCloseCollapse = () => {
        setIsCollapseOpen(false);
    };
    const onOpenUsersCollapse = () => {
        setIsCollapseUsersOpen(true);
    };
    const onCloseUsersCollapse = () => {
        setIsCollapseUsersOpen(false);
    };

    const [countNew, setCountNew] = useState()
    const [countConfirmed, setCountConfirmed] = useState()
    const [countPending, setCountPending] = useState()
    const [countAnnule, setCountAnnule] = useState()
    const [countLivre, setCountLivre] = useState()
    const [countRetour, setCountRetour] = useState()

    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd] = useState('')

    const [lineChartData, setLineChartData] = useState(null);
    const [slider, setSlider] = useState(null)
    const [imagesProduct, setImagesProduct] = useState([])
    const [products, setProducts] = useState([])
    const [users, setUsers] = useState([])

    const [chiffreAffaire, setChiffreAffaire] = useState()
    const [chargesStats, setChargesStats] = useState()

    const [chargesData, setChargesData] = useState();
    const [costsData, setCostsData] = useState();
    const [tauxData, setTauxData] = useState();

    const [productsNames, setProductsNames] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [productId, setProductId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [productStats, setProductStats] = useState();
    const [usersStatsSuivi, setUsersStatsSuivi] = useState();
    const [usersStatsStock, setUsersStatsStock] = useState();
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState(currentYear);


    const generateYearOptions = () => {
        const yearOptions = [];
        for (let year = 2024; year <= currentYear; year++) {
            yearOptions.push(
                <option key={year} value={year}>
                    {year}
                </option>
            );
        }
        return yearOptions;
    };


    const handleYearChange = (event) => {
        setYear(event.target.value);
        if (event.target.value === "0") {
            setYear(currentYear)
        }
    };

    const fetchBarData = (userId) => {
        axiosClient.get(`/commandes/getCommandesCount/?client=${userId}&product=${selectedProduct}`)
            .then((res) => {
                setCountNew(res.data.count_new);
                setCountPending(res.data.count_pending);
                setCountConfirmed(res.data.count_confirmed);
                setCountLivre(res.data.count_livre);
                setCountRetour(res.data.count_retourne);
                setCountAnnule(res.data.count_annule);
            })
            .catch((error) => {
                console.error(error);
            });

    }

    const labelsCharges = [t("Livraison"), t("Marqueting"), t("Salaires"), t("Stockage"), t("Emballage"), t("Maintenance"), t("Assurance"), t("Service client"), t("Matériel")];

    const dataChartCharges = {
        labels: labelsCharges,
        datasets: [
            {
                label: t("Charges"),
                data: [chargesData?.livraison,
                chargesData?.marketing,
                chargesData?.salaires,
                chargesData?.fraisStockage,
                chargesData?.fraisEmballage,
                chargesData?.fraisMaintenance,
                chargesData?.fraisAssurance,
                chargesData?.fraisServiceClient,
                chargesData?.fraisMateriel],
                // data: [100, 150, 200, 480, 503, 400, 178, 330, 250],
                backgroundColor: [
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(91, 255, 76, 0.2)",
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(55, 93, 179, 0.2)",
                    "rgba(220, 20, 60, 0.2)",
                    "rgba(127, 255, 0, 0.2)",
                    "rgba(240, 93, 253, 0.2)"
                ],
                borderColor: [
                    "rgb(54, 162, 235)",
                    "rgba(153, 102, 255)",
                    "rgba(91, 255, 76)",
                    "rgb(255, 99, 132)",
                    "rgb(255, 206, 86)",
                    "rgb(55, 93, 179)",
                    "rgb(220, 20, 60)",
                    "rgb(127, 255, 0)",
                    "rgb(240, 93, 253)"
                ],
                borderWidth: 1,
            },
        ],
    };
    const optionsCharges = {
        plugins: {
            title: {
                display: true,
                text: 'Charges Chart',
                font: {
                    size: 16,
                },
            },
            legend: { display: false }
        },
    };

    const labels = [t("Nouveau"), t("Encours"), t("Confirmée"), t("Livrée"), t("Retournée"), t("Annulée")];

    const dataChart = {
        labels: labels,
        datasets: [
            {
                label: "",
                data: [countNew, countPending, countConfirmed, countLivre, countRetour, countAnnule],
                backgroundColor: [
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(246, 173, 85, 0.2)",
                    "rgba(104, 211, 145, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 99, 132, 0.2)",

                ],
                borderColor: [
                    "rgb(54, 162, 235)",
                    "rgba(246, 173, 85)",
                    "rgb(104, 211, 145)",
                    "rgba(153, 102, 255)",
                    "rgba(153, 102, 255)",
                    "rgb(255, 99, 132)",


                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Charges Chart',
                font: {
                    size: 16,
                },
            },
            legend: {
                display: false
            }
        },
    };



    const lineChartOptionsDashboard = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            type: "datetime",
            categories: [
                    "Jan",
                    t("Fév"),
                    "Mar",
                    t("Avr"),
                    t("Mai"),
                    t("Jui"),
                    t("Juil"),
                    t("Aou"),
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
            ],
            labels: {
                style: {
                    colors: "darkgray",
                    fontSize: "12px",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "darkgray",
                    fontSize: "12px",
                },
            },
        },
        legend: {
            show: false,
        },
        grid: {
            strokeDashArray: 5,
            borderColor: "gray"
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                shadeIntensity: 0,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 0,
                stops: [],
            },
            colors: ["#582CFF", "#ff7b7b"],
        },
        colors: ["#582CFF", "#ff7b7b"],
    };

    // Settings for the slider
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const settingsUsers = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    const fetchChiffreAffaire = async (userId) => {
        try {
            const res = await axiosClient.get(`/commandes/getChiffreAffaire/?client=${userId}`);
            setChiffreAffaire(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };
    const fetchProfitNet = async (userId) => {
        try {
            const res = await axiosClient.get(`/charges/getStatsCharges/?client=${userId}`);
            setChargesStats(res.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    const fetchDataEvolution = async () => {
        try {
            // Fetch revenus data
            const revenusRes = await axiosClient.get(`/commandes/getRevenusByMonth/?client=${user?.id}&dateStart=${dateStart}&year=${year}&product=${selectedProduct}`);
            const revenusData = revenusRes.data || [];

            // Fetch charges data (adjust according to your actual API endpoint and logic)
            const chargesRes = await axiosClient.get(`/charges/getChargesByMonth/?client=${user?.id}&dateStart=${dateStart}&year=${year}&product=${selectedProduct}`);
            const chargesData = chargesRes.data || [];

            // Set line chart data
            setLineChartData([
                {
                    name: t("Revenus"),
                    data: revenusData,
                },
                {
                    name: t("Charges"),
                    data: chargesData,
                },
            ]);


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchCharges = async (userId) => {
        axiosClient.get(`/charges/getCharges/?client=${userId}&dateStart=${dateStart}&dateEnd=${dateEnd}&product=${selectedProduct}`)
            .then((response) => {
                setChargesData(response.data)
            })
            .catch((error) => {
                console.error(error);

            });
    };
    const fetchCosts = async (userId) => {
        axiosClient.get(`/commandes/getCosts/?client=${userId}&product=${selectedProduct}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
            .then((response) => {
                setCostsData(response.data)
            })
            .catch((error) => {
                console.error(error);

            });
    };
    const fetchTaux = async (userId,userSuivi) => {
        axiosClient.get(`/commandes/getTaux/?client=${userId}&product=${selectedProduct}&dateStart=${dateStart}&dateEnd=${dateEnd}&userSuivi=${userSuivi}`)
            .then((response) => {
                setTauxData(response.data)
            })
            .catch((error) => {
                console.error(error);

            });
    };
    const fetchProductsNames = async (userId) => {
        axiosClient.get(`/products/get_All_Products_names/?client=${userId}`)
            .then((response) => {
                setProductsNames(response.data)
            })
            .catch((error) => {
                console.error(error);

            });
    };

    const fetchProducts = async (userId, productId) => {
        axiosClient.get(`/products/getAllProducts/?page=1&page_size=10&id_user=${userId}&product=${productId}`)
            .then((response) => {
                setProducts(response.data.results)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const fetchUsers = async (userId) => {
        axiosClient.get(`/clients/getClientUsers/?page=1&page_size=99999&id=${userId}`)
            .then((response) => {
                setUsers(response.data.results || [])
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const fetchProductStats = async (userId, productId) => {
        axiosClient.get(`/commandes/getStatsProduct/?client=${userId}&product=${productId}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
            .then((response) => {
                setProductStats(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const fetchUsersStats = async (userId,staff,productId) => {
        axiosClient.get(`/clients/getStatsUsersSuivi/?client=${userId}&staff=${staff}&product=${productId}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
            .then((response) => {
                setUsersStatsSuivi(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
        axiosClient.get(`/clients/getStatsUsersStock/?client=${userId}&staff=${staff}&product=${productId}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
            .then((response) => {
                setUsersStatsStock(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    useEffect(() => {
        if (resetting) {
            onCloseCollapse();
            onCloseUsersCollapse()
            fetchDataEvolution();
            fetchBarData(user?.id);
            fetchChiffreAffaire(user?.id);
            fetchProfitNet(user?.id);
            fetchCharges(user?.id);
            fetchCosts(user?.id);
            fetchTaux(user?.id,SelectedSuiviUser)
            fetchProductsNames(user?.id);
            fetchProducts(user?.id, selectedProduct);
            fetchUsers(user?.id);
            fetchProductStats(user?.id, selectedProduct ? selectedProduct : productId);
            fetchUsersStats(user?.id,selectedUser?.id, selectedProduct);
            setResetting(false);  // Reset the resetting state
            setSelectedSuiviUser("")
        }
    }, [resetting]);

    const onClickStats = () => {
        if (user?.id && (dateStart || year)) {
            setLineChartData(null)
            fetchDataEvolution();
        }
        if (year === "") {
            fetchDataEvolution();
        }
        fetchDataEvolution();
        fetchBarData(user?.id);
        fetchCharges(user?.id)
        fetchCosts(user?.id)
        fetchTaux(user?.id,SelectedSuiviUser)
        fetchProductsNames(user?.id)
        fetchProducts(user?.id, selectedProduct)
        fetchUsers(user?.id)
        fetchProductStats(user?.id, selectedProduct ? selectedProduct : productId)
        fetchUsersStats(user?.id,selectedUser?.id, selectedProduct);
        // onCloseDrawer()
        onOpenCollapse()
    }
    useEffect(() => {
        if (user?.id && (dateStart || year)) {
            setLineChartData(null)
            fetchDataEvolution();
        }
        if (year === "") {
            fetchDataEvolution();
        }
        fetchDataEvolution();
        fetchBarData(user?.id);
        fetchChiffreAffaire(user?.id);
        fetchProfitNet(user?.id);
        fetchCharges(user?.id)
        fetchCosts(user?.id)
        fetchTaux(user?.id,SelectedSuiviUser)
        fetchProductsNames(user?.id)
        fetchProducts(user?.id, selectedProduct)
        fetchUsers(user?.id)
        fetchProductStats(user?.id, selectedProduct ? selectedProduct : productId)
        fetchUsersStats(user?.id,selectedUser?.id, selectedProduct);

    }, [user, productId, year,selectedUser?.id,SelectedSuiviUser]);

    const reset = () => {
        setDateStart('');
        setDateEnd('');
        setSelectedProduct('');
        setResetting(true);
    };

    const getMinEndDate = (startDate) => {
        if (!startDate) return '';
        const date = new Date(startDate);
        date.setDate(date.getDate() + 1);
        return date.toISOString().split('T')[0];
    };

    const borderColor = useColorModeValue("gray.300", "gray.600");
    const bgCollapse = useColorModeValue("gray.50", "gray.700");

    return (
        <Flex mt={2} flexDirection='column' position={"relative"}>
            <style>{styles}</style>
            {/* -----------------Filter drawer button ----------------- */}
            <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        onOpenDrawer()
                    }}
                    colorScheme='purple'
                >
                    <FaFilter size={18} />

                </Button>
            </Flex>

            {/* -----------------Header section ----------------- */}
            <Flex mt={2} flexDirection={{ base: 'column', md: "row" }} justifyContent="space-between" justify={"center"}>
                <Flex>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "30px" }} color="gray">

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Statistiques")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Flex>


            </Flex>
            {/* -----------------top section ----------------- */}
            <Grid
                templateColumns={{ sm: '1fr', lg: '1.5fr 1.5fr' }}
                maxW={{ sm: '100%', md: '100%' }}
                gap='24px'
                my='20px'>

                {/* -----------------Order statuses section ----------------- */}
                <Card rounded={'xl'} p={{ base: "5px", md: "15px" }}>
                    <CardBody>
                        <Flex direction='column' w='100%'>
                            <Box
                                bg={useColorModeValue('linear-gradient(71deg, rgba(255,255,255,1) 0%, rgba(239,229,253,1) 53%, rgba(214,188,250,1) 100%)', 'linear-gradient(71deg, rgba(50,62,81,1) 0%, rgba(65,49,117,1) 100%)')}
                                borderRadius='20px'
                                shadow={'xl'}
                                w='100%'

                                // minH={{ sm: '300px' }}
                                display={{ sm: 'flex', md: 'block' }}
                                justify={{ sm: 'center', md: 'flex-center' }}
                                align={{ sm: 'center', md: 'flex-start' }}
                                minH={{ base: '180px', md: '220px' }}
                                p={{ base: '15px', sm: "20px", md: '22px' }}>

                                <Bar style={{ width: "100%" }} data={dataChart} options={options} />
                            </Box>
                            <Flex direction='column' mt='24px' mb='10px' alignSelf='flex-start'>
                                <Text fontSize='lg' fontWeight='bold' mb='6px'>
                                    {t("Statuts des commandes")}
                                </Text>
                                {/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										(+23%)
									</Text>{' '}
									than last week
								</Text> */}
                            </Flex>
                            <SimpleGrid gap={{ base: "12px", sm: '12px' }} columns={{ base: 2, md: 3 }}>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <MdOutlineAddShoppingCart size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Nouveau")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countNew}
                                    </Text>
                                    <Progress colorScheme='brand' bg='blue.300' borderRadius='30px' h='5px' value={20} />
                                </Flex>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <TbTruckDelivery size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Encours")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countPending}
                                    </Text>
                                    <Progress colorScheme='brand' bg='yellow.300' borderRadius='30px' h='5px' value={90} />
                                </Flex>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <MdOutlineAddShoppingCart size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Confirmée")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countConfirmed}
                                    </Text>
                                    <Progress colorScheme='brand' bg='green.300' borderRadius='30px' h='5px' value={20} />
                                </Flex>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <TbTruckDelivery size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Livrée")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countLivre}
                                    </Text>
                                    <Progress colorScheme='brand' bg='purple.300' borderRadius='30px' h='5px' value={90} />
                                </Flex>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <TbTruckReturn size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Retournée")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countRetour}
                                    </Text>
                                    <Progress colorScheme='brand' bg='orange.300' borderRadius='30px' h='5px' value={30} />
                                </Flex>
                                <Flex direction='column'>
                                    <Flex alignItems='center'>
                                        <IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
                                            <LuPackageX size={"23px"} />
                                        </IconBox>
                                        <Text fontSize='sm' color='gray.400'>
                                            {t("Annulée")}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize={{ sm: 'md', lg: 'lg' }}

                                        fontWeight='bold'
                                        mb='6px'
                                        my='6px'>
                                        {countAnnule}
                                    </Text>
                                    <Progress colorScheme='brand' bg='red.300' borderRadius='30px' h='5px' value={50} />
                                </Flex>
                            </SimpleGrid>
                        </Flex>
                    </CardBody>
                </Card>

                {/* -----------------top right section ----------------- */}
                <Flex h={'auto'} gap={5} flexDirection={'column'} >

                    {/* -----------------general cards stats section ----------------- */}
                    <Flex gap={5}>
                        <Card w={"50%"} rounded={'xl'} p='10px '>
                            <CardHeader ps='22px'>
                                <Flex direction='column' alignSelf='flex-start' justifyContent={"space-between"}>
                                    <Text fontSize='lg' fontWeight='bold' mb='6px'>
                                        {t("Chiffre d'affaire")}
                                    </Text>
                                    <Text>{chiffreAffaire} MAD</Text>
                                </Flex>
                            </CardHeader>

                        </Card>
                        <Card w={"50%"} rounded={'xl'} p='10px '>
                            <CardHeader ps='22px'>
                                <Flex direction='column' alignSelf='flex-start'>
                                    <Text fontSize='lg' fontWeight='bold' mb='6px'>
                                        {t("Profit net")}
                                    </Text>
                                    <Text>{chiffreAffaire - chargesStats} MAD</Text>
                                </Flex>
                            </CardHeader>

                        </Card>
                    </Flex>
                    {/* ----------------- Evolution of Income and Expenses section ----------------- */}
                    <Card h={"100%"} rounded={'xl'} p='10px '>
                        <CardHeader ps='22px'>
                            <Flex alignItems={"center"} gap={3} mb='2px' direction={{ base: "column", md: 'row' }} justifyContent={"space-between"} >
                                <Text w={{ base: "100%", md: "75%", lg: "70%" }} fontSize='lg' fontWeight='bold' >
                                    {t("Évolution des Revenus et des Charges")}
                                </Text>
                                {/* <Input onChange={ e=> setYear(e.target.value)} value={year} w={{base:"100%",md:"25%",lg:"30%"}} type='month'/> */}
                                <Select rounded={'lg'} w={{ base: "100%", md: "25%", lg: "30%" }} defaultValue={year} onChange={handleYearChange} >
                                    <option value="0">	{t("années")}</option>
                                    {generateYearOptions()}

                                </Select>
                            </Flex>
                        </CardHeader>
                        <Box mb={2} w='100%' h={{ base: '200px', sm: "300px" }}>
                            {lineChartData &&
                                <LineChart
                                    lineChartData={lineChartData}
                                    lineChartOptions={lineChartOptionsDashboard}
                                />}

                        </Box>
                    </Card>
                </Flex>

            </Grid>

            {/* -----------------products list section ----------------- */}
            <Flex flexDirection={"column"} px={8} width="100%" >
                <Flex align={"center"} justifyContent={"space-between"}>
                    <Text py={5} fontSize={"24px"}>{t("Liste des produits")}</Text>
                    <Flex _hover={{
                        color: "blue.500"
                    }} textDecoration={"underline"} cursor={"pointer"} gap={1} align={"center"} justify={"center"}>
                        <Text onClick={() => navigate('/products')} py={5} fontSize={"17px"}>{t("Voir tout")}</Text>
                        <IoIosArrowForward fontSize={"13px"} />

                    </Flex>


                </Flex>
                <Slider  {...settings} style={{ width: '100%', height: "100%" }}>
                    {products?.map((product, index) => (
                        
                        <Flex  cursor={"pointer"} onClick={() => {
                            setProductId(product.id)
                            onOpenCollapse()
                        }

                        } w={"100%"} flexDirection={'column'} justifyContent={"center"} alignItems={'center'}>
                            <Image
                                h={"150px"}
                                borderRadius={"20px"}
                                key={index}
                                src={product?.images[0].image}
                                objectFit="contain"
                                mx="5px"
                                padding={2}
                            />
                            <Text w={"100%"} textAlign={"center"}>{product.title}</Text>

                            {/* <Flex bg={'green'} w={"90%"} flexDirection={'column'} justifyContent={"center"} alignItems={"center"}>
                                <Image
                                    borderRadius={"20px"}
                                    key={index}
                                    src={slideImage?.image}
                                    objectFit="contain"
                                    mx="5px"
                                    padding={2}
                                />
                                <Text w={"100%"} bg={"yellow"} textAlign={"center"}>title</Text>
                            </Flex> */}
                        </Flex>

                    ))}

                    {/* <Flex
                    
                    border={"1px"}
                    borderColor={'gray.400'}
                    height={"100%"}
                    borderRadius={"20px"}
                    
                    width={"100%"}
                    padding={2}
                    onClick={onToggle}  
                    flexDirection={'column'} 
                    justifyContent={"center"} 
                    alignItems={'center'}>
                    
            
                    <Text height={"100%"}>
                        voir plus
                    </Text>


                </Flex> */}

                </Slider>
                <Collapse mt='4' in={isCollapseOpen} >
                    <Box
                        p='20px'
                        // color='black'

                        // bg='gray.50'
                        rounded='md'
                    // shadow='md'
                    // boxShadow={"2xl"}
                    >
                        <Flex w={"100%"} justifyContent={"flex-end"}>
                            <CloseButton mr={-5} onClick={() => onCloseCollapse()} size='md' />

                        </Flex>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
                            <StatsCard title={t('nombre de fois commandé')} stat={productStats?.product_ordered} icon={<MdAddShoppingCart size={'3em'} />} />
                            <StatsCard title={t('nombre de fois livré')} stat={productStats?.product_delivered} icon={<LiaShippingFastSolid size={'3em'} />} />
                            <StatsCard title={t('nombre de fois annulé')} stat={productStats?.product_canceled} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
                        </SimpleGrid>
                    </Box>
                </Collapse>
            </Flex>

            {/* -----------------users list section ----------------- */}
            <Flex pb={3} flexDirection={"column"} px={8} width="100%" height="100%">
                <Text py={5} fontSize={"24px"}>{t("Liste des personnels")}</Text>
                <Slider {...settingsUsers} style={{ width: '100%', height: "100%" }}>

                    {users?.map((user, index) => (
                        <>
                            <Flex w={"100%"} flexDirection={'column'} justifyContent={"center"} alignItems={'center'}>
                                {/* <Avatar
                                
                                size={"2xl"}
                                key={index}
                                src={slideImage?.image}
                                objectFit="contain"
                                mx="5px"
                                padding={2}
                            />
                            <Text w={"100%"} textAlign={"center"}>title 2</Text> */}

                                <Flex onClick={() => {
                                    setSelectedUser(user);
                                    onOpenUsersCollapse()
                                    // setSelectedUserId(user.id)
                                }

                                } w={"90%"} flexDirection={'column'} justifyContent={"center"} alignItems={"center"}>
                                    <Avatar

                                        size={"xl"}
                                        key={index}
                                        src={user?.image}
                                        objectFit="contain"
                                        mx="5px"
                                        padding={2}
                                    />
                                    <Text w={"100%"} textAlign={"center"}>{user.first_name} {user.last_name}</Text>
                                </Flex>
                            </Flex>
                        </>


                    ))}
                </Slider>
                <Collapse in={isCollapseUsersOpen} >
                    <Box
                        p='20px'
                        rounded='md'
                    >

                        {selectedUser?.role_client === "RespoSuiviClient" ?
                            <>
                                <Flex w={"100%"} justifyContent={"flex-end"}>
                                    <CloseButton mr={-5} onClick={() => onCloseUsersCollapse()} size='md' />
                                </Flex>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={{ base: 5, lg: 8 }}>
                                    <UsersStatsCard title={t('Nombre de commandes en cours')} stat={usersStatsSuivi?.num_commandes_en_cours} icon={<MdAddShoppingCart size={'3em'} />} />
                                    <UsersStatsCard title={t('Nombre de commandes confirmés')} stat={usersStatsSuivi?.num_commandes_confirmes} icon={<LiaShippingFastSolid size={'3em'} />} />
                                    <UsersStatsCard title={t('Nombre de commandes livrés')} stat={usersStatsSuivi?.num_commandes_livres} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
                                    <UsersStatsCard title={t('Nombre de commandes annulés')} stat={usersStatsSuivi?.num_commandes_annules} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
                                </SimpleGrid>
                            </>

                            :
                            selectedUser?.role_client === "StockManagerClient"
                                ? 
                                <>
                                    <Flex w={"100%"} justifyContent={"flex-end"}>
                                        <CloseButton mr={-5} onClick={() => onCloseUsersCollapse()} size='md' />
                                    </Flex>
                                    < SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={{ base: 5, lg: 8 }}>
                                        <UsersStatsCard title={t('Nombre de commandes en cours')} stat={usersStatsStock?.num_commandes_en_cours} icon={<MdAddShoppingCart size={'3em'} />} />
                                        <UsersStatsCard title={t('Nombre de commandes Emballés')} stat={usersStatsStock?.num_commandes_embales} icon={<LiaShippingFastSolid size={'3em'} />} />
                                        <UsersStatsCard title={t('Nombre de commandes Collectés')} stat={usersStatsStock?.num_commandes_collecte} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
                                        <UsersStatsCard title={t('Nombre de commandes annulés')} stat={usersStatsStock?.num_commandes_annules} icon={<MdOutlineRemoveShoppingCart size={'3em'} />} />
                                    </SimpleGrid>
                                </>

                                : null
                        }
                    </Box>
                    {/* <Flex
                    mt='5'
                    w={"100%"}

                    alignItems={"center"}
                    justifyContent={"center"}
                // shadow='md'
                >
                    {selectedUserId?.role_client === "RespoSuiviClient" ?
                        <Flex
                            rounded='md'
                            p='20px'
                            bg={bgCollapse}
                            w={"50%"}
                            flexDirection={"column"}>
                            <Text>Nombre de commandes en cours : 1500</Text>
                            <Text>Nombre de commandes confirmés : 1500</Text>
                            <Text>Nombre de commandes livrés : 1500</Text>
                            <Text>Nombre de commandes annulé : 1500</Text>
                        </Flex>
                        : selectedUserId?.role_client === "StockManagerClient"
                            ?
                            <Flex
                                rounded='md'
                                p='20px'
                                bg={bgCollapse}
                                w={"50%"}
                                flexDirection={"column"}>
                                <Text>Nombre de commandes en cours : 1500</Text>
                                <Text>Nombre de commandes Emballés : 2800</Text>
                                <Text>Nombre de commandes Collectés : 2800</Text>
                            </Flex> : null
                    }

                </Flex> */}
                </Collapse>
            </Flex>

            {/* -----------------bottom section ----------------- */}
            <Grid
                templateColumns={{ sm: '1fr', lg: '1.9fr 1.1fr' }}
                maxW={{ sm: '100%', md: '100%' }}
                gap='24px'
                my='20px'>

                {/* -----------------charges stats section ----------------- */}
                <Card rounded={'xl'} p={{ base: "5px", md: "15px" }}>
                    <CardHeader>
                        <Flex direction='column' alignSelf='flex-start'>
                            <Text fontSize='lg' fontWeight='bold' >
                                {t("Statistiques des charges")}
                            </Text>

                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Flex direction='column' w='100%'>
                            <Box
                                bg={useColorModeValue('linear-gradient(71deg, rgba(255,255,255,1) 0%, rgba(239,229,253,1) 53%, rgba(214,188,250,1) 100%)', 'linear-gradient(71deg, rgba(50,62,81,1) 0%, rgba(65,49,117,1) 100%)')}
                                borderRadius='20px'
                                shadow={'xl'}
                                w='100%'

                                // minH={{ sm: '300px' }}
                                display={{ sm: 'flex', md: 'block' }}
                                justify={{ sm: 'center', md: 'flex-center' }}
                                align={{ sm: 'center', md: 'flex-start' }}
                                minH={{ base: '180px', md: '220px' }}
                                p={{ base: '15px', sm: "20px", md: '22px' }}>

                                <Bar style={{ width: "100%" }} data={dataChartCharges} options={optionsCharges} />
                            </Box>


                        </Flex>
                    </CardBody>
                </Card>

                {/* -----------------bottom right costs cards section ----------------- */}
                <Flex flexDirection={"column"} gap={5} >
                    <Card rounded={'xl'} p='16px' height={"fit-content"} overflowX={{ sm: 'scroll', xl: 'hidden' }}>

                        <CardHeader pl={0}>
                            <Flex direction='column' alignSelf='flex-start'>
                                <Text fontSize='lg' fontWeight='bold' >
                                    {t("Coûts")}
                                </Text>

                            </Flex>
                        </CardHeader>
                        <Card mb={2} shadow={'none'}>
                            <CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
                                <Flex align={"center"} gap={2}>
                                    <Flex fontSize={'md'} direction={"column"}>
                                        <Text>{t("Coût par prospect")}</Text>
                                    </Flex>
                                </Flex>
                                <Badge gap={1} display={"flex"} rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='orange'>
                                    <Text fontSize={16} >
                                        {parseFloat(costsData?.cost_per_lead).toFixed(2)}
                                    </Text>
                                    <Text fontSize={10} fontWeight={"semibold"}>
                                        MAD
                                    </Text>

                                </Badge>
                            </CardBody>
                        </Card>
                        <Card mb={2} shadow={'none'}>
                            <CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
                                <Flex align={"center"} gap={2}>
                                    <Flex fontSize={'md'} direction={"column"}>
                                        <Text>{t("Coût par prospect confirmé")}</Text>
                                    </Flex>
                                </Flex>

                                <Badge gap={1} display={"flex"} rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='green'>
                                    <Text fontSize={16} >
                                        {parseFloat(costsData?.cost_per_confirmed_lead).toFixed(2)}
                                    </Text>
                                    <Text fontSize={10} fontWeight={"semibold"}>
                                        MAD
                                    </Text>

                                </Badge>
                            </CardBody>
                        </Card>
                        <Card mb={2} shadow={'none'}>
                            <CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
                                <Flex align={"center"} gap={2}>
                                    <Flex fontSize={'md'} direction={"column"}>
                                        <Text>{t("Coût par prospect livré")}</Text>
                                    </Flex>
                                </Flex>

                                <Badge gap={1} display={"flex"} rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='blue'>
                                    <Text fontSize={16} >
                                        {parseFloat(costsData?.cost_per_delivered_lead).toFixed(2)}
                                    </Text>
                                    <Text fontSize={10} fontWeight={"semibold"}>
                                        MAD
                                    </Text>

                                </Badge>
                            </CardBody>
                        </Card>


                    </Card>
                    <Card rounded={'xl'} p='16px' height={"fit-content"} overflowX={{ sm: 'scroll', xl: 'hidden' }}>

                        <CardHeader pl={0}>
                            <Flex align={"center"} gap={5} direction='row' justifyContent={"space-between"}>
                                <Text  w={"40%"} fontSize='lg' fontWeight='bold' >
                                    {t("Taux")}
                                </Text>
                                <Select w={"60%"} size={"sm"} rounded={"md"} value={SelectedSuiviUser} onChange={(e) => setSelectedSuiviUser(e.target.value)}>
                                    <option value="">{t("Choisissez un responsable de suivi")}</option>
                                    {users.filter(e=>e.role==="RespoSuiviClient").map((user) => (
                                        <option value={user?.id}>{user?.first_name} {user.last_name}</option>

                                    ))}
                                </Select>

                            </Flex>
                        </CardHeader>
                        <Card mb={2} shadow={'none'}>
                            <CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
                                <Flex align={"center"} gap={2}>
                                    <Flex fontSize={'md'} direction={"column"}>
                                        <Text>{t("Taux de confirmation")}</Text>
                                    </Flex>
                                </Flex>
                                <Badge rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='cyan'>
                                    <Text fontSize={15} >
                                        {parseFloat(tauxData?.taux_confirmation).toFixed(2)} %
                                    </Text>
                                </Badge>
                            </CardBody>
                        </Card>
                        <Card mb={2} shadow={'none'}>
                            <CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
                                <Flex align={"center"} gap={2}>
                                    <Flex fontSize={'md'} direction={"column"}>
                                        <Text>{t("Taux de livraison")}</Text>
                                    </Flex>
                                </Flex>
                                <Badge rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='purple'>
                                    <Text fontSize={15} >
                                        {parseFloat(tauxData?.taux_delivery).toFixed(2)} %
                                    </Text>
                                </Badge>
                            </CardBody>
                        </Card>


                    </Card>
                </Flex>

            </Grid>

            {/* -----------------filters drawer----------------- */}
            <Drawer
                isOpen={isDrawerOpen}
                placement='left'
                initialFocusRef={firstField}
                onClose={onCloseDrawer}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        {t("Filtrer par")} :
                    </DrawerHeader>

                    <DrawerBody>
                        <Flex gap={5} flexDirection={"column"}  >
                            <Box >
                                <Text mb={2} >{t("Date debut")}</Text>
                                <Input borderRadius={"5"} borderColor={borderColor} onChange={e => {
                                    setDateStart(e.target.value)
                                    setDateEnd(getMinEndDate(e.target.value))
                                }

                                } value={dateStart} placeholder='Select Date' size='md' type='date' />

                            </Box>
                            <Box>
                                <Text mb={2} >{t('Date fin')}</Text>

                                <Input min={(dateStart)} borderRadius={"5"} borderColor={borderColor} onChange={e => setDateEnd(e.target.value)} value={dateEnd} placeholder='Select Date' size='md' type='date' />

                            </Box>
                            <Divider borderColor={'gray.300'} my={2} />
                            <Box>
                                <Text mb={2} >{t("Produit")}</Text>

                                <Select rounded={"md"} value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                    <option value="">{t("Chosissez un produit")}</option>
                                    {productsNames.map((product) => (
                                        <option value={product?.id}>{product?.title}</option>

                                    ))}
                                </Select>
                            </Box>
                        </Flex>

                    </DrawerBody>

                    <DrawerFooter gap={2} display={'flex'} borderTopWidth='1px'>
                        <Button variant='outline' onClick={onCloseDrawer}>
                            {t("Annuler")}
                        </Button>
                        <IconButton

                            onClick={
                                () => reset()

                            }
                            icon={<GrPowerReset />}
                            colorScheme="purple"
                        />
                        <Button colorScheme='pink' onClick={onClickStats}>{t("Appliquer")}</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

        </Flex >
    )
}

export default Statistics