import { Navigate, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useStateContext } from '../context/ContextProvider';
import axiosClient from '../axios-client';
import { useToast } from '@chakra-ui/toast';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button } from '@chakra-ui/react';

const RequireAuth = ({ element, allowedRoles }) => {
  const { user, token, setToken } = useStateContext();
  const isAuthenticated = !!token;
  const navigate = useNavigate();

  const toast = useToast()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  //setting up encrypted id informations
  // useEffect(() => {
  //   if (localStorage.getItem("mutumAdmin") && localStorage.getItem("tsaroAdmin")) {
  //     setEmail(decryptString(localStorage.getItem("mutumAdmin"), 25))
  //     setPassword(decryptString(localStorage.getItem("tsaroAdmin"), 25))
  //   }
  // }, [])


  //store actual time in second
  function storeActualTime() {
    const currentTime = new Date();
    const storedTime = currentTime.toISOString(); // Convert to ISO date-time format
    localStorage.setItem('refreshTimeAdmin', storedTime);
  }

  //decryption function
  function decryptString(encryptedText, shift) {
    if (!Number.isInteger(shift) || shift < 1 || shift > 25) {
      throw new Error("Shift must be an integer between 1 and 25.");
    }

    const decryptedArray = [];
    for (let i = 0; i < encryptedText.length; i++) {
      let charCode = encryptedText.charCodeAt(i);

      // Decrypt uppercase letters
      if (charCode >= 65 && charCode <= 90) {
        charCode = ((charCode - 65 - shift + 26) % 26) + 65;
      }
      // Decrypt lowercase letters
      else if (charCode >= 97 && charCode <= 122) {
        charCode = ((charCode - 97 - shift + 26) % 26) + 97;
      }

      decryptedArray.push(String.fromCharCode(charCode));
    }

    return decryptedArray.join("");
  }


  useEffect(() => {
    axiosClient.get('auth/user/')
      .then((response) => {
        if (!allowedRoles.includes(response.data.role)) {
          return navigate('/unauthorized')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [allowedRoles])


  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  return (
    <Fragment>
      {element}
    </Fragment>
  )
};

export default RequireAuth;