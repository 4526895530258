import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PiPlusBold } from 'react-icons/pi';
import Confirmation from './Confirmation';
import Stock from './Stock';
import Suivi from './Suivi';
import { useStateContext } from '../../../../context/ContextProvider';
import Facture from './Facture';

function MyOrders() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { user } = useStateContext()
    return (
        <Box position="relative" mb={5}>

            {/* <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        navigate("/NewOrder")
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex> */}

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Mes commandes")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>

            <Stack>
                <Tabs isLazy>
                    <TabList>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Confirmation")}</Text>
                        </Tab>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Stock")}</Text>
                        </Tab>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Suivi")}</Text>
                        </Tab>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Facture")}</Text>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {user && <Confirmation user={user} />}
                        </TabPanel>
                        <TabPanel>
                            <Stock />
                        </TabPanel>
                        <TabPanel>
                            <Suivi />
                        </TabPanel>
                        <TabPanel>
                            <Facture/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Stack>
        </Box>
    )
}

export default MyOrders