import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardBody, CardHeader, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { DeleteIcon, EditIcon, HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../../assets/images/bell.png'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../../components/MyPagination';
import { MdHistory } from 'react-icons/md';
import coupon from '../../../assets/images/coupon.png'

function GestCoupons() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { user } = useStateContext();
    const toast = useToast()
    const cancelRef = useRef()

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [changeModal, setChangeModal] = useState(true)
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [id, setId] = useState(null);

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState()
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)

    const [couponData, setCouponData] = useState();
    const [couponId, setCouponId] = useState('');

    const [title, setTitle] = useState("");
    const [reduction, setReduction] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [limitUsage, setLimitUsage] = useState();

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [date , setDate]=useState("")


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    const [currentPage, setCurrentPage] = useState(0);

    //////////////////////////////////////////////////////////////////////////////////

    //get the data
    useEffect(() => {

        axiosClient.get(`/coupons/getAllCoupons/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&client=${user.id}&date=${date}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

    }, [pageSize, currentPage, searchTerm,date])

    function update() {
        axiosClient.get(`/coupons/getAllCoupons/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&client=${user.id}&date=${date}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });
    }

    const AjoutCoupon = () => {
        if (data.length > 0) {
            const check = data.find(obj => {
                return obj.code.toLowerCase() === title.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t('Ce coupon exist déja !'))
            }
        }
        const formData = new FormData()
        formData.append("code", title)
        formData.append("reduction", reduction)
        formData.append("start_date", startDate)
        formData.append("end_date", endDate)
        formData.append("limit_usage", limitUsage)
        formData.append("added_by", user.id)
        axiosClient.post('/coupons/', formData)
            .then((response) => {
                setData([response.data, ...data]);
                setTitle('')
                setReduction('')
                setStartDate()
                setEndDate()
                setLimitUsage()
                setMessage("")
                update()
                toast({
                    description: t(`Le coupon est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                })
            })
        onClose()
        update()
    }

    const UpdateCoupon = (id) => {
        if (data.length > 0) {
            const check = data.find(obj => {
                return obj.code.toLowerCase() === title.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t('Ce coupon existe déja !'))
            }
        }
        const formData = new FormData();
        if (title) formData.append("code", title)
        if (reduction) formData.append("reduction", reduction)
        if (startDate) formData.append("start_date", startDate)
        if (endDate) formData.append("end_date", endDate)
        if (limitUsage) formData.append("limit_usage", limitUsage)

        axiosClient.patch(`/coupons/${id}/`, formData).then(() => {

            update()

            toast({
                description: t(`Le coupon est modifié avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
            })
            setTitle('')
            setReduction('')
            setStartDate()
            setEndDate()
            setLimitUsage()
            setMessage("")
        })

        onClose()
    }
    const reductionColor = useColorModeValue('purple.500', 'purple.400')
    function reset() {
        setTitle('')
        setReduction('')
        setStartDate()
        setEndDate()
        setLimitUsage()
        setMessage("")
    }

    const handleDelete = (id) => {
        if (!id) return;
        const formData = new FormData();
        formData.append("is_deleted", true)
        axiosClient.patch(`/coupons/${id}/`, formData)
            .then((response) => {
                update()
                // setData((prevData) => prevData.filter((row) => row.id !== id));
                // setNotesData(notesData.filter((row) => row.id !== id));
                toast({
                    description: t("le coupon est supprimé avec succès"),
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }
    return (
        <Box position={"relative"}>
            <Flex zIndex={999} position={"fixed"} bottom={10} right={5}>
                <Button

                    onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                    colorScheme='purple'
                >
                    <PiPlusBold size={18} />

                </Button>
            </Flex>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Coupons</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>

            </Flex>

            <Flex
                px={2}
                py={5}
                mx={{ base: 1, md: 5 }}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={{ base: 5, md: 2 }}
                mb={5}
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        justify={'center'}
                        gap={5}
                        direction={{ base: 'row', lg: 'row' }}
                    >
                        <Image mb={4} ml={{ base: 0, md: 4 }} src={coupon} w={{ base: "40px", md: "70px" }} h={{ base: "40px", md: "70px" }}></Image>
                        <Flex direction={{ base: 'column', md: 'row' }} w={{ base: '100%', lg: "50%" }}>

                            <Input onChange={e=> setDate(e.target.value)} mr={{ base: 0, md: 5 }} mb={4} borderRadius={"5"}  w={{ base: '100%', md: '30%' }} placeholder='Select Date' size='md' type='date' />

                            <InputGroup w={{ base: '100%', lg: "70%" }}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color='gray.300' />}
                                />
                                <Input
                                    borderRadius={"5"}
                                    placeholder={t("Recherche...")}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    mb={4}
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                />  </InputGroup>
                        </Flex>


                    </Flex>
                    <TableContainer>
                        <Table variant='simple' >
                            <Thead>
                                <Tr>
                                    <Th fontFamily='Plus Jakarta Display'>Code</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t("Réduction")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t("Date debut")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t("Date fin")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t("limite usage")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t("Date Création")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}
                                {((!searchTerm && !date )&& paginationData?.length === 0)  ? (
                                    <Tr>
                                        <Td colSpan={11}>{t("Il n'y a pas de coupons à afficher")}</Td>
                                    </Tr>) :
                                    <>
                                        {(paginationData?.length !== 0) &&
                                            data.map((row) => (
                                                <Tr p={0} >
                                                    <Td >{row.code}</Td>
                                                    <Td fontWeight={"bold"} fontSize={"xl"} color={reductionColor}>{row.reduction}</Td>
                                                    <Td>{new Date(row.start_date).toLocaleDateString(t('fr-FR'))}</Td>
                                                    <Td>{new Date(row.end_date).toLocaleDateString(t('fr-FR'))}</Td>
                                                    <Td>{row.limit_usage}</Td>
                                                    <Td>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'))}</Td>

                                                    <Td>
                                                        <Menu >
                                                            <MenuButton
                                                                colorScheme='cyan'
                                                                as={IconButton}
                                                                aria-label='Options'
                                                                icon={<HamburgerIcon />}
                                                                variant='outline'
                                                            />
                                                            <MenuList>
                                                                <MenuItem onClick={() => {
                                                                    onOpen()
                                                                    setChangeModal(false)
                                                                    setCouponData(row)
                                                                    setCouponId(row.id)
                                                                }} icon={<EditIcon />} >
                                                                    {t("Modifier")}
                                                                </MenuItem>
                                                                <MenuItem onClick={() => {
                                                                    setId(row.id);
                                                                    onOpenAlert();
                                                                }} icon={<DeleteIcon />} >
                                                                    {t("Supprimer")}
                                                                </MenuItem>
                                                                <MenuItem icon={<MdHistory />} >
                                                                    {t("Historique")}
                                                                </MenuItem>

                                                            </MenuList>
                                                        </Menu>

                                                        {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") && <IconButton
                                                                    ml={5}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        setId(row.id);
                                                                        onOpenAlert();
                                                                    }}
                                                                    colorScheme='red'
                                                                    icon={<DeleteIcon />}>
                                                                    {t("Supprimer")}
                                                                </IconButton>
                                                                } */}

                                                    </Td>
                                                </Tr>
                                            ))}
                                    </>}



                                {((searchTerm && paginationData?.length === 0) || (date && paginationData?.length === 0) || (checkLengthData)) && (

                                    <Tr>
                                        <Td colSpan={11}>{t("Aucun coupon correspondant n'a été trouvée.")}</Td>
                                    </Tr>

                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select>
                    </Flex>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                </Stack>
            </Flex>


            {changeModal ?
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    reset()

                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'un coupon")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Code")}</FormLabel>
                                        <Input value={title} onChange={(e) => setTitle(e.target.value)} maxLength={50} name="Titre" placeholder={t('Titre de coupon...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Réduction")}</FormLabel>
                                        <Input value={reduction} onChange={(e) => setReduction(e.target.value)} maxLength={50} name="Réduction" type='number' placeholder={t('Réduction de coupon...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Date début")}</FormLabel>
                                        <Input value={startDate} onChange={(e) => setStartDate(e.target.value)} maxLength={50} name="startdate" type='date' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Date fin")}</FormLabel>
                                        <Input value={endDate} onChange={(e) => setEndDate(e.target.value)} maxLength={50} name="enddate" type='date' />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>{t("Limite d'usage")}</FormLabel>
                                        <Input value={limitUsage} onChange={(e) => setLimitUsage(e.target.value)} maxLength={50} name="limitUsage" placeholder={t("Limite d'usage de coupon...")} />
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>

                            {(!title || !reduction || !startDate || !endDate || !limitUsage)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutCoupon}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }

                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    reset()

                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Modification de coupon")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Code")}</FormLabel>
                                        <Input defaultValue={couponData.code} onChange={(e) => setTitle(e.target.value)} maxLength={50} name="Titre" placeholder={t('Titre de coupon...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Réduction")}</FormLabel>
                                        <Input defaultValue={couponData.reduction} onChange={(e) => setReduction(e.target.value)} maxLength={50} name="Réduction" type='number' placeholder={t('Réduction de coupon...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Date début")}</FormLabel>
                                        <Input defaultValue={couponData.start_date} onChange={(e) => setStartDate(e.target.value)} maxLength={50} name="startdate" type='date' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Date fin")}</FormLabel>
                                        <Input defaultValue={couponData.end_date} onChange={(e) => setEndDate(e.target.value)} maxLength={50} name="enddate" type='date' />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>{t("Limite d'usage")}</FormLabel>
                                        <Input defaultValue={couponData.limit_usage} onChange={(e) => setLimitUsage(e.target.value)} maxLength={50} name="limitUsage" placeholder={t("Limite d'usage de coupon...")} />
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateCoupon(couponId)}
                            >
                                {t("Modifier")}
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>

    )
}

export default GestCoupons