import logo from "../assets/images/logo.jpg";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { useEffect, useState } from "react";
import axiosClient, { linkImage } from "../axios-client";
import { FaChevronCircleLeft, FaStickyNote, FaUser, FaUserFriends, FaUserTie } from "react-icons/fa";
import { BsFillInfoCircleFill, BsHousesFill, BsPersonVcardFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { FaMapLocationDot, FaSackDollar, FaShop } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";
import LanguageChanger from "../components/language/LanguageChanger";
import { useTranslation } from "react-i18next";
import { PiDotOutline } from "react-icons/pi";
import { ImStatsBars } from "react-icons/im";

import { BiCategory, BiCategoryAlt, BiLogOut, BiMessageSquareDetail, BiSolidUser, BiSupport } from "react-icons/bi";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Image,
  HStack,
  Center,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Badge,
  Divider,
  AccordionItem,
  Accordion,
  AccordionPanel,
  List,
  ListItem,
  AccordionButton,
  Link,
  Card,
  CardBody,
  Heading,
} from "@chakra-ui/react";
import {
  FiHome,
  FiMenu,
  FiMoon,
  FiSun,
  FiChevronDown,

} from "react-icons/fi";
import { MdEventNote, MdNotificationsActive, MdOutlineNotificationsActive } from "react-icons/md";
import { ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import { GoAlertFill, GoDot } from "react-icons/go";
import { IoCartOutline } from "react-icons/io5";
import { RiCoupon3Fill, RiMenuFoldLine, RiMenuUnfoldLine, RiShoppingCartFill } from "react-icons/ri";
import { IoMdPricetags } from "react-icons/io";

export default function DefaultLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSiderBar, setIsSideBar] = useState("block");
  const [changedWidth, setChangedWidth] = useState("280px");
  const { user } = useStateContext();

  const handleCloseSideBar = () => {
    if (isSiderBar === "block") {
      setIsSideBar("none");
      setChangedWidth("full");
    } else {
      setIsSideBar("block");
      setChangedWidth("280px");
    }
  };
  const location = useLocation();

  // Define a list of paths where padding should be removed.
  const pathsWithNoPadding = ['/ajouter-evenement', '/modifier-evenement', '/partenaires-signales'];
  // const pathsWithNoPadding2 = ['/modifier-evenement'];
  // const pathsWithNoPadding3 = ['/partenaires-signalés'];


  const paddingValue = pathsWithNoPadding.includes(location.pathname) ? '0' : '4';
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: isSiderBar }}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}


      <MobileNav
        onOpen={onOpen}
        handleCloseSideBar={handleCloseSideBar}
        isSiderBar={isSiderBar}
      />

      <Box ml={{ base: 0, md: changedWidth }} p={paddingValue}>

        <Outlet />
      </Box>


    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { user, setToken, setUser } = useStateContext();
  const userRole = user.role;
  console.log(user.role);
  const { t } = useTranslation();

  // current user data


  const LinkItems = {
    ///////////////////////////////////// ADMIN LINKS /////////////////////////////////////
    Admin: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Utilisateurs"), icon: FaUserTie, path: "/mes-utilisateurs" },
      { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Notifications", icon: MdNotificationsActive, path: "/notifications" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets-admin" },
      // { name: t("Gestion Admins"), icon: FaUserTie, path: "/admins" },
      // { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      // { name: t("Gestion Partenaires"), icon: FaUserFriends, path: "/partenaires" },
      // { name: t("Réseau de Partenaires"), icon: FaMapLocationDot, path: "/localisations" },
      // { name: t("Partenaires Signalés"), icon: GoAlertFill, path: "/partenaires-signales" },

      // { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      // { name: t("Gestion Catégories"), icon: BiCategory, path: "/categories" },
      // { name: "Apa agency services", icon: BsHousesFill, path: "/apa-agency-services" },
      // { name: "Apa promotions", icon: SettingsIcon, path: "/apa-promotions" },
      // { name: t("Types d'abonnements"), icon: HiCursorClick, path: "/types_abonnements" },
      // { name: t("Espace Événements"), icon: MdEventNote, path: "/evenements" },
      // { name: "Notifications", icon: MdNotificationsActive, path: "/notifications" },
      // { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      // { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/aPropos" },
      // { name: t("Paramètres"), icon: SettingsIcon, path: "/contacts" },

    ],
    Manager: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Notifications", icon: MdNotificationsActive, path: "/notifications" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets-admin" },


    ],
    RespoSuivi: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
    ],
    StockManager: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
    ],

    ///////////////////////////////////// CLIENT LINKS /////////////////////////////////////

    AdminClient: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Statistiques"), icon: ImStatsBars, path: "/statistics" },
      { name: t("Gestion Utilisateurs"), icon: FaUserTie, path: "/utilisateurs" },
      { name: t("Gestion Stock"), icon: RiShoppingCartFill, path: "/stock" },
      { name: t("Gestion Commandes"), icon: IoMdPricetags, path: "/orders" },

      { name: "Notes", icon: FaStickyNote, path: "/notes" },
      { name: "Coupons", icon: RiCoupon3Fill, path: "/coupons" },
      { name: t("Charges"), icon: FaSackDollar, path: "/charges" },
      // { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      // { name: t("Marketplace"), icon: FaShop, path: "/l" },
      { name: t("Support"), icon: BiSupport, path: "/ticket" },
      // { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/f" },



    ],
    ManagerClient: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      // { name: t("Gestion Utilisateurs"), icon: FaUserTie, path: "/utilisateurs" },
      { name: t("Gestion Stock"), icon: RiShoppingCartFill, path: "/stock" },
      { name: t("Gestion Commandes"), icon: IoMdPricetags, path: "/orders" },


      { name: "Notes", icon: FaStickyNote, path: "/notes" },
      { name: "Coupons", icon: RiCoupon3Fill, path: "/coupons" },
      { name: t("Charges"), icon: FaSackDollar, path: "/charges" },
      // { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      // { name: t("Marketplace"), icon: FaShop, path: "/l" },
      { name: t("Support"), icon: BiSupport, path: "/ticket" },
      // { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/f" },
    ],
    RespoSuiviClient: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Commandes"), icon: IoMdPricetags, path: "/orders" },
      { name: t("Support"), icon: BiSupport, path: "/ticket" },

    ],
    StockManagerClient: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Commandes"), icon: IoMdPricetags, path: "/orders" },
      { name: t("Gestion Stock"), icon: RiShoppingCartFill, path: "/stock" },

      { name: t("Support"), icon: BiSupport, path: "/ticket" },

    ],
  };
  const items = LinkItems[userRole] || [];

  const onLogout = (ev) => {
    ev.preventDefault();
    setUser({});
    setToken(null);
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("refreshTimeAdmin");
    localStorage.removeItem("role");

  };
  return (
    <Box

      pl={{ base: 0, md: 3 }}
      py={{ base: 0, md: 3 }}

      transition="3s ease"
      color="white"
      // bgGradient={useColorModeValue("linear(to-b, purple.300,purple.300, purple.300)", "linear(to-t, black, black)")}
      w={{ base: "full", md: "280px" }}
      pos="fixed"

      h="full"
      // pb="20px"


      {...rest}

    >
      <Box borderRadius={{ base: 0, md: "xl" }} w={"100%"} h={"100%"} position="relative"
        bgGradient={useColorModeValue("linear(to-b,#110a29, #6c006d, purple.400)", "linear(to-b, purple.800,#110a29, #6c006d)")}>
        <Flex flexDirection={"column"} pt={2} h="20%" alignItems="center" mx="8" justifyContent="center">

          <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} position={"absolute"} top={2} right={2} />

          <Image rounded={'full'} src={logo} width="100px" h={70} w={70} ></Image>

          <Text mt={3} fontSize={"xl"}>Ecom System</Text>
        </Flex>
        <Flex py={2} alignItems={'center'} flexDirection={"column"}>
          <Divider />
        </Flex>
        <Box overflowY={"scroll"} py={0} my={0} h={'68%'}
          css={{
            /* Hide scrollbar */
            "&::-webkit-scrollbar": {
              display: "none",
            },
            /* Optional: Firefox scrollbar */
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {items.map((link, key) => (
            <NavItem
              items={items}
              key={link.name}
              icon={link.icon}
              onClose={onClose}
            >
              {link.name}
            </NavItem>
          ))}
        </Box>

        <Flex py={2} alignItems={'center'} flexDirection={"column"}>
          <Divider borderColor='purple.300' />

        </Flex>
        <Flex
          justify={"center"}
          align="center"
          mt={1}
          color={"purple.100"}
          cursor="pointer"
          gap={2}
          onClick={onLogout}
        >


          <BiLogOut size={20} />
          <Text fontSize="md">{t("Déconnexion")}</Text>


        </Flex>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, items, onClose, ...rest }) => {
  const { user } = useStateContext()
  const navigate = useNavigate();
  const { setUser } = useStateContext();
  const { t } = useTranslation()
  const location = useLocation();
  const pathLink = items.find((e) => e.name === children);
  const { countTickets, updateTicketCount, countTicketsManager } = useStateContext()
  // current user data
  // useEffect(() => {
  //   axiosClient.get("auth/user/").then(({ data }) => {
  //     setUser(data);
  //   });
  //   axiosClient.get('/tickets/getTotalTicketsCount/') // Assuming this endpoint retrieves user data
  //     .then((res) => {
  //       setCount(res.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [count,setCount]);
  useEffect(() => {
    updateTicketCount()
  }, [])

  // useEffect(() => {
  //   const socket = new WebSocket('ws://localhost:8000/ws/tickets/');

  //   socket.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     setCount(data.count);
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const handleClick = () => {
    if (pathLink.path === "/orders" || pathLink.path === "/stock") {
      return
    }
    else {
      navigate(pathLink.path);
      onClose();
    }

  };


  return (

    <Box
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align={(children === t("Gestion Commandes") || children === t("Gestion Stock")) ? "start" : "center"}
        mx="4"
        p="4"
        my={2}
        // mx="4"
        rounded={"xl"}
        boxShadow={pathLink.path === location.pathname ? "6px 8px 10px 0px #ffffff2b" : ""}
        fontWeight={pathLink.path === location.pathname ? "semibold" : ""}


        // bg={pathLink.path === location.pathname ? "gray.100" : ""}
        role="group"
        // fontWeight={pathLink.path === location.pathname ? "semibold" : ""}
        // color={pathLink.path === location.pathname ? "blue.600" : ""}
        cursor="pointer"
        // _hover={{

        //   bg: "#D6BCFA",
        //   color: "black",
        // }}
        {...rest}
      >
        {icon && (
          <Icon
            bg={pathLink.path === location.pathname ? "rgb(255 255 255 / 27%)" : ""}
            p={pathLink.path === location.pathname ? 1.5 : 0}
            rounded={pathLink.path === location.pathname ? "md" : ""}
            // color={pathLink.path === location.pathname ? "gray" : ""}
            mr="4"
            fontSize={pathLink.path === location.pathname ? "30" : "18"}
            // _groupHover={{
            //   color: "white",
            // }}
            as={icon}
          />
        )}
        {children === "Tickets" ? (
          <>
            <Box flex={"1"}>
              <Text fontSize={pathLink.path === location.pathname ? "md" : "sm"}>{children}</Text>
            </Box>
            {(countTickets > 0 && user.role === "AdminClient") && (
              <Badge
                rounded="full"
                bg="#df2e38"
                style={{ padding: "2px 7px 2px 6px" }}
                color="white"
                ml="2"
              >
                {countTickets}
              </Badge>
            )}
            {(countTicketsManager > 0 && user.role === "ManagerClient") && (
              <Badge
                rounded="full"
                bg="#df2e38"
                style={{ padding: "2px 7px 2px 6px" }}
                color="white"
                ml="2"
              >
                {countTicketsManager}
              </Badge>
            )}
          </>
        ) :
          children === (t("Gestion Commandes")) ? (
            <>
              <Accordion allowMultiple width="100%" borderColor={'transparent'}>
                <AccordionItem bg={'transparent'}>
                  <AccordionButton m={0} p={0} alignItems={'start'} display={'flex'} justifyContent={'space-between'} _hover={'none'}
                  >
                    <Text fontSize="sm">{children}</Text>
                    <ChevronDownIcon p={0} m={0} fontSize="22px" />
                  </AccordionButton>


                  <AccordionPanel rounded={"xl"} p={0} m={0} w={"100%"}>
                    <Flex align={'center'}>
                      {/* <Center height='50px'>
                        <Divider h={"50px"} orientation='vertical' bg={'black'} />
                      </Center> */}
                      <List mt={3} spacing={3} w={"100%"}>

                        {["AdminClient", "ManagerClient", "RespoSuiviClient"].includes(user.role) && <ListItem w={"100%"}>
                          <Flex
                            align="center"
                            gap={1}
                            mx="1"
                            // my={2}
                            // mx="4"
                            rounded={"xl"}
                            fontSize={location.pathname === "/list_new_orders" ? "15" : "14"}
                            // boxShadow={location.pathname === "/list_new_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                            fontWeight={location.pathname === "/list_new_orders" ? "semibold" : ""}
                            onClick={() => {
                              navigate("/list_new_orders")
                              onClose()
                            }}
                          >
                            <PiDotOutline color={location.pathname === "/list_new_orders" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                            {t("Nouvelles commandes")}
                          </Flex>
                        </ListItem>}

                        {["StockManagerClient"].includes(user.role) && <ListItem w={"100%"}>
                          <Flex
                            align="center"
                            gap={1}
                            mx="1"
                            // my={2}
                            // mx="4"
                            rounded={"xl"}
                            fontSize={location.pathname === "/New-Confirmed-Orders" ? "15" : "14"}
                            // boxShadow={location.pathname === "/New-Confirmed-Orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                            fontWeight={location.pathname === "/New-Confirmed-Orders" ? "semibold" : ""}
                            onClick={() => {
                              navigate("/New-Confirmed-Orders")
                              onClose()
                            }}
                          >
                            <PiDotOutline color={location.pathname === "/New-Confirmed-Orders" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                            {t("Nouvelles commandes confirmées")}
                          </Flex>
                        </ListItem>}

                        {["AdminClient", "ManagerClient"].includes(user.role) && <ListItem w={"100%"}>
                          <Flex
                            align="center"
                            gap={1}
                            mx="1"
                            // my={2}
                            // mx="4"
                            rounded={"xl"}
                            fontSize={location.pathname === "/my_orders" ? "15" : "14"}
                            // boxShadow={location.pathname === "/my_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                            fontWeight={location.pathname === "/my_orders" ? "semibold" : ""}
                            onClick={() => {
                              navigate("/my_orders")
                              onClose()
                            }}
                          >
                            <PiDotOutline color={location.pathname === "/my_orders" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                            {t("Mes commandes")}
                          </Flex>
                        </ListItem>}

                        {["RespoSuiviClient"].includes(user.role) && <ListItem w={"100%"}>
                          <Flex
                            align="center"
                            gap={1}
                            mx="1"
                            // my={2}
                            // mx="4"
                            rounded={"xl"}
                            fontSize={location.pathname === "/Gestion-confirmation" ? "15" : "14"}
                            // boxShadow={location.pathname === "/my_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                            fontWeight={location.pathname === "/Gestion-confirmation" ? "semibold" : ""}
                            onClick={() => {
                              navigate("/Gestion-confirmation")
                              onClose()
                            }}
                          >
                            <PiDotOutline color={location.pathname === "/Gestion-confirmation" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                            Confirmation
                          </Flex>
                        </ListItem>}

                        {["StockManagerClient"].includes(user.role) &&
                          <ListItem w={"100%"}>
                            <Flex
                              align="center"
                              gap={1}
                              mx="1"
                              // my={2}
                              // mx="4"
                              rounded={"xl"}
                              fontSize={location.pathname === "/Gestion-stock" ? "15" : "14"}
                              // boxShadow={location.pathname === "/my_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                              fontWeight={location.pathname === "/Gestion-stock" ? "semibold" : ""}
                              onClick={() => {
                                navigate("/Gestion-stock")
                                onClose()
                              }}
                            >
                              <PiDotOutline color={location.pathname === "/Gestion-stock" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                              Stock
                            </Flex>
                          </ListItem>}

                        {["RespoSuiviClient"].includes(user.role) &&
                          <>
                            <ListItem w={"100%"}>
                              <Flex
                                align="center"
                                gap={1}
                                mx="1"
                                // my={2}
                                // mx="4"
                                rounded={"xl"}
                                fontSize={location.pathname === "/Gestion-suivi" ? "15" : "14"}
                                // boxShadow={location.pathname === "/my_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                                fontWeight={location.pathname === "/Gestion-suivi" ? "semibold" : ""}
                                onClick={() => {
                                  navigate("/Gestion-suivi")
                                  onClose()
                                }}
                              >
                                <PiDotOutline color={location.pathname === "/Gestion-suivi" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                                {t("Suivi de commande")}
                              </Flex>
                            </ListItem>
                            <ListItem w={"100%"}>
                              <Flex
                                align="center"
                                gap={1}
                                mx="1"
                                // my={2}
                                // mx="4"
                                rounded={"xl"}
                                fontSize={location.pathname === "/Facture" ? "15" : "14"}
                                // boxShadow={location.pathname === "/my_orders" ? "6px 8px 10px 0px #ffffff2b" : ""}
                                fontWeight={location.pathname === "/Facture" ? "semibold" : ""}
                                onClick={() => {
                                  navigate("/Facture")
                                  onClose()
                                }}
                              >
                                <PiDotOutline color={location.pathname === "/Facture" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                                {t("Facture")}
                              </Flex>
                            </ListItem>
                          </>
                        }


                      </List>
                    </Flex>
                  </AccordionPanel>


                </AccordionItem>
              </Accordion>


            </>
          )
            :
            children === t("Gestion Stock") ? (
              <>
                <Accordion allowMultiple width="100%" borderColor={'transparent'}>
                  <AccordionItem bg={'transparent'}>
                    <AccordionButton m={0} p={0} alignItems={'start'} display={'flex'} justifyContent={'space-between'} _hover={'none'}
                    >
                      <Text fontSize="sm">{children}</Text>
                      <ChevronDownIcon p={0} m={0} fontSize="22px" />
                    </AccordionButton>


                    <AccordionPanel rounded={"xl"} p={0} m={0} w={"100%"}>
                      <Flex align={'center'}>
                        {/* <Center height='50px'>
                        <Divider h={"50px"} orientation='vertical' bg={'black'} />
                      </Center> */}
                        <List mt={3} spacing={3} w={"100%"}>

                          <ListItem w={"100%"}>
                            <Flex
                              align="center"
                              gap={1}
                              mx="1"
                              // my={2}
                              // mx="4"
                              rounded={"xl"}
                              fontSize={location.pathname === "/My_Stock" ? "15" : "14"}
                              // boxShadow={location.pathname === "/My_Stock" ? "6px 8px 10px 0px #ffffff2b" : ""}
                              fontWeight={location.pathname === "/My_Stock" ? "semibold" : ""}
                              onClick={() => {
                                navigate("/My_Stock")
                                onClose()
                              }}
                            >
                              <PiDotOutline color={location.pathname === "/My_Stock" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                              {t("Produits")}
                            </Flex>
                          </ListItem>

                          <ListItem w={"100%"}>
                            <Flex
                              align="center"
                              gap={1}
                              mx="1"
                              // my={2}
                              // mx="4"
                              rounded={"xl"}
                              fontSize={location.pathname === "/categories" ? "15" : "14"}
                              // boxShadow={location.pathname === "/categories" ? "6px 8px 10px 0px #ffffff2b" : ""}
                              fontWeight={location.pathname === "/categories" ? "semibold" : ""}
                              onClick={() => {
                                navigate("/categories")
                                onClose()
                              }}
                            >
                              <PiDotOutline color={location.pathname === "/categories" ? "white" : "rgb(255 255 255 / 39%)"} size={24} />
                              {t("Catégories")}
                            </Flex>
                          </ListItem>



                        </List>
                      </Flex>
                    </AccordionPanel>


                  </AccordionItem>
                </Accordion>


              </>
            )
              : (
                <Text fontSize={pathLink.path === location.pathname ? "md" : "sm"}>{children}</Text>
              )}

      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, handleCloseSideBar, isSiderBar, ...rest }) => {
  const { user, setToken, setUser, count, setCount, countNotes, setCountNotes } = useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation()
  const [notifs, setNotifs] = useState([]);
  // const [count, setCount] = useState('')
  const [notes, setNotes] = useState([]);
  // const [countNotes, setCountNotes] = useState('')

  // Logout Function
  const navigate = useNavigate();

  const onLogout = (ev) => {
    ev.preventDefault();
    setUser({});
    setToken(null);
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("refreshTimeAdmin");
    localStorage.removeItem("role");

  };
  const [changeBgNavBar, setChangeBgNavBar] = useState('white')

  const getUserNotifs = async () => {
    try {
      await axiosClient
        .get(`notifications/getUserNotifs/?page_size=7&page=1&userRole=${user.role}&userId=${user.id}`)
        .then((res) => {
          setNotifs(res.data.results.notifs);

          setCount(res.data.results.count);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log('ERROR : ', error);

    }
  }

  const getUserNotes = async () => {
    try {
      await axiosClient
        .get(`notes/getUserNotes/?page_size=7&page=1&userRole=${user.role}&userId=${user.id}`)
        .then((res) => {
          setNotes(res.data.results.notes);

          setCountNotes(res.data.results.count);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log('ERROR : ', error);
    }
  }

  useEffect(() => {
    
    getUserNotifs()

    getUserNotes()
    
  }, [user, count, countNotes]);

  function update() {
    if (user.id) {
      axiosClient
        .get(`notifications/getUserNotifs/?page_size=7&page=1&userRole=${user?.role}&userId=${user?.id}`)
        .then((res) => {
          setNotifs(res.data.results.notifs);

          setCount(res.data.results.count);
        })
        .catch((error) => {
          console.error(error);
        });

      axiosClient
        .get(`notes/getUserNotes/?page_size=7&page=1&userRole=${user?.role}&userId=${user?.id}`)
        .then((res) => {
          setNotes(res.data.results.notes);

          setCountNotes(res.data.results.count);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 50) {
        setChangeBgNavBar("#ffffff42");
      } else {
        setChangeBgNavBar("white");
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  const IsReadNotif = (id) => {
    axiosClient.get(`/notifications/${id}/`)
      .then((response) => {
        const notification = response.data;

        if (!notification.is_read.includes(user.id)) {
          notification.is_read.push(user.id);

          axiosClient.put(`/notifications/${id}/`, {
            ...notification,
            is_read: notification.is_read,
          })
            .then((response) => {
              update()
              window.location.reload()
            })
            .catch((error) => {
              console.error(error)
            });
        }
      })
      .catch((error) => {
        console.error(error)
      });

  };

  const IsReadNote = (id) => {
    axiosClient.get(`/notes/${id}/`)
      .then((response) => {
        const note = response.data;

        if (!note.is_read.includes(user.id)) {
          note.is_read.push(user.id);

          axiosClient.put(`/notes/${id}/`, {
            ...note,
            is_read: note.is_read,
          })
            .then((response) => {
              update()
              window.location.reload()
            })
            .catch((error) => {
              console.error(error)
            });
        }
      })
      .catch((error) => {
        console.error(error)
      });

  };

  const colorCondition = useColorModeValue("black", changeBgNavBar === "white" ? "black" : "white")
  const notifBg = useColorModeValue("gray.100", "gray.600")
  const notifBgRead = useColorModeValue("white", "gray.700")
  const notifHover = useColorModeValue("gray.50", "gray.800")
  const txtColor = useColorModeValue('black', 'gray.300')
  const boxShadowColor = useColorModeValue('rgba(0, 0, 0, 0.08)', 'rgba(255, 255, 255, 0.1)'); // Example of dynamic color based on color mode
  const menuItembg = useColorModeValue("gray.100", "gray.700")

  const flexContent = (
    <>



      {/* {isSiderBar === "block" ? (
        <>
          <Text fontFamily='Plus Jakarta Display' as="cite" ml={10} fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} > {t("Accueil")}</span>  / <span >{t("Tableau de bord")}</span></Text>

        </>
      ) : (
        <>
          <Text
            display={{ base: 'none', md: "block" }}
            color="black"
            ml={10}
            fontWeight={"bold"}
            fontSize={{ base: "md", sm: "md", md: "xl", lg: "xl", xl: '2xl' }}
          >
            Ecom System
          </Text>
        </>
      )} */}
      <HStack justify={"end"} shadow={"lg"} rounded={"3xl"} px={5}
        background={changeBgNavBar}
        backdropFilter={"auto"}
        backdropBlur={'8px'}
        position={'fixed'}
        zIndex={999}
        spacing={{ base: "0", md: "5" }}>
        {/* {isSiderBar === "block" ?
          <RiMenuFoldLine
            display={{ base: "none", md: 'flex' }}
            color={"black"}
            size={"18px"}
            cursor='pointer'
            onClick={handleCloseSideBar} />
          :
          <RiMenuUnfoldLine
            display={{ base: "none", md: 'flex' }}
            color={"black"}
            size={"18px"}
            cursor='pointer'
            onClick={handleCloseSideBar} />
        }

        <RiMenuFoldLine
          display={{ base: "flex", md: "none" }}
          color={"black"}
          size={"18px"}
          cursor='pointer'
          onClick={onOpen} /> */}
        {isSiderBar === "block" ? (
          <IconButton
            display={{ base: "none", md: "flex" }}
            icon={<RiMenuFoldLine color={colorCondition} />}
            onClick={handleCloseSideBar}
            borderColor={'purple.200'}
            border={"none"}
            variant="outline"

          />
        ) : (
          <IconButton
            display={{ base: "none", md: "flex" }}
            onClick={handleCloseSideBar}
            icon={<RiMenuUnfoldLine color={colorCondition} />}
            borderColor={'purple.200'}
            border={"none"}
            variant="outline"

            aria-label="open menu"

          />
        )}

        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          icon={<RiMenuFoldLine color={colorCondition} />}
          borderColor={'purple.200'}
          border={"none"}
          variant="outline"

          aria-label="open menu"

        />

        <LanguageChanger color={colorCondition} />
        {(user?.role === "ManagerClient" || user?.role === "AdminClient") &&
          <Flex pr={{ base: 3, md: 0 }}>

            <ImStatsBars
              fontSize={"20px"}
              color={colorCondition}
              cursor={'pointer'}
              onClick={() => navigate("/statistics")}
            />
          </Flex>}
        {(user?.role === "ManagerClient" || user?.role === "RespoSuiviClient" || user?.role === "StockManagerClient") &&
          <Menu zIndex={"1"}>
            <MenuButton
              background={"transparent"}
              _hover='none'
              position="relative"

            >
              <Badge
                color={colorCondition}
                borderRadius={"25"}
                opacity={.9}
                background={countNotes ? "blue.50" : "transparent"}
                px={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  size="sm"
                  variant=""
                  // color={"#f2c478"}
                  icon={<MdOutlineNotificationsActive fontSize={"20px"} />}
                />
                <Text pr={countNotes ? 2 : 0} fontSize={13}>{countNotes ? countNotes < 100 ? countNotes : '+99' : ""}</Text>
              </Badge>


            </MenuButton>

            <MenuList

              w={{ base: "200px", md: "450px" }}
              maxH="450px"
              overflow="scroll"
              overflowX={'hidden'}
            >
              {notes.map((row) => (
                <MenuItem
                  // onClick={() =>
                  //   navigate(`/notification-details`, {
                  //     state: {
                  //       idNotif: row.id,
                  //     },
                  //   })
                  // }
                  _hover={{ backgroundColor: "transparent" }}
                  minH="48px"
                >
                  <Card backgroundColor={row.is_read.includes(user?.id) ? 'white' : 'gray.100'}
                    onClick={() => {
                      IsReadNote(row.id)
                      navigate(`/detail-note`, {
                        state: {
                          idNote: row.id
                        }
                      })

                    }
                    }
                    _hover={{ backgroundColor: "gray.50" }} w={"100%"}>
                    <CardBody>
                      <Heading size="sm">{row.title}</Heading>
                      <Flex w={"100%"} justify={"end"}>
                        <Text fontSize={"sm"} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'))}</Text>
                      </Flex>
                    </CardBody>
                  </Card>
                </MenuItem>
              ))}
              {notes.length === 0 && (
                <Text px={2} p={5}>
                  {t("Il n'y a aucune note")}
                </Text>
              )}

              <Flex color={txtColor} w="100%" justify="center">
                <Link text-center href="/my-notes">
                  {t("Afficher tout")} ...
                </Link>
              </Flex>
            </MenuList>
          </Menu>
        }
        {(user?.role === "Manager" || user?.role === "RespoSuivi" || user?.role === "StockManager" || user?.role === "AdminClient") &&

          <Menu zIndex={"1"}>
            <MenuButton
              background={"transparent"}
              _hover='none'
              position="relative"

            >
              <Badge
                color={colorCondition}
                borderRadius={"25"}
                opacity={.9}
                background={count ? "blue.50" : "transparent"}
                px={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  size="sm"
                  variant=""
                  // color={"#f2c478"}
                  icon={<MdOutlineNotificationsActive fontSize={"20px"} />}
                />
                <Text pr={count ? 2 : 0} fontSize={13}>{count ? count < 100 ? count : '+99' : ""}</Text>
              </Badge>


            </MenuButton>

            <MenuList

              w={{ base: "200px", md: "450px" }}
              maxH="450px"
              overflow="scroll"
              overflowX={'hidden'}
            >
              {notifs

                .map((row) => (
                  <MenuItem
                    // onClick={() =>
                    //   navigate(`/notification-details`, {
                    //     state: {
                    //       idNotif: row.id,
                    //     },
                    //   })
                    // }
                    _hover={{ backgroundColor: "transparent" }}
                    minH="48px"
                  >
                    <Card boxShadow={`0 4px 6px -1px ${boxShadowColor}, 0 2px 4px -1px ${boxShadowColor}`}
                      backgroundColor={row.is_read.includes(user.id) ? notifBgRead : notifBg}
                      onClick={() => {
                        IsReadNotif(row.id)
                        navigate(`/detail-notification`, {
                          state: {
                            idNotif: row.id
                          }
                        })

                      }
                      }
                      _hover={{ backgroundColor: notifHover }} w={"100%"}>
                      <CardBody>
                        <Heading size="sm">{row.title}</Heading>
                        <Flex w={"100%"} justify={"end"}>
                          <Text fontSize={"sm"} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'))}</Text>
                        </Flex>
                      </CardBody>
                    </Card>
                  </MenuItem>
                ))}
              {notifs.length === 0 && (
                <Text px={2} p={5}>
                  {t("Il n'y a aucune notification")}
                </Text>
              )}

              <Flex color={txtColor} w="100%" justify="center">
                <Link text-center href="/my-notifications">
                  {t("Afficher tout")} ...
                </Link>
              </Flex>
            </MenuList>
          </Menu>
        }

        <Flex pr={{ base: 3, md: 0 }}>
          {/* <MdOutlineNotificationsActive
            color={colorCondition}
            size={"18px"}
            cursor={'pointer'}
            onClick={toggleColorMode} /> */}


          {colorMode === "light" ? <FiMoon
            fontSize={"19px"}
            color="black"
            cursor={'pointer'}
            onClick={toggleColorMode} /> :
            <FiSun
              fontSize={"19px"}
              color="#D69E2E"
              cursor={'pointer'}
              onClick={toggleColorMode} />}
        </Flex>

        <Flex px={3} alignItems={"center"}>
          <Menu >
            <MenuButton
              py={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={linkImage + user.image} />
                <VStack
                  display={{ base: "none", lg: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color={colorCondition}>
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text fontSize="xs" color="purple.300">
                    {(user.role === "Admin" || user.role === "AdminClient")
                      ? t("Administrateur")
                      : (user.role === "Manager" || user.role === "ManagerClient")
                        ? t("Manager")
                        : (user.role === "RespoSuivi" || user.role === "RespoSuiviClient")
                          ? t("Centre d'appel")
                          : (user.role === "StockManager" || user.role === "StockManagerClient")
                            ? t("Gestionnaire de stock")

                            : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color={colorCondition}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              color={useColorModeValue("black", "white")}
              // minW='200px'
              zIndex={9999}
              borderColor={useColorModeValue("gray.100", "gray.700")}
              alignItems={"center"}
              bg={useColorModeValue("gray.100", "gray.700")}
              boxShadow={"xl"}
            >
              <Center mt={3} mb={3}>
                <Avatar size={"xl"} src={linkImage + user.image} />
              </Center>

              <MenuItem
                fontSize={'sm'}
                icon={<BiSolidUser size={15} />}
                bg={useColorModeValue("gray.100", "gray.700")}
                onClick={() => navigate("/profil")}
              >
                {" "}
                {t("Profil")}
              </MenuItem>
              <MenuItem
                fontSize={'sm'}
                icon={<FaUserEdit size={15} />}
                // _hover={{ bg: "gray.200" }}
                bg={useColorModeValue("gray.100", "gray.700")}
                onClick={() => navigate("/modifier-profil")}
              >
                {" "}
                {t("Mes paramètres")}
              </MenuItem>
              {user.role !== "Admin" &&
                <MenuItem
                  fontSize={'sm'}
                  icon={<BiMessageSquareDetail size={15} />}
                  // _hover={{ bg: "gray.200" }}
                  bg={menuItembg}
                  onClick={() => navigate("/my-tickets")}
                >
                  {" "}
                  {t("Mes tickets")}
                </MenuItem>}

              {/* <MenuItem
                fontSize={'sm'}
                icon={<ImStatsBars size={15} />}
                // _hover={{ bg: "gray.200" }}
                bg={useColorModeValue("gray.100", "gray.700")}
                onClick={() => navigate("/statistics")}
              >
                {" "}
                {t("Statistiques")}
              </MenuItem> */}
              <MenuDivider borderColor={'gray.300'} />
              <MenuItem
                icon={<BiLogOut size={20} />}
                // _hover={{ bg: "gray.200" }}
                bg={useColorModeValue("gray.100", "gray.700")}
                onClick={onLogout}
                color={'red.400'}
              >
                {t("Déconnexion")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  );
  const b = useColorModeValue("linear(to-r, black, gray.700)", "linear(to-l, black, black)");
  const border = useColorModeValue("black", "gray.700");
  return (
    <Box>
      {isSiderBar === "block" ? (
        <Flex
          pt={5}
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={{ base: 0, md: 60, lg: 60 }}
          bgGradient={"transparent"}
          // borderBottomWidth="1px"
          // borderBottomColor={border}
          justifyContent={"end"}
          position={'relative'}
          {...rest}
        >
          {flexContent}
        </Flex>
      ) : (
        <Flex
          pt={5}
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="black"
          marginLeft={0}
          bgGradient={"transparent"}
          // borderBottomWidth="1px"
          // borderBottomColor={border}
          justifyContent={"end"}
          {...rest}
        >
          {flexContent}
        </Flex>
      )}

    </Box>
  );
};






