import React, { useEffect, useState } from 'react'
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Stack, Tabs, Button, TabList, TabPanels, Tab, TabPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, IconButton, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, SkeletonCircle, Switch, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast, Breadcrumb, BreadcrumbItem, BreadcrumbLink, FormControl, FormLabel } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbListDetails } from 'react-icons/tb';
import { useStateContext } from '../../../../context/ContextProvider';
import axiosClient from '../../../../axios-client';
import Not_Assigned_Suivi from './Not_Assigned';
import Assigned_Suivi from './Assigned';
import MyPagination from '../../../../components/MyPagination';

function GestSuivi() {
    const { user } = useStateContext()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const toast = useToast()


    const [statusDelivery, setStatusDelivery] = useState('')

    const [dataOrder, setDataOrder] = useState([])
    const [loading, setLoading] = useState(false)
    const [paginationData, setPaginationData] = useState()

    const [searchTerm, setSearchTerm] = useState('');
    const [statusSelected, setStatusSelected] = useState('');

    const [dateDebutValue, setDateDebutValue] = useState("");
    const [dateFinValue, setDateFinValue] = useState("");


    // PAGINATION

    const [currentPage, setCurrentPage] = useState(0);

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    const fetchData = (pageSize, currentPage, searchTerm, statusSelected, dateDebutValue, dateFinValue) => {
        try {
            setLoading(true)
            axiosClient.get(`/commandes/getSuiviOrder/?clientId=${user.id}&page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&statusSelected=${statusSelected}&dateDebut=${dateDebutValue}&dateFin=${dateFinValue}`).then((res) => {
                // if (user.role === ('AdminClient')) {
                //     setDataOrder(res.data.filter(e => e.client === user.id && e.is_deleted === false && e.assigned_to !== null && e.assigned_to_stock === null && e.assigned_to_suivi === null))
                // }
                // else if (user.role === ('ManagerClient')) {
                //     setDataOrder(res.data.filter(e => e.client === user.works_for && e.is_deleted === false && e.assigned_to !== null && e.assigned_to_stock === null && e.assigned_to_suivi === null))
                // }
                // else {
                //     setDataOrder(res.data.filter(e => e.assigned_to === user.id && e.is_deleted === false))
                // }
                console.log('RESULTS DATA : ', res.data.results);
                if (res.data.length !== 0) {
                    setDataOrder(res.data.results)
                    setPaginationData(res.data)
                } else {
                    setDataOrder([])
                    setPaginationData([])
                }
            })
            setLoading(false)
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(pageSize, currentPage, searchTerm, statusSelected, dateDebutValue, dateFinValue)
    }, [user?.id, currentPage, searchTerm, pageSize, statusSelected, dateDebutValue, dateFinValue])

    // Update status

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [statusId, setStatusId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setStatusId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setStatusDelivery('')
        setStatusId()
    };

    const HandleChangeStatus = async () => {
        try {
            await axiosClient.patch(`commandes/${statusId}/`, {
                status_delivery: statusDelivery
            }).then((res) => {
                setDataOrder(dataOrder.map((item) => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            status_delivery: res.data.status_delivery
                        }
                    }
                    return item
                }))
                onCloseAlert()
                toast({
                    description: t(`Statut est changé avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }

    const borderColor = useColorModeValue("gray.300", "gray.600")
    const textSM = useColorModeValue('gray.400', 'gray.500')
    const bgStack = useColorModeValue('white', 'gray.700')

    //vider l'input de date fin
    useEffect(() => {
        if (!dateDebutValue) {
            // Si dateDebutValue est vide, on réinitialise dateFinValue
            setDateFinValue('');
        } else if (dateFinValue && dateDebutValue >= dateFinValue) {
            // Si dateDebutValue est supérieur ou égal à dateFinValue, on réinitialise dateFinValue
            setDateFinValue('');
        }
    }, [dateDebutValue, dateFinValue]);

    return (
        <Box mt="5px">

            <Flex>
                <Box w="90%" mt={2} mb={5}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Gestion suivi")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>

            {user.role === ("AdminClient" || "ManagerClient") && <Stack>

                <Tabs isLazy>
                    <TabList>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Non attribué")}</Text>
                        </Tab>
                        <Tab>
                            <Text fontSize={{ base: 12, lg: 15 }}>{t("Attribué")}</Text>
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Not_Assigned_Suivi />
                        </TabPanel>
                        <TabPanel>
                            <Assigned_Suivi />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            </Stack>}

            <Flex
                px={2}
                py={5}
                mx={5}
                bg={bgStack}
                rounded={"2xl"}
                mt={2}
                align={'center'}
                justify={'center'}

            >

                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', xl: 'row' }}
                        mb={2}
                    >
                        <Flex w={"100%"} direction={{ base: 'column', lg: 'row' }}>

                            <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', lg: '50%' }} mr={5} mb={4} value={statusSelected} onChange={e => setStatusSelected(e.target.value)}>
                                <option value="">{t("Statut de suivi")}</option>
                                <option value='Nouveau'>{t('Nouveau')}</option>
                                <option value='Ramassé'>{t('Ramassé')}</option>
                                <option value='Expédié'>{t('Expédié')}</option>
                                <option value='Reçu à destination'>{t('Reçu à destination')}</option>
                                <option value='Pendant la distribution'>{t('Pendant la distribution')}</option>
                                <option value='Programmé'>{t('Programmé')}</option>
                                <option value='Hors zone'>{t('Hors zone')}</option>
                                <option value='Non atteint'>{t('Non atteint')}</option>
                                <option value='Annulé'>{t('Annulé')}</option>
                                <option value='Refusé'>{t('Refusé')}</option>
                                <option value='Livré'>{t('Livré')}</option>
                                <option value='Préparation du retour'>{t('Préparation du retour')}</option>
                                <option value="Retour à l'agence">{t("Retour à l'agence")}</option>
                                <option value="Retour à l'entrepôt">{t("Retour à l'entrepôt")}</option>
                            </Select>
                            <Flex gap={2} w={{ base: '100%', lg: '50%' }}>
                                <FormControl position="relative" mb={4}>
                                    <FormLabel
                                        position="absolute"
                                        top={dateDebutValue ? "9px" : "45%"}
                                        left="10px"
                                        background={useColorModeValue('white', 'gray.700')}
                                        borderRadius='lg'
                                        px={1}
                                        pt={1}
                                        color="gray.500"
                                        fontSize={dateDebutValue ? 10 : 16}
                                        transform={dateDebutValue ? "translateY(-100%)" : "translateY(-50%)"}
                                        transition="all 0.2s ease-out"
                                        zIndex={2}
                                        w={!dateDebutValue && 95}
                                        onClick={() => document.getElementById('dateDebutInput').focus()} // Focuses the input when clicking the label
                                        cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                    >
                                        Date début
                                    </FormLabel>
                                    <Input
                                        id="dateDebutInput" // Assign an ID to the input for reference
                                        type="date"
                                        borderColor={borderColor}
                                        borderRadius="5"
                                        size="md"
                                        fontSize={{ base: 13, lg: 16 }}
                                        w="100%"
                                        value={dateDebutValue}
                                        onChange={(e) => setDateDebutValue(e.target.value)}
                                        position="relative"
                                        placeholder=" " // This keeps space for the floating label
                                        _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                    />
                                </FormControl>
                                <FormControl position="relative" mb={4}>
                                    <FormLabel
                                        position="absolute"
                                        top={dateFinValue ? "9px" : "45%"}
                                        left="10px"
                                        background={useColorModeValue('white', 'gray.700')}
                                        borderRadius='lg'
                                        px={1}
                                        pt={1}
                                        color="gray.500"
                                        fontSize={dateFinValue ? 10 : 16}
                                        transform={dateFinValue ? "translateY(-100%)" : "translateY(-50%)"}
                                        transition="all 0.2s ease-out"
                                        zIndex={2}
                                        w={!dateFinValue && 95}
                                        onClick={() => document.getElementById('dateFinInput').focus()} // Focuses the input when clicking the label
                                        cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                    >
                                        Date fin
                                    </FormLabel>
                                    <Input
                                        id="dateFinInput" // Assign an ID to the input for reference
                                        type="date"
                                        borderColor={borderColor}
                                        borderRadius="5"
                                        size="md"
                                        fontSize={{ base: 13, lg: 16 }}
                                        w="100%"
                                        value={dateFinValue}
                                        min={dateDebutValue || undefined}
                                        onChange={(e) => setDateFinValue(e.target.value)}
                                        position="relative"
                                        placeholder=" " // This keeps space for the floating label
                                        _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                        disabled={!dateDebutValue}
                                    />
                                </FormControl>
                            </Flex>


                            <InputGroup size="md" w={{ base: '100%', xl: "40%" }} ml={{ base: 0, xl: 3 }}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color={borderColor} />}
                                />
                                <Input
                                    borderColor={borderColor}
                                    borderRadius="5"
                                    placeholder={t("Recherche...")}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setCurrentPage(0)
                                        setSearchTerm(e.target.value)
                                    }}
                                    mb={4}
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                />
                            </InputGroup>
                        </Flex>

                        <InputGroup size="md" w={{ base: '100%', xl: "40%" }} ml={{ base: 0, xl: 3 }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color={borderColor} />}
                            />
                            <Input
                                borderColor={borderColor}
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />
                        </InputGroup>
                    </Flex>

                    <TableContainer>
                        <Table size={{ base: 'sm', md: 'md' }} variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>{t("#ID")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Client")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("téléphone")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Status")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Coupons")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Prix")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Options")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>

                                {loading &&
                                    <Tr>
                                        <Td><Skeleton h={10} w={10} /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td>
                                            <Flex flexDirection={"column"} gap={2}>
                                                <Skeleton height='20px' />
                                                <Skeleton height='10px' />
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex flexDirection={"column"} gap={2}>
                                                <Skeleton height='20px' />
                                                <Skeleton height='10px' />
                                                <Skeleton height='10px' />
                                            </Flex>
                                        </Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                    </Tr>
                                }
                                {
                                    dataOrder.length > 0 ?
                                        dataOrder.map((val, key) => {
                                            return (
                                                <Tr key={key}>
                                                    <Td>{val.code}</Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontWeight='semibold'>{val.full_name}</Text>
                                                            {val.email !== "undefined" && <Text fontSize='sm' color={textSM}>{val.email}</Text>}
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontSize={'md'}>{val.pays}, {val.ville}</Text>
                                                            <Text fontSize={'sm'} color={textSM}>{val.address}</Text>
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <a href={`https://wa.me/${val.tel}`} target='blank'>
                                                            {val.tel}
                                                        </a>
                                                    </Td>
                                                    <Td>
                                                        <Badge colorScheme={
                                                            val?.status_delivery !== 'Collecté'
                                                                ? (['Épuisé', 'Annulé'].includes(val?.status_delivery))
                                                                    ? 'red'
                                                                    : ([null, ''].includes(val?.status_delivery))
                                                                        ? 'blue'
                                                                        : 'orange'
                                                                : 'green'
                                                        } rounded="xl" px={2} cursor='pointer' textTransform='uppercase' onClick={() => { onOpenAlert(val.id) }}>
                                                            {val?.status_delivery ? val?.status_delivery : 'Nouveau'}
                                                        </Badge>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign='center'>
                                                            {val.coupon ? val.couponTitle : '-'}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon && 15} fontWeight='semibold' textDecoration={val?.coupon && 'line-through'}>{val?.price}</Text>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon ? 11 : 13} fontWeight='gray'>MAD</Text>
                                                        </Flex>
                                                        {
                                                            val?.coupon &&
                                                            <Flex>
                                                                <Text fontWeight='semibold'>{val?.priceWithCoupon}</Text>
                                                                <Text fontWeight='gray' fontSize={13}>MAD</Text>
                                                            </Flex>
                                                        }
                                                    </Td>
                                                    <Td>
                                                        <Button variant='outline' colorScheme='cyan' leftIcon={<TbListDetails />} onClick={() => navigate('/Detail-Gestion-confirmation', {
                                                            state: {
                                                                id: val.id,
                                                                tel: val.tel
                                                            }
                                                        })}>
                                                            Détails
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td colSpan={6}>
                                                <Text fontSize={18}>Donnée introuvable</Text>
                                            </Td>
                                        </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>

                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select>
                    </Flex >
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                </Stack >


                {/* Alert to Update confirmation status */}

                < AlertDialog
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseAlert}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t('Statut de livraison')}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                <Text>
                                    {t('Ici, vous pouvez modifier le statut de livraison.')}
                                </Text>

                                <Select
                                    mt={3}
                                    rounded='lg'
                                    size={"sm"}
                                    value={statusDelivery}
                                    onChange={e => setStatusDelivery(e.target.value)}
                                >
                                    <option value="">{t("Statut de livraison")}</option>
                                    <option value='Ramassé'>{t('Ramassé')}</option>
                                    <option value='Expédié'>{t('Expédié')}</option>
                                    <option value='Reçu à destination'>{t('Reçu à destination')}</option>
                                    <option value='Pendant la distribution'>{t('Pendant la distribution')}</option>
                                    <option value='Programmé'>{t('Programmé')}</option>
                                    <option value='Hors zone'>{t('Hors zone')}</option>
                                    <option value='Non atteint'>{t('Non atteint')}</option>
                                    <option value='Annulé'>{t('Annulé')}</option>
                                    <option value='Refusé'>{t('Refusé')}</option>
                                    <option value='Livré'>{t('Livré')}</option>
                                    <option value='Préparation du retour'>{t('Préparation du retour')}</option>
                                    <option value="Retour à l'agence">{t("Retour à l'agence")}</option>
                                    <option value="Retour à l'entrepôt">{t("Retour à l'entrepôt")}</option>
                                </Select>
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!statusDelivery} mr={3} onClick={HandleChangeStatus}>
                                    {t('Modifier')}
                                </Button>
                                <Button onClick={onCloseAlert} size="sm" >{t('Annuler')}</Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Flex>

        </Box>
    )
}

export default GestSuivi