import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { GrAdd } from 'react-icons/gr';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { createRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
// import animationData from '../../assets/lot/suivi.json';
import React from 'react';
import { FcLock } from 'react-icons/fc';
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../context/ContextProvider";
import { AddIcon, SearchIcon } from '@chakra-ui/icons'
import img3 from '../../../assets/images/3.jpg';
import empty from '../../../assets/images/empty.png';



import {
    Box,
    Flex,
    useColorModeValue,
    Stack,
    Heading,
    Image,
    Text,
    Button,
    Badge,
    Spacer,
    InputGroup,
    InputLeftElement,
    Input,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    IconButton,
    FormControl,
    Textarea,
    Toast,
    useToast,
    FormLabel,
    Select,
    Alert,
    AlertIcon,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink

} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../../components/MyPagination';
const PAGE_SIZE = 4;
// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//     }
// };
function MyTickets() {
    // current user
    const { user } = useStateContext();
    const {t} = useTranslation()
    //data
    const [plainteCategory, setPlainteCategory] = useState('')
    const [plainteSujet, setPlainteSujet] = useState('')
    const [plainteContent, setPlainteContent] = useState('')


    const [changeModal, setChangeModal] = useState(true);
    const [reponseApprenant, setReponseApprenant] = useState('')
    const [oldReponseApprenant, setOldReponseApprenant] = useState('')
    const [resUserByWho, setResUserByWho] = useState('')
    const [userTime, setUserTime] = useState('')
    const [resByWho, setResByWho] = useState('')
    const [time, setTime] = useState('')

    const [message, setmessage] = useState('')
    const handleCategoryChange = (ev) => {
        setPlainteCategory(ev.target.value)
    }

    const [selectedUrgence, setSelectedUrgence] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [plaintes, setPlaintes] = useState([]);
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');

    const myColor = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [id, setId] = useState(null);
    const [reponse, setReponse] = useState('');

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState()
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)

     ////////////////////////////////the size of the table//////////////////////////////
     const PAGE_SIZE = 10;
     const [pageSize, setPageSize] = useState(PAGE_SIZE);
     const handlePageSizeChange = (event) => {
         const newSize = parseInt(event.target.value, 10);
         setPageSize(newSize);
         setCurrentPage(0);
     };
     const [currentPage, setCurrentPage] = useState(0);
 
     //////////////////////////////////////////////////////////////////////////////////
 

    useEffect(() => {
        setLoadingData(true)
        axiosClient.get(`/tickets/getUserTickets/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&category=${selectedCategory}&user=${user.id}&etat=${selectedEtat}`)
        .then((response) => {
            let filteredData = response.data.results;
            setPaginationData(response.data);

            if (filteredData.length === 0) {
                setCheckLengthData(true)
            }
            else {
                setData(filteredData);
            }
            setLoadingData(false)
        })
        .catch((error) => {
            console.error(error);
            setLoadingData(false)

        });
    }, [searchTerm,selectedEtat, selectedCategory, user.id, time, userTime,currentPage,pageSize])

    // const IsRead = (id) => {
    //     axiosClient.get(`/tickets/${id}/`)
    //       .then((response) => {
    //         const ticket = response.data;
    //         const formData = new FormData()
    //         if ( ticket.response.length>0 && !ticket.is_read) {
    //            formData.append("response_read" , "True")
    //           axiosClient.patch(`/tickets/${id}/`,formData)
    //             .then((response) => {
    //               console.log('ok');
    //             //   update()
    //             })
    //             .catch((error) => {
    //               console.error(error)
    //             });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error(error)
    //       });
    
    //   };
    

    const lightTitle = 'linear(to-l, #040e56, #497dff)'
    const darkTitle = 'linear(to-l, #497dff, #497dff)'
    return (
        <Box >

           
            <Flex>
                <Box w="100%" display={"flex"} flexDirection={{base:'column', md:"row"}} justifyContent="space-between" mt={2} mb={5}>
                    <div style={{display:"flex" , flexDirection:'row' , alignItems :"center" , gap: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Mes tickets")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    </div>
                    
                   
                </Box>

            </Flex>
            
           
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={'center' }
                p={{base:1,sm:5}}
                gap={10}
            >

                <Flex
                    direction="column"
                    width={{ base: "100%", lg: "70%" }}
                    justify={{ base: 'flex-start', md: 'space-between' }}

                >
                    <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ base: 'column', lg: 'row' }}
                    mb={{ base: 2, md: 4 }}
                  >
                    <Flex mb={3} w={"100%"}>
                      <Select borderRadius="5"  borderColor={borderColor} fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        <option value="">{t("Toutes les catégories")}</option>
                        <option value="technical_support">{t("Support technique")}</option>
                        <option value="account_assistance">{t("Assistance pour le compte")}</option>
                        <option value="feedback_suggestions">{t("Commentaires et suggestions")}</option>
                        <option value="content_issues">{t("Problèmes de contenu")}</option>
                        <option value="general_inquiries">{t("Demandes générales")}</option>
                        {/* <option value="problem_with_client">Problème avec client</option> */}
                      </Select>

                      <Select borderRadius="5" borderColor={borderColor} fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '30%' }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                        <option value="">{t("Tout")}</option>
                        <option value="true">{t("Réglé")}</option>
                        <option value="false">{t("En cours")}</option>
                      </Select>

                    </Flex>

                    <InputGroup   w={{ base: '100%', lg: "50%" }}>
                      <InputLeftElement
                        pointerEvents='none'
                        children={<SearchIcon color='gray.300' />}
                      />
                      <Input
                      borderColor={borderColor}
                        borderRadius="5"
                        placeholder={t("Recherche...")}
                        value={searchTerm}
                        onChange={(e) => {
                          setCurrentPage(0)
                          setSearchTerm(e.target.value)
                        }}
                        mb={4}
                        sx={{
                          marginLeft: 'auto',
                        }}
                      />
                    </InputGroup>

                  </Flex>

                  {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        

                                        <Stack
                                        bg={myColor}
                                        mb={2}
                                        rounded={'lg'}
                                        p={5}
                                        boxShadow={'md'}
                                        alignItems={'start'}
                                        justify="space-between"
                                        as={"Button"}
        
                                    >
                                        <Flex direction="row"
                                            justify="space-between"
                                            ml={'auto'}
                                            width="full">
                                            <Stack direction={'row'} align={'center'}>
                                                <Text fontSize={'lg'} fontWeight="semibold">{row.sujet}</Text>
                                                <Stack mt={1}>
                                                    <Badge
                                                        rounded={'lg'}
                                                        colorScheme={'blue'}
                                                    >
                                                        
                                                        {row.category === "technical_support" ? t("Support technique") :
                                            row.category === "account_assistance" ? t("Assistance pour le compte") :
                                            row.category === "feedback_suggestions" ? t("Commentaires et suggestions") :
                                            row.category === "content_issues" ? t("Problèmes de contenu") :
                                            row.category === "general_inquiries" ? t("Demandes générales") : ""}
                                                    </Badge></Stack>
                                            </Stack>
                                            <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(row.created_at).toLocaleDateString(t("fr-FR"))}</Text>
        
                                        </Flex>
        
                                        <Flex
                                            direction="row"
                                            width="full"
                                            ml={'auto'}
                                            justify="space-between">
                                            <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                                {row.content}
        
                                            </Text>
        
                                            <Flex align={'center'} mt={1} direction={'column'}>
                                                <Badge
                                                    mb={2}
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? t('Réglé') : t('En cours')}
                                                </Badge>
                                                
                                            </Flex>
                                        </Flex>
                                    </Stack>
        
                                    ))}

                            
                        {/* ))} */}
                    {(!loadingData && data?.length === 0) && (
                        <Flex mb={'10'} align={'center'} justify={'center'}>
                            <Image
                            opacity={0.2}
                                height={200} width={300}
                                rounded={'md'}
                                alt={'empty'}
                                src={
                                    empty
                                }
                                objectFit={'empty'}
                            />
                        </Flex>)}
                        {((searchTerm || selectedEtat !== null || selectedCategory !== "") && paginationData?.length === 0 || (checkLengthData)) && (
                          <Text>{t("Aucune ligne correspondante n'a été trouvée.")}</Text>
                        )}
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                    <Flex justify={"space-evenly"} align={'center'} gap={3} py={5} flexDirection={{ base: 'column', md: "row" }} w="100%">

                        {/* <Select rounded={"lg"} size={"sm"} w="90px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select> */}
                        <MyPagination
                            paginationData={paginationData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            PAGE_SIZE={pageSize}
                        />
                    </Flex>
                    {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

                </Flex>

                {/* <Flex
                    w={{ base: '100%', md: '30%' }}
                    justify="center"
                    alignItems="center"
                    p={{ base: '3', md: '5' }}
                >
                    <Image
                        display={{base:'none' , md:'flex'}}
                        height={450} width={450}
                        rounded={'10px 150px'}
                        alt={'feature image'}
                        src={
                            img3
                        }
                        objectFit={'cover'}
                    />
                </Flex> */}

            </Flex>

           
                <Modal  onClose={() => {
                    setmessage('')
                    setReponseApprenant('')
                    setUserTime('')
                    onClose()
                }} isOpen={isOpen} isCentered>
                    <ModalOverlay />
                    <ModalContent >
                        <ModalHeader>{t("Réponse")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                           
                        {reponse ? 
                      <Flex w="100%" >

                        <Flex direction={"column"} justifyContent="end" w={"100%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.50" border={"1px solid #8ceb8c"} color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{new Date(time).toLocaleDateString(t("fr-FR"))}</Text>

                        </Flex>
                      </Flex> 
                      : <Text>{t("il n'y a pas encore de réponse")}</Text>}

                        </ModalBody>
                        <ModalFooter>

                           
                        </ModalFooter>
                    </ModalContent>
                </Modal>
               
            
        </Box>

    )
}

export default MyTickets