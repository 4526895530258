import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import RequireAuth from "./hooks/RequireAuth";

// import Dashboard from "./views/dashboard/Dashboard";

import Login from "./views/auth/Login";
import ResetPassword from "./views/auth/ResetPassword";
import ResetPwdPage from "./views/auth/ResetPwdPage";
import Dashboard from "./views/dashboard/Dashboard";
import Profil from "./views/profile/Profil";
import EditProfile from "./views/profile/EditProfile";
import Security from "./views/profile/Security";
import GestNotes from "./views/client/gestion_notes/GestNotes";
import GestUsers from "./views/client/gestion_users/GestUsers";
import MyStock from "./views/client/gestion_stock/MyStock";
import AddStock from "./views/client/gestion_stock/AddStock";
import EditStock from "./views/client/gestion_stock/EditStock";
import EditUser from "./views/client/gestion_users/EditUser";
import SecurityUser from "./views/client/gestion_users/SecurityUser";
import AddUser from "./views/client/gestion_users/AddUser";
import { GestTickets } from "./views/client/gestion_tickets/GestTickets";
import DetailsStock from "./views/client/gestion_stock/DetailsStock";
import UpgradeStock from "./views/client/gestion_stock/UpgradeStock";
import HistoriqueStock from "./views/client/gestion_stock/HistoriqueStock";
import NewOrder from "./views/client/gestion_orders/NewOrder";
import GestCoupons from "./views/client/gestion_coupons/GestCoupons";
import RegisterDemande from "./views/admin/gestion_demandes_inscription/RegisterDemande";
import { Ticket } from "./views/client/gestion_tickets/Ticket";
import MyTickets from "./views/client/gestion_tickets/MyTickets";
import GestNotifications from "./views/admin/gestion_notifications/GestNotifications";
import GestClients from "./views/admin/gestion_clients/GestClients";
import { GestTicketsAdmin } from "./views/admin/gestion_tickets_admin/GestTicketsAdmin";
import GestCharges from "./views/client/gestion_charges/GestCharges";
import AddUserAdmin from "./views/admin/gestion_users_admin/AddUserAdmin";
import SecurityUserAdmin from "./views/admin/gestion_users_admin/SecurityUserAdmin";
import EditUserAdmin from "./views/admin/gestion_users_admin/EditUserAdmin";
import GestUsersAdmin from "./views/admin/gestion_users_admin/GestUsersAdmin";
import GestStock from "./views/client/gestion_orders/gest_Stock/GestStock";
import Details_GestConfirmation from "./views/client/gestion_orders/Details_GestConfirmation";
import Register from "./views/auth/Register";
import GestSuivi from "./views/client/gestion_orders/gest_Suivi/GestSuivi";
import GestConfirmation from "./views/client/gestion_orders/gest_Confirmation/GestConfirmation";
import MyOrders from "./views/client/gestion_orders/gest_MesCommandes/MyOrders";
import ClientDetails from "./views/admin/gestion_clients/ClientDetails";
import UserNotifications from "./views/notifications/UserNotifications";
import New_Commandes from "./views/client/gestion_orders/gest_MesCommandes/New_Commandes";
import UserNotes from "./views/notes/UserNotes";
import NoteDetail from "./views/notes/NoteDetail";
import NotificationDetail from "./views/notifications/NotificationDetail";
import GestCategory from "./views/client/gestion_categories/GestCategory";
import Statistics from "./views/client/statistics/Statistics";
import NewOrdersFromExcel from "./views/client/gestion_orders/NewOrdersFromExcel";
import NewOrdersFromAPI from "./views/client/gestion_orders/NewOrdersFromAPI";
import New_Confirmed_Orders from "./views/client/gestion_orders/gest_Stock/NewConfirmedOrders";
import Facture from "./views/client/gestion_orders/gest_Factures/Facture";
import AllProducts from "./views/client/statistics/AllProducts";



const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [

      {
        path: '/',
        element: <RequireAuth element={<Dashboard />} />
      },
      {
        path: '/profil',
        element: <RequireAuth element={<Profil />} />
      },
      {
        path: '/modifier-profil',
        element: <RequireAuth element={<EditProfile />} />
      },
      {
        path: '/securite-de-compte',
        element: <RequireAuth element={<Security />} />
      },
      {
        path: '/notes',
        element: <RequireAuth element={<GestNotes />} />
      },
      {
        path: '/utilisateurs',
        element: <RequireAuth element={<GestUsers />} />
      },
      {
        path: '/modifier-utilisateur',
        element: <RequireAuth element={<EditUser />} />
      },
      {
        path: '/securite-utilisateur',
        element: <RequireAuth element={<SecurityUser />} />
      },

      {
        path: '/My_Stock',
        element: <RequireAuth element={<MyStock />} />
      },
      {
        path: '/NewStock',
        element: <RequireAuth element={<AddStock />} />
      },
      {
        path: '/EditStock',
        element: <RequireAuth element={<EditStock />} />
      },
      {
        path: '/ajouter_utilisateur',
        element: <RequireAuth element={<AddUser />} />
      },
      {
        path: '/tickets',
        element: <RequireAuth element={<GestTickets />} />
      },
      {
        path: '/DetailStock',
        element: <RequireAuth element={<DetailsStock />} />
      },
      {
        path: '/UpgradeStock',
        element: <RequireAuth element={<UpgradeStock />} />
      },
      {
        path: '/HistoriqueStock',
        element: <RequireAuth element={<HistoriqueStock />} />
      },

      //ORDER Routes
      {
        path: '/my_orders',
        element: <RequireAuth element={<MyOrders />} />
      },
      {
        path: '/NewOrder',
        element: <RequireAuth element={<NewOrder />} />
      },
      {
        path: '/NewOrderExcel',
        element: <RequireAuth element={<NewOrdersFromExcel />} />
      },
      {
        path: '/NewOrderAPI',
        element: <RequireAuth element={<NewOrdersFromAPI />} />
      },



      {
        path: '/coupons',
        element: <RequireAuth element={<GestCoupons />} />
      },
      {
        path: '/demandes-inscription',
        element: <RequireAuth element={<RegisterDemande />} />
      },
      {
        path: '/ticket',
        element: <RequireAuth element={<Ticket />} />
      },
      {
        path: '/my-tickets',
        element: <RequireAuth element={<MyTickets />} />
      },
      {
        path: '/Gestion-confirmation',
        element: <RequireAuth element={<GestConfirmation />} />
      },
      {
        path: '/Gestion-stock',
        element: <RequireAuth element={<GestStock />} />
      },
      {
        path: '/New-Confirmed-Orders',
        element: <RequireAuth element={<New_Confirmed_Orders />} />
      },
      {
        path: '/Gestion-suivi',
        element: <RequireAuth element={<GestSuivi />} />
      },
      {
        path: '/Detail-Gestion-confirmation',
        element: <RequireAuth element={<Details_GestConfirmation />} />
      },
      {
        path: '/list_new_orders',
        element: <RequireAuth element={<New_Commandes />} />
      },
      {
        path: '/Facture',
        element: <RequireAuth element={<Facture />} />
      },

      ////////////////////////////////////////Admin routes///////////////////////////////////////////// 
      {
        path: '/notifications',
        element: <RequireAuth element={<GestNotifications />} />
      },
      {
        path: '/clients',
        element: <RequireAuth element={<GestClients />} />
      },
      {
        path: '/tickets-admin',
        element: <RequireAuth element={<GestTicketsAdmin />} />
      },
      {
        path: '/charges',
        element: <RequireAuth element={<GestCharges />} />
      },
      {
        path: '/mes-utilisateurs',
        element: <RequireAuth element={<GestUsersAdmin />} />
      },
      {
        path: '/modifier-utilisateur-admin',
        element: <RequireAuth element={<EditUserAdmin />} />
      },
      {
        path: '/securite-utilisateur-admin',
        element: <RequireAuth element={<SecurityUserAdmin />} />
      },
      {
        path: '/ajouter-utilisateur-admin',
        element: <RequireAuth element={<AddUserAdmin />} />
      },
      {
        path: '/details-client',
        element: <RequireAuth element={<ClientDetails />} />
      },

      {
        path: '/my-notifications',
        element: <RequireAuth element={<UserNotifications />} />
      },
      {
        path: '/my-notes',
        element: <RequireAuth element={<UserNotes />} />
      },
      {
        path: '/detail-note',
        element: <RequireAuth element={<NoteDetail />} />
      },
      {
        path: '/detail-notification',
        element: <RequireAuth element={<NotificationDetail />} />
      },
      {
        path: '/categories',
        element: <RequireAuth element={<GestCategory />} />
      },
      {
        path: '/statistics',
        element: <RequireAuth element={<Statistics />} />
      },
      {
        path: '/products',
        element: <RequireAuth element={<AllProducts />} />
      },
      
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/register',
        element: <Register />
      },
      {
        path: '/reset_password',
        element: <ResetPassword />
      },
      {
        path: '/reinitialisation_de_motdepasse/:userId/:token/:expires',
        element: <ResetPwdPage />
      },
    ]
  },

  // {
  //   path: "*",
  //   element: <NotFound />
  // }
])

export default router;
