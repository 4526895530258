import { Avatar, Badge, Box, Button, Card, CardBody, CardHeader, CircularProgress, CircularProgressLabel, Flex, Grid, Hide, Icon, Progress, SimpleGrid, Skeleton, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BsArrowRight, BsPersonVcardFill } from 'react-icons/bs';
import { FaUserTie } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import IconBox from '../../components/Icons/IconBox';
import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from '../../components/Icons/Icons.js';
import { IoCheckmarkDoneCircleSharp, IoDocument, IoEllipsisHorizontal } from 'react-icons/io5';
import { BiHappy } from 'react-icons/bi';
import { LuPackageX } from "react-icons/lu";
import { TbTruckDelivery, TbTruckReturn } from "react-icons/tb";


import LineChart from '../../components/Charts/LineChart';
import axiosClient, { linkImage } from '../../axios-client.js';
import { useStateContext } from '../../context/ContextProvider.jsx';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	BarElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
} from "chart.js";

import { MdOutlineAddShoppingCart } from 'react-icons/md';
import SimplePagination from '../../components/SimplePagination.jsx';
import MyPagination from '../../components/MyPagination.jsx';
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function Dashboard() {
	const { t } = useTranslation();
	const { user } = useStateContext()
	const navigate = useNavigate()
	const [usersOldCount, setUsersOldCount] = useState()
	const [usersNewCount, setUsersNewCount] = useState()


	const [countLivreThisMonth, setCountLivreThisMonth] = useState(0)

	const [commandesOldCount, setCommandesOldCount] = useState()
	const [commandesNewCount, setCommandesNewCount] = useState()

	const [productsOldCount, setProductsOldCount] = useState()
	const [productsNewCount, setProductsNewCount] = useState()

	const [ticketsOldCount, setTicketsOldCount] = useState()
	const [ticketsNewCount, setTicketsNewCount] = useState()

	const [top10Products, setTop10products] = useState([])
	const [currentPage, setCurrentPage] = useState(0);

	const [paginationData, setPaginationData] = useState()
	const [loadingData, setLoadingData] = useState(true)
	const [checkLengthData, setCheckLengthData] = useState(false)
	const [commandes, setCommandes] = useState([])

	const [countNew, setCountNew] = useState()
	const [countAnnule, setCountAnnule] = useState()
	const [countLivre, setCountLivre] = useState()
	const [countRetour, setCountRetour] = useState()

	const [revenus, setRevenus] = useState([])
	const [charges, setCharges] = useState([])

	////////////////////////////////the size of the table//////////////////////////////
	const PAGE_SIZE = 4;
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const handlePageSizeChange = (event) => {
		const newSize = parseInt(event.target.value, 10);
		setPageSize(newSize);
		setCurrentPage(0);
	};
	//////////////////////////////////////////////////////////////////////////////////


	const fetchBarData = (userId) => {
		
		axiosClient.get(`/commandes/getCommandesCount/?client=${userId}`)
            .then((res) => {
                setCountNew(res.data.count_new);

                setCountLivre(res.data.count_livre);
                setCountRetour(res.data.count_retourne);
                setCountAnnule(res.data.count_annule);
            })
            .catch((error) => {
                console.error(error);
            });

	}

	// const fetchRevenusAndCharges = () => {
	// 	axiosClient.get('/commandes/getRevenusByMonth/')
	// 		.then((res) => {
	// 			setRevenus(res.data);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});

	// 	axiosClient.get(`/charges/getChargesByMonth/?client=${user.id}`)
	// 		.then((res) => {
	// 			setCharges(res.data);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});

	// }
	const fetchRevenus = async (userId) => {
		try {
			const res = await axiosClient.get(`/commandes/getRevenusByMonth/?client=${userId}`);
			return res.data;
		} catch (error) {
			console.error('Error fetching revenus:', error);
			return null; // Or handle error according to your requirements
		}
	};

	const fetchCharges = async (userId) => {
		try {
			const res = await axiosClient.get(`/charges/getChargesByMonth/?client=${userId}`);
			return res.data;
		} catch (error) {
			console.error('Error fetching charges:', error);
			return null; // Or handle error according to your requirements
		}
	};

	const labels = [t("Nouveau"), t("Livrée"),t("Retournée"), t("Annulée")];

	const dataChart = {
		labels: labels,
		datasets: [
			{
				label:"",
				data: [countNew, countLivre, countRetour, countAnnule],
				backgroundColor: [
					"rgba(54, 162, 235, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(246, 173, 85, 0.2)",
					"rgba(255, 99, 132, 0.2)",
				],
				borderColor: [
					"rgb(54, 162, 235)",
					"rgba(153, 102, 255)",
					"rgba(246, 173, 85)",
					"rgb(255, 99, 132)",

				],
				borderWidth: 1,
			},
		],
	};
	const options = {
		plugins: {
			title: {
				display: true,
				text: 'Charges Chart',
				font: {
					size: 16,
				},
			},
			legend:{
				display:false
			}
		},
	};


	const lineChartDataDashboard = async () => {
		const revenusData = await fetchRevenus(user.id);
		const chargesData = await fetchCharges(user.id);
		return [
			{
				name: t("Revenus"),
				data: revenusData || [],
			},
			{
				name: "Charges",
				data: chargesData || [],
			},
		];
	};

	const lineChartOptionsDashboard = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		tooltip: {
			theme: "dark",
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			type: "datetime",
			categories: [
				"Jan",
				t("Fév"),
				"Mar",
				t("Avr"),
				t("Mai"),
				t("Jui"),
				t("Juil"),
				t("Aou"),
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			labels: {
				style: {
					colors: "darkgray",
					fontSize: "12px",
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				style: {
					colors: "darkgray",
					fontSize: "12px",
				},
			},
		},
		legend: {
			show: false,
		},
		grid: {
			strokeDashArray: 5,
			borderColor: "gray"
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "dark",
				type: "vertical",
				shadeIntensity: 0,
				gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
				inverseColors: true,
				opacityFrom: 0.8,
				opacityTo: 0,
				stops: [],
			},
			colors: ["#582CFF", "#ff7b7b"],
		},
		colors: ["#582CFF", "#ff7b7b"],
	};

	const fetchData = () => {
		axiosClient.get(`count_users/${user.id}/`)
			.then((response) => {
				setUsersOldCount(response.data.old_count);
				setUsersNewCount(response.data.new_count);
			})
			.catch((error) => {
				console.log(error);
			});

		axiosClient.get(`count_commandes/${user.id}/`)
			.then((response) => {
				setCommandesOldCount(response.data.old_count);
				setCommandesNewCount(response.data.new_count);
			})
			.catch((error) => {
				console.log(error);
			});

		axiosClient.get(`count_products/${user.id}/`)
			.then((response) => {
				setProductsOldCount(response.data.old_count);
				setProductsNewCount(response.data.new_count);
			})
			.catch((error) => {
				console.log(error);
			});
		axiosClient.get(`count_tickets/${user.id}/`)
			.then((response) => {
				setTicketsOldCount(response.data.old_count);
				setTicketsNewCount(response.data.new_count);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	const fetchCommandes = () => {

		axiosClient.get(`/commandes/fetch_month_commandes/?page_size=${pageSize}&page=${currentPage + 1}&client=${user.id}`)
			.then((response) => {
				let filteredCmd = response.data.results
				setPaginationData(response.data);

				if (filteredCmd.length === 0) {
					setCheckLengthData(true)
				}
				else {
					setCommandes(filteredCmd);
				}
				setLoadingData(false)
			})
			.catch((error) => {
				console.error(error);
				setLoadingData(false)
			});
		axiosClient.get(`count_commandes_livre_this_month/${user.id}/`)
			.then((response) => {
				setCountLivreThisMonth(response.data.count);
			})
			.catch((error) => {
				console.log(error);
			});

	}
	const fetchTop10Products = () => {
		axiosClient.get(`top_products/${user.id}/`)
			.then((response) => {
				setTop10products(response.data);
			})
			.catch((error) => {
				console.log(error);
			});


	}

	const [lineChartData, setLineChartData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await lineChartDataDashboard();
				setLineChartData(data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		fetchData()
		fetchTop10Products()
		fetchCommandes()
		fetchBarData(user?.id)
	}, [user]);

	return (
		<Flex mt={5} flexDirection='column' >
		
			<SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
				{/* MiniStatistics Card */}
				<Card rounded={"xl"} bg={useColorModeValue("white", "gray.700")}>
					<CardBody>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nombre de personnels")}
								</StatLabel>
								<Flex>
									{(usersOldCount !== 0 || usersNewCount === 0) &&
										<StatNumber fontSize='lg' color='blue.700' fontWeight='bold'>
											{usersOldCount}
										</StatNumber>
									}

									{usersNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {usersNewCount}
										</StatHelpText>}


								</Flex>
							</Stat>
							<IconBox as='box' h={'45px'} w={'45px'} bg='teal.300'>
								<FaUserGroup size={"25px"} color='white' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card rounded={"xl"} bg={useColorModeValue("white", "gray.700")} minH='83px'>
					<CardBody>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nombre de commandes")}
								</StatLabel>
								<Flex>
									{(commandesOldCount !== 0 || commandesNewCount === 0) &&
										<StatNumber fontSize='lg' color='blue.700' fontWeight='bold'>
											{commandesOldCount}
										</StatNumber>
									}

									{commandesNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {commandesNewCount}
										</StatHelpText>
									}


								</Flex>
							</Stat>
							<IconBox as='box' h={'45px'} w={'45px'} bg='red.300'>
								<FaUserTie size={"25px"} color='white' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card rounded={"xl"} bg={useColorModeValue("white", "gray.700")}>
					<CardBody>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nombre de produits")}
								</StatLabel>
								<Flex>
									{(productsOldCount !== 0 || productsNewCount === 0) &&
										<StatNumber fontSize='lg' color='blue.700' fontWeight='bold'>
											{productsOldCount}
										</StatNumber>
									}

									{productsNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {productsNewCount}
										</StatHelpText>
									}


								</Flex>
							</Stat>
							<IconBox as='box' h={'45px'} w={'45px'} bg='blue.300'>
								<IoDocument size={"25px"} color='white' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card rounded={"xl"} bg={useColorModeValue("white", "gray.700")}>
					<CardBody>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Réclamations")}
								</StatLabel>
								<Flex>
									{(ticketsOldCount !== 0 || ticketsNewCount === 0) &&
										<StatNumber fontSize='lg' color='blue.700' fontWeight='bold'>
											{ticketsOldCount}
										</StatNumber>
									}

									{ticketsNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {ticketsNewCount}
										</StatHelpText>
									}

								</Flex>
							</Stat>
							<IconBox as='box' h={'45px'} w={'45px'} bg='yellow.400'>
								<BsPersonVcardFill size={"25px"} color='white' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
			</SimpleGrid>
			<Grid
				templateColumns={{ sm: '1fr', lg: '1.7fr 1.3fr' }}
				maxW={{ sm: '100%', md: '100%' }}
				gap='24px'
				my='26px'>
				{/* Sales Overview */}
				<Card rounded={'xl'} p='10px '>
					<CardHeader ps='22px'>
						<Flex direction='column' alignSelf='flex-start'>
							<Text fontSize='lg' fontWeight='bold' mb='6px'>
							{t("Évolution des Revenus et des Charges")}
							</Text>
							{/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
								<Text as='span' color='green.400' fontWeight='bold'>
									(+5%) more
								</Text>{' '}
								in 2021
							</Text> */}
						</Flex>
					</CardHeader>
					<Box w='100%' minH={{ base: '200px', sm:"300px" }}>
						{lineChartData &&
							<LineChart
								lineChartData={lineChartData}
								lineChartOptions={lineChartOptionsDashboard}
							/>}

					</Box>
				</Card>
				{/* Active Users */}
				<Card rounded={'xl'} p={{base:"5px",md:"15px"}}>
					<CardBody>
						<Flex direction='column' w='100%'>
							<Box
								bg={useColorModeValue('linear-gradient(71deg, rgba(255,255,255,1) 0%, rgba(239,229,253,1) 53%, rgba(214,188,250,1) 100%)', 'linear-gradient(71deg, rgba(50,62,81,1) 0%, rgba(65,49,117,1) 100%)')}
								borderRadius='20px'
								shadow={'xl'}
								w='100%' 
								
								// minH={{ sm: '300px' }}
								display={{ sm: 'flex', md: 'block' }}
								justify={{ sm: 'center', md: 'flex-start' }}
								align={{ sm: 'center', md: 'flex-start' }}
								minH={{ base: '180px', md: '220px' }}
								p={{ base: '15px',sm:"20px" ,  md: '22px' }}>
								{/* <BarChart

									barChartOptions={barChartOptionsDashboard}
									barChartData={barChartDataDashboard}
								/> */}
								<Bar  data={dataChart}  options={options} />
							</Box>
							<Flex direction='column' mt='24px' mb='10px' alignSelf='flex-start'>
								<Text fontSize='lg' fontWeight='bold' mb='6px'>
								{t("Statuts de commandes")}
								</Text>
								{/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										(+23%)
									</Text>{' '}
									than last week
								</Text> */}
							</Flex>
							<SimpleGrid gap={{base:"12px", sm: '12px' }} columns={{base:2,md:4}}>
								<Flex  direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<MdOutlineAddShoppingCart size={"23px"} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											{t("Nouveau")}
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										{countNew}
									</Text>
									<Progress colorScheme='brand' bg='blue.300' borderRadius='30px' h='5px' value={20} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<TbTruckDelivery size={"23px"} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											{t("Livrée")}
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										{countLivre}
									</Text>
									<Progress colorScheme='brand' bg='purple.300' borderRadius='30px' h='5px' value={90} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<TbTruckReturn size={"23px"} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											{t("Retournée")}
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										{countRetour}
									</Text>
									<Progress colorScheme='brand' bg='orange.300' borderRadius='30px' h='5px' value={30} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<LuPackageX size={"23px"} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											{t("Annulée")}
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										{countAnnule}
									</Text>
									<Progress colorScheme='brand' bg='red.300' borderRadius='30px' h='5px' value={50} />
								</Flex>
							</SimpleGrid>
						</Flex>
					</CardBody>
				</Card>
			</Grid>

			<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '2fr 1fr' }} gap='24px'>
				{/* Projects */}
				<Card rounded={'xl'} p='16px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
					<CardHeader p='12px 0px 20px 0px'>
						<Flex direction='column'>
							<Text fontSize='lg' fontWeight='bold' pb='8px'>
								{t("Les commandes livrées")}
							</Text>
							<Flex align='center'>
								<Icon as={IoCheckmarkDoneCircleSharp} color='teal.300' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									<Text fontWeight='bold' as='span'>
										{countLivreThisMonth} {t("Livrées")}
									</Text>{' '}
									{t("ce mois.")}
								</Text>
							</Flex>
						</Flex>
					</CardHeader>
					<TableContainer>
						<Table size={"md"} variant='simple'>
							<Thead>
								<Tr my='.8rem' ps='0px'>
									<Th
										fontSize={"sm"}
										ps='0px'
										color='gray.500'
										fontFamily='Plus Jakarta Display'
										borderBottomColor='#56577A'>
										{t("Code commande")}
									</Th>
									<Th fontSize={"sm"} color='gray.500' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										Client
									</Th>
									<Th fontSize={"sm"} color='gray.500' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										{t("Gestionnaire")}
									</Th>
									<Th fontSize={"sm"} color='gray.500' fontFamily='Plus Jakarta Display' borderBottomColor='#56577A'>
										{t("Prix")}
									</Th>

								</Tr>
							</Thead>
							<Tbody>
								{loadingData &&
									<Tr>

										<Td> <Skeleton height='20px' /></Td>
										<Td> <Skeleton height='20px' /></Td>
										<Td> <Skeleton height='20px' /></Td>
										<Td> <Skeleton height='20px' /></Td>

									</Tr>}
								{(paginationData?.length !== 0) && commandes.map((row) =>
									<Tr my='.8rem' ps='0px'>
										<Td ps='0px'>{row.code}</Td>
										<Td >
											<Flex gap={2} direction='column'>
												<Text
													fontSize='sm'

													fontWeight='normal'
													minWidth='100%'>
													{row.full_name}
												</Text>
												<Text fontSize='sm' color='gray.500' fontWeight='normal'>
													{row.tel}
												</Text>
											</Flex>
										</Td>

										<Td >
											<Flex gap={2} align={"center"}>
												<Avatar size={"sm"} name='Dan Abrahmov' src={row.image} />
												<Flex gap={1} direction={"column"}>
													<Text>{row.gst_first_name} </Text>
													<Text>{row.gst_last_name}</Text>
												</Flex>
											</Flex>
										</Td>
										<Td   >
											<Flex fontWeight={"semibold"} gap={1}>
												<Text >{row.prix}</Text> <Text h={"100%"} fontSize={10} >MAD</Text>
											</Flex>

										</Td>
									</Tr>
								)}
								{(commandes?.length === 0) && (
									<Tr>
										<Td colSpan={11}>{t("il n'y a pas encore de données.")}</Td>
									</Tr>
								)}




								{/* {dashboardTableData.map((row, index, arr) => {
								return (
									<DashboardTableRow
										name={row.name}
										logo={row.logo}
										members={row.members}
										budget={row.budget}
										progression={row.progression}
										lastItem={index === arr.length - 1 ? true : false}
									/>
								);
							})} */}
							</Tbody>
						</Table>

					</TableContainer>
					<Flex justify="space-between" align="center" w="100%">
						<Box flex="1">
							<MyPagination
								paginationData={paginationData}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								PAGE_SIZE={pageSize}
							/>
						</Box>
					</Flex>

				</Card>
				{/* Orders Overview */}
				<Card rounded={'xl'} p='16px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
					<CardHeader p='12px 0px 20px 0px'>
						<Flex direction='column'>
							<Text fontSize='lg' fontWeight='bold' pb='8px'>
								Top 10 {t("produits")}
							</Text>
							<Flex align='center'>
								<Icon as={IoCheckmarkDoneCircleSharp} color='teal.300' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									<Text fontWeight='bold' as='span'>
										{t("basé sur les produits livrés")}
									</Text>
								</Text>
							</Flex>
						</Flex>
					</CardHeader>
					{top10Products.slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
						<Card cursor={"pointer"} transition={"transform .3s"} mb={2} _hover={{
							shadow: "lg",
							transform: "scale(1.05)",
						}} shadow={'none'}>
							<CardBody alignItems={"center"} display={"flex"} justifyContent={"space-between"} p={2}>
								<Flex align={"center"} gap={2}>
									<Avatar borderRadius={"md"} name='Dan Abrahmov' src={linkImage + row.image} />
									<Flex fontSize={'sm'} direction={"column"}>
										<Text>{row.title}</Text>
										<Text color='gray' fontSize={"xs"}>{row.code}</Text>
									</Flex>
								</Flex>
								<Badge rounded={"lg"} px={4} py={1} variant='subtle' colorScheme='purple'>
									{row.count}
								</Badge>
							</CardBody>
						</Card>
					))}

					<Flex justify="space-between" align="center" w="100%">
						<Box flex="1">
							<SimplePagination
								data={top10Products}
								PAGE_SIZE={pageSize}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						</Box>


					</Flex>


				</Card>
			</Grid>
		</Flex>
	)
}

export default Dashboard