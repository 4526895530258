import React, { useRef, useState } from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, IconButton, Image, Input, InputGroup, InputLeftElement, Progress, Select, Skeleton, SkeletonCircle, Stack, Switch, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useColorModeValue, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CiImport } from "react-icons/ci";


import './assets/Files/Exemple.xlsx'
import { useStateContext } from '../../../context/ContextProvider';
import axiosClient from '../../../axios-client';
import { RiFileExcel2Fill } from 'react-icons/ri';

import img from './assets/images/logo_Excel.png'
import exempleFile from './assets/Files/Exemple.xlsx'


function NewOrdersFromExcel() {
    const navigate = useNavigate()
    const { user } = useStateContext()
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const toast = useToast()
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        console.log('Fiile : ', file);
        if (file && file?.name.length > 70) {
            toast({
                description: t("Le nom du fichier ne doit pas dépasser 60 caractères"),
                variant: 'left-accent',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right'
            })
            setFile(null)
            if (fileInputRef.current) fileInputRef.current.value = null;  // Réinitialiser l'input
            return;
        }
        else if (file && !['.xlsx', '.xls', '.csv'].includes(file?.name.substring(file?.name.lastIndexOf('.')))) {
            toast({
                description: t("Il faut respecter les extensions du fichier [.xlsx , .xls , .csv]"),
                variant: 'left-accent',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',

            })
            setFile(null)
            if (fileInputRef.current) fileInputRef.current.value = null;  // Réinitialiser l'input
            return;
        }
        else setFile(file);
    };

    // const handleSubmit = async () => {
    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await axiosClient.post(`import-orders/${user?.id}/`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });
    //     } catch (error) {
    //         console.error('There was an error uploading the file!', error);
    //     }
    // };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        handleFileChange({ target: { files: [file] } });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSubmit = async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);
        try {
            const response = await axiosClient.post(`import-orders/${user?.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });

            console.log("RESPOOOONSE File Uploaded : ",response);
            

            toast({
                description: t("Le fichier a été importé avec succès."),
                variant: 'left-accent',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right'
            });
        } catch (error) {
            console.error('There was an error uploading the file!', error);
            toast({
                description: t("Une erreur s'est produite lors de l'importation du fichier."),
                variant: 'left-accent',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right'
            });
        } finally {
            setUploading(false);
            setProgress(0);
        }
    };

    return (
        <Box position={"relative"} mb={5}>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                      
                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/list_new_orders')}>{t("Nouvelles commandes")} </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Ajout par Excel")} </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>

            <Flex
                px={2}
                py={5}
                mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={2}
                align={'center'}
                justify={'center'}

            >
                <Stack
                    w={'full'}
                    maxW='full'
                    rounded={'lg'}
                    mt={2}
                    px={{ base: 1, md: 5 }}
                >
                    <Flex flexDirection='column'>
                        <Text>{t("Pas sûr de ce qu'il faut inclure ?")}</Text>
                        <Text>
                            {t("Téléchargez notre")}&nbsp;
                            <a style={{ color: '#299ccb', textDecoration: "underline" }} href={exempleFile} download="Exemple.xlsx">
                                {t("fichier Excel")}
                            </a>
                            &nbsp;{t("d'exemple.")}
                        </Text>
                    </Flex>

                    <Flex position='relative' cursor='pointer' _hover={{ bg: useColorModeValue('gray.200', 'gray.800') }} flexDirection='column' alignItems='center' justifyContent='center' h={150} border={`2px dashed`} borderColor={useColorModeValue('purple.400', 'purple.600')} rounded='lg'
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        {file ?
                            <Flex alignItems='center' gap={2}>
                                <Box h={25} w={25}>
                                    <Image src={img} />
                                </Box>
                                <Text>{file?.name}</Text>
                            </Flex>
                            :
                            <>
                                <CiImport size={45} />
                                <Text>
                                    {t('Faites glisser et déposez votre fichier ici')}&nbsp;
                                    [.xlsx , .xls , .csv]
                                </Text>
                            </>
                        }
                        <Input ref={fileInputRef}
                            position="absolute" top={0} left={0} h="100%" opacity={0} type='file' accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                    </Flex>
                    {uploading && (
                        <Progress
                            hasStripe
                            isAnimated
                            value={progress}
                            size='sm'
                            width='100%'
                            mt={2}
                        />
                    )}
                    <Flex justifyContent='end' gap={2}>
                        {file &&
                            <Button colorScheme='red' onClick={() => {
                                if (fileInputRef.current) fileInputRef.current.value = null;
                                setFile(null)
                            }}>{t("Annuler")}</Button>
                        }
                        <Button isDisabled={!file} variant='outline' colorScheme='purple' onClick={handleSubmit}>{t("Terminer l'importation")}</Button>
                    </Flex>
                </Stack>
            </Flex>
        </Box>
    )
}

export default NewOrdersFromExcel