// import logo from '../../assets/images/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createRef, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
// import login from '../../assets/images/logo.png'

import {
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    Stack,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    DarkMode,
} from "@chakra-ui/react";

// Assets
import signInImage from "../../assets/images/signInImage.jpg";
import { useTranslation } from 'react-i18next';
import ReactCardFlip from 'react-card-flip';

import { motion, transform } from 'framer-motion'

export default function Login() {
    const { t } = useTranslation();

    const titleColor = "white";
    const textColor = "gray.400";
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { setToken, isLoading, setIsLoading } = useStateContext()
    const [message, setMessage] = useState([])
    const toast = useToast()
    const navigate = useNavigate()

    const [isFlipped, setIsFlipped] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)

    function handleFlip() {
        if (!isAnimating) {
            setIsFlipped(!isFlipped)
            setIsAnimating(true)
        }
    }


    //encryption function
    function encryptString(plainText, shift) {
        if (!Number.isInteger(shift) || shift < 1 || shift > 25) {
            throw new Error("Shift must be an integer between 1 and 25.");
        }

        const encryptedArray = [];
        for (let i = 0; i < plainText.length; i++) {
            let charCode = plainText.charCodeAt(i);

            // Encrypt uppercase letters
            if (charCode >= 65 && charCode <= 90) {
                charCode = ((charCode - 65 + shift) % 26) + 65;
            }
            // Encrypt lowercase letters
            else if (charCode >= 97 && charCode <= 122) {
                charCode = ((charCode - 97 + shift) % 26) + 97;
            }

            encryptedArray.push(String.fromCharCode(charCode));
        }

        return encryptedArray.join("");
    }


    const onSubmit = ev => {
        toast.closeAll()
        ev.preventDefault()
        setIsLoading(true)
        const payload = {
            email,
            password,
        }

        axiosClient.post('token/user/', payload)
            .then(({ data }) => {
                setToken(data.access);
                setIsLoading(false);
                localStorage.setItem("role", data.role)
                // if (data.role === 'RESPO') {
                //     console.log(data.role)
                //     navigate("/formations")
                //     return;
                // }
            })
            .catch((err) => {
                const response = err.response;
                // if (response.data.message === "Mot de passe incorrect") {
                //     setMessage({ alert: t(response.data.message), target: "password" });
                // }
                // else if (response.data.message === "Votre compte est désactivé") { setMessage({ alert: t(response.data.message), target: "" }); }
                // else {
                //     setMessage({ alert: t("Email incorrect"), target: "email" });
                // }
                setMessage({ alert: t(response.data.message), target: "" })

                setIsLoading(false)
            })

    }
    const [showPassword, setShowPassword] = useState(false);
    const isFormFilled = email && password;

    return (
        <Flex position='relative' backgroundSize={'cover'} bgImage={signInImage} h={'100vh'}>
            <Flex
                flexDirection={'row'} h={'100vh'} w={'100%'}>
                <Box

                    display={{ base: "none", lg: "flex" }}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    px={2}
                    h={'100%'} w={{lg:"40%",xl:"50%"}}>

                    <Text
                        mb={2}
                        textAlign='center'
                        color='White'
                        letterSpacing='8px'
                        fontSize={{lg:'md',xl:"2xl"}}
                        fontWeight='500'>
                        {t("BIENVENU DANS ECO SYSTEM")}
                    </Text>
                    <Text
                        textAlign='center'
                        color='transparent'
                        letterSpacing='10px'
                        fontSize={{lg:'xl',xl:"3xl"}}
                        fontFamily="initial"
                        fontWeight='bold'
                        bgClip='text !important'
                        bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                        ECOM SYSTEM
                    </Text>
                </Box>
                <Flex h={'100%'} w={{ base: "100%",lg:"60%", xl: '50%' }} alignItems={'center'} justifyContent={'center'}>
                <Flex
                                w={{ base: "100%", sm: "90%", md:'60%', lg: '90%' }}
                                backdropFilter='auto' backdropBlur='8px'
                                padding={10}
                                direction='column'
                                // sx={{
                                //     filter: "blur(4px)",
                                // }}
                                mr={{ base: 0, xl: 40 }}
                                rounded={"xl"}
                                background='#2b283fab'
                                // mt={{ base: "50px", md: "100px", lg: "140px", xl: "100px" }}
                            >
                                <div style={{ zIndex: 999 }}>
                                    <Heading color={titleColor} fontSize='32px' mb='20px'>
                                        {t("Login")}
                                    </Heading>
                                    <Text
                                        mb='36px'
                                        ms='4px'
                                        color={textColor}
                                        fontWeight='bold'
                                        fontSize='14px'>
                                        {t("Entrez votre adresse e-mail et votre mot de passe pour se connecter")}
                                    </Text>
                                    {message.alert &&
                                        <Alert status='error' rounded="md">
                                            <AlertIcon />
                                            {message.alert}
                                        </Alert>
                                    }
                                    <FormControl mb="3" id="email" isRequired >
                                        <FormLabel color={titleColor} >{t("Adresse email")}</FormLabel>
                                        <Input color={titleColor} onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t('Tapez votre adresse email')} />
                                    </FormControl>
                                    <FormControl id="password" isRequired isInvalid={message.target === "password"}>
                                        <FormLabel mb="3" color={titleColor}>{t("Mot de passe")}</FormLabel>
                                        <InputGroup mb="3">
                                            <Input color={titleColor} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder={t('Tapez votre mot de passe')} />
                                            <InputRightElement h={'full'}>
                                                <Button
                                                    color={titleColor}
                                                    variant={'ghost'}
                                                    _hover='none'
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }>
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>


                                    <Stack spacing={10}>
                                        <Stack
                                            direction={{ base: 'column', sm: 'row' }}
                                            align={'start'}
                                            justify={'space-between'}>
                                            <Link href='/reset_password' color={'purple.300'}>{t('Mot de passe oublié ?')}</Link>
                                        </Stack>
                                        <Button
                                            fontSize='13px'
                                            type="submit" colorScheme='purple' onClick={onSubmit} isLoading={isLoading}
                                            isDisabled={!isFormFilled}>
                                            {isLoading ? <Spinner size="sm" /> : t("Connexion")}
                                        </Button>
                                    </Stack>

                                    <Flex gap={2} mt={3}>
                                        <Text color="purple.300">{t("Vous n'avez pas de compte ?")}</Text>
                                        <Text color='purple.200' textDecorationLine="underline" cursor='pointer' _hover={{ color: "purple.100" }} onClick={() => navigate('/register')}>{t("S'inscrire")}</Text>
                                    </Flex>

                                </div>

                            </Flex>

                </Flex>
            </Flex>


        </Flex>
    );
}

