import {
    Alert,
    AlertIcon,
    Avatar,
    AvatarBadge,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Stack,
    Text,
    Textarea,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Resizer from "react-image-file-resizer";
import { CloseIcon } from "@chakra-ui/icons";
import { AiOutlinePlus } from "react-icons/ai";
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from "@chakra-ui/react";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../context/ContextProvider";



function AddStock() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const steps = [
        { title: "Images" },
        { title: "Infos" },
        { title: t("Propriétés") },
    ];
    const { user } = useStateContext()

    const [imageListe, setimageListe] = useState([]);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [loadingImg, setLoadingImg] = useState(false);
    const [inputKey, setInputKey] = useState(0);

    const { activeStep, setActiveStep } = useSteps({ index: 0 });

    //MESSAGES ERROR
    const [messageErrImages, setMessageErrImages] = useState('')
    const [messageErrTitle, setMessageErrTitle] = useState('')

    // data Categories
    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);

    const fetchDataCategories = async () => {
        try {
            await axiosClient.get(`/categories/getAllCategories/?page_size=100&page=1&&client=${user.id}`).then(res => {
                const categories = res.data.results.map(cat => ({
                    value: cat.id,
                    label: cat.name
                }));
                setCategoriesOptions(categories)
            })
        } catch (error) {
            console.error('Error : ', error);

        }
    }

    useEffect(() => {
        fetchDataCategories()
    }, [])

    // function display images
    const handleChangeImage = (e) => {
        const images = e.target.files;
        const extensions = [".png", ".jpg", ".jpeg", ".webp"];

        if (images && images.length > 0) {
            if (selectedImageList.length + images.length > 5) {
                toast({
                    description: t("Vous ne pouvez séléctionner que 5 images au maximum !"),
                    status: "warning",
                    variant: "left-accent",
                    duration: 2000,
                    isClosable: true,
                    position: "top-center",
                });
                setInputKey((prevKey) => prevKey + 1);
                return;
            }
            const selectedImages = Array.from(images);

            selectedImages.forEach(async (image) => {
                if (image.name.length > 60) {
                    toast({
                        description: t(
                            "Nom de l'image dépasse 60 caractères! Veuillez modifier le nom ! "
                        ),
                        status: "warning",
                        variant: "left-accent",
                        duration: 2000,
                        isClosable: true,
                        position: "top-center",
                    });
                } else if (
                    !extensions.includes(
                        image.name.substring(image.name.lastIndexOf("."))
                    )
                ) {
                    toast({
                        title: t("Erreur d'extension."),
                        description: t(
                            "Veuillez modifier l'extension de l'image {'[*.png, *.jpg, *.jpeg, *.webp]'} !"
                        ),
                        status: "warning",
                        variant: "left-accent",
                        duration: 2000,
                        isClosable: true,
                        position: "top-center",
                    });
                } else {
                    const imageURL = URL.createObjectURL(image);
                    const uri = await new Promise((resolve, reject) => {
                        Resizer.imageFileResizer(
                            image,
                            451,
                            800,
                            "png",
                            100,
                            0,
                            (uri) => {
                                resolve(uri);
                            },
                            "file"
                        );
                    });
                    setimageListe((prevImageList) => [...prevImageList, uri]);
                    setSelectedImageList((prevSelectedImages) => [
                        ...prevSelectedImages,
                        imageURL,
                    ]);
                }
            });

            // Reset the input value after processing all selected images
            setInputKey((prevKey) => prevKey + 1);
        }
    };

    //inputs info produits
    const [titreProduit, setTitreProduit] = useState('')
    const [descriptionProduit, setDescriptionProduit] = useState('')

    // inputs Properties Product
    const [dataProperties, setDataProperties] = useState([
        { color: "", size: "", quantity: "", price: "" },
    ]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const colorText_BoxProperties = useColorModeValue("gray", "gray.400");
    const colorInput_BoxProperties = useColorModeValue("black", "gray.400");
    const colorBorderInput_BoxProperties = useColorModeValue(
        "gray.300",
        "gray.500"
    );

    const addProperty = () => {
        setDataProperties([
            ...dataProperties,
            { color: "", size: "", quantity: "", purchase_price: "" },
        ]);
    };

    const removeProperty = (index) => {
        const listProp = [...dataProperties];
        listProp.splice(index, 1);
        setDataProperties(listProp);
    };

    const handleChangeProperty = (e, index) => {
        const { name, value } = e.target;
        const listProp = [...dataProperties];
        listProp[index][name] = value;
        setDataProperties(listProp);
    };

    const calculateTotalQuantity = () => {
        let totalQuantity = 0;
        dataProperties.forEach((item) => {
            // Convertir la quantité en nombre et l'ajouter au total
            totalQuantity += parseInt(item.quantity || 0); // Si la quantité est vide, on prend 0
        });
        return totalQuantity;
    };

    const addTotalQntt = () => {
        let totalQuantity = 0;
        dataProperties.forEach((item) => {
            // Convertir la quantité en nombre et l'ajouter au total
            totalQuantity += parseInt(item.quantity || 0); // Si la quantité est vide, on prend 0
        });
        setTotalQuantity(totalQuantity);
    };

    const removeTotlaQntt = (qtt) => {
        if (qtt) {
            setTotalQuantity(totalQuantity - parseInt(qtt));
        }
    };

    const addTotalPrice = () => {
        let totalPrice = 0;
        dataProperties.forEach((item) => {
            // Convertir la quantité en nombre et l'ajouter au total
            totalPrice += parseInt(item.purchase_price || 0); // Si la quantité est vide, on prend 0
        });
        setTotalPrice(totalPrice);
    };

    const removeTotlaPrice = (prix) => {
        if (prix) {
            setTotalPrice(totalPrice - parseInt(prix));
        }
    };

    // Background & color useColor
    const bg = useColorModeValue("white", "#212835")
    const bg2 = useColorModeValue("gray.50", "#212835")
    const colorTxt = useColorModeValue("gray", "gray.400")
    const colorTxt2 = useColorModeValue("gray.500", "gray.400")
    const colorTxt3 = useColorModeValue("gray", "gray.200")
    const borderColor = useColorModeValue("gray.500", "gray.400")

    useEffect(() => {
        console.log(user);
    })

    // Back-End
    const addProduct = () => {

        if (imageListe.length === 0) {
            setMessageErrImages(t('Il faut ajouter au moins une image !'))
            setActiveStep(0)
            return;
        }

        if (!titreProduit) {
            setMessageErrTitle(t('Il faut ajouter un titre !'))
            setActiveStep(1)
            return
        }

        //fonction si les champs de dataProperties sont vides
        const areAllFieldsEmpty = () => {
            return dataProperties.every(item => {
                return Object.values(item).every(value => value.trim() === '');
            });
        };

        console.log(areAllFieldsEmpty());

        const formData = new FormData()
        formData.append('title', titreProduit)
        formData.append('description', descriptionProduit)
        formData.append('total_quantity', totalQuantity)
        formData.append('total_price', totalPrice)        
        formData.append('category', selectedCategory)
        formData.append('product_added_by', user.id)
        formData.append('historique', JSON.stringify([
            {
                'title': titreProduit,
                'description': descriptionProduit,
                'total_price': totalPrice,
                'category': selectedCategory,
                'user': user.id,
                'created_at': new Date().toLocaleString()
            }
        ]))

        axiosClient.post('/products/', formData).then(async (res) => {
            await addStockImage(res.data)
            if (!areAllFieldsEmpty()) {
                await addStock(res.data)
            }
            navigate('/My_Stock')
        })
    }

    const addStockImage = (data) => {
        const formDataArray = imageListe.map((image) => {
            const formData = new FormData();
            formData.append("product", data.id);
            formData.append("image", image);
            return formData;
        });

        Promise.all(formDataArray.map(async (formData, index) => {
            let progressBar = 0;

            return await axiosClient.post("/productImages/", formData
                // , {
                //     onUploadProgress: (data) => {
                //         progressBar = Math.round((data.loaded / data.total) * 100);
                //         setUploaded({
                //             item: index,
                //             uploaded,
                //             progressBar,
                //         });
                //     },
                // }
            )
                .then((response) =>
                //  ({
                //     id: response?.data.id,
                //     service: data.id,
                //     image: response?.data.image,


                // })
                {
                    // if (index === formDataArray.length - 1) { navigate('/apa-agency-services') }

                }
                );

        }))
        // .then((responses) => {
        //     const newImages = responses.map(response => ({
        //         id: response.id,
        //         service: response.service,
        //         image: response.image,
        //     }));
        //     data.images = newImages;
        //     console.log('List image inside loop: ', data);

        // })
        // .catch((error) => {
        //     console.error('Error uploading images:', error);
        // });
    }

    const addStock = (data) => {

        const formDataArray = dataProperties.map((val) => {
            const formData = new FormData();
            formData.append("color", val.color);
            formData.append("size", val.size);
            formData.append("quantity", val.quantity);
            formData.append("purchase_price", val.purchase_price);
            formData.append("product", data.id);
            formData.append('stock_added_by', user.id)
            formData.append('historique', JSON.stringify([
                {
                    'color': val.color,
                    'size': val.size,
                    'quantity': val.quantity,
                    'purchase_price': val.purchase_price,
                    'user': user.id,
                    'created_at': new Date().toLocaleString()
                }
            ]))

            return formData;
        });

        Promise.all(formDataArray.map(async (formData, index) => {

            return await axiosClient.post("/stock/", formData
                // , {
                //     onUploadProgress: (data) => {
                //         progressBar = Math.round((data.loaded / data.total) * 100);
                //         setUploaded({
                //             item: index,
                //             uploaded,
                //             progressBar,
                //         });
                //     },
                // }
            )
                .then((response) =>
                //  ({
                //     id: response?.data.id,
                //     service: data.id,
                //     image: response?.data.image,


                // })
                {
                    // if (index === formDataArray.length - 1) { navigate('/apa-agency-services') }

                }
                );

        }))
    }

    return (
        <Stack>
            <Flex>
                <Box w="90%" pt="12px" mb={8}>
                  
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem >
                            <BreadcrumbLink onClick={() => navigate('/My_Stock')}>{t("Gestion stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Nouveau stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>
            <Flex justify="center">
                <Stepper w={{ base: "100%", lg: "75%" }} index={activeStep}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink="0">
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>
            </Flex>

            <Flex justify={"center"}>
                <Box
                    bg={bg}
                    borderWidth="1px"
                    rounded="lg"
                    w={{ base: "100%", lg: "75%" }}
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    p={6}
                    m="10px auto"
                    as="form"
                >
                    {
                        activeStep === 0 ?

                            <>
                                <Stack
                                    pt={3}
                                    px={{ base: 2, lg: 7 }}
                                    bg={bg}
                                    rounded="lg"
                                >
                                    <Text color={colorTxt}>
                                        {t("Ajouter les images jusqu'au 5 images")}
                                    </Text>

                                    {messageErrImages && <Alert status='error' variant='left-accent'>
                                        <AlertIcon />
                                        {messageErrImages}
                                    </Alert>}

                                    <Flex
                                        align={"center"}
                                        w={"100%"}
                                        // gap={2}
                                        px={5}
                                        py={5}
                                        justify={"center"}
                                        // py={{ base: 3, xl: 0 }}

                                        minHeight={"100px"}
                                    // style={{
                                    //     border: '1px dashed lightgray',
                                    // }}
                                    >
                                        {selectedImageList.length === 0 && (
                                            <Flex

                                            // align={'center'}
                                            // justify={'center'}
                                            // style={{ width: '100%' }}
                                            >
                                                <Button
                                                    size={{ base: "sm", md: "md" }}
                                                    ml={{ base: 0, lg: 5 }}
                                                    colorScheme="purple"
                                                    variant="outline"
                                                >
                                                    <AiOutlinePlus style={{ fontSize: "18px", marginRight: 2 }} />
                                                    <Text ml="2" fontWeight="lighter">
                                                        {t("Télécharger des images")}
                                                    </Text>
                                                    <input
                                                        key={inputKey}
                                                        multiple
                                                        type="file"
                                                        id="inputGroupFile01"
                                                        style={{
                                                            position: "absolute",
                                                            left: 0,
                                                            top: 0,
                                                            height: "100%",
                                                            opacity: 0,
                                                            cursor: "pointer",
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
                                                        onMouseLeave={(e) =>
                                                            (e.target.style.cursor = "context-menu")
                                                        }
                                                        onChange={handleChangeImage}
                                                        accept="image/png,image/jpg,image/jpeg,image/webp"
                                                    />
                                                </Button>
                                            </Flex>
                                        )}
                                        <Grid
                                            templateColumns={{
                                                base: "repeat(1,1fr)",
                                                sm: "repeat(3,1fr)",
                                                md: "repeat(3,1fr)",
                                                lg: "repeat(4,1fr)",
                                                xl: "repeat(5,1fr)",
                                            }}
                                            gap={3}
                                        >
                                            {selectedImageList &&
                                                selectedImageList.map((val, key) => (
                                                    <GridItem>
                                                        <Flex
                                                            flexDirection={"row"}
                                                            w={"100%"}
                                                            key={key}
                                                            style={{
                                                                position: "relative",
                                                            }}
                                                        >
                                                            <Avatar
                                                                size={"xl"}
                                                                src={val}
                                                                borderRadius={"10px"}
                                                                style={{
                                                                    objectFit: "contain",
                                                                }}
                                                            >
                                                                <AvatarBadge
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        position: "absolute",
                                                                        fontSize: 10,

                                                                        cursor: "pointer",
                                                                        // color: '#c7c4c4',
                                                                        background: "black",
                                                                        right: "-5%",
                                                                        top: "-5%",
                                                                    }}
                                                                >
                                                                    <CloseIcon
                                                                        onClick={() => {
                                                                            // deleteimg(key)
                                                                            const newImageList = imageListe;
                                                                            const deleteItemImageListe = newImageList.splice(
                                                                                key,
                                                                                1
                                                                            );
                                                                            setSelectedImageList(
                                                                                selectedImageList.filter((e) => e !== val)
                                                                            );
                                                                            setimageListe(newImageList);
                                                                        }}
                                                                    />
                                                                </AvatarBadge>
                                                            </Avatar>
                                                        </Flex>
                                                    </GridItem>
                                                ))}

                                            {selectedImageList.length > 0 && selectedImageList.length < 5 && (
                                                <Flex
                                                    align={"center"}
                                                    justify={"center"}
                                                    style={{
                                                        height: "100px",
                                                    }}
                                                >
                                                    <Button colorScheme="blue" variant="outline">
                                                        <AiOutlinePlus style={{ fontSize: 22 }} />
                                                        <input
                                                            key={inputKey}
                                                            multiple
                                                            type="file"
                                                            onChange={handleChangeImage}
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: "100%",
                                                                top: 0,
                                                                left: 0,
                                                                opacity: 0,
                                                            }}
                                                            accept="image/png,image/jpg,image/jpeg,image/webp"
                                                        />
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Grid>
                                    </Flex>
                                </Stack>
                            </>
                            : activeStep === 1
                                ?

                                <>
                                    <Stack
                                        mt={3}
                                        px={{ base: 2, lg: 7 }}
                                        bg={bg}
                                        rounded="lg"
                                    >
                                        {/* <Text color={useColorModeValue('gray', 'gray.400')}></Text> */}
                                        <Heading
                                            color={colorTxt2}
                                            w="100%"
                                            fontSize={"3xl"}
                                            textAlign={"center"}
                                            fontWeight="normal"
                                            mb={5}
                                        >
                                            {t("Informations du produit")}
                                        </Heading>

                                        {messageErrTitle && <Alert status='error' variant='left-accent'>
                                            <AlertIcon />
                                            {messageErrTitle}
                                        </Alert>}

                                        <Flex mt={2}>
                                            <FormControl display={"flex"} flexDirection={{ base: 'column', md: 'row' }} id="titre" isRequired>
                                                <FormLabel
                                                    color={colorTxt}
                                                    w={"80%"}
                                                >
                                                    {t("Titre")}
                                                </FormLabel>
                                                <Input
                                                    id="titre"
                                                    name="titreProduit"
                                                    type="text"
                                                    color={colorTxt2}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    placeholder={t("Titre")}
                                                    value={titreProduit}
                                                    onChange={e => setTitreProduit(e.target.value)}
                                                />
                                            </FormControl>
                                        </Flex>

                                        <Flex mt={2}>
                                            <FormControl display={"flex"} flexDirection={{ base: 'column', md: 'row' }} id="titre">
                                                <FormLabel
                                                    color={colorTxt}
                                                    w={"80%"}
                                                >
                                                    {t("Catégorie")}
                                                </FormLabel>
                                                <div style={{ width: '104%' }}>
                                                    <Select
                                                        onChange={e=>setSelectedCategory(e.target.value)}
                                                        placeholder={t("Choisissez une catégorie")}
                                                        value={selectedCategory}
                                                        isClearable
                                                        isSearchable
                                                        name="category"
                                                    >
                                                        {categoriesOptions.map((val,key)=>{
                                                            return(
                                                                <option key={key} value={val.value}>{val.label}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </Flex>

                                        <Flex mt={3}>
                                            <FormControl display={"flex"} flexDirection={{ base: 'column', md: 'row' }} id="description" isRequired>
                                                <FormLabel
                                                    color={colorTxt}
                                                    w={"80%"}
                                                >
                                                    Description
                                                </FormLabel>
                                                <Textarea
                                                    id="description"
                                                    name="descriptionProduit"
                                                    color={colorTxt2}
                                                    borderColor={colorBorderInput_BoxProperties}
                                                    _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                    resize={"none"}
                                                    placeholder="Description"
                                                    value={descriptionProduit}
                                                    onChange={e => setDescriptionProduit(e.target.value)}
                                                ></Textarea>
                                            </FormControl>
                                        </Flex>

                                    </Stack>
                                </>
                                :
                                <>
                                    <Stack
                                        mt={3}
                                        pt={3}
                                        px={{ base: 2, lg: 7 }}
                                        bg={bg}
                                        rounded="lg"
                                        pb={5}
                                    >
                                        {/* <Text color={useColorModeValue('gray', 'gray.400')}>Propriétés produit</Text> */}
                                        <Heading
                                            color={borderColor}
                                            w="100%"
                                            fontSize={"3xl"}
                                            textAlign={"center"}
                                            fontWeight="normal"
                                            mb={5}
                                        >
                                            {t("Propriétés du produit")}
                                        </Heading>
                                        {dataProperties.map((val, key) => {
                                            return (
                                                <Box key={key} mt={2}>
                                                    <Flex gap={5}>
                                                        <FormControl id="couleur" isRequired>
                                                            <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                                {t("Couleur")}
                                                            </FormLabel>
                                                            <Input
                                                                id="couleur"
                                                                name="color"
                                                                type="text"
                                                                color={colorInput_BoxProperties}
                                                                borderColor={colorBorderInput_BoxProperties}
                                                                _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                                placeholder={t("Couleur")}
                                                                value={val.color}
                                                                onChange={(e) => handleChangeProperty(e, key)}
                                                            />
                                                        </FormControl>
                                                        <FormControl id="size" isRequired>
                                                            <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                                {t("Taille")}
                                                            </FormLabel>
                                                            <Input
                                                                id="size"
                                                                name="size"
                                                                type="text"
                                                                color={colorInput_BoxProperties}
                                                                borderColor={colorBorderInput_BoxProperties}
                                                                _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                                placeholder={t("Taille")}
                                                                value={val.size}
                                                                onChange={(e) => handleChangeProperty(e, key)}
                                                            />
                                                        </FormControl>
                                                    </Flex>
                                                    <Flex gap={5} mt={3}>
                                                        <FormControl id="quantity" isRequired>
                                                            <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                                {t('Quantité')}
                                                            </FormLabel>
                                                            <Input
                                                                id="quantity"
                                                                name="quantity"
                                                                type="text"
                                                                color={colorInput_BoxProperties}
                                                                borderColor={colorBorderInput_BoxProperties}
                                                                _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                                placeholder={t("Quantité")}
                                                                value={val.quantity}
                                                                onChange={(e) => {
                                                                    handleChangeProperty(e, key);
                                                                    addTotalQntt();
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <FormControl id="price" isRequired>
                                                            <FormLabel color={colorText_BoxProperties} w={"80%"}>
                                                                {t("Prix d'achat")}
                                                            </FormLabel>
                                                            <Input
                                                                id="price"
                                                                name="purchase_price"
                                                                type="text"
                                                                color={colorInput_BoxProperties}
                                                                borderColor={colorBorderInput_BoxProperties}
                                                                _focus={{ boxShadow: "none", borderColor: "gray" }}
                                                                placeholder={t("Prix d'achat")}
                                                                value={val.purchase_price}
                                                                onChange={(e) => {
                                                                    handleChangeProperty(e, key);
                                                                    addTotalPrice();
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Flex>

                                                    <Flex justifyContent={"end"} mt={3} gap={3}>
                                                        <Flex justifyContent={"end"} gap={3}>
                                                            {dataProperties.length > 1 && (
                                                                <Button
                                                                    colorScheme="red"
                                                                    size={"sm"}
                                                                    variant={"outline"}
                                                                    onClick={() => {
                                                                        removeProperty(key);
                                                                        removeTotlaQntt(val.quantity);
                                                                        removeTotlaPrice(val.price);
                                                                    }}
                                                                >
                                                                    {t('Supprimer')}
                                                                </Button>
                                                            )}
                                                            {dataProperties.length - 1 === key && (
                                                                <Button
                                                                    colorScheme="green"
                                                                    size={"sm"}
                                                                    variant={"outline"}
                                                                    onClick={() => {
                                                                        addProperty();
                                                                    }}
                                                                >
                                                                    Plus
                                                                </Button>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            );
                                        })}
                                    </Stack>

                                    <Stack
                                        mt={3}
                                        pt={3}
                                        px={7}
                                        bg={bg2}
                                        rounded="lg"
                                        pb={5}
                                    >
                                        <Text
                                            color={colorTxt}
                                        >Total</Text>
                                        <Flex justifyContent={"space-between"}>
                                            <Flex mt={2} gap={2}>
                                                <Text
                                                    textTransform={"uppercase"}
                                                    color={colorTxt}
                                                >
                                                    {t("Quantité")} :{" "}
                                                </Text>
                                                <Text
                                                    color={colorTxt3}
                                                >
                                                    {totalQuantity}
                                                </Text>
                                            </Flex>
                                            <Flex mt={2} gap={2}>
                                                <Text
                                                    textTransform={"uppercase"}
                                                    color={colorTxt}
                                                >
                                                    {t("Prix")} :{" "}
                                                </Text>
                                                <Text
                                                    color={colorTxt3}
                                                >
                                                    {totalPrice}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Stack>
                                </>
                    }
                    <ButtonGroup mt="5%" w="100%">
                        <Flex w="100%" justifyContent="space-between">
                            <Flex>
                                <Button
                                    onClick={() => {
                                        setActiveStep(activeStep - 1)
                                    }}
                                    isDisabled={activeStep === 0}
                                    colorScheme="blue"
                                    variant="solid"
                                    w="7rem"
                                    mr="5%"
                                >
                                    {t("Précédent")}
                                </Button>
                                <Button
                                    w="7rem"
                                    isDisabled={activeStep === 2}
                                    onClick={() => {
                                        setActiveStep(activeStep + 1)
                                    }}
                                    colorScheme="blue"
                                    variant="outline"
                                >
                                    {t("Suivant")}
                                </Button>
                            </Flex>

                        </Flex>
                    </ButtonGroup>

                </Box>
                {/* <Box w={{ base: '100%', md: '80%', lg: '70%' }}>
                     
  
                      
  
                     
  
                      <Flex mt={5} justifyContent={'end'}>
                          <Button colorScheme='purple' size={"sm"} variant={'outline'} onClick={() => {
                          }}>Ajouter</Button>
                      </Flex>
  
                  </Box> */}
            </Flex>
            <Flex
                justify={'center'}>
                <Flex w={{ base: '100%', lg: '75%' }} justify={"end"}>
                    {activeStep === 2 ? (
                        // <Button
                        //     w="7rem"
                        //     colorScheme="red"
                        //     variant="solid"
                        //     onClick={() => {
                        //         toast({
                        //             title: 'Account created.',
                        //             description: "We've created your account for you.",
                        //             status: 'success',
                        //             duration: 3000,
                        //             isClosable: true,
                        //         })
                        //     }}>
                        //     Ajouter
                        // </Button>
                        <Button
                            w="7rem"
                            colorScheme="purple"
                            size={"md"}
                            variant={"outline"}
                            onClick={addProduct}
                        >
                            {t("Ajouter")}
                        </Button>
                    ) : null}
                </Flex>
            </Flex>
        </Stack>
    );
}

export default AddStock;