// import logo from '../../assets/images/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createRef, useEffect, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
// import login from '../../assets/images/logo.png'

import {
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    Stack,
    Alert,
    AlertIcon,
    Checkbox,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    DarkMode,
} from "@chakra-ui/react";

// Assets
import signInImage from "../../assets/images/signInImage.jpg";
import { useTranslation } from 'react-i18next';
import ReactCardFlip from 'react-card-flip';

import { motion, transform } from 'framer-motion'

export default function Register() {
    const { t } = useTranslation();

    const titleColor = "white";
    const textColor = "gray.400";
    const { setToken, isLoading, setIsLoading } = useStateContext()
    const [message, setMessage] = useState([])
    const toast = useToast()
    const navigate = useNavigate()

    const [isChecked, setIsChecked] = useState(false)

    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tel, setTel] = useState()
    const confirmPassword = createRef()

    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const emailRegex = /^\S+@\S+\.\S+$/;

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(password)) {
            missingRequirements.push("a special Character");
        } else if (!lowercaseRegex.test(password)) {
            missingRequirements.push("a lowercase letter");
        } else if (!uppercaseRegex.test(password)) {
            missingRequirements.push("an uppercase letter");
        } else if (!digitRegex.test(password)) {
            missingRequirements.push("a number");
        } else if (!minLengthRegex.test(password)) {
            missingRequirements.push("a minimum length of 8 characters");
        }

        if (missingRequirements.length > 0) {
            const missingText = `You need ${missingRequirements.join(", ")} in your password.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.300');
        } else {
            setPasswordStrength('The password is correct !');
            setPasswordColor('green.300');
        }
    }
    const isFormValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('The password must be at least 8 characters long.');
        }

        if (!digitRegex.test(password)) {
            errors.push('The password must contain at least one number.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('The password must contain at least one lowercase letter.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('The password must contain at least one uppercase letter.');
        }
        if (!specialchar.test(password)) {
            errors.push('The password must contain at least one special character (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('The password must not exceed 20 characters.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };
    useEffect(() => {
        checkPasswordStrength();
    }, [password]);
    const onRegister = ev => {
        ev.preventDefault()
        // setIsLoading(true)
        toast.closeAll()

        // Check if the email exists in the dataDemandes array
        // const emailExistsInDemandes = dataDemandes.some((demande) => demande.email === email);

        // const emailExistsInApprenants = dataApprenants.some((apprenant) => apprenant.email === email);

        // if (emailExistsInApprenants) {
        //   setMessage({
        //     alert: "A user with the entered email already exists",
        //     target: "email",
        //   });
        //   setIsLoading(false);
        //   return;
        // }
        // else if (emailExistsInDemandes) {
        //   setMessage({
        //     alert: "A user with this email address already exists in the registration requests. Please wait while we process your request.",
        //     target: "email",
        //   });
        //   setIsLoading(false);
        //   return;
        // }


        const formData = new FormData()
        formData.append("first_name", first_name)
        formData.append("last_name", last_name)
        formData.append("email", email)
        formData.append("password", password)
        formData.append("tel", tel)
        formData.append("etat_demande", "EnCours")


        setMessage({})
        if (password !== confirmPassword.current.value) {
          setMessage({ alert: "Veuillez confirmer le mot de passe.", target: "password" })
          setIsLoading(false)
          return
        } else if (passwordStrength !== 'Fort' && password.length > 0 && !isFormValid(password)) {
          setMessage({ alert: "Entrer un mot de passe valide s'il vous plait.", target: "password" });
          setIsLoading(false)
          return;
        }

        else if (!emailRegex.test(email)) {
          setMessage({ alert: "Please enter a valid email.", target: "email" });
          setIsLoading(false)
        }

        else {
        axiosClient.post('demandeInscription/', formData)
            .then(() => {
                //   setIsLoading(false)
                toast({
                    title: "Demande envoyée",
                    description: "Votre demande a été soumise avec succès, veuillez attendre que votre compte soit validé.",
                    status: "success",
                    position: "top",
                    duration: 2000,
                    variant: 'left-accent',
                    isClosable: true,
                });

                  navigate('/login')
                  setFirstName()
                  setLastName()
                  setEmail()
                  setPassword()
                  setTel()
            })
            .catch((err) => {
                const response = err.response;
                console.log(response);
                if (response.status === 400) {
                  setMessage({ alert: "Ce mail existe déjà parmi les demandes d'inscription. Veuillez patienter pendant que nous traitons votre demande.", target: "email" });
                } else {
                  setMessage({ alert: response.data, target: "all" });
                }
                  setIsLoading(false);
            });
        }
    }
    const [showPassword, setShowPassword] = useState(false);
    const isFormFilled = email && password && tel && first_name && last_name ;

    return (
        <Flex position='relative' backgroundSize={'cover'} bgImage={signInImage} h={'100vh'}>
            <Flex
                flexDirection={'row'} h={'100vh'} w={'100%'}>
                <Box

                    display={{ base: "none", lg: "flex" }}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    px={2}
                    h={'100%'} w={{ lg: "40%", xl: "50%" }}>

                    <Text
                        mb={2}
                        textAlign='center'
                        color='White'
                        letterSpacing='8px'
                        fontSize={{ lg: 'md', xl: "2xl" }}
                        fontWeight='500'>
                        {t("BIENVENU DANS ECO SYSTEM")}
                    </Text>
                    <Text
                        textAlign='center'
                        color='transparent'
                        letterSpacing='10px'
                        fontSize={{ lg: 'xl', xl: "3xl" }}
                        fontFamily="initial"
                        fontWeight='bold'
                        bgClip='text !important'
                        bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                        ECOM SYSTEM
                    </Text>
                </Box>
                <Flex h={'100%'} w={{ base: "100%", lg: "60%", xl: '50%' }} alignItems={'center'} justifyContent={'center'}>
                    <Flex
                        w={{ base: "100%", sm: "90%", md: '70%', lg: '90%' }}
                        backdropFilter='auto' backdropBlur='8px'
                        padding={10}
                        direction='column'
                        // sx={{
                        //     filter: "blur(4px)",
                        // }}
                        mr={{ base: 0, xl: 40 }}
                        rounded={"xl"}
                        background='#2b283fab'
                    // mt={{ base: "50px", md: "100px", lg: "140px", xl: "100px" }}
                    >
                        <div style={{ zIndex: 999 }}>
                            <Heading color={titleColor} fontSize='32px' mb='30px'>
                                {t("Inscription")}
                            </Heading>
                            {message.alert &&
                                <Alert mb={2} status='error' rounded="md">
                                    <AlertIcon />
                                    {message.alert}
                                </Alert>
                            }
                            <Flex gap={3}>
                                <FormControl mb="3" id="nom" isRequired >
                                    <FormLabel color={titleColor} >{t("Nom")}</FormLabel>
                                    <Input color={titleColor} onChange={(e) => setLastName(e.target.value)} type="text" id='nom' placeholder={t('Tapez votre nom')} />
                                </FormControl>
                                <FormControl mb="3" id="prenom" isRequired >
                                    <FormLabel color={titleColor} >{t("Prénom")}</FormLabel>
                                    <Input color={titleColor} onChange={(e) => setFirstName(e.target.value)} type="text" id='prenom' placeholder={t('Tapez votre prénom')} />
                                </FormControl>
                            </Flex>

                            <FormControl mb={3} id="email" isRequired >
                                <FormLabel color={titleColor} >{t("Adresse email")}</FormLabel>
                                <Input color={titleColor} onChange={(e) => setEmail(e.target.value)} type="text" id='email' placeholder={t('Tapez votre adresse email')} />
                            </FormControl>

                            <Flex gap={3} direction={{ base: 'column', md: 'row' }}>
                                <FormControl>
                                    <FormLabel mb="3" color={titleColor} id='password'>{t("Mot de passe")}</FormLabel>
                                    <InputGroup mb="3">
                                        <Input color={titleColor} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} id='password' placeholder={t('Tapez votre mot de passe')} />
                                        <InputRightElement h={'full'}>
                                            <Button
                                                color={titleColor}
                                                variant={'ghost'}
                                                _hover='none'
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel mb="3" color={titleColor} id='confirmPassword'>{t("Confirmation mot de passe")}</FormLabel>
                                    <InputGroup mb="3">
                                        <Input color={titleColor} ref={confirmPassword} type={'password'} id='confirmPassword' placeholder={t('confirmez votre mot de passe')} />
                                        {/* <InputRightElement h={'full'}>
                                            <Button
                                                color={titleColor}
                                                variant={'ghost'}
                                                _hover='none'
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement> */}
                                    </InputGroup>
                                </FormControl>

                            </Flex>
                            {password.length > 0 ?
                                <Text align="left" justify="left" fontSize="sm" mb={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                : ""}

                            <FormControl mb={3} id="tel" isRequired >
                                <FormLabel color={titleColor} >{t("Numéro de téléphone")}</FormLabel>
                                <Input color={titleColor} onChange={(e) => setTel(e.target.value)} type="number" id='tel' placeholder={t('Tapez votre numéro de téléphone')} />
                            </FormControl>
                            <FormControl mb={3} id="cp"  >
                                <FormLabel color={titleColor} >{t("Code parrainage")}</FormLabel>
                                <Checkbox
                                    mb={3}
                                    color={titleColor}
                                    isChecked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                >
                                    {t("Avez-vous un code parrainage ?")}
                                </Checkbox>
                                {isChecked &&
                                    <Input color={titleColor} type="text" id='email' placeholder={t('code parrainage')} />
                                }
                            </FormControl>



                            <Stack spacing={10} mt={5}>
                                <Button
                                    fontSize='13px'
                                    type="submit" colorScheme='blue' onClick={onRegister} isLoading={isLoading}
                                    isDisabled={!isFormFilled}>
                                    {isLoading ? <Spinner size="sm" /> : t("S'inscrire")}
                                </Button>
                            </Stack>

                            <Flex gap={2} mt={3}>
                                <Text color="blue.300">{t('Vous avez déjà un compte ?')}</Text>
                                <Text color='blue.200' textDecorationLine="underline" cursor='pointer' _hover={{ color: "blue.100" }} onClick={() => navigate('/login')} >{t("Se connecter")}</Text>
                            </Flex>

                        </div>

                    </Flex>

                </Flex>
            </Flex>


        </Flex>
    );
}

