import { AddIcon, ChevronDownIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Checkbox, Flex, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosMenu } from 'react-icons/io';
import { PiPlusBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom';
import outOfOrder from './../assets/images/empty-cart.png'
import { LuMinus } from "react-icons/lu";
import { RiDeleteBinLine, RiFileTransferLine } from "react-icons/ri";
import { useStateContext } from '../../../../context/ContextProvider';
import axiosClient from '../../../../axios-client';
import { TbListDetails } from 'react-icons/tb';



function Suivi() {
  const { user } = useStateContext()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const toast = useToast()


  const [statusConfirmation, setStatusConfirmation] = useState('')

  const [dataOrdres, setDataOrdres] = useState([])
  const [loading, setLoading] = useState(false)

  const [userStock, setUserStock] = useState([])

  const [userStockSelected, setUserStockSelected] = useState()


  const [dataIsEmpty, setDataIsEmpty] = useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      await axiosClient.get('/commandes/').then((res) => {
        // setDataOrdres(res.data.filter(e => e.client === user.id && e.is_deleted === false && e.assigned_to === null && e.assigned_to_stock === null && e.assigned_to_suivi === null))
        setDataOrdres(res.data.filter(e => e.client === user.id && e.is_deleted === false && e.assigned_to !== null ))
        if (res.data.length === 0) {
          setDataIsEmpty(true)
        }
      })
      await axiosClient.get('/clients/').then(res => {
        setUserStock(res.data.filter(e => e.works_for === user.id && e.role_client === 'RespoSuiviClient'))
        console.log('confirmation users : ', res.data.filter(e => e.works_for === user.id && e.role_client === 'RespoSuiviClient'));
      })
      setLoading(false)
    } catch (error) {
      console.log('ERROR : ', error);
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  // État pour stocker les lignes sélectionnées
  const [selectedRows, setSelectedRows] = useState([]);

  // Fonction pour gérer le clic sur la case à cocher
  const handleCheckboxClick = (index) => {
    // Vérifie si la ligne est déjà sélectionnée
    const selectedIndex = selectedRows.indexOf(index);
    if (selectedIndex === -1) {
      // Si la ligne n'est pas déjà sélectionnée, l'ajouter à l'état
      setSelectedRows([...selectedRows, index]);
    } else {
      // Si la ligne est déjà sélectionnée, la retirer de l'état
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
    }
  };

  console.log('SELECTED ROWs : ', selectedRows);

  //ASSIGN ORDER

  // const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  // const assign_order_to = async (user) => {

  //     for (let index = 0; index < selectedRows.length; index++) {
  //         try {
  //             await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
  //                 assigned_to: user
  //             })
  //         } catch (error) {
  //             console.log('Error : ', error);
  //         }
  //     }

  //     setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
  //     onClose()
  // }

  // DELETE

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = React.useRef()
  const [deletedId, setDeletedId] = useState()

  const onOpenAlert = (id) => {
    setIsAlertOpen(true);
    setDeletedId(id)
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
    setDeletedId()
  };

  const DeleteArrayOfOrders = async () => {
    console.log('DELETED ID ; ', deletedId);
    if (deletedId !== null) {
      try {
        await axiosClient.patch(`commandes/${deletedId}/`, {
          is_deleted: true
        }).then(res => {
          setDataOrdres(dataOrdres.filter(e => e.id !== deletedId))
        })
      } catch (error) {
        console.log('ERROR : ', error);
      }
    }
    else {
      for (let index = 0; index < selectedRows.length; index++) {
        try {
          await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
            is_deleted: true
          })
        } catch (error) {
          console.log('Error : ', error);
        }
      }

      setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
    }
    onCloseAlert()
  }

  // Update status
  const [isAlertUpdateStatusOpen, setIsAlertUpdateStatusOpen] = useState(false);
  const updateStatusRef = React.useRef()
  const [statusId, setStatusId] = useState()

  const onOpenUpdateStatusAlert = (id) => {
    setIsAlertUpdateStatusOpen(true);
    setStatusId(id)
  };
  const onCloseUpdateStatusAlert = () => {
    setIsAlertUpdateStatusOpen(false);
    setStatusConfirmation('')
    setStatusId()
  };

  const HandleChangeStatus = async () => {
    try {
      await axiosClient.patch(`commandes/${statusId}/`, {
        status_confirmation: statusConfirmation
      }).then((res) => {
        setDataOrdres(dataOrdres.map((item) => {
          if (item.id === res.data.id) {
            return {
              ...item,
              status_confirmation: res.data.status_confirmation
            }
          }
          return item
        }))
        onCloseUpdateStatusAlert()
        toast({
          description: t(`Statut est changé avec succès`),
          status: 'success',
          duration: 2000,
          isClosable: true,
          variant: 'left-accent',
          position: 'bottom-right'
        })
      })
    } catch (error) {
      console.log('ERROR : ', error);
    }
  }


  //USECOLOREMODE Variables
  const bgHoverStack = useColorModeValue('gray.200', 'gray.500')
  const borderColor = useColorModeValue("gray.300", "gray.600")
  const textSM = useColorModeValue('gray.400', 'gray.500')

  return (
    <Box position="relative" my={5}>

      <Stack
        py={5}
        // mx={5}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={"2xl"}
        mt={2}
        px={{ base: 1, md: 5 }}
      >
        {!dataIsEmpty &&


          <Flex
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: 'column', lg: 'row' }}
            mb={2}
          >
            <Flex w={"100%"}>
              {user.role === ('AdminClient' || 'ManagerClient') &&
                <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={'selectedUser'} >
                  <option value="">{t("Tout les utilisateurs")}</option>
                  {userStock.map((val, key) => {
                    return (
                      <option key={key} value={val.id}>{`${val.first_name} ${val.last_name}`}</option>
                    )
                  })}
                </Select>
              }

              <Flex w={"100%"}>
                <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={'selectedUser'} >
                  <option value="">{t("Statut de confirmation")}</option>
                  <option value='Nouveau'>{t('Nouveau')}</option>
                  <option value='Pas de réponse'>{t('Pas de réponse')}</option>
                  <option value='Messagerie vocale'>{t('Messagerie vocale')}</option>
                  <option value='En attente'>{t('En attente')}</option>
                  <option value='Ligne occupée'>{t('Ligne occupée')}</option>
                  <option value='Mauvais numéro'>{t('Mauvais numéro')}</option>
                  <option value='Annulé'>{t('Annulé')}</option>
                  <option value='Confirmé'>{t('Confirmé')}</option>
                </Select>
              </Flex>
            </Flex>

            <InputGroup size="md" w={{ base: '100%', lg: "40%" }}>
              <InputLeftElement
                pointerEvents='none'
                children={<SearchIcon color={borderColor} />}
              />
              <Input
                borderColor={borderColor}
                borderRadius="5"
                placeholder={t("Recherche...")}
                value={'searchTerm'}
                mb={4}
                sx={{
                  marginLeft: 'auto',
                }}
              />  </InputGroup>
          </Flex>
        }


        {!dataIsEmpty &&
          <TableContainer>
            <Table size={{ base: 'sm', md: 'md' }} variant='simple'>
              <Thead>
                <Tr>
                  {/* <Th borderBottomColor='gray.400'>-</Th> */}
                  <Th borderBottomColor='gray.400'>{t("#ID")}</Th>
                  <Th borderBottomColor='gray.400'>{t("User confirmation")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Client")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Téléphone")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Status")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Coupons")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Prix")}</Th>
                  <Th borderBottomColor='gray.400'>{t("Options")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  {loading &&
                    <Tr>
                      {/* <Td><Skeleton h={10} w={10} /></Td> */}
                      <Td><Skeleton height='20px' /></Td>
                      <Td><Skeleton height='20px' /></Td>
                      <Td>
                        <Flex flexDirection={"column"} gap={2}>
                          <Skeleton height='20px' />
                          <Skeleton height='10px' />
                        </Flex>
                      </Td>
                      <Td>
                        <Flex flexDirection={"column"} gap={2}>
                          <Skeleton height='20px' />
                          <Skeleton height='10px' />
                          <Skeleton height='10px' />
                        </Flex>
                      </Td>
                      <Td><Skeleton height='20px' /></Td>
                      <Td><Skeleton height='20px' /></Td>
                      <Td><Skeleton height='20px' /></Td>
                      <Td><Skeleton height='20px' /></Td>
                      <Td><Skeleton height='20px' /></Td>
                    </Tr>
                  }
                  {dataOrdres.map((val, key) => {
                    const isSelected = selectedRows.includes(val.id);
                    return (
                      <Tr key={key}>
                        {/* <Td>
                                                    <Checkbox size="md" colorScheme='purple' _focus={"none"} _active={'none'}
                                                        onChange={() => handleCheckboxClick(val.id)}
                                                        isChecked={isSelected} />
                                                </Td> */}
                        <Td>
                          <Text>#{val.code}</Text>
                        </Td>
                        <Td>
                          <Text>{val.assigned_to}</Text>
                        </Td>
                        <Td>
                          <Flex flexDirection={"column"}>
                            <Text fontWeight='semibold'>{val.full_name}</Text>
                            {val.email !== "undefined" && <Text fontSize='sm' color={textSM}>{val.email}</Text>}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex flexDirection={"column"}>
                            <Text fontSize={'md'}>{val.pays}, {val.ville}</Text>
                            <Text fontSize={'sm'} color={textSM}>{val.address}</Text>
                          </Flex>
                        </Td>
                        <Td>
                          <a href={`https://wa.me/${val.tel}`} target='blank'>
                            {val.tel}
                          </a>
                        </Td>
                        <Td>
                          <Badge colorScheme={
                            val.status_confirmation !== 'Confirmé'
                              ? (['Mauvais numéro', 'Annulé'].includes(val.status_confirmation))
                                ? 'red'
                                : (val.status_confirmation === 'Nouveau')
                                  ? 'blue'
                                  : 'orange'
                              : 'green'
                          } rounded="xl" px={2} cursor='pointer' textTransform='uppercase' onClick={() => {
                            if (val?.status_stock === null) {
                              onOpenUpdateStatusAlert(val.id)
                            }
                          }}>
                            {val.status_confirmation}
                          </Badge>
                        </Td>
                        <Td>
                          <Text textAlign='center'>
                            {val.coupon ? val.coupon : '-'}
                          </Text>
                        </Td>
                        <Td>
                          <Text>
                            {val.price} DH
                          </Text>
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label='Options'
                              icon={<HamburgerIcon />}
                              variant='outline'
                            />
                            <MenuList>
                              <MenuItem icon={<TbListDetails size={18} />} onClick={() => {
                                navigate('/Detail-Gestion-confirmation', {
                                  state: {
                                    id: val.id,
                                    tel: val.tel
                                  }
                                })
                              }}>
                                Détails
                              </MenuItem>
                              <MenuItem icon={<RiDeleteBinLine size={18} />} onClick={() => {
                                onOpenAlert(val.id)
                              }}>
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    )
                  })}
                </>
              </Tbody>
            </Table>
          </TableContainer>}



        {
          dataIsEmpty &&
          <Flex flexDirection="column" alignItems='center' gap={2}>
            <Image src={outOfOrder} h={200} w={200} opacity={.5} />
            <Text color='gray.500'>Pas de commande</Text>
          </Flex>
        }
      </Stack>






      {/* Alert to Delete Order */}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {t("Supprimer")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                {t("Annuler")}
              </Button>
              <Button onClick={DeleteArrayOfOrders} colorScheme='red' ml={3}>
                {t("Supprimer")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Alert to Update confirmation status */}

      <AlertDialog
        isOpen={isAlertUpdateStatusOpen}
        leastDestructiveRef={updateStatusRef}
        onClose={onCloseUpdateStatusAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {t('Statut de confirmation')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                Ici, vous pouvez modifier le statut de confirmation.
              </Text>

              <Select
                mt={3}
                rounded='lg'
                size={"sm"}
                value={statusConfirmation}
                onChange={e => setStatusConfirmation(e.target.value)}
              >
                <option value=''>{t('Statut de confirmation')}</option>
                <option value='Pas de réponse'>{t('Pas de réponse')}</option>
                <option value='Messagerie vocale'>{t('Messagerie vocale')}</option>
                <option value='En attente'>{t('En attente')}</option>
                <option value='Ligne occupée'>{t('Ligne occupée')}</option>
                <option value='Mauvais numéro'>{t('Mauvais numéro')}</option>
                <option value='Annulé'>{t('Annulé')}</option>
                <option value='Confirmé'>{t('Confirmé')}</option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!statusConfirmation} mr={3} onClick={HandleChangeStatus}>
                Modifier
              </Button>
              <Button onClick={onCloseUpdateStatusAlert} size="sm" >Annuler</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </Box >
  )
}

export default Suivi