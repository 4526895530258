import { AddIcon, ChevronDownIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Checkbox, Flex, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosMenu } from 'react-icons/io';
import { PiPlusBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom';
import outOfOrder from './../assets/images/empty-cart.png'
import { LuMinus } from "react-icons/lu";
import { RiDeleteBinLine, RiFileTransferLine } from "react-icons/ri";
import { useStateContext } from '../../../../context/ContextProvider';
import axiosClient from '../../../../axios-client';
import { TbListDetails } from 'react-icons/tb';



function Not_Assigned_Suivi() {
    const { user } = useStateContext()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const toast = useToast()


    const [statusDelivery, setStatusDelivery] = useState('')

    const [dataOrdres, setDataOrdres] = useState([])
    const [loading, setLoading] = useState(false)

    const [userStock, setUserStock] = useState([])

    const [userStockSelected, setUserStockSelected] = useState()


    const [dataIsEmpty, setDataIsEmpty] = useState(false)

    const fetchData = async () => {
        try {
            setLoading(true)
            await axiosClient.get('/commandes/').then((res) => {
                setDataOrdres(res.data.filter(e => e.client === user.id && e.is_deleted === false && e.status_stock === "Collecté" && e.assigned_to === null))
                if (res.data.length === 0) {
                    setDataIsEmpty(true)
                }
            })
            await axiosClient.get('/clients/').then(res => {
                setUserStock(res.data.filter(e => e.works_for === user.id && e.role_client === 'StockManagerClient'))
                console.log('Stock users : ', res.data.filter(e => e.works_for === user.id && e.role_client === 'StockManagerClient'));
            })
            setLoading(false)
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    // État pour stocker les lignes sélectionnées
    const [selectedRows, setSelectedRows] = useState([]);

    // Fonction pour gérer le clic sur la case à cocher
    const handleCheckboxClick = (index) => {
        // Vérifie si la ligne est déjà sélectionnée
        const selectedIndex = selectedRows.indexOf(index);
        if (selectedIndex === -1) {
            // Si la ligne n'est pas déjà sélectionnée, l'ajouter à l'état
            setSelectedRows([...selectedRows, index]);
        } else {
            // Si la ligne est déjà sélectionnée, la retirer de l'état
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        }
    };

    console.log('SELECTED ROWs : ', selectedRows);

    //ASSIGN ORDER

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    const assign_order_to = async (user) => {

        for (let index = 0; index < selectedRows.length; index++) {
            try {
                await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                    assigned_to_stock: user
                })
            } catch (error) {
                console.log('Error : ', error);
            }
        }

        setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
        onClose()
    }

    // DELETE

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [deletedId, setDeletedId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setDeletedId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setDeletedId()
    };

    const DeleteArrayOfOrders = async () => {
        console.log('DELETED ID ; ', deletedId);
        if (deletedId !== null) {
            try {
                await axiosClient.patch(`commandes/${deletedId}/`, {
                    is_deleted: true
                }).then(res => {
                    setDataOrdres(dataOrdres.filter(e => e.id !== deletedId))
                })
            } catch (error) {
                console.log('ERROR : ', error);
            }
        }
        else {
            for (let index = 0; index < selectedRows.length; index++) {
                try {
                    await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                        is_deleted: true
                    })
                } catch (error) {
                    console.log('Error : ', error);
                }
            }

            setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
        }
        onCloseAlert()
    }

    // Update status
    const [isAlertUpdateStatusOpen, setIsAlertUpdateStatusOpen] = useState(false);
    const updateStatusRef = React.useRef()
    const [statusId, setStatusId] = useState()

    const onOpenUpdateStatusAlert = (id) => {
        setIsAlertUpdateStatusOpen(true);
        setStatusId(id)
    };
    const onCloseUpdateStatusAlert = () => {
        setIsAlertUpdateStatusOpen(false);
        setStatusDelivery('')
        setStatusId()
    };

    const HandleChangeStatus = async () => {
        try {
            await axiosClient.patch(`commandes/${statusId}/`, {
                status_delivery: statusDelivery
            }).then((res) => {
                setDataOrdres(dataOrdres.map((item) => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            status_delivery: res.data.status_delivery
                        }
                    }
                    return item
                }))
                onCloseUpdateStatusAlert()
                toast({
                    description: t(`Statut est changé avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }


    //USECOLOREMODE Variables
    const bgHoverStack = useColorModeValue('gray.200', 'gray.500')
    const textSM = useColorModeValue('gray.400', 'gray.500')

    return (
        <Box position="relative" my={5}>


            <Stack
                px={2}
                py={5}
                // mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"xl"}
                mt={2}
            >
                {!dataIsEmpty &&
                    <Flex
                        justifyContent={"space-between"}
                        alignItems="center"
                        w={"100%"}
                        px={4}
                    >
                        <Flex alignItems='center' gap={5}>
                            <Checkbox
                                size="lg"
                                colorScheme='purple'
                                _focus={"none"}
                                _active={'none'}
                                icon={<LuMinus />}
                            />

                            {selectedRows.length !== 0 &&
                                <Flex>
                                    <Stack p={3} rounded='full' cursor='pointer' title='Affecter' _hover={{
                                        background: bgHoverStack
                                    }}
                                        onClick={onOpen}>
                                        <RiFileTransferLine size={20} />
                                    </Stack>
                                    <Stack p={3} rounded='full' cursor='pointer' title='Supprimer' _hover={{
                                        background: bgHoverStack
                                    }}
                                        onClick={() => onOpenAlert(null)}>
                                        <RiDeleteBinLine size={20} />
                                    </Stack>
                                </Flex>
                            }

                        </Flex>
                        <InputGroup mb={0} size="md" w={{ base: '80%', md: "60%", lg: "40%" }} borderColor={"gray.400"}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray' />}
                            />
                            <Input

                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />
                        </InputGroup>
                    </Flex>
                }


                {!dataIsEmpty &&
                    <TableContainer>
                        <Table size={{ base: 'sm', md: 'md' }} variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th borderBottomColor='gray.400'>-</Th>
                                    <Th borderBottomColor='gray.400'>{t("#ID")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Client")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Téléphone")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Status")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Coupons")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Prix")}</Th>
                                    <Th borderBottomColor='gray.400'>{t("Options")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <>
                                    {loading &&
                                        <Tr>
                                            <Td><Skeleton h={10} w={10} /></Td>
                                            <Td><Skeleton height='20px' /></Td>
                                            <Td>
                                                <Flex flexDirection={"column"} gap={2}>
                                                    <Skeleton height='20px' />
                                                    <Skeleton height='10px' />
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Flex flexDirection={"column"} gap={2}>
                                                    <Skeleton height='20px' />
                                                    <Skeleton height='10px' />
                                                    <Skeleton height='10px' />
                                                </Flex>
                                            </Td>
                                            <Td><Skeleton height='20px' /></Td>
                                            <Td><Skeleton height='20px' /></Td>
                                            <Td><Skeleton height='20px' /></Td>
                                            <Td><Skeleton height='20px' /></Td>
                                            <Td><Skeleton height='20px' /></Td>
                                        </Tr>
                                    }
                                    {dataOrdres.map((val, key) => {
                                        const isSelected = selectedRows.includes(val.id);
                                        return (
                                            <Tr key={key} >
                                                <Td>
                                                    <Checkbox size="md" colorScheme='purple' _focus={"none"} _active={'none'}
                                                        onChange={() => handleCheckboxClick(val.id)}
                                                        isChecked={isSelected} />
                                                </Td>
                                                <Td>
                                                    <Text>#{val.code}</Text>
                                                </Td>
                                                <Td>
                                                    <Flex flexDirection={"column"}>
                                                        <Text fontWeight='semibold'>{val.full_name}</Text>
                                                        {val.email !== "undefined" && <Text fontSize='sm' color={textSM}>{val.email}</Text>}
                                                    </Flex>
                                                </Td>
                                                <Td>
                                                    <Flex flexDirection={"column"}>
                                                        <Text fontSize={'md'}>{val.pays}, {val.ville}</Text>
                                                        <Text fontSize={'sm'} color={textSM}>{val.address}</Text>
                                                    </Flex>
                                                </Td>
                                                <Td>
                                                    <a href={`https://wa.me/${val.tel}`} target='blank'>
                                                        {val.tel}
                                                    </a>
                                                </Td>
                                                <Td>
                                                    <Badge colorScheme={
                                                        val?.status_delivery !== 'Collecté'
                                                            ? (['Épuisé', 'Annulé'].includes(val?.status_delivery))
                                                                ? 'red'
                                                                : ([null, ''].includes(val?.status_delivery))
                                                                    ? 'blue'
                                                                    : 'orange'
                                                            : 'green'
                                                    } rounded="xl" px={2} cursor='pointer' textTransform='uppercase' onClick={() => { onOpenUpdateStatusAlert(val.id) }}>
                                                        {val?.status_delivery ? val?.status_delivery : 'Nouveau'}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <Text textAlign='center'>
                                                        {val.coupon ? val.coupon : '-'}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text>
                                                        {val.price} DH
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton
                                                            as={IconButton}
                                                            aria-label='Options'
                                                            icon={<HamburgerIcon />}
                                                            variant='outline'
                                                        />
                                                        <MenuList>
                                                            <MenuItem icon={<TbListDetails size={18} />} onClick={() => {
                                                                navigate('/Detail-Gestion-confirmation', {
                                                                    state: {
                                                                        id: val.id,
                                                                        tel: val.tel
                                                                    }
                                                                })
                                                            }}>
                                                                Détails
                                                            </MenuItem>
                                                            <MenuItem icon={<RiFileTransferLine size={18} />} onClick={() => {
                                                                handleCheckboxClick(val.id)
                                                                onOpen()
                                                            }}>
                                                                Attribuer
                                                            </MenuItem>
                                                            <MenuItem icon={<RiDeleteBinLine size={18} />} onClick={() => {
                                                                onOpenAlert(val.id)
                                                            }}>
                                                                Supprimer
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </>
                            </Tbody>
                        </Table>
                    </TableContainer>}



                {
                    dataIsEmpty &&
                    <Flex flexDirection="column" alignItems='center' gap={2}>
                        <Image src={outOfOrder} h={200} w={200} opacity={.5} />
                        <Text color='gray.500'>Pas de commande</Text>
                    </Flex>
                }
            </Stack>



            {/* Modal to assign order */}
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>attribuer la commande</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody pb={6}>
                        <Text>
                            Ici, vous pouvez attribuer une commande spécifique à un responsable de stock.
                        </Text>

                        <Select
                            mt={3}
                            rounded='lg'
                            size={"sm"}
                            value={userStockSelected}
                            onChange={e => setUserStockSelected(e.target.value)}
                        >
                            <option value=''>Utilisateurs de confirmation</option>
                            {userStock.map((val, key) => {
                                return (
                                    <option key={key} value={val.id}>{`${val.first_name} ${val.last_name}`}</option>
                                )
                            })}
                        </Select>

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!userStockSelected} mr={3} onClick={() => assign_order_to(userStockSelected)}>
                            Attribuer
                        </Button>
                        <Button onClick={onClose} size="sm" >Annuler</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/* Alert to Delete Order */}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={DeleteArrayOfOrders} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Alert to Update confirmation status */}

            <AlertDialog
                isOpen={isAlertUpdateStatusOpen}
                leastDestructiveRef={updateStatusRef}
                onClose={onCloseUpdateStatusAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Statut de livraison')}
                        </AlertDialogHeader>


                        <AlertDialogBody>
                            <Text>
                                Ici, vous pouvez modifier le statut de livraison.
                            </Text>

                            <Select
                                mt={3}
                                rounded='lg'
                                size={"sm"}
                                value={statusDelivery}
                                onChange={e => setStatusDelivery(e.target.value)}
                            >
                                <option value="">{t("Statut de livraison")}</option>
                                <option value='Ramassé'>{t('Ramassé')}</option>
                                <option value='Expédié'>{t('Expédié')}</option>
                                <option value='Reçu à destination'>{t('Reçu à destination')}</option>
                                <option value='Pendant la distribution'>{t('Pendant la distribution')}</option>
                                <option value='Programmé'>{t('Programmé')}</option>
                                <option value='Hors zone'>{t('Hors zone')}</option>
                                <option value='Non atteint'>{t('Non atteint')}</option>
                                <option value='Annulé'>{t('Annulé')}</option>
                                <option value='Refusé'>{t('Refusé')}</option>
                                <option value='Livré'>{t('Livré')}</option>
                                <option value='Préparation du retour'>{t('Préparation du retour')}</option>
                                <option value="Retour à l'agence">{t("Retour à l'agence")}</option>
                                <option value="Retour à l'entrepôt">{t("Retour à l'entrepôt")}</option>
                            </Select>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!statusDelivery} mr={3} onClick={HandleChangeStatus}>
                                Modifier
                            </Button>
                            <Button onClick={onCloseUpdateStatusAlert} size="sm" >Annuler</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box >
    )
}

export default Not_Assigned_Suivi