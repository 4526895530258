import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../axios-client";
import { MdAdd, MdDelete, MdEdit, MdOutlineHeadsetMic } from "react-icons/md";
import { GrDeliver } from "react-icons/gr";
import { FaBoxOpen } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import { FiEdit3 } from "react-icons/fi";
import { TiShoppingCart } from "react-icons/ti";
import ReactSelect from "react-select";
import { RiCoupon3Fill } from "react-icons/ri";
import { IoMdPricetags } from "react-icons/io";
import { BsCart4 } from "react-icons/bs";

function Details_GestConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useStateContext();
  const toast = useToast();

  const [statusConfirmation, setStatusConfirmation] = useState("");
  const [statusStock, setStatusStock] = useState("");
  const [statusDelivery, setStatusDelivery] = useState("");
  const [loading, setLoading] = useState(false);

  const [detailOrder, setDetailOrder] = useState();
  const [productsOrderData, setProductsOrderData] = useState([]);

  const [quantityTotal, setQuantityTotal] = useState(0);

  const [dataCoupon, setDataCoupon] = useState([]);
  const [couponSelected, setCouponSelected] = useState();

  const [dataInfoClient, setDataInfoClient] = useState([]);

  const [nomCompletClient, setNomCompletClient] = useState();
  const [emailClient, setEmailClient] = useState();
  const [paysClient, setPaysClient] = useState();
  const [villeClient, setVilleClient] = useState();
  const [adresseClient, setAdresseClient] = useState();
  const [phoneClient, setPhoneClient] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      await axiosClient
        .get(`commandes/${location.state.id}/`)
        .then(async (res) => {
          setDetailOrder(res.data);
          setNomCompletClient(res.data.full_name || "");
          setEmailClient(res.data.email || "");
          setPaysClient(res.data.pays || "");
          setVilleClient(res.data.ville || "");
          setAdresseClient(res.data.address || "");
          setPhoneClient(res.data.tel || "");
          await axiosClient
            .get(
              `/commandes/getdataOldOrdersClient/?id=${location.state.id}&clientPhone=${location.state.tel}`
            )
            .then((res) => {
              setDataInfoClient(res.data);
            });
        });
      await axiosClient
        .get(`/produitCommande/getProductsOrder/?commande=${location.state.id}`)
        .then((res) => {
          setProductsOrderData(res.data);
          const data = res.data;
          setQuantityTotal(res.data.quantity);
          let total = 0;
          for (let index = 0; index < data.length; index++) {
            total = total + data[index].quantity;
          }
          setQuantityTotal(total);
        });
      await axiosClient
        .get(`coupons/get_All_Coupon_names/?client=${user.id}`)
        .then((res) => {
          setDataCoupon(res.data);
        });
    } catch (error) {
      console.log("ERROR : ", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Update Infos Client
  const [isModalEditInfosClientOpen, setIsModalEditInfosClientOpen] =
    useState(false);
  const ModalEditInfosClientRef = React.useRef();

  const onOpenModalEditInfosClient = () => {
    setIsModalEditInfosClientOpen(true);
  };
  const onCloseModalEditInfosClient = () => {
    setIsModalEditInfosClientOpen(false);
  };

  const updateInfosClient = async () => {
    try {
      const formData = new FormData();

      nomCompletClient && formData.append("full_name", nomCompletClient);
      emailClient && formData.append("email", emailClient);
      adresseClient && formData.append("address", adresseClient);
      paysClient && formData.append("pays", paysClient);
      villeClient && formData.append("ville", villeClient);
      phoneClient && formData.append("tel", phoneClient);

      await axiosClient
        .patch(`commandes/${location.state.id}/`, formData)
        .then((res) => {
          toast({
            description: t("Infos modifiées avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });

          setDetailOrder(res.data);

          setNomCompletClient(res.data.full_name || "");
          setEmailClient(res.data.email || "");
          setPaysClient(res.data.pays || "");
          setVilleClient(res.data.ville || "");
          setAdresseClient(res.data.address || "");
          setPhoneClient(res.data.tel || "");

          onCloseModalEditInfosClient();
        });
    } catch (error) {
      console.error("ERROR : ", error);
    }
  };

  // Update status confirmation

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = React.useRef();
  const [statusId, setStatusId] = useState();

  const onOpenAlert = (id) => {
    setIsAlertOpen(true);
    setStatusId(id);
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
    setStatusConfirmation("");
    setStatusId();
  };

  const HandleChangeStatus = async () => {
    try {
      await axiosClient
        .patch(`commandes/${location.state.id}/`, {
          status_confirmation: statusConfirmation,
        })
        .then((res) => {
          setDetailOrder((prevData) => {
            return {
              ...prevData,
              status_confirmation: res.data.status_confirmation,
            };
          });
          // setDataOrder(dataOrder.map((item) => {
          //     if (item.id === res.data.id) {
          //         return {
          //             ...item,
          //             status_confirmation: res.data.status_confirmation
          //         }
          //     }
          //     return item
          // }))
          onCloseAlert();
          toast({
            description: t("Statut est changé avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  // Update status stock

  const [isAlertOpenStatusStock, setIsAlertOpenStatusStock] = useState(false);
  const cancelRefStatusStock = React.useRef();

  const onOpenAlertStatusStock = () => {
    setIsAlertOpenStatusStock(true);
  };

  const onCloseAlertStatusStock = () => {
    setIsAlertOpenStatusStock(false);
    setStatusStock("");
  };

  const HandleChangeStatusStock = async () => {
    try {
      await axiosClient
        .patch(`commandes/${location.state.id}/`, {
          status_stock: statusStock,
        })
        .then((res) => {
          setDetailOrder((prevData) => {
            return {
              ...prevData,
              status_stock: res.data.status_stock,
            };
          });
          // setDataOrder(dataOrder.map((item) => {
          //     if (item.id === res.data.id) {
          //         return {
          //             ...item,
          //             status_confirmation: res.data.status_confirmation
          //         }
          //     }
          //     return item
          // }))
          onCloseAlertStatusStock();
          toast({
            description: t("Statut est changé avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  // Update status delivery

  const [isAlertOpenStatusDelivery, setIsAlertOpenStatusDelivery] =
    useState(false);
  const cancelRefStatusDelivery = React.useRef();

  const onOpenAlertStatusDelivery = () => {
    setIsAlertOpenStatusDelivery(true);
  };

  const onCloseAlertStatusDelivery = () => {
    setIsAlertOpenStatusDelivery(false);
    setStatusDelivery("");
  };

  const HandleChangeStatusDelivery = async () => {
    try {
      await axiosClient
        .patch(`commandes/${location.state.id}/`, {
          status_delivery: statusDelivery,
        })
        .then((res) => {
          setDetailOrder((prevData) => {
            return {
              ...prevData,
              status_delivery: res.data.status_delivery,
            };
          });
          // setDataOrder(dataOrder.map((item) => {
          //     if (item.id === res.data.id) {
          //         return {
          //             ...item,
          //             status_confirmation: res.data.status_confirmation
          //         }
          //     }
          //     return item
          // }))
          onCloseAlertStatusDelivery();
          toast({
            description: t("Statut est changé avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  // Add or Update Coupon Command

  const [isAlertOpenCoupon, setIsAlertOpenCoupon] = useState(false);
  const cancelRefCoupon = React.useRef();

  const onOpenAlertCoupon = () => {
    setIsAlertOpenCoupon(true);
  };

  const onCloseAlertCoupon = () => {
    setIsAlertOpenCoupon(false);
    setCouponSelected();
  };

  const HandleChangeCoupon = async () => {
    const reduction = dataCoupon.find(
      (e) => e.id === parseInt(couponSelected)
    ).reduction;
    console.log(
      "Réduction : ",
      dataCoupon.find((e) => e.id === parseInt(couponSelected)).reduction
    );
    try {
      await axiosClient
        .patch(`commandes/${location.state.id}/`, {
          coupon: couponSelected,
          priceWithCoupon:
            parseFloat(detailOrder?.price) -
            (parseFloat(detailOrder?.price) * reduction) / 100,
        })
        .then((res) => {
          setDetailOrder((prevData) => {
            return {
              ...prevData,
              coupon: res.data.coupon,
              couponTitle: res.data.couponTitle,
              priceWithCoupon: res.data.priceWithCoupon,
            };
          });
          // setDataOrder(dataOrder.map((item) => {
          //     if (item.id === res.data.id) {
          //         return {
          //             ...item,
          //             status_confirmation: res.data.status_confirmation
          //         }
          //     }
          //     return item
          // }))
          setCouponSelected();
          onCloseAlertCoupon();
          toast({
            description: t("Coupon ajouté avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  const deleteCoupon = async () => {
    try {
      await axiosClient
        .patch(`commandes/${location.state.id}/`, {
          coupon: null,
          priceWithCoupon: null,
        })
        .then((res) => {
          setDetailOrder((prevData) => {
            return {
              ...prevData,
              coupon: null,
              couponTitle: null,
            };
          });
          // setDataOrder(dataOrder.map((item) => {
          //     if (item.id === res.data.id) {
          //         return {
          //             ...item,
          //             status_confirmation: res.data.status_confirmation
          //         }
          //     }
          //     return item
          // }))
          onCloseAlertCoupon();
          toast({
            description: t("Coupon supprimé avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  //Drawer

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = React.useRef();

  const onOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Modal Add Produt

  //Variables

  // Get Product
  const [optionsTitre, setOptionsTitre] = useState([]);
  const [productSelected, setProductSelected] = useState();

  const fetchProducts = async () => {
    let optionsTitreData = [];
    await axiosClient.get(`/products/get_All_Products/?client=${user.id}`).then((res) => {
      for (let index = 0; index < res.data.length; index++) {
        optionsTitreData.push({
          value: res.data[index].id,
          label: res.data[index].title,
        });
      }
    });
    setOptionsTitre(optionsTitreData);
  };

  useEffect(() => {
    fetchProducts();
  }, [user]);

  const [dataProducts, setDataProducts] = useState([]);
  const [colorSelected, setColorSelected] = useState([]);
  const [sizeSelected, setSizeSelected] = useState([]);
  const [imageProduct, setImageProduct] = useState();
  const [colorInput, setColorInput] = useState();
  const [sizeInput, setSizeInput] = useState();
  const [quantityInput, setQuantityInput] = useState();
  const [purchase_PriceInput, setPurchase_PriceInput] = useState();

  useEffect(() => {
    if (productSelected) {
      try {
        let colorOptions = [];
        let sizeOptions = [];
        axiosClient.get(`products/${productSelected.value}/`).then((res) => {
          setDataProducts(res.data);
          setImageProduct(res.data?.images[0]?.image);
          //   for (let index = 0; index < res.data.stock.length; index++) {
          //     if (res.data.stock[index].color.length !== 0) {
          //       colorOptions.push({
          //         value: res.data.stock[index].id,
          //         label: res.data.stock[index].color,
          //       });
          //     }

          //     if (res.data.stock[index].size) {
          //       sizeOptions.push({
          //         value: res.data.stock[index].id,
          //         label: res.data.stock[index].size,
          //       });
          //     }
          //   }

        //   const produit = res.data.find((e) => e.id === productSelected.value);
          console.log('PPPPPPPPPPPPP ! ',res.data);
          // GET COLORS
          if (res.data) {
            // Utilisation d'un Set pour éviter les duplications en ignorant la casse
            const uniqueColors = new Set(
                res.data.stock
                .filter((e) => e.color.length !== 0)
                .map((val) => val.color.toLowerCase()) // Conversion en minuscule
            );

            // Recréer le tableau avec les couleurs uniques, en gardant le format original
            colorOptions = [...uniqueColors].map((color) => ({
              value: res.data.stock.find(
                (val) => val.color.toLowerCase() === color
              )?.id,
              label: color.charAt(0).toUpperCase() + color.slice(1), // Rendre la première lettre en majuscule
            }));
          }

          // GET SIZES      
          if (res.data) {
            // Utilisation d'un Set pour éviter les duplications en ignorant la casse
            const uniqueSizes = new Set(
                res.data.stock
                .filter((e) => e.size.length !== 0)
                .map((val) => val.size.toLowerCase()) // Conversion en minuscule
            );
      
            // Recréer le tableau avec les tailles uniques, en gardant le format original
            sizeOptions = [...uniqueSizes].map((size) => ({
              value: res.data.stock.find((val) => val.size.toLowerCase() === size)?.id,
              label: size.charAt(0).toUpperCase() + size.slice(1), // Rendre la première lettre en majuscule
            }));
          }
          console.log('colorOptionscolorOptions : ',colorOptions);
          console.log('sizeOptionssizeOptions : ',sizeOptions);

          setColorSelected(colorOptions);
          setSizeSelected(sizeOptions);
        });
      } catch (error) {
        console.log("ERROR : ", error);
      }
    }
  }, [productSelected]);

  const addProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("product", productSelected.value);
      formData.append("commande", location.state.id);
      formData.append("color", colorInput ? colorInput.label : "");
      formData.append("size", sizeInput ? sizeInput.label : "");
      formData.append("quantity", quantityInput);
      formData.append("price", purchase_PriceInput);
      await axiosClient
        .post(`produitCommande/`, formData)
        .then(async (res) => {
          try {
            const newPrice =
              parseInt(detailOrder?.price) +
              purchase_PriceInput * quantityInput;
            await axiosClient
              .patch(`commandes/${location.state.id}/`, {
                price: newPrice,
                priceWithCoupon:
                  detailOrder?.coupon &&
                  newPrice - (newPrice * detailOrder?.couponReduction) / 100,
              })
              .then((res) => {
                setDetailOrder((prevData) => {
                  return {
                    ...prevData,
                    price: res.data.price,
                    priceWithCoupon:
                      detailOrder?.coupon && res.data.priceWithCoupon,
                  };
                });
                toast({
                  description: t("Produit est ajouté avec succès"),
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  variant: "left-accent",
                  position: "bottom-right",
                });
              });
            setProductsOrderData([...productsOrderData, res.data]);
            setQuantityTotal(quantityTotal + parseInt(quantityInput));
            onCloseModalAddProduct();
          } catch (error) {}
        })
        .catch((err) => {
          console.log("ERROR Catch ! ", err);
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  const [isModalAddProductOpen, setIsModalAddProductOpen] = useState(false);
  const ModalAddProductRef = React.useRef();

  const onOpenModalAddProduct = () => {
    setIsModalAddProductOpen(true);
  };
  const onCloseModalAddProduct = () => {
    setIsModalAddProductOpen(false);
    setProductSelected();
    setDataProducts([]);
    setColorInput();
    setSizeInput();
    setQuantityInput();
    setPurchase_PriceInput();
  };

  // Modal edit Product

  const [isModalEditProductOpen, setIsModalEditProductOpen] = useState(false);
  const ModalEditProductRef = React.useRef();
  const [idProduct, setIdProduct] = useState();
  const [totalAchat, setTotalAchat] = useState();
  const [oldQtt, setOldQtt] = useState();
  const [oldPurchase_PriceInput, setOldPurchase_PriceInput] = useState();
  const [loadingUpdateModal, setLoadingUpdateModal] = useState(false);

  const onOpenModalEditProduct = () => {
    setIsModalEditProductOpen(true);
  };
  const onCloseModalEditProduct = () => {
    setIsModalEditProductOpen(false);
    setProductSelected();
    setDataProducts([]);
    setColorInput();
    setSizeInput();
    setQuantityInput();
    setPurchase_PriceInput();
    setIdProduct();
    setTotalAchat();
    setOldQtt();
    setOldPurchase_PriceInput();
  };

  const [getDataProduct_IdProduct, setGetDataProduct_IdProduct] = useState([]);

  useEffect(() => {
    if (idProduct) {
      setLoadingUpdateModal(true);
      axiosClient.get(`produitCommande/${idProduct}/`).then(async (res) => {
        await setGetDataProduct_IdProduct(res.data);
        let colorOptions = [];
        let sizeOptions = [];
        if (res.data) {
            // Utilisation d'un Set pour éviter les duplications en ignorant la casse
            const uniqueColors = new Set(
                res.data.produit?.stock
                .filter((e) => e.color.length !== 0)
                .map((val) => val.color.toLowerCase()) // Conversion en minuscule
            );

            // Recréer le tableau avec les couleurs uniques, en gardant le format original
            colorOptions = [...uniqueColors].map((color) => ({
              value: res.data.produit?.stock.find(
                (val) => val.color.toLowerCase() === color
              )?.id,
              label: color.charAt(0).toUpperCase() + color.slice(1), // Rendre la première lettre en majuscule
            }));
          }

          // GET SIZES      
          if (res.data) {
            // Utilisation d'un Set pour éviter les duplications en ignorant la casse
            const uniqueSizes = new Set(
                res.data.produit?.stock
                .filter((e) => e.size.length !== 0)
                .map((val) => val.size.toLowerCase()) // Conversion en minuscule
            );
      
            // Recréer le tableau avec les tailles uniques, en gardant le format original
            sizeOptions = [...uniqueSizes].map((size) => ({
              value: res.data.produit?.stock.find((val) => val.size.toLowerCase() === size)?.id,
              label: size.charAt(0).toUpperCase() + size.slice(1), // Rendre la première lettre en majuscule
            }));
          }
          console.log('colorOptionscolorOptions : ',colorOptions);
          console.log('sizeOptionssizeOptions : ',sizeOptions);

          setColorSelected(colorOptions);
          setSizeSelected(sizeOptions);
        
        setLoadingUpdateModal(false);
      });
    }
  }, [idProduct]);

  const EditProductOrder = async () => {
    try {
      const qte = quantityInput ? quantityInput : oldQtt;
      const prix = purchase_PriceInput
        ? purchase_PriceInput
        : oldPurchase_PriceInput;
      const formData = new FormData();
      {
        colorInput &&
          formData.append("color", colorInput ? colorInput.label : "");
      }
      {
        sizeInput && formData.append("size", sizeInput ? sizeInput.label : "");
      }
      {
        quantityInput && formData.append("quantity", parseInt(quantityInput));
      }
      {
        purchase_PriceInput &&
          formData.append("price", parseFloat(purchase_PriceInput));
      }
      await axiosClient
        .patch(`produitCommande/${idProduct}/`, formData)
        .then(async (res) => {
          setProductsOrderData(
            productsOrderData.map((val) => {
              if (val.id === res.data.id) {
                return {
                  ...val,
                  color: res.data.color,
                  size: res.data.size,
                  quantity: res.data.quantity,
                  price: res.data.price,
                };
              }
              return val;
            })
          );

          if (quantityInput || purchase_PriceInput) {
            quantityInput &&
              setQuantityTotal(
                quantityTotal - parseInt(oldQtt) + parseInt(quantityInput)
              );
            await axiosClient
              .patch(`commandes/${location.state.id}/`, {
                price: parseInt(detailOrder?.price) - totalAchat + prix * qte,
                priceWithCoupon:
                  detailOrder?.coupon &&
                  parseInt(detailOrder?.price) -
                    totalAchat +
                    prix * qte -
                    ((parseInt(detailOrder?.price) - totalAchat + prix * qte) *
                      detailOrder?.couponReduction) /
                      100,
              })
              .then((res) => {
                setDetailOrder((prevData) => {
                  return {
                    ...prevData,
                    price: res.data.price,
                    priceWithCoupon:
                      detailOrder?.coupon && res.data.priceWithCoupon,
                  };
                });
                onCloseModalEditProduct();
              });
          } else {
            onCloseModalEditProduct();
          }
          toast({
            description: t("Produit est modifié avec succès"),
            status: "success",
            duration: 2000,
            isClosable: true,
            variant: "left-accent",
            position: "bottom-right",
          });
        })
        .catch((err) => {
          console.log("ERROR Catch ! ", err);
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  // Delete Product
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const deleteAlertRef = React.useRef();
  const [deletedId, setDeletedId] = useState();

  const onOpenDeleteAlert = (id) => {
    setDeleteAlertOpen(true);
    setDeletedId(id);
  };
  const onCloseDeleteAlert = () => {
    setDeleteAlertOpen(false);
    setDeletedId();
  };

  const DeleteProduct = async () => {
    try {
      await axiosClient
        .delete(`produitCommande/${deletedId}/`)
        .then(async () => {
          await axiosClient
            .patch(`commandes/${location.state.id}/`, {
              price: parseInt(detailOrder?.price) - totalAchat,
              priceWithCoupon:
                detailOrder?.coupon &&
                parseInt(detailOrder?.price) -
                  totalAchat -
                  ((parseInt(detailOrder?.price) - totalAchat) *
                    detailOrder?.couponReduction) /
                    100,
            })
            .then((res) => {
              setDetailOrder((prevData) => {
                return {
                  ...prevData,
                  price: res.data.price,
                  priceWithCoupon:
                    detailOrder?.coupon && res.data.priceWithCoupon,
                };
              });
              setQuantityTotal(quantityTotal - parseInt(oldQtt));
              setProductsOrderData(
                productsOrderData.filter((e) => e.id !== deletedId)
              );
              setTotalAchat();
              onCloseDeleteAlert();
              toast({
                description: t("Produit est supprimé avec succès"),
                status: "success",
                duration: 2000,
                isClosable: true,
                variant: "left-accent",
                position: "bottom-right",
              });
            });
        });
    } catch (error) {
      console.log("ERROR : ", error);
    }
  };

  const btnMore = useColorModeValue("purple.400", "purple.200");
  const hoverBtnMore = useColorModeValue("purple.600", "purple.400");
  const menuItemDelete = useColorModeValue("red", "red.300");
  const bgAddProduct = useColorModeValue("gray.200", "gray.600");
  const textAddProduct = useColorModeValue("gray.600", "gray.200");

  return (
    <Box position={"relative"} mb={5}>
      <Flex>
        <Box
          w="100%"
          display={"flex"}
          justifyContent="space-between"
          flexDirection={{ base: "column", md: "row" }}
          mt={2}
          mb={{ base: 0, md: 5 }}
        >
          <Breadcrumb
            fontFamily="Plus Jakarta Display"
            fontStyle={"italic"}
            fontSize={{ base: "20px", sm: "25px", lg: "27px" }}
            color="gray"
          >
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => navigate("/")}>
                {t("Accueil")}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{t("Détails commande")}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Flex>

      <Box
        bg={useColorModeValue("white", "gray.700")}
        p={5}
        rounded="xl"
        mx={5}
      >
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          gap={{ base: 5, md: 0 }}
        >
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            alignItems={"center"}
            gap={4}
          >
            <Avatar h={70} w={70} rounded="xl" position={"relative"}>
              <Flex
                bg="red"
                height={6}
                width={6}
                rounded="full"
                position="absolute"
                top={-2}
                right={-2}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                border="2px solid"
                borderColor={useColorModeValue("white", "gray.700")}
                background="#a0aec0"
                onClick={onOpenModalEditInfosClient}
              >
                <MdEdit
                  color={useColorModeValue("white", "gray.700")}
                  size={16}
                />
              </Flex>
              {/* <MdEdit color='black' cursor='pointer' size={15} style={{
                                background:'red',
                                borderRadius:'50%',
                                height:40,
                                width:70
                            }}/> */}
            </Avatar>
            <Flex flexDirection={"column"}>
              <Text fontSize={15} fontWeight="semibold">
                {detailOrder?.full_name}
              </Text>
              <Text fontSize={15}>{detailOrder?.tel}</Text>
              <Text
                fontSize={13}
                color={useColorModeValue("gray.400", "gray.500")}
              >{`${detailOrder?.pays}, ${detailOrder?.ville}, ${detailOrder?.address}`}</Text>
              {detailOrder?.email !== "undefined" && (
                <Text fontSize={13} color={"lightgray"}>
                  {detailOrder?.email}
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            gap={3}
            align="center"
          >
            {t(`${detailOrder?.status_confirmation}`) !== t("Confirmé") &&
              (detailOrder?.status_stock === null ||
                detailOrder?.status_stock === "") &&
              (detailOrder?.status_delivery === null ||
                detailOrder?.status_delivery === "") && (
                <Badge
                  colorScheme={
                    t(`${detailOrder?.status_confirmation}`) !== t("Confirmé")
                      ? [t("Mauvais numéro"), t("Annulé")].includes(
                          t(`${detailOrder?.status_confirmation}`)
                        )
                        ? "red"
                        : t(`${detailOrder?.status_confirmation}`) ===
                          t("Nouveau")
                        ? "blue"
                        : "orange"
                      : "green"
                  }
                  rounded="xl"
                  textAlign="center"
                  minW={120}
                  py={3}
                  px={3}
                  cursor="pointer"
                  textTransform="uppercase"
                  onClick={() => {
                    if (
                      detailOrder?.assigned_to === user?.id ||
                      ["AdminClient", "ManagerClient"].includes(user.role)
                    ) {
                      onOpenAlert();
                    }
                  }}
                >
                  <Flex gap={2}>
                    <MdOutlineHeadsetMic size={18} />
                    <Text>{t(`${detailOrder?.status_confirmation}`)}</Text>
                  </Flex>
                </Badge>
              )}
            {detailOrder?.status_stock !== "Collecté" &&
              detailOrder?.status_confirmation === "Confirmé" &&
              (detailOrder?.status_delivery === null ||
                detailOrder?.status_delivery === "") && (
                <Badge
                  colorScheme={
                    detailOrder?.status_stock !== "Collecté"
                      ? ["Épuisé", "Annulé"].includes(detailOrder?.status_stock)
                        ? "red"
                        : [null, ""].includes(detailOrder?.status_stock)
                        ? "blue"
                        : "orange"
                      : "green"
                  }
                  rounded="xl"
                  textAlign="center"
                  minW={120}
                  py={3}
                  px={3}
                  cursor="pointer"
                  textTransform="uppercase"
                  onClick={() => {
                    if (
                      detailOrder?.assigned_to_stock === user?.id ||
                      ["AdminClient", "ManagerClient"].includes(user.role)
                    ) {
                      onOpenAlertStatusStock();
                    }
                  }}
                >
                  <Flex gap={2}>
                    <FaBoxOpen size={18} />
                    <Text>
                      {detailOrder?.status_stock
                        ? t(`${detailOrder?.status_stock}`)
                        : t("Nouveau")}
                    </Text>
                  </Flex>
                </Badge>
              )}
            {detailOrder?.status_stock === "Collecté" && (
              <Badge
                colorScheme={
                  detailOrder?.status_delivery !== "Livré"
                    ? ["Refusé", "Annulé"].includes(
                        detailOrder?.status_delivery
                      )
                      ? "red"
                      : detailOrder?.status_delivery === "Nouveau" ||
                        detailOrder?.status_delivery === "" ||
                        detailOrder?.status_delivery === null
                      ? "blue"
                      : "orange"
                    : "green"
                }
                rounded="xl"
                textAlign="center"
                minW={120}
                py={3}
                px={3}
                cursor="pointer"
                textTransform="uppercase"
                onClick={() => {
                  if (
                    detailOrder?.assigned_to === user?.id ||
                    ["AdminClient", "ManagerClient"].includes(user.role)
                  ) {
                    onOpenAlertStatusDelivery();
                  }
                }}
              >
                <Flex gap={2}>
                  <GrDeliver size={18} />
                  <Text>
                    {detailOrder?.status_delivery
                      ? t(`${detailOrder?.status_delivery}`)
                      : t("Nouveau")}
                  </Text>
                </Flex>
              </Badge>
            )}
            <Badge
              colorScheme="purple"
              rounded="xl"
              textAlign="center"
              minW={120}
              py={3}
              px={3}
              cursor={
                detailOrder?.status_confirmation !== "Confirmé" && "pointer"
              }
              textTransform="uppercase"
              onClick={() => {
                {
                  if (
                    t(`${detailOrder?.status_confirmation}`) !==
                      t("Confirmé") &&
                    (detailOrder?.assigned_to === user?.id ||
                      ["AdminClient", "ManagerClient"].includes(user.role))
                  ) {
                    onOpenAlertCoupon();
                  }
                }
              }}
            >
              <Flex gap={2}>
                <RiCoupon3Fill size={18} />
                <Text textTransform="capitalize">
                  {detailOrder?.couponTitle
                    ? detailOrder?.couponTitle
                    : detailOrder?.status_confirmation !== "Confirmé"
                    ? t("Ajouter un coupon")
                    : t("Pas de coupon")}
                </Text>
              </Flex>
            </Badge>
            <Button
              _active={"none"}
              colorScheme="green"
              variant="outline"
              w={120}
              py={3}
              h={"auto"}
              rounded="xl"
              fontSize={15}
              onClick={onOpenDrawer}
            >
              {t("Plus")}
            </Button>
          </Flex>

          {/* {(user.role === "StockManagerClient") && <Flex
                        flexDirection={{ base: 'column', lg: "row" }}
                        gap={3}
                        align='center'>
                        <Badge
                            colorScheme={
                                detailOrder?.status_stock !== 'Collecté'
                                    ? (['Épuisé', 'Annulé'].includes(detailOrder?.status_stock))
                                        ? 'red'
                                        : ([null, ''].includes(detailOrder?.status_stock))
                                            ? 'blue'
                                            : 'orange'
                                    : 'green'
                            }
                            rounded="xl" textAlign='center' minW={120} py={3} px={3} cursor='pointer' textTransform='uppercase' onClick={() => { onOpenAlertStatusStock() }}>
                            <Flex gap={2}>
                                <FaBoxOpen size={18} />
                                <Text>
                                    {detailOrder?.status_stock ? detailOrder?.status_stock : 'Nouveau'}
                                </Text>
                            </Flex>
                        </Badge>
                    </Flex>} */}
        </Flex>
      </Box>

      <Box
        mt={10}
        bg={useColorModeValue("white", "gray.700")}
        p={5}
        rounded="xl"
        mx={5}
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="start">
            <Flex align="center" gap={1}>
              <TiShoppingCart
                color={useColorModeValue("#a0aec0", "#a0aec0")}
                size={20}
              />
              <Text fontSize={22} fontWeight="semibold">
                {t("Panier")}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <SimpleGrid
          px={{ base: 2, md: 10 }}
          mb={5}
          mt={2}
          templateColumns={{
            base: "repeat(auto-fill,minmax(250px,1fr))",
            md: "repeat(auto-fill,minmax(200px,1fr))",
          }}
          spacing={7}
        >
          {productsOrderData.map((val, key) => {
            return (
              <Card
                key={key}
                mt={5}
                rounded={"xl"}
                shadow="lg"
                borderWidth={1}
                borderColor={"gray.200"}
              >
                <CardBody p={0} m={0}>
                  <Stack position={"relative"}>
                    <Image
                      height={"200px"}
                      src={val.produit?.images[0]?.image}
                      alt="Image"
                      objectFit="cover"
                      roundedTop={"xl"}
                    />

                    {user.role !== "StockManagerClient" &&
                      // (detailOrder?.status_stock === null || detailOrder?.status_stock === '') && (detailOrder?.status_delivery === null || detailOrder?.status_delivery === '')
                      detailOrder?.status_confirmation !== "Confirmé" &&
                      (detailOrder?.assigned_to === user?.id ||
                        ["AdminClient", "ManagerClient"].includes(
                          user.role
                        )) && (
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<CiMenuKebab size={15} />}
                            variant="solid"
                            position={"absolute"}
                            rounded={"full"}
                            top={2}
                            right={2}
                            size={"sm"}
                            colorScheme={"blue"}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<FiEdit3 />}
                              onClick={() => {
                                onOpenModalEditProduct();
                                setIdProduct(val.id);
                                setTotalAchat(val.quantity * val.price);
                                setOldQtt(val.quantity);
                                setOldPurchase_PriceInput(val.price);
                              }}
                            >
                              {t("Modifier")}
                            </MenuItem>
                            {productsOrderData.length > 1 && (
                              <MenuItem
                                color={menuItemDelete}
                                icon={<MdDelete color={menuItemDelete} />}
                                onClick={() => {
                                  onOpenDeleteAlert(val.id);
                                  setTotalAchat(val.quantity * val.price);
                                  setOldQtt(val.quantity);
                                }}
                              >
                                {t("Supprimer")}
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      )}
                  </Stack>
                  <Stack p={3} alignItems="start" mt={2}>
                    <Heading
                      fontSize={"xl"}
                      fontFamily={"body"}
                      fontWeight={500}
                    >
                      {val.produit?.title}
                    </Heading>
                    {/* <Text lineHeight={5} textAlign={'justify'} color={'descColor'}>
                                            {val.produit?.description}
                                        </Text> */}

                    <Flex justifyContent="space-between" w="full">
                      <Flex gap={2}>
                        <Text color="gray">{t("Qté")} :</Text>
                        <Text fontWeight="semibold">{val.quantity}</Text>
                      </Flex>
                      <Flex gap={2}>
                        <Text color="gray">{t("Prix")} :</Text>
                        <Flex gap={1}>
                          <Text fontWeight="semibold">{val.price}</Text>
                          <Text fontWeight="gray" fontSize={13}>
                            MAD
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Stack>
                </CardBody>
              </Card>
            );
          })}

          {user.role !== "StockManagerClient" &&
            // (detailOrder?.status_stock === null || detailOrder?.status_stock === '') && (detailOrder?.status_delivery === null || detailOrder?.status_delivery === '')
            detailOrder?.status_confirmation !== "Confirmé" &&
            (detailOrder?.assigned_to === user?.id ||
              ["AdminClient", "ManagerClient"].includes(user.role)) && (
              <>
                <Card
                  mt={5}
                  rounded={"2xl"}
                  bg={bgAddProduct}
                  cursor="pointer"
                  onClick={onOpenModalAddProduct}
                >
                  <CardBody>
                    <Flex
                      flexDirection="column"
                      align={"center"}
                      justify={"center"}
                      h={"full"}
                    >
                      <Text color={textAddProduct}>
                        <MdAdd size={25} />
                      </Text>
                      <Text color={textAddProduct} fontWeight="semibold">
                        {t("Nouveau produit")}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </>
            )}
        </SimpleGrid>
      </Box>

      <Box
        mt={10}
        // bg={useColorModeValue('white', 'gray.700')}
        // p={5}
        rounded="xl"
        mx={5}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
          spacing={{ base: 5, lg: 8 }}
        >
          <Stat
            px={{ base: 2, md: 4 }}
            py={"5"}
            shadow={"xl"}
            bg={useColorModeValue("white", "gray.700")}
            rounded={"lg"}
          >
            <Flex justifyContent={"space-between"}>
              <Box pl={{ base: 2, md: 4 }}>
                <StatLabel fontWeight={"medium"} isTruncated>
                  <Text color="gray">{t("Produit")}</Text>
                </StatLabel>
                <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
                  <Text fontWeight="semibold">
                    {productsOrderData?.length}{" "}
                  </Text>
                </StatNumber>
              </Box>
              <Box
                my={"auto"}
                color={useColorModeValue("purple.500", "purple.300")}
                alignContent={"center"}
              >
                {<FaBoxOpen size={"3em"} />}
              </Box>
            </Flex>
          </Stat>
          <Stat
            px={{ base: 2, md: 4 }}
            py={"5"}
            shadow={"xl"}
            bg={useColorModeValue("white", "gray.700")}
            rounded={"lg"}
          >
            <Flex justifyContent={"space-between"}>
              <Box pl={{ base: 2, md: 4 }}>
                <StatLabel fontWeight={"medium"} isTruncated>
                  <Text color="gray">{t("Quantité")}</Text>
                </StatLabel>
                <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
                  <Text fontWeight="semibold">{quantityTotal}</Text>
                </StatNumber>
              </Box>
              <Box
                my={"auto"}
                color={useColorModeValue("blue.500", "blue.300")}
                alignContent={"center"}
              >
                {<BsCart4 size={"3em"} />}
              </Box>
            </Flex>
          </Stat>
          <Stat
            px={{ base: 2, md: 4 }}
            py={"5"}
            shadow={"xl"}
            bg={useColorModeValue("white", "gray.700")}
            rounded={"lg"}
          >
            <Flex justifyContent={"space-between"}>
              <Box pl={{ base: 2, md: 4 }}>
                <StatLabel fontWeight={"medium"} isTruncated>
                  <Text color="gray">{t("Total prix")}</Text>
                </StatLabel>
                <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
                  <Flex gap={1}>
                    <Flex>
                      <Text
                        color={detailOrder?.coupon && "gray.400"}
                        fontSize={detailOrder?.coupon && 15}
                        fontWeight="semibold"
                        textDecoration={detailOrder?.coupon && "line-through"}
                      >
                        {detailOrder?.price}
                      </Text>
                      <Text
                        color={detailOrder?.coupon && "gray.400"}
                        fontSize={detailOrder?.coupon ? 11 : 13}
                        fontWeight="gray"
                      >
                        MAD
                      </Text>
                    </Flex>
                    {detailOrder?.coupon && (
                      <>
                        /
                        <Flex>
                          <Text fontWeight="semibold">
                            {detailOrder?.priceWithCoupon}
                          </Text>
                          <Text fontWeight="gray" fontSize={13}>
                            MAD
                          </Text>
                        </Flex>
                      </>
                    )}
                  </Flex>
                </StatNumber>
              </Box>
              <Box
                my={"auto"}
                color={useColorModeValue("green.500", "green.300")}
                alignContent={"center"}
              >
                {<IoMdPricetags size={"3em"} />}
              </Box>
            </Flex>
          </Stat>
          {/* <StatsCard title={'Servers'} stat={'1,000'} icon={<FiServer size={'3em'} />} />
                    <StatsCard title={'Datacenters'} stat={'7'} icon={<GoLocation size={'3em'} />} /> */}
        </SimpleGrid>
      </Box>

      {/* Alert to Update confirmation status */}

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Statut de confirmation")}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {t("Ici, vous pouvez modifier le statut de confirmation.")}
              </Text>

              <Select
                mt={3}
                rounded="lg"
                size={"sm"}
                value={statusConfirmation}
                onChange={(e) => setStatusConfirmation(e.target.value)}
              >
                <option value="">{t("Statut de confirmation")}</option>
                <option value="Pas de réponse">{t("Pas de réponse")}</option>
                <option value="Messagerie vocale">
                  {t("Messagerie vocale")}
                </option>
                <option value="En attente">{t("En attente")}</option>
                <option value="Ligne occupée">{t("Ligne occupée")}</option>
                <option value="Mauvais numéro">{t("Mauvais numéro")}</option>
                <option value="Annulé">{t("Annulé")}</option>
                <option value="Confirmé">{t("Confirmé")}</option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="yellow"
                size="sm"
                variant="outline"
                isDisabled={!statusConfirmation}
                mr={3}
                onClick={HandleChangeStatus}
              >
                {t("Modifier")}
              </Button>
              <Button onClick={onCloseAlert} size="sm">
                {t("Annuler")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Alert to Update stock status */}

      <AlertDialog
        isOpen={isAlertOpenStatusStock}
        leastDestructiveRef={cancelRefStatusStock}
        onClose={onCloseAlertStatusStock}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Statut de stock")}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{t("Ici, vous pouvez modifier le statut de stock.")}</Text>

              <Select
                mt={3}
                rounded="lg"
                size={"sm"}
                value={statusStock}
                onChange={(e) => setStatusStock(e.target.value)}
              >
                <option value="">{t("Statut de stock")}</option>
                <option value="Épuisé">{t("Épuisé")}</option>
                <option value="Annulé">{t("Annulé")}</option>
                <option value="En cours">{t("En cours")}</option>
                <option value="Emballé">{t("Emballé")}</option>
                <option value="Collecté">{t("Collecté")}</option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="yellow"
                size="sm"
                variant="outline"
                isDisabled={!statusStock}
                mr={3}
                onClick={HandleChangeStatusStock}
              >
                {t("Modifier")}
              </Button>
              <Button onClick={onCloseAlertStatusStock} size="sm">
                {t("Annuler")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Alert to Update stock delivery */}

      <AlertDialog
        isOpen={isAlertOpenStatusDelivery}
        leastDestructiveRef={cancelRefStatusDelivery}
        onClose={onCloseAlertStatusDelivery}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Statut de livraison")}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {t("Ici, vous pouvez modifier le statut de livraison.")}
              </Text>

              <Select
                mt={3}
                rounded="lg"
                size={"sm"}
                value={statusDelivery}
                onChange={(e) => setStatusDelivery(e.target.value)}
              >
                <option value="">{t("Statut de livraison")}</option>
                <option value="Ramassé">{t("Ramassé")}</option>
                <option value="Expédié">{t("Expédié")}</option>
                <option value="Reçu à destination">
                  {t("Reçu à destination")}
                </option>
                <option value="Pendant la distribution">
                  {t("Pendant la distribution")}
                </option>
                <option value="Programmé">{t("Programmé")}</option>
                <option value="Hors zone">{t("Hors zone")}</option>
                <option value="Non atteint">{t("Non atteint")}</option>
                <option value="Annulé">{t("Annulé")}</option>
                <option value="Refusé">{t("Refusé")}</option>
                <option value="Livré">{t("Livré")}</option>
                <option value="Préparation du retour">
                  {t("Préparation du retour")}
                </option>
                <option value="Retour à l'agence">
                  {t("Retour à l'agence")}
                </option>
                <option value="Retour à l'entrepôt">
                  {t("Retour à l'entrepôt")}
                </option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="yellow"
                size="sm"
                variant="outline"
                isDisabled={!statusDelivery}
                mr={3}
                onClick={HandleChangeStatusDelivery}
              >
                {t("Modifier")}
              </Button>
              <Button onClick={onCloseAlertStatusDelivery} size="sm">
                {t("Annuler")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Alert to Add or Update Coupon */}

      <AlertDialog
        isOpen={isAlertOpenCoupon}
        leastDestructiveRef={cancelRefCoupon}
        onClose={onCloseAlertCoupon}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Coupon")}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {t(
                  "Ici, vous pouvez ajouter, modifier ou supprimer le coupon."
                )}
              </Text>

              <Select
                mt={3}
                rounded="lg"
                size={"sm"}
                defaultValue={
                  detailOrder?.coupon ? detailOrder?.coupon : couponSelected
                }
                onChange={(e) => setCouponSelected(e.target.value)}
              >
                <option value="">{t("Coupon")}</option>
                {dataCoupon.map((val, key) => {
                  return (
                    <option key={key} value={val.id}>
                      {val.code}
                    </option>
                  );
                })}
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Flex justifyContent="space-between" w="100%">
                <Flex>
                  <Button onClick={deleteCoupon} size="sm" colorScheme="red">
                    {t("Supprimer")}
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    colorScheme="yellow"
                    size="sm"
                    variant="outline"
                    isDisabled={!couponSelected}
                    mr={3}
                    onClick={HandleChangeCoupon}
                  >
                    {t("Modifier")}
                  </Button>
                  <Button onClick={onCloseAlertCoupon} size="sm">
                    {t("Annuler")}
                  </Button>
                </Flex>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Drawer */}
      <Drawer
        size={{ sm: "full", md: "md" }}
        placement={"right"}
        onClose={onCloseDrawer}
        isOpen={isDrawerOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {t("Informations client")}
          </DrawerHeader>
          <DrawerBody>
            <Flex flexDirection="column" gap={2}>
              {dataInfoClient.length > 0 ? (
                dataInfoClient.map((val, key) => {
                  return (
                    <Flex align="center" justify="space-between" key={key}>
                      <Text>{val.code}</Text>
                      <Badge
                        colorScheme="blue"
                        rounded="xl"
                        textAlign="center"
                        minW={120}
                        py={3}
                        px={3}
                        textTransform="uppercase"
                      >
                        {t("Confirmé")}
                      </Badge>
                    </Flex>
                  );
                })
              ) : (
                <Flex>
                  <Text>{t("Aucune donnée n'a été trouvée.")}</Text>
                </Flex>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Modal to add product in order */}
      <Modal
        size="lg"
        closeOnOverlayClick={ModalAddProductRef}
        isOpen={isModalAddProductOpen}
        onClose={onCloseModalAddProduct}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Ajout du produit")} </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel>{t("Liste des produits")}</FormLabel>
              <ReactSelect
                onChange={setProductSelected}
                value={productSelected}
                name="produit"
                options={optionsTitre}
                placeholder={t("Produit")}
                isSearchable={true}
                isClearable={true}
              />
            </FormControl>

            {dataProducts.length !== 0 && (
              <>
                <Flex justifyContent={"center"} my={2}>
                  <Avatar
                    h={150}
                    w={150}
                    borderRadius="12px"
                    me="18px"
                    name="image"
                    src={imageProduct}
                  />
                </Flex>

                <Flex flexDirection={"row"} gap={2}>
                  {colorSelected.length !== 0 && (
                    <FormControl>
                      <FormLabel>{t("Couleur")}</FormLabel>
                      <ReactSelect
                        onChange={setColorInput}
                        value={colorInput}
                        name="colorInput"
                        options={colorSelected}
                        placeholder={t("Couleur")}
                        isClearable
                      />
                    </FormControl>
                  )}

                  {sizeSelected.length !== 0 && (
                    <FormControl>
                      <FormLabel>{t("Taille")}</FormLabel>
                      <ReactSelect
                        onChange={setSizeInput}
                        value={sizeInput}
                        name="SizeInput"
                        options={sizeSelected}
                        placeholder={t("Taille")}
                        isClearable
                      />
                    </FormControl>
                  )}
                </Flex>

                <Flex mt={3} gap={2}>
                  <FormControl isRequired>
                    <FormLabel>{t("Quantité")}</FormLabel>
                    <Input
                      placeholder={t("Quantité")}
                      defaultValue={quantityInput}
                      onChange={(e) => setQuantityInput(e.target.value)}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>{t("Prix d'achat")}</FormLabel>
                    <Input
                      placeholder={t("Prix d'achat")}
                      defaultValue={purchase_PriceInput}
                      onChange={(e) => setPurchase_PriceInput(e.target.value)}
                    />
                  </FormControl>
                </Flex>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              size="sm"
              variant="outline"
              mr={3}
              onClick={addProduct}
            >
              {t("Ajouter")}
            </Button>
            <Button onClick={onCloseModalAddProduct} size="sm">
              {t("Annuler")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal to edit product in order */}
      <Modal
        size="lg"
        closeOnOverlayClick={ModalEditProductRef}
        isOpen={isModalEditProductOpen}
        onClose={onCloseModalEditProduct}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Modification du produit")}</ModalHeader>
          <ModalCloseButton />
          {!loadingUpdateModal && (
            <ModalBody pb={6}>
              <Text fontWeight="semibold" fontSize={18}>
                {getDataProduct_IdProduct.produit?.title}
              </Text>

              <Flex justifyContent={"center"} my={2}>
                <Avatar
                  h={150}
                  w={150}
                  borderRadius="12px"
                  me="18px"
                  name="image"
                  src={getDataProduct_IdProduct.produit?.images[0]?.image}
                />
              </Flex>

              <Flex flexDirection={"row"} gap={2}>
                {colorSelected?.length !== 0 && (
                  <FormControl>
                    <FormLabel>{t("Couleur")}</FormLabel>
                    <ReactSelect
                      onChange={setColorInput}
                      value={
                        colorInput
                          ? colorInput
                          : {
                              value: colorSelected?.find(
                                (e) =>
                                  e.label === getDataProduct_IdProduct?.color
                              )?.value,
                              label: getDataProduct_IdProduct?.color,
                            }
                      }
                      name="colorInput"
                      options={colorSelected}
                      placeholder={t("Couleur")}
                    />
                  </FormControl>
                )}

                {sizeSelected?.length !== 0 && (
                  <FormControl>
                    <FormLabel>{t("Taille")}</FormLabel>
                    <ReactSelect
                      onChange={setSizeInput}
                      value={
                        sizeInput
                          ? sizeInput
                          : {
                              value: colorSelected?.find(
                                (e) =>
                                  e.label === getDataProduct_IdProduct?.size
                              )?.value,
                              label: getDataProduct_IdProduct?.size,
                            }
                      }
                      name="sizeInput"
                      options={sizeSelected}
                      placeholder={t("Taille")}
                    />
                  </FormControl>
                )}
              </Flex>

              <Flex mt={3} gap={2}>
                <FormControl isRequired>
                  <FormLabel>{t("Quantité")}</FormLabel>
                  <Input
                    placeholder={t("Quantité")}
                    defaultValue={getDataProduct_IdProduct?.quantity}
                    onChange={(e) => setQuantityInput(e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>{t("Prix d'achat")}</FormLabel>
                  <Input
                    placeholder={t("Prix d'achat")}
                    defaultValue={getDataProduct_IdProduct?.price}
                    onChange={(e) => setPurchase_PriceInput(e.target.value)}
                  />
                </FormControl>
              </Flex>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              colorScheme="blue"
              size="sm"
              variant="outline"
              mr={3}
              onClick={EditProductOrder}
            >
              {t("Modifier")}
            </Button>
            <Button onClick={onCloseModalEditProduct} size="sm">
              {t("Annuler")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert to Delete Product */}

      <AlertDialog
        isOpen={deleteAlertOpen}
        leastDestructiveRef={deleteAlertRef}
        onClose={onCloseDeleteAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Supprimer")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                "êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement."
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={deleteAlertRef} onClick={onCloseDeleteAlert}>
                {t("Annuler")}
              </Button>
              <Button onClick={DeleteProduct} colorScheme="red" ml={3}>
                {t("Supprimer")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Modal to edit Info Client */}
      <Modal
        size="xl"
        closeOnOverlayClick={ModalEditInfosClientRef}
        isOpen={isModalEditInfosClientOpen}
        onClose={onCloseModalEditInfosClient}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Modification des infos client")}</ModalHeader>
          <ModalCloseButton />
          {!loadingUpdateModal && (
            <ModalBody pb={6}>
              <Flex gap={4} mt={3}>
                <FormControl isRequired>
                  <FormLabel>{t("Nom complet")}</FormLabel>
                  <Input
                    type="text"
                    placeholder={t("Nom complet")}
                    value={nomCompletClient}
                    onChange={(e) => setNomCompletClient(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email {t("(optionnel)")}</FormLabel>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={emailClient}
                    onChange={(e) => setEmailClient(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Flex gap={4} mt={3}>
                <FormControl isRequired>
                  <FormLabel>{t("Pays")}</FormLabel>
                  <Input
                    type="text"
                    placeholder={t("Pays")}
                    value={paysClient}
                    onChange={(e) => setPaysClient(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t("Ville")}</FormLabel>
                  <Input
                    type="email"
                    placeholder={t("Ville")}
                    value={villeClient}
                    onChange={(e) => setVilleClient(e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Flex gap={4} mt={3}>
                <FormControl isRequired>
                  <FormLabel>{t("Adresse")}</FormLabel>
                  <Input
                    type="text"
                    placeholder={t("Adresse")}
                    value={adresseClient}
                    onChange={(e) => setAdresseClient(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t("N°téléphone")}</FormLabel>
                  <Input
                    type="email"
                    placeholder={t("N°téléphone")}
                    value={phoneClient}
                    onChange={(e) => setPhoneClient(e.target.value)}
                  />
                </FormControl>
              </Flex>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              colorScheme="blue"
              size="sm"
              variant="outline"
              mr={3}
              onClick={updateInfosClient}
            >
              {t("Modifier")}
            </Button>
            <Button onClick={onCloseModalEditInfosClient} size="sm">
              {t("Annuler")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Details_GestConfirmation;
