import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, AvatarGroup, Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, ButtonGroup, Card, CardBody, CardFooter, Divider, Flex, Heading, IconButton, Image, Input, InputGroup, InputLeftElement, Select, SimpleGrid, Skeleton, SkeletonText, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoAddSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { AddIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { CiMenuKebab } from "react-icons/ci";
import { FiEdit3 } from 'react-icons/fi';
import { MdDelete, MdHistory, MdOutlineAddShoppingCart } from 'react-icons/md';
import { TbListDetails } from "react-icons/tb";
import axiosClient from '../../../axios-client';

import outOfStock from './assets/images/out-of-stock.png'
import { useStateContext } from '../../../context/ContextProvider';
import MyPagination from '../../../components/MyPagination';




function MyStock() {

    const { user } = useStateContext()
    const { t } = useTranslation();
    const navigate = useNavigate()

    const userId = user.id
    const userRole = user.role
    const userWorks_For = user.works_for

    const { isOpen, onOpen, onClose } = useDisclosure()

    const descColor = useColorModeValue('gray.400', 'gray.500')
    const menuCard = useColorModeValue('white', 'gray.500')
    const menuItemDelete = useColorModeValue("red", "red.300")

    const [paginationData, setPaginationData] = useState()
    const [dataProduct, setDataProduct] = useState([])
    const [loading, setLoading] = useState(false)

    const [dataImagesProduct, setDataImagesProduct] = useState([])
    const getImagesProduct = (id) => {
        setDataImagesProduct(dataProduct.find(e => e.id === id).images)
        console.table(dataProduct.find(e => e.id === id)?.images);
        onOpen()
    }



    // DELETE

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [deletedId, setDeletedId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setDeletedId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setDeletedId()
    };

    const deleteProduct = () => {
        axiosClient.patch(`/products/${deletedId}/`, {
            is_deleted: true
        }).then(() => {
            setDataProduct(dataProduct.filter(e => e.id !== deletedId))
            onCloseAlert()
        })
    }

    // PAGINATION

    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    //GEt Data 

    const fetchData = async (pageSize, currentPage, searchTerm) => {
        try {
            setLoading(true)

            await axiosClient.get(`/products/getAllProducts/?id_user=${user.id}&page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`).then(async (res) => {
                if (res.data.length !== 0) {
                    setDataProduct(res.data.results)
                    setPaginationData(res.data)
                } else {
                    setDataProduct([])
                    setPaginationData([])
                }
                setLoading(false)
                console.log('DATA : ', res.data);
            }).catch(err => {
                setLoading(false)
                console.log('ERROR : ', err);
            })
        } catch (error) {
            setLoading(false)
            console.log('ERROR : ', error);
        }
    }

    useEffect(() => {
        console.log("ID User : ", userRole === "AdminClient" ? userId : userWorks_For);
        fetchData(pageSize, currentPage, searchTerm)
    }, [user?.id, currentPage, searchTerm, pageSize])

    console.log('DATA setDataProduct : ', dataProduct);

    return (
        <Stack position={'relative'}>

            <Box position='fixed' bottom={5} right={5} zIndex={999}>
                <Button colorScheme='purple' variant={'solid'} onClick={() => { navigate('/NewStock') }}>
                    <IoAddSharp size={18} />
                </Button>
            </Box>

            <Flex>
                <Box w="90%" pt='12px' mb={5}>
                    <Breadcrumb fontFamily='Plus Jakarta Display' fontStyle={"italic"} fontSize={{ base: "20px", sm: "25px", lg: "27px" }} color="gray">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>{t("Accueil")}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{t("Gestion stock")}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Box>
            </Flex>
            <Flex
                justifyContent="end"
                alignItems="center"
                direction={{ base: 'column', lg: 'row' }}
                mb={2}
            // pt={3}
            >
                {/* <Flex w={"100%"}>
                            <Select  borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} >
                                <option value="">{t("Tout les utilisateurs")}</option>
                                <option value="ADMIN">{t("Administrateur")}</option>
                                <option value="RESPO">{t("Responsable")}</option>
                                <option value="AGENTSUPPORT">{t("Agent de support client")} </option>
                                <option value="RESPOEVENTS">{t("Responsable des événements")}</option>
                            </Select>
                            <Select  borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mb={4} >
                                <option value="">{t("Tout")}</option>
                                <option value="true">{t("Activé")}</option>
                                <option value="false">{t("Désactivé")}</option>
                            </Select>
                        </Flex> */}


                <InputGroup size="md" w={{ base: '100%', lg: "40%" }} borderColor={useColorModeValue('gray.400', "gray.500")}>
                    <InputLeftElement
                        pointerEvents='none'
                        children={<SearchIcon color='gray.300' />}
                    />
                    <Input

                        borderRadius="5"
                        placeholder={t("Recherche...")}
                        borderColor={useColorModeValue("gray.300", "gray.600")}
                        mb={4}
                        sx={{
                            marginLeft: 'auto',
                        }}
                        value={searchTerm}
                        onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                        }}
                    />  </InputGroup>

            </Flex>

            <SimpleGrid px={10} templateColumns='repeat(auto-fill,minmax(250px,1fr))' spacing={7}>
                {/* {
                    loading &&
                    <Card mt={5} rounded={"2xl"}>
                        <CardBody>
                            <Stack position={"relative"}>
                                <Skeleton height={200} borderRadius='2xl' mt={-12} />
                            </Stack>
                            <Stack alignItems='center'>
                                <SkeletonText mt='4' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
                            </Stack>
                            <Stack>
                                <SkeletonText mt='6' noOfLines={2} spacing='4' skeletonHeight='2' />
                            </Stack>

                            <Stack alignItems='center'>
                                <SkeletonText mt='6' w={95} noOfLines={1} spacing='4' skeletonHeight='2' />
                            </Stack>
                        </CardBody>
                    </Card>
                } */}

                {(!loading && dataProduct.length !== 0) &&
                    dataProduct.map((val, key) => {
                        return (
                            <Card key={key} mt={5} rounded={"2xl"}>
                                <CardBody>
                                    <Stack position={"relative"}>
                                        <Image
                                            height={"200px"}
                                            mt={-12}
                                            src={val.images[0]?.image}
                                            alt='Image'
                                            borderRadius='2xl'
                                            objectFit="cover"
                                            cursor={'pointer'}
                                            onClick={() => {
                                                getImagesProduct(val.id)
                                            }}
                                        />
                                        <Menu>
                                            <MenuButton
                                                as={IconButton}
                                                aria-label='Options'
                                                icon={<CiMenuKebab size={15} />}
                                                variant='solid'
                                                position={'absolute'}
                                                rounded={'full'}
                                                top={-10}
                                                right={2}
                                                size={'sm'}
                                                colorScheme={"blue"}
                                            />
                                            <MenuList>
                                                <MenuItem icon={<TbListDetails />} onClick={() => navigate('/DetailStock', {
                                                    state: {
                                                        id: val.id
                                                    }
                                                })}>
                                                    {t("Détails")}
                                                </MenuItem>
                                                <MenuItem icon={<MdOutlineAddShoppingCart />} onClick={() => navigate('/UpgradeStock', {
                                                    state: {
                                                        id: val.id
                                                    }
                                                })}>
                                                    {t("Mise à niveau du stock")}
                                                </MenuItem>
                                                <MenuItem icon={<FiEdit3 />} onClick={() => navigate('/EditStock', {
                                                    state: {
                                                        id: val.id
                                                    }
                                                })}>
                                                    {t("Modifier")}
                                                </MenuItem>
                                                <MenuItem icon={<MdHistory />} onClick={() => navigate('/HistoriqueStock')}>
                                                    {t("Historique")}
                                                </MenuItem>
                                                <MenuItem color={menuItemDelete} icon={<MdDelete color={menuItemDelete} />} onClick={() => {
                                                    onOpenAlert(val.id)
                                                }}>
                                                    {t("Supprimer")}
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Stack>
                                    <Stack pt={3} align={'center'}>

                                        <Flex gap={3} direction={"row"} justify={'space-between'} align={"center"}>
                                            <Heading fontSize={'xl'} fontFamily={'body'} >
                                                {val.title}
                                            </Heading>
                                            <Badge px={2} py={1} rounded={"lg"} colorScheme='green'>
                                                <Heading fontSize={'sm'} fontWeight={450} >
                                                    {val.code}
                                                </Heading>
                                            </Badge>

                                        </Flex>
                                        <Text lineHeight={5} textAlign={'justify'} color={descColor}>
                                            {val.description}
                                        </Text>
                                        <Stack direction={'row'} align={'center'}>
                                            <Text fontWeight={"semibold"} fontSize={'lg'}>
                                                {t('Qte')} : {val.total_quantity}
                                            </Text>

                                        </Stack>
                                    </Stack>
                                </CardBody>
                            </Card>
                        )
                    })
                }
            </SimpleGrid>

            {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
            {(!loading && dataProduct.length !== 0) &&
                <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            paginationData={paginationData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            PAGE_SIZE={pageSize}
                        />
                    </Box>

                    <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Select>
                </Flex>
            }
            {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}

            {
                (!loading && dataProduct.length === 0) &&
                <Flex flexDirection="column" alignItems='center' gap={2}>
                    <Image src={outOfStock} h={200} w={200} opacity={.5} />
                    <Text color='gray.500'>{t("Aucune donnée n'a été trouvée.")}</Text>
                </Flex>
            }


            <Drawer placement='bottom' onClose={() => {
                onClose()
                setDataImagesProduct([])
            }} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent
                    background={"#ffffff42"}
                    backdropFilter='auto' backdropBlur='8px'>
                    <DrawerBody>
                        <Flex gap={5} justify={{ base: 'none', md: 'center' }}>
                            {
                                dataImagesProduct.map((val, key) => {
                                    return (
                                        <Avatar key={key} h={150} w={150} borderRadius={'xl'} name='image' src={val.image} />
                                    )
                                })
                            }
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>



            {/* Alert to Delete Product */}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={deleteProduct} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Stack>
    )
}

export default MyStock