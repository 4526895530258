import PropTypes from "prop-types";
// import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
// import Flags from "country-flag-icons/react/3x2";
import { Button, Image, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { IoLanguage } from "react-icons/io5";
import fr from "../../assets/images/countries/fr.png";
import en from "../../assets/images/countries/us.png";

const LanguageChanger = ({ currency,color }) => {
    const { i18n } = useTranslation();
    // const dispatch = useDispatch();
    const changeLanguageTrigger = e => {
        console.log("I18n", i18n); // Log the i18n object
        const languageCode = e.target.value;
        i18n.changeLanguage(languageCode);
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng')); // Default language

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (language) => {
        // Implement language change logic here
        console.log(`Selected language: ${language}`);
        setSelectedLanguage(language);
        const languageCode = language;
        i18n.changeLanguage(languageCode);
        handleClose();
    };

    // const Flag = ({ countryCode }) => {
    //     const FlagComponent = Flags[countryCode.toUpperCase()];

    //     if (!FlagComponent) {
    //         // Handle the case where the country code is not valid or not supported
    //         return null;
    //     }

    //     return <FlagComponent style={{ width: '24px', height: 'auto' }} />;
    // };


    return (

        <>
            {/* <Button
                sx={{ color: '#000000' }}
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleClick}
                
            >
                hi
            </Button> */}
            <Menu >
                <MenuButton size={"sm"} w={'fit-content'} _active={'none'} _hover={"none"} background={'transparent'} color={color} transition="all 0.3s"
              _focus={{ boxShadow: "none" }} as={Button} rightIcon={<ChevronDownIcon />} leftIcon={<IoLanguage />}>
                    <Text>{selectedLanguage==='fn' ? "Français" : "English"}</Text>
                </MenuButton>
                <MenuList  zIndex={9999} borderColor="gray.100"
              alignItems={"center"}
              bg={useColorModeValue("gray.100", "black")}
              color="white"
              boxShadow={"xl"}>
                    <MenuItem   size={"sm"}  _hover={{ bg: "gray.200", color: "white" }}
                bg={useColorModeValue("gray.100", "black")} onClick={() => handleLanguageChange('en')} style={{ display: 'flex', alignItems: 'center' }}>
                      <Image src={en} w={"20px"} mr={3}/> <Text fontSize={"sm"} color={'black'}>English</Text> 

                    </MenuItem>
                    <MenuItem size={"sm"} _hover={{ bg: "gray.200", color: "white" }}
                bg={useColorModeValue("gray.100", "black")} onClick={() => handleLanguageChange('fn')} style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={fr} w={"20px"} mr={3}/> <Text fontSize={"sm"} color={'black'}>Français</Text> 

                    </MenuItem>

                </MenuList>
            </Menu>

        </>
    );
};

LanguageChanger.propTypes = {
    currency: PropTypes.shape({}),
};

export default LanguageChanger;
