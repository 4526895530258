import { AddIcon, ChevronDownIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Avatar, Badge, Box, Button, Checkbox, Flex, FormControl, FormLabel, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosMenu } from 'react-icons/io';
import { PiPlusBold } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import outOfOrder from '../assets/images/empty-cart.png'
import { LuMinus } from "react-icons/lu";
import { RiDeleteBinLine, RiFileTransferLine } from "react-icons/ri";
import { useStateContext } from '../../../../context/ContextProvider';
import { TbListDetails } from 'react-icons/tb';
import MyPagination from '../../../../components/MyPagination';



function Confirmation({ user }) {
    // const { user } = useStateContext()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const toast = useToast()

    const [dataOrdres, setDataOrdres] = useState([])
    const [loading, setLoading] = useState(false)
    const [paginationData, setPaginationData] = useState()

    const [searchTerm, setSearchTerm] = useState('');
    const [userSelected, setUserSelected] = useState('');
    const [statusSelected, setStatusSelected] = useState('');

    const [dateDebutValue, setDateDebutValue] = useState("");
    const [dateFinValue, setDateFinValue] = useState("");

    const [userConfirmation, setUserConfirmation] = useState([])

    const [userConfirmationSelected, setUserConfirmationSelected] = useState()



    const fetchUsers = async () => {
        try {
            await axiosClient.get(`/clients/getClientUsers/?id=${user.id}&role=RespoSuiviClient&page_size=100`).then(res => {
                setUserConfirmation(res.data.results || [])
                console.log('Confirmation users ll : ', res.data);
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [user.id])


    // PAGINATION

    const [currentPage, setCurrentPage] = useState(0);

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    const fetchData = async (pageSize, currentPage, searchTerm, userSelected, statusSelected, dateDebutValue, dateFinValue) => {
        try {
            setLoading(true)
            await axiosClient.get(`/commandes/getConfirmOrder/?clientId=${user.id}&page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&statusSelected=${statusSelected}&userSelected=${userSelected}&dateDebut=${dateDebutValue}&dateFin=${dateFinValue}`).then((res) => {
                // setDataOrdres(res.data.filter(e => e.client === user.id && e.is_deleted === false && e.assigned_to !== null && e.assigned_to_stock === null && e.assigned_to_suivi === null))
                console.log('RESULTS DATA : ', res.data.results);
                if (res.data.length !== 0) {
                    setDataOrdres(res.data.results)
                    setPaginationData(res.data)
                } else {
                    setDataOrdres([])
                    setPaginationData([])
                }
            })

            setLoading(false)
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData(pageSize, currentPage, searchTerm, userSelected, statusSelected, dateDebutValue, dateFinValue)
    }, [user?.id, currentPage, searchTerm, pageSize, userSelected, statusSelected, dateDebutValue, dateFinValue])

    // État pour stocker les lignes sélectionnées
    const [selectedRows, setSelectedRows] = useState([]);

    // Fonction pour gérer le clic sur la case à cocher
    const handleCheckboxClick = (index) => {
        // Vérifie si la ligne est déjà sélectionnée
        const selectedIndex = selectedRows.indexOf(index);
        if (selectedIndex === -1) {
            // Si la ligne n'est pas déjà sélectionnée, l'ajouter à l'état
            setSelectedRows([...selectedRows, index]);
        } else {
            // Si la ligne est déjà sélectionnée, la retirer de l'état
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        }

        console.log('INDEEEEEEEX : ', index);
    };

    console.log('SELECTED ROWs : ', selectedRows);

    //ASSIGN ORDER

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    const assign_order_to = async (user) => {

        console.log('USEEEER :', user);

        for (let index = 0; index < selectedRows.length; index++) {
            try {
                await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                    assigned_to: user
                }).then(res => {
                    setDataOrdres(dataOrdres.map((val) => {
                        if (val.id === res.data.id) {

                            return {
                                ...val,
                                assigned_to: user,
                                first_name: res.data.first_name,
                                last_name: res.data.last_name,
                            }
                        }
                        return val
                    }))
                })
            } catch (error) {
                console.log('Error : ', error);
            }
        }

        // setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
        setSelectedRows([])
        onClose()
    }

    // DELETE

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const [deletedId, setDeletedId] = useState()

    const onOpenAlert = (id) => {
        setIsAlertOpen(true);
        setDeletedId(id)
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setDeletedId()
    };

    const DeleteArrayOfOrders = async () => {
        console.log('DELETED ID ; ', deletedId);
        if (deletedId !== null) {
            try {
                await axiosClient.patch(`commandes/${deletedId}/`, {
                    is_deleted: true
                }).then(res => {
                    setDataOrdres(dataOrdres.filter(e => e.id !== deletedId))
                })
            } catch (error) {
                console.log('ERROR : ', error);
            }
        }
        else {
            for (let index = 0; index < selectedRows.length; index++) {
                try {
                    await axiosClient.patch(`/commandes/${selectedRows[index]}/`, {
                        is_deleted: true
                    })
                } catch (error) {
                    console.log('Error : ', error);
                }
            }

            setDataOrdres(dataOrdres.filter(e => !selectedRows.includes(e.id)))
        }
        onCloseAlert()
    }

    // Update status


    const [statusConfirmation, setStatusConfirmation] = useState('')
    const [isModalEditStatusOpen, setIsModalEditStatusOpen] = useState(false);
    const ModalEditStatusRef = React.useRef()
    const [statusId, setStatusId] = useState()

    const onOpenModalEditStatus = (id) => {
        setIsModalEditStatusOpen(true);
        setStatusId(id)
    };
    const onCloseModalEditStatus = () => {
        setIsModalEditStatusOpen(false);
        setStatusConfirmation('')
        setStatusId()
    };

    const HandleChangeStatus = async () => {
        try {
            await axiosClient.patch(`commandes/${statusId}/`, {
                status_confirmation: statusConfirmation
            }).then((res) => {
                setDataOrdres(dataOrdres.map((item) => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            status_confirmation: res.data.status_confirmation
                        }
                    }
                    return item
                }))
                onCloseModalEditStatus()
                toast({
                    description: t(`Statut est changé avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        } catch (error) {
            console.log('ERROR : ', error);
        }
    }

    //USECOLOREMODE Variables
    const bgHoverStack = useColorModeValue('gray.200', 'gray.500')
    const borderColor = useColorModeValue("gray.300", "gray.600")
    const textSM = useColorModeValue('gray.400', 'gray.500')

    //vider l'input de date fin
    useEffect(() => {
        if (!dateDebutValue) {
            // Si dateDebutValue est vide, on réinitialise dateFinValue
            setDateFinValue('');
        } else if (dateFinValue && dateDebutValue >= dateFinValue) {
            // Si dateDebutValue est supérieur ou égal à dateFinValue, on réinitialise dateFinValue
            setDateFinValue('');
        }
    }, [dateDebutValue, dateFinValue]);

    return (
        <Box>

            <Stack
                px={2}
                py={5}
                // mx={5}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={"2xl"}
                mt={2}
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ base: 'column', lg: 'row' }}
                    mb={2}
                >
                    <Flex w={{ base: "100%", lg: '40%' }} direction='column'>
                        <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={'100%'} mr={5} mb={4} value={userSelected} onChange={e => setUserSelected(e.target.value)}>
                            <option value="">{t("Tout les utilisateurs")}</option>
                            {userConfirmation.map((val, key) => {
                                return (
                                    <option key={key} value={val.id}>{`${val.first_name} ${val.last_name}`}</option>
                                )
                            })}
                        </Select>

                        <Flex w={"100%"}>
                            <Select borderColor={borderColor} borderRadius="5" size="md" fontSize={{ base: 13, lg: 16 }} w={'100%'} mb={4} value={statusSelected} onChange={e => setStatusSelected(e.target.value)}>
                                <option value=''>{t('Statut de confirmation')}</option>
                                <option value='Nouveau'>{t('Nouveau')}</option>
                                <option value='Pas de réponse'>{t('Pas de réponse')}</option>
                                <option value='Messagerie vocale'>{t('Messagerie vocale')}</option>
                                <option value='En attente'>{t('En attente')}</option>
                                <option value='Ligne occupée'>{t('Ligne occupée')}</option>
                                <option value='Mauvais numéro'>{t('Mauvais numéro')}</option>
                                <option value='Annulé'>{t('Annulé')}</option>
                                <option value='Confirmé'>{t('Confirmé')}</option>
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex w={{ base: "100%", lg: '40%' }} direction='column'>
                        <Flex gap={2}>
                            <FormControl position="relative" mb={4}>
                                <FormLabel
                                    position="absolute"
                                    top={dateDebutValue ? "9px" : "45%"}
                                    left="10px"
                                    background={useColorModeValue('white', 'gray.700')}
                                    borderRadius='lg'
                                    px={1}
                                    pt={1}
                                    color="gray.500"
                                    fontSize={dateDebutValue ? 10 : 16}
                                    transform={dateDebutValue ? "translateY(-100%)" : "translateY(-50%)"}
                                    transition="all 0.2s ease-out"
                                    zIndex={2}
                                    w={!dateDebutValue && 95}
                                    onClick={() => document.getElementById('dateDebutInput').focus()} // Focuses the input when clicking the label
                                    cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                >
                                    Date début
                                </FormLabel>
                                <Input
                                    id="dateDebutInput" // Assign an ID to the input for reference
                                    type="date"
                                    borderColor={borderColor}
                                    borderRadius="5"
                                    size="md"
                                    fontSize={{ base: 13, lg: 16 }}
                                    w="100%"
                                    value={dateDebutValue}
                                    onChange={(e) => setDateDebutValue(e.target.value)}
                                    position="relative"
                                    placeholder=" " // This keeps space for the floating label
                                    _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                />
                            </FormControl>
                            <FormControl position="relative" mb={4}>
                                <FormLabel
                                    position="absolute"
                                    top={dateFinValue ? "9px" : "45%"}
                                    left="10px"
                                    background={useColorModeValue('white', 'gray.700')}
                                    borderRadius='lg'
                                    px={1}
                                    pt={1}
                                    color="gray.500"
                                    fontSize={dateFinValue ? 10 : 16}
                                    transform={dateFinValue ? "translateY(-100%)" : "translateY(-50%)"}
                                    transition="all 0.2s ease-out"
                                    zIndex={2}
                                    w={!dateFinValue && 95}
                                    onClick={() => document.getElementById('dateFinInput').focus()} // Focuses the input when clicking the label
                                    cursor="pointer" // Changes cursor to pointer to indicate it's clickable
                                >
                                    Date fin
                                </FormLabel>
                                <Input
                                    id="dateFinInput" // Assign an ID to the input for reference
                                    type="date"
                                    borderColor={borderColor}
                                    borderRadius="5"
                                    size="md"
                                    fontSize={{ base: 13, lg: 16 }}
                                    w="100%"
                                    value={dateFinValue}
                                    min={dateDebutValue || undefined}
                                    onChange={(e) => setDateFinValue(e.target.value)}
                                    position="relative"
                                    placeholder=" " // This keeps space for the floating label
                                    _placeholder={{ color: 'transparent' }} // Hides the default placeholder
                                    disabled={!dateDebutValue}
                                />
                            </FormControl>
                        </Flex>
                        <InputGroup size="md" w={'100%'}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color={borderColor} />}
                            />
                            <Input
                                borderColor={borderColor}
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>


                <TableContainer>
                    <Table size={{ base: 'sm', md: 'md' }} variant='simple'>
                        <Thead>
                            <Tr>
                                {/* <Th borderBottomColor='gray.400'>-</Th> */}
                                <Th borderBottomColor='gray.400'>{t("#ID")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Responsable")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Client")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Adresse")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Téléphone")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Status")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Coupons")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Prix")}</Th>
                                <Th borderBottomColor='gray.400'>{t("Options")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <>
                                {loading &&
                                    <Tr>
                                        <Td><Skeleton h={10} w={10} /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td>
                                            <Flex flexDirection={"column"} gap={2}>
                                                <Skeleton height='20px' />
                                                <Skeleton height='10px' />
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex flexDirection={"column"} gap={2}>
                                                <Skeleton height='20px' />
                                                <Skeleton height='10px' />
                                                <Skeleton height='10px' />
                                            </Flex>
                                        </Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                        <Td><Skeleton height='20px' /></Td>
                                    </Tr>
                                }
                                {
                                    dataOrdres.length > 0 ?
                                        dataOrdres.map((val, key) => {
                                            const isSelected = selectedRows.includes(val.id);
                                            return (
                                                <Tr key={key}>
                                                    {/* <Td>
                                                    <Checkbox size="md" colorScheme='purple' _focus={"none"} _active={'none'}
                                                        onChange={() => handleCheckboxClick(val.id)}
                                                        isChecked={isSelected} />
                                                </Td> */}
                                                    <Td>
                                                        <Text>{val.code}</Text>
                                                    </Td>
                                                    <Td>
                                                        {val.assigned_to ?
                                                            <Flex flexDirection="column">
                                                                <Text>{val.first_name}</Text>
                                                                <Text>{val.last_name}</Text>
                                                            </Flex>
                                                            :
                                                            <Text>-</Text>
                                                        }
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontWeight='semibold'>{val.full_name}</Text>
                                                            {val.email !== "undefined" && <Text fontSize='sm' color={textSM}>{val.email}</Text>}
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <Flex flexDirection={"column"}>
                                                            <Text fontSize={'md'}>{val.pays}, {val.ville}</Text>
                                                            <Text fontSize={'sm'} color={textSM}>{val.address}</Text>
                                                        </Flex>
                                                    </Td>
                                                    <Td>
                                                        <a href={`https://wa.me/${val.tel}`} target='blank'>
                                                            {val.tel}
                                                        </a>
                                                    </Td>
                                                    <Td>
                                                        <Badge colorScheme={
                                                            val.status_confirmation !== 'Confirmé'
                                                                ? (['Mauvais numéro', 'Annulé'].includes(val.status_confirmation))
                                                                    ? 'red'
                                                                    : (val.status_confirmation === 'Nouveau')
                                                                        ? 'blue'
                                                                        : 'orange'
                                                                : 'green'
                                                        }
                                                            rounded="xl"
                                                            px={2}
                                                            cursor='pointer'
                                                            onClick={() => {
                                                                if (val?.status_stock === null || val?.status_stock === '') {
                                                                    onOpenModalEditStatus(val.id)
                                                                } else {
                                                                    toast({
                                                                        description:
                                                                            <Flex flexDirection='column'>
                                                                                <Text fontWeight='semibold'>{t('Désolé!')}</Text>
                                                                                {val?.status_stock !== 'Collecté'
                                                                                    ?
                                                                                    <Text>{t('Impossible de modifier le statut de cette commande car elle est déjà en stock.')}</Text>
                                                                                    :
                                                                                    <Text>{t('Impossible de modifier le statut de cette commande car elle est déjà en Livraison.')}</Text>
                                                                                }
                                                                            </Flex>
                                                                        ,
                                                                        status: 'error',
                                                                        duration: 5000,
                                                                        isClosable: true,
                                                                        variant: 'left-accent',
                                                                        position: 'top-center'
                                                                    })
                                                                }
                                                            }}>
                                                            {t(`${val.status_confirmation}`)}
                                                        </Badge>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign='center'>
                                                            {val.coupon ? val.couponTitle : '-'}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Flex>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon && 15} fontWeight='semibold' textDecoration={val?.coupon && 'line-through'}>{val?.price}</Text>
                                                            <Text color={val?.coupon && 'gray.400'} fontSize={val?.coupon ? 11 : 13} fontWeight='gray'>MAD</Text>
                                                        </Flex>
                                                        {
                                                            val?.coupon &&
                                                            <Flex>
                                                                <Text fontWeight='semibold'>{val?.priceWithCoupon}</Text>
                                                                <Text fontWeight='gray' fontSize={13}>MAD</Text>
                                                            </Flex>
                                                        }
                                                    </Td>
                                                    <Td>
                                                        <Menu>
                                                            <MenuButton
                                                                as={IconButton}
                                                                aria-label='Options'
                                                                icon={<HamburgerIcon />}
                                                                variant='outline'
                                                            />
                                                            <MenuList>
                                                                <MenuItem icon={<TbListDetails size={18} />} onClick={() => {
                                                                    navigate('/Detail-Gestion-confirmation', {
                                                                        state: {
                                                                            id: val.id,
                                                                            tel: val.tel
                                                                        }
                                                                    })
                                                                }}>
                                                                    {t("Détails")}
                                                                </MenuItem>
                                                                <MenuItem icon={<RiFileTransferLine size={18} />} onClick={() => {
                                                                    handleCheckboxClick(val.id)
                                                                    onOpen()
                                                                }}>
                                                                    {t("Attribuer")}
                                                                </MenuItem>
                                                                <MenuItem icon={<RiDeleteBinLine size={18} />} onClick={() => {
                                                                    onOpenAlert(val.id)
                                                                }}>
                                                                    {t("Supprimer")}
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                        :
                                        <Tr>
                                            <Td colSpan={9}>
                                                <Text fontSize={16}>{t("Donnée introuvable")}</Text>
                                            </Td>
                                        </Tr>
                                }
                            </>
                        </Tbody>
                    </Table>
                </TableContainer>


                {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}
                <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            paginationData={paginationData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            PAGE_SIZE={pageSize}
                        />
                    </Box>

                    <Select rounded={"md"} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </Select>
                </Flex>
                {/* //////////////////////////////////////////Pagination////////////////////////////////////////// */}


            </Stack>



            {/* Modal to assign order */}
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
                onClose()
                setSelectedRows([])
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Attribuer la commande")}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody pb={6}>
                        <Text>
                            {t("Ici, vous pouvez attribuer une commande spécifique à un responsable de confirmation.")}
                        </Text>

                        <Select
                            mt={3}
                            rounded='lg'
                            size={"sm"}
                            value={userConfirmationSelected}
                            onChange={e => setUserConfirmationSelected(e.target.value)}
                        >
                            <option value=''>{t("Responsable de confirmation")}</option>
                            {userConfirmation.map((val, key) => {
                                return (
                                    <option key={key} value={val.id}>{`${val.first_name} ${val.last_name}`}</option>
                                )
                            })}
                        </Select>

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!userConfirmationSelected} mr={3} onClick={() => assign_order_to(userConfirmationSelected)}>
                            {t("Attribuer")}
                        </Button>
                        <Button onClick={() => {
                            onClose()
                            setSelectedRows([])
                        }} size="sm" >{t("Annuler")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/* Alert to Delete Order */}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={DeleteArrayOfOrders} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>



            {/* Alert to Update confirmation status */}

            <AlertDialog
                isOpen={isModalEditStatusOpen}
                leastDestructiveRef={ModalEditStatusRef}
                onClose={onCloseModalEditStatus}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Statut de confirmation')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {t("Ici, vous pouvez modifier le statut de confirmation.")}
                            </Text>

                            <Select
                                mt={3}
                                rounded='lg'
                                size={"sm"}
                                value={statusConfirmation}
                                onChange={e => setStatusConfirmation(e.target.value)}
                            >
                                <option value=''>{t('Statut de confirmation')}</option>
                                <option value='Pas de réponse'>{t('Pas de réponse')}</option>
                                <option value='Messagerie vocale'>{t('Messagerie vocale')}</option>
                                <option value='En attente'>{t('En attente')}</option>
                                <option value='Ligne occupée'>{t('Ligne occupée')}</option>
                                <option value='Mauvais numéro'>{t('Mauvais numéro')}</option>
                                <option value='Annulé'>{t('Annulé')}</option>
                                <option value='Confirmé'>{t('Confirmé')}</option>
                            </Select>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme='yellow' size="sm" variant='outline' isDisabled={!statusConfirmation} mr={3} onClick={HandleChangeStatus}>
                                {t("Modifier")}
                            </Button>
                            <Button onClick={onCloseModalEditStatus} size="sm" >{t("Annuler")}</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box >
    )
}

export default Confirmation